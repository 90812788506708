<div class="search-bar">
  <img src="assets/icons/search-outline.svg" />
  <input
    [type]="type"
    [name]="name!"
    [disabled]="disabled"
    [placeholder]="placeholder || 'Suchen...'"
    [ngModel]="inputText"
    [autocomplete]="autocomplete"
    (ngModelChange)="onInputChange($event)"
    (focus)="hasFocus.next(true)"
    (focusout)="hasFocus.next(false)"
    #input
  />
</div>
