import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { MehrarbeitUmbuchungDto } from '../../api/models';

export const MehrarbeitUmbuchungenActions = createActionGroup({
  source: 'MehrarbeitUmbuchungen',
  events: {
    'Load Mehrarbeitumbuchungen': emptyProps(),
    'Load Mehrarbeitumbuchungen Success': props<{
      data: MehrarbeitUmbuchungDto[];
      totalCount: number;
    }>(),
    'Load Mehrarbeitumbuchungen Failure': props<{ error: unknown }>(),
    'Create Mehrarbeitumbuchung': props<{ umbuchungInSekunden: number, umbuchungDatum: string }>(),
    'Create Mehrarbeitumbuchung Success': props<{ data: MehrarbeitUmbuchungDto }>(),
    'Create Mehrarbeitumbuchung Failure': props<{ error: unknown }>(),
    'Set Page Size': props<{ pageSize: number }>(),
    'Set Page Index': props<{ pageIndex: number }>(),
    'Set Selected Month And Year': props<{
      selectedMonth: number;
      selectedYear: number;
    }>(),
  },
});
