/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { createOneWochenplan$Json } from '../fn/wochenplan/create-one-wochenplan-json';
import { CreateOneWochenplan$Json$Params } from '../fn/wochenplan/create-one-wochenplan-json';
import { createOneWochenplan$Plain } from '../fn/wochenplan/create-one-wochenplan-plain';
import { CreateOneWochenplan$Plain$Params } from '../fn/wochenplan/create-one-wochenplan-plain';
import { createWochenplanZuordnungForMitarbeiter$Json } from '../fn/wochenplan/create-wochenplan-zuordnung-for-mitarbeiter-json';
import { CreateWochenplanZuordnungForMitarbeiter$Json$Params } from '../fn/wochenplan/create-wochenplan-zuordnung-for-mitarbeiter-json';
import { createWochenplanZuordnungForMitarbeiter$Plain } from '../fn/wochenplan/create-wochenplan-zuordnung-for-mitarbeiter-plain';
import { CreateWochenplanZuordnungForMitarbeiter$Plain$Params } from '../fn/wochenplan/create-wochenplan-zuordnung-for-mitarbeiter-plain';
import { getWochenplaene$Json } from '../fn/wochenplan/get-wochenplaene-json';
import { GetWochenplaene$Json$Params } from '../fn/wochenplan/get-wochenplaene-json';
import { getWochenplaene$Plain } from '../fn/wochenplan/get-wochenplaene-plain';
import { GetWochenplaene$Plain$Params } from '../fn/wochenplan/get-wochenplaene-plain';
import { getWochenplanById$Json } from '../fn/wochenplan/get-wochenplan-by-id-json';
import { GetWochenplanById$Json$Params } from '../fn/wochenplan/get-wochenplan-by-id-json';
import { getWochenplanById$Plain } from '../fn/wochenplan/get-wochenplan-by-id-plain';
import { GetWochenplanById$Plain$Params } from '../fn/wochenplan/get-wochenplan-by-id-plain';
import { KalendereintragDto } from '../models/kalendereintrag-dto';
import { setWochenplanInactive } from '../fn/wochenplan/set-wochenplan-inactive';
import { SetWochenplanInactive$Params } from '../fn/wochenplan/set-wochenplan-inactive';
import { WochenplanDto } from '../models/wochenplan-dto';

@Injectable({ providedIn: 'root' })
export class WochenplanApiService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getWochenplaene()` */
  static readonly GetWochenplaenePath = '/v1/wochenplaene';

  /**
   * Holt alle Wochenpläne, optional mit SoftDeleted Wochenplaenen.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getWochenplaene$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getWochenplaene$Plain$Response(params?: GetWochenplaene$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<WochenplanDto>>> {
    return getWochenplaene$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Holt alle Wochenpläne, optional mit SoftDeleted Wochenplaenen.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getWochenplaene$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getWochenplaene$Plain(params?: GetWochenplaene$Plain$Params, context?: HttpContext): Observable<Array<WochenplanDto>> {
    return this.getWochenplaene$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<WochenplanDto>>): Array<WochenplanDto> => r.body)
    );
  }

  /**
   * Holt alle Wochenpläne, optional mit SoftDeleted Wochenplaenen.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getWochenplaene$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getWochenplaene$Json$Response(params?: GetWochenplaene$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<WochenplanDto>>> {
    return getWochenplaene$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Holt alle Wochenpläne, optional mit SoftDeleted Wochenplaenen.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getWochenplaene$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getWochenplaene$Json(params?: GetWochenplaene$Json$Params, context?: HttpContext): Observable<Array<WochenplanDto>> {
    return this.getWochenplaene$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<WochenplanDto>>): Array<WochenplanDto> => r.body)
    );
  }

  /** Path part for operation `createOneWochenplan()` */
  static readonly CreateOneWochenplanPath = '/v1/wochenplaene';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createOneWochenplan$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createOneWochenplan$Plain$Response(params?: CreateOneWochenplan$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<WochenplanDto>> {
    return createOneWochenplan$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createOneWochenplan$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createOneWochenplan$Plain(params?: CreateOneWochenplan$Plain$Params, context?: HttpContext): Observable<WochenplanDto> {
    return this.createOneWochenplan$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<WochenplanDto>): WochenplanDto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createOneWochenplan$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createOneWochenplan$Json$Response(params?: CreateOneWochenplan$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<WochenplanDto>> {
    return createOneWochenplan$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createOneWochenplan$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createOneWochenplan$Json(params?: CreateOneWochenplan$Json$Params, context?: HttpContext): Observable<WochenplanDto> {
    return this.createOneWochenplan$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<WochenplanDto>): WochenplanDto => r.body)
    );
  }

  /** Path part for operation `getWochenplanById()` */
  static readonly GetWochenplanByIdPath = '/v1/wochenplaene/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getWochenplanById$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getWochenplanById$Plain$Response(params: GetWochenplanById$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<WochenplanDto>> {
    return getWochenplanById$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getWochenplanById$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getWochenplanById$Plain(params: GetWochenplanById$Plain$Params, context?: HttpContext): Observable<WochenplanDto> {
    return this.getWochenplanById$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<WochenplanDto>): WochenplanDto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getWochenplanById$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getWochenplanById$Json$Response(params: GetWochenplanById$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<WochenplanDto>> {
    return getWochenplanById$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getWochenplanById$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getWochenplanById$Json(params: GetWochenplanById$Json$Params, context?: HttpContext): Observable<WochenplanDto> {
    return this.getWochenplanById$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<WochenplanDto>): WochenplanDto => r.body)
    );
  }

  /** Path part for operation `createWochenplanZuordnungForMitarbeiter()` */
  static readonly CreateWochenplanZuordnungForMitarbeiterPath = '/v1/mitarbeiter/{mitarbeiterId}/wochenplaene';

  /**
   * Erstellt eine Wochenplan Zuordnung für einen Mitarbeiter.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createWochenplanZuordnungForMitarbeiter$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createWochenplanZuordnungForMitarbeiter$Plain$Response(params: CreateWochenplanZuordnungForMitarbeiter$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<KalendereintragDto>>> {
    return createWochenplanZuordnungForMitarbeiter$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Erstellt eine Wochenplan Zuordnung für einen Mitarbeiter.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createWochenplanZuordnungForMitarbeiter$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createWochenplanZuordnungForMitarbeiter$Plain(params: CreateWochenplanZuordnungForMitarbeiter$Plain$Params, context?: HttpContext): Observable<Array<KalendereintragDto>> {
    return this.createWochenplanZuordnungForMitarbeiter$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<KalendereintragDto>>): Array<KalendereintragDto> => r.body)
    );
  }

  /**
   * Erstellt eine Wochenplan Zuordnung für einen Mitarbeiter.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createWochenplanZuordnungForMitarbeiter$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createWochenplanZuordnungForMitarbeiter$Json$Response(params: CreateWochenplanZuordnungForMitarbeiter$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<KalendereintragDto>>> {
    return createWochenplanZuordnungForMitarbeiter$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Erstellt eine Wochenplan Zuordnung für einen Mitarbeiter.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createWochenplanZuordnungForMitarbeiter$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createWochenplanZuordnungForMitarbeiter$Json(params: CreateWochenplanZuordnungForMitarbeiter$Json$Params, context?: HttpContext): Observable<Array<KalendereintragDto>> {
    return this.createWochenplanZuordnungForMitarbeiter$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<KalendereintragDto>>): Array<KalendereintragDto> => r.body)
    );
  }

  /** Path part for operation `setWochenplanInactive()` */
  static readonly SetWochenplanInactivePath = '/v1/wochenplaene/{wochenplanid}';

  /**
   * Setzt einen Wochenplan inaktiv (Soft Delete).
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `setWochenplanInactive()` instead.
   *
   * This method doesn't expect any request body.
   */
  setWochenplanInactive$Response(params: SetWochenplanInactive$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return setWochenplanInactive(this.http, this.rootUrl, params, context);
  }

  /**
   * Setzt einen Wochenplan inaktiv (Soft Delete).
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `setWochenplanInactive$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  setWochenplanInactive(params: SetWochenplanInactive$Params, context?: HttpContext): Observable<void> {
    return this.setWochenplanInactive$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
