import { createActionGroup, emptyProps, props } from '@ngrx/store';
import {
  CreateBuchungDto,
  CreateBuchungForTimeframeDto,
  UpdateBuchungDto,
} from '../../api/models';
import { BuchungDto } from '../../api/models/buchung-dto';

export const BuchungenActions = createActionGroup({
  source: 'Buchungen',
  events: {
    'Load Buchungen': emptyProps(),
    'Load Buchungen Success': props<{
      data: BuchungDto[];
      totalCount: number;
    }>(),
    'Load Buchungen For Timeframe': props<{
      start: string;
      end: string;
      mitarbeiterId: string;
    }>(),
    'Load Buchungen For Timeframe Success': props<{
      buchungen: BuchungDto[];
    }>(),
    'Load Buchungen For Timeframe Failure': props<{ error: unknown }>(),
    'Load Buchungen Failure': props<{ error: unknown }>(),
    'Load Buchungen Fuer Tag': props<{ date: Date }>(),
    'Load Buchungen Fuer Tag Success': props<{ buchungen?: BuchungDto[] }>(),
    'Load Buchungen Fuer Tag Failure': props<{ error: unknown }>(),
    'Create Single Buchung': props<{ data: CreateBuchungDto }>(),
    'Create Single Buchung Success': props<{ data: BuchungDto }>(),
    'Create Single Buchung Failure': props<{ error: unknown }>(),
    'Create Buchung For Timeframe': props<{
      data: CreateBuchungForTimeframeDto;
    }>(),
    'Create Buchung For Timeframe Success': props<{ data: BuchungDto[] }>(),
    'Create Buchung For Timeframe Failure': props<{ error: unknown }>(),
    'Create Buchung For Timeframe And Tarif Attribut': props<{
      tarifAttribut: string;
      data: CreateBuchungForTimeframeDto;
    }>(),
    'Create Buchung For Timeframe And Tarif Attribut Success': props<{
      data: BuchungDto[];
    }>(),
    'Create Buchung For Timeframe And Tarif Attribut Failure': props<{
      error: unknown;
    }>(),
    'Delete Buchung': props<{ id: string }>(),
    'Delete Buchung Success': props<{ id: string }>(),
    'Delete Buchung Failure': props<{ error: unknown }>(),
    'Update Buchung': props<{ id: string; data: UpdateBuchungDto }>(),
    'Update Buchung Success': props<{ data: BuchungDto }>(),
    'Update Buchung Failure': props<{ error: unknown }>(),
    'Set Page Size': props<{ pageSize: number }>(),
    'Set Page Index': props<{ pageIndex: number }>(),
    'Set Selected Month And Year': props<{
      selectedMonth: number;
      selectedYear: number;
    }>(),
    'Set Include Deleted': props<{ includeDeleted: boolean }>(),
  },
});
