/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { createKalendereintraegeFuerMitarbeiterUndZeitraum$Json } from '../fn/kalendereintrag/create-kalendereintraege-fuer-mitarbeiter-und-zeitraum-json';
import { CreateKalendereintraegeFuerMitarbeiterUndZeitraum$Json$Params } from '../fn/kalendereintrag/create-kalendereintraege-fuer-mitarbeiter-und-zeitraum-json';
import { createKalendereintraegeFuerMitarbeiterUndZeitraum$Plain } from '../fn/kalendereintrag/create-kalendereintraege-fuer-mitarbeiter-und-zeitraum-plain';
import { CreateKalendereintraegeFuerMitarbeiterUndZeitraum$Plain$Params } from '../fn/kalendereintrag/create-kalendereintraege-fuer-mitarbeiter-und-zeitraum-plain';
import { createKalendereintragForMitarbeiter$Json } from '../fn/kalendereintrag/create-kalendereintrag-for-mitarbeiter-json';
import { CreateKalendereintragForMitarbeiter$Json$Params } from '../fn/kalendereintrag/create-kalendereintrag-for-mitarbeiter-json';
import { createKalendereintragForMitarbeiter$Plain } from '../fn/kalendereintrag/create-kalendereintrag-for-mitarbeiter-plain';
import { CreateKalendereintragForMitarbeiter$Plain$Params } from '../fn/kalendereintrag/create-kalendereintrag-for-mitarbeiter-plain';
import { deleteKalendereintraegeForMitarbeiter } from '../fn/kalendereintrag/delete-kalendereintraege-for-mitarbeiter';
import { DeleteKalendereintraegeForMitarbeiter$Params } from '../fn/kalendereintrag/delete-kalendereintraege-for-mitarbeiter';
import { deleteOneKalendereintrag } from '../fn/kalendereintrag/delete-one-kalendereintrag';
import { DeleteOneKalendereintrag$Params } from '../fn/kalendereintrag/delete-one-kalendereintrag';
import { getKalendereintraegeForMitarbeiter$Json } from '../fn/kalendereintrag/get-kalendereintraege-for-mitarbeiter-json';
import { GetKalendereintraegeForMitarbeiter$Json$Params } from '../fn/kalendereintrag/get-kalendereintraege-for-mitarbeiter-json';
import { getKalendereintraegeForMitarbeiter$Plain } from '../fn/kalendereintrag/get-kalendereintraege-for-mitarbeiter-plain';
import { GetKalendereintraegeForMitarbeiter$Plain$Params } from '../fn/kalendereintrag/get-kalendereintraege-for-mitarbeiter-plain';
import { KalendereintragDto } from '../models/kalendereintrag-dto';

@Injectable({ providedIn: 'root' })
export class KalendereintragApiService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `deleteOneKalendereintrag()` */
  static readonly DeleteOneKalendereintragPath = '/v1/kalendereintraege/{kalendereintragId}';

  /**
   * Löscht den Kalendereintrag mit der angegebenen ID.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteOneKalendereintrag()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteOneKalendereintrag$Response(params: DeleteOneKalendereintrag$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteOneKalendereintrag(this.http, this.rootUrl, params, context);
  }

  /**
   * Löscht den Kalendereintrag mit der angegebenen ID.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteOneKalendereintrag$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteOneKalendereintrag(params: DeleteOneKalendereintrag$Params, context?: HttpContext): Observable<void> {
    return this.deleteOneKalendereintrag$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getKalendereintraegeForMitarbeiter()` */
  static readonly GetKalendereintraegeForMitarbeiterPath = '/v1/mitarbeiter/{mitarbeiterId}/kalendereintraege';

  /**
   * Gibt Kalendereinträge für einen Mitarbeiter und Zeitraum zurück.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getKalendereintraegeForMitarbeiter$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getKalendereintraegeForMitarbeiter$Plain$Response(params: GetKalendereintraegeForMitarbeiter$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<KalendereintragDto>>> {
    return getKalendereintraegeForMitarbeiter$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Gibt Kalendereinträge für einen Mitarbeiter und Zeitraum zurück.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getKalendereintraegeForMitarbeiter$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getKalendereintraegeForMitarbeiter$Plain(params: GetKalendereintraegeForMitarbeiter$Plain$Params, context?: HttpContext): Observable<Array<KalendereintragDto>> {
    return this.getKalendereintraegeForMitarbeiter$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<KalendereintragDto>>): Array<KalendereintragDto> => r.body)
    );
  }

  /**
   * Gibt Kalendereinträge für einen Mitarbeiter und Zeitraum zurück.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getKalendereintraegeForMitarbeiter$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getKalendereintraegeForMitarbeiter$Json$Response(params: GetKalendereintraegeForMitarbeiter$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<KalendereintragDto>>> {
    return getKalendereintraegeForMitarbeiter$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Gibt Kalendereinträge für einen Mitarbeiter und Zeitraum zurück.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getKalendereintraegeForMitarbeiter$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getKalendereintraegeForMitarbeiter$Json(params: GetKalendereintraegeForMitarbeiter$Json$Params, context?: HttpContext): Observable<Array<KalendereintragDto>> {
    return this.getKalendereintraegeForMitarbeiter$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<KalendereintragDto>>): Array<KalendereintragDto> => r.body)
    );
  }

  /** Path part for operation `deleteKalendereintraegeForMitarbeiter()` */
  static readonly DeleteKalendereintraegeForMitarbeiterPath = '/v1/mitarbeiter/{mitarbeiterId}/kalendereintraege';

  /**
   * Löscht alle Kalendereinträge für einen Mitarbeiter in dem angegebenen Zeitraum.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteKalendereintraegeForMitarbeiter()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteKalendereintraegeForMitarbeiter$Response(params: DeleteKalendereintraegeForMitarbeiter$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteKalendereintraegeForMitarbeiter(this.http, this.rootUrl, params, context);
  }

  /**
   * Löscht alle Kalendereinträge für einen Mitarbeiter in dem angegebenen Zeitraum.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteKalendereintraegeForMitarbeiter$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteKalendereintraegeForMitarbeiter(params: DeleteKalendereintraegeForMitarbeiter$Params, context?: HttpContext): Observable<void> {
    return this.deleteKalendereintraegeForMitarbeiter$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `createKalendereintragForMitarbeiter()` */
  static readonly CreateKalendereintragForMitarbeiterPath = '/v1/mitarbeiter{mitarbeiterId}/kalendereintraege';

  /**
   * Erstellt einen Kalendereintrag für den Mitarbeiter.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createKalendereintragForMitarbeiter$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createKalendereintragForMitarbeiter$Plain$Response(params: CreateKalendereintragForMitarbeiter$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<KalendereintragDto>> {
    return createKalendereintragForMitarbeiter$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Erstellt einen Kalendereintrag für den Mitarbeiter.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createKalendereintragForMitarbeiter$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createKalendereintragForMitarbeiter$Plain(params: CreateKalendereintragForMitarbeiter$Plain$Params, context?: HttpContext): Observable<KalendereintragDto> {
    return this.createKalendereintragForMitarbeiter$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<KalendereintragDto>): KalendereintragDto => r.body)
    );
  }

  /**
   * Erstellt einen Kalendereintrag für den Mitarbeiter.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createKalendereintragForMitarbeiter$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createKalendereintragForMitarbeiter$Json$Response(params: CreateKalendereintragForMitarbeiter$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<KalendereintragDto>> {
    return createKalendereintragForMitarbeiter$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Erstellt einen Kalendereintrag für den Mitarbeiter.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createKalendereintragForMitarbeiter$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createKalendereintragForMitarbeiter$Json(params: CreateKalendereintragForMitarbeiter$Json$Params, context?: HttpContext): Observable<KalendereintragDto> {
    return this.createKalendereintragForMitarbeiter$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<KalendereintragDto>): KalendereintragDto => r.body)
    );
  }

  /** Path part for operation `createKalendereintraegeFuerMitarbeiterUndZeitraum()` */
  static readonly CreateKalendereintraegeFuerMitarbeiterUndZeitraumPath = '/v1/mitarbeiter{mitarbeiterId}/kalendereintraegeFuerZeitraum';

  /**
   * Erstellt Kalendereintraege für den Mitarbeiter für den angegebenen Zeitraum.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createKalendereintraegeFuerMitarbeiterUndZeitraum$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createKalendereintraegeFuerMitarbeiterUndZeitraum$Plain$Response(params: CreateKalendereintraegeFuerMitarbeiterUndZeitraum$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<KalendereintragDto>>> {
    return createKalendereintraegeFuerMitarbeiterUndZeitraum$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Erstellt Kalendereintraege für den Mitarbeiter für den angegebenen Zeitraum.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createKalendereintraegeFuerMitarbeiterUndZeitraum$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createKalendereintraegeFuerMitarbeiterUndZeitraum$Plain(params: CreateKalendereintraegeFuerMitarbeiterUndZeitraum$Plain$Params, context?: HttpContext): Observable<Array<KalendereintragDto>> {
    return this.createKalendereintraegeFuerMitarbeiterUndZeitraum$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<KalendereintragDto>>): Array<KalendereintragDto> => r.body)
    );
  }

  /**
   * Erstellt Kalendereintraege für den Mitarbeiter für den angegebenen Zeitraum.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createKalendereintraegeFuerMitarbeiterUndZeitraum$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createKalendereintraegeFuerMitarbeiterUndZeitraum$Json$Response(params: CreateKalendereintraegeFuerMitarbeiterUndZeitraum$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<KalendereintragDto>>> {
    return createKalendereintraegeFuerMitarbeiterUndZeitraum$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Erstellt Kalendereintraege für den Mitarbeiter für den angegebenen Zeitraum.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createKalendereintraegeFuerMitarbeiterUndZeitraum$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createKalendereintraegeFuerMitarbeiterUndZeitraum$Json(params: CreateKalendereintraegeFuerMitarbeiterUndZeitraum$Json$Params, context?: HttpContext): Observable<Array<KalendereintragDto>> {
    return this.createKalendereintraegeFuerMitarbeiterUndZeitraum$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<KalendereintragDto>>): Array<KalendereintragDto> => r.body)
    );
  }

}
