import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { DlSelectOptionClickEvent } from '../select-option-click-event';

@Component({
  selector: 'dl-select-option',
  templateUrl: './select-option.component.html',
  styleUrls: ['./select-option.component.css'],
})
export class DlSelectOptionComponent implements OnInit, AfterViewInit {
  @Input() value: unknown | undefined;
  @Output() clicked = new EventEmitter<DlSelectOptionClickEvent<unknown>>();
  selectedText = new BehaviorSubject('');
  selectedHtml = new BehaviorSubject('');

  private _selectedSubject = new BehaviorSubject<boolean>(false);

  set isSelected(isSelected: boolean) {
    this._selectedSubject.next(isSelected);
  }

  get isSelected(): boolean {
    return this._selectedSubject.getValue();
  }

  get elementRef(): ElementRef {
    return this._elr;
  }

  constructor(private _elr: ElementRef) {}

  ngOnInit(): void {
    // null accepted
    if (this.value === undefined) {
      throw new Error('Value is undefined on dl-select-option');
    }
  }

  ngAfterViewInit() {
    this._getSelectedText();
    this._listenToSelectChanges();
  }

  /**
   * Gets the textContent of the html host element and sets it as the selected Text
   */
  private _getSelectedText(): void {
    this.selectedText.next(
      this._getHostElement().textContent || (this.value as string)
    )
    this.selectedHtml.next(
      this._getHostElement().innerHTML
    )
  }

  /**
   * Gets the HTML host element
   */
  private _getHostElement(): HTMLElement {
    return this._elr.nativeElement as HTMLElement;
  }

  @HostListener('click')
  onClick(): void {
    this.clicked.emit({
      elementRef: this._elr,
      value: this.value,
    });
  }

  /**
   *
   */
  private _listenToSelectChanges(): void {
    this._selectedSubject.subscribe((newState: boolean) => {
      if (newState === true) {
        this._getHostElement().classList.add('selected');
      } else {
        this._getHostElement().classList.remove('selected');
      }
    });
  }
}
