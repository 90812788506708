import { Component, forwardRef, Renderer2 } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { DlBaseInput } from '@datenlotse/components/input';

@Component({
  selector: 'dl-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => DlSearchBarComponent),
    },
  ],
})
export class DlSearchBarComponent extends DlBaseInput {
  constructor(_renderer: Renderer2) {
    super(_renderer);
  }
}
