/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { getMehrarbeitsverbuchungForMitarbeiter } from '../fn/auswertung/get-mehrarbeitsverbuchung-for-mitarbeiter';
import { GetMehrarbeitsverbuchungForMitarbeiter$Params } from '../fn/auswertung/get-mehrarbeitsverbuchung-for-mitarbeiter';
import { getMonatsauswertungForMitarbeiter } from '../fn/auswertung/get-monatsauswertung-for-mitarbeiter';
import { GetMonatsauswertungForMitarbeiter$Params } from '../fn/auswertung/get-monatsauswertung-for-mitarbeiter';
import { getMonatsauswertungForMitarbeiterCsv } from '../fn/auswertung/get-monatsauswertung-for-mitarbeiter-csv';
import { GetMonatsauswertungForMitarbeiterCsv$Params } from '../fn/auswertung/get-monatsauswertung-for-mitarbeiter-csv';
import { sendMonatsauswertungForAllMitarbeiter } from '../fn/auswertung/send-monatsauswertung-for-all-mitarbeiter';
import { SendMonatsauswertungForAllMitarbeiter$Params } from '../fn/auswertung/send-monatsauswertung-for-all-mitarbeiter';
import { sendMonatsauswertungForMitarbeiter } from '../fn/auswertung/send-monatsauswertung-for-mitarbeiter';
import { SendMonatsauswertungForMitarbeiter$Params } from '../fn/auswertung/send-monatsauswertung-for-mitarbeiter';

@Injectable({ providedIn: 'root' })
export class AuswertungApiService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `sendMonatsauswertungForMitarbeiter()` */
  static readonly SendMonatsauswertungForMitarbeiterPath = '/v1/mitarbeiter/{mitarbeiterId}/monatsauswertungen/mail';

  /**
   * Erstellt eine Monatsauswertung für den angegebenen Mitarbeiter und versendet diese an seine Private Email.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sendMonatsauswertungForMitarbeiter()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  sendMonatsauswertungForMitarbeiter$Response(params: SendMonatsauswertungForMitarbeiter$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return sendMonatsauswertungForMitarbeiter(this.http, this.rootUrl, params, context);
  }

  /**
   * Erstellt eine Monatsauswertung für den angegebenen Mitarbeiter und versendet diese an seine Private Email.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sendMonatsauswertungForMitarbeiter$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  sendMonatsauswertungForMitarbeiter(params: SendMonatsauswertungForMitarbeiter$Params, context?: HttpContext): Observable<void> {
    return this.sendMonatsauswertungForMitarbeiter$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getMonatsauswertungForMitarbeiter()` */
  static readonly GetMonatsauswertungForMitarbeiterPath = '/v1/mitarbeiter/{mitarbeiterId}/monatsauswertung/{monat}';

  /**
   * Erstellt eine Monatsauswertung für den angegebenen Mitarbeiter.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMonatsauswertungForMitarbeiter()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMonatsauswertungForMitarbeiter$Response(params: GetMonatsauswertungForMitarbeiter$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return getMonatsauswertungForMitarbeiter(this.http, this.rootUrl, params, context);
  }

  /**
   * Erstellt eine Monatsauswertung für den angegebenen Mitarbeiter.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMonatsauswertungForMitarbeiter$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMonatsauswertungForMitarbeiter(params: GetMonatsauswertungForMitarbeiter$Params, context?: HttpContext): Observable<void> {
    return this.getMonatsauswertungForMitarbeiter$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getMonatsauswertungForMitarbeiterCsv()` */
  static readonly GetMonatsauswertungForMitarbeiterCsvPath = '/v1/mitarbeiter/{mitarbeiterId}/monatsauswertung/{monat}/csv';

  /**
   * Erstellt eine Monatsauswertung für den Mitarbeiter mit dem angegeben Layout als CSV Datei.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMonatsauswertungForMitarbeiterCsv()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMonatsauswertungForMitarbeiterCsv$Response(params: GetMonatsauswertungForMitarbeiterCsv$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return getMonatsauswertungForMitarbeiterCsv(this.http, this.rootUrl, params, context);
  }

  /**
   * Erstellt eine Monatsauswertung für den Mitarbeiter mit dem angegeben Layout als CSV Datei.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMonatsauswertungForMitarbeiterCsv$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMonatsauswertungForMitarbeiterCsv(params: GetMonatsauswertungForMitarbeiterCsv$Params, context?: HttpContext): Observable<void> {
    return this.getMonatsauswertungForMitarbeiterCsv$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `sendMonatsauswertungForAllMitarbeiter()` */
  static readonly SendMonatsauswertungForAllMitarbeiterPath = '/v1/monatsauswertungen/mail/all';

  /**
   * Erstellt eine Monatsauswertung für alle Mitarbeiter und versendet diese an ihre Private Email.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sendMonatsauswertungForAllMitarbeiter()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  sendMonatsauswertungForAllMitarbeiter$Response(params?: SendMonatsauswertungForAllMitarbeiter$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return sendMonatsauswertungForAllMitarbeiter(this.http, this.rootUrl, params, context);
  }

  /**
   * Erstellt eine Monatsauswertung für alle Mitarbeiter und versendet diese an ihre Private Email.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sendMonatsauswertungForAllMitarbeiter$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  sendMonatsauswertungForAllMitarbeiter(params?: SendMonatsauswertungForAllMitarbeiter$Params, context?: HttpContext): Observable<void> {
    return this.sendMonatsauswertungForAllMitarbeiter$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getMehrarbeitsverbuchungForMitarbeiter()` */
  static readonly GetMehrarbeitsverbuchungForMitarbeiterPath = '/v1/mitarbeiter/{mitarbeiterId}/mehrarbeitsverbuchung/{monat}';

  /**
   * Erstellt eine Mehrarbeitsverbuchung für den angegebenen Mitarbeiter.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMehrarbeitsverbuchungForMitarbeiter()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMehrarbeitsverbuchungForMitarbeiter$Response(params: GetMehrarbeitsverbuchungForMitarbeiter$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return getMehrarbeitsverbuchungForMitarbeiter(this.http, this.rootUrl, params, context);
  }

  /**
   * Erstellt eine Mehrarbeitsverbuchung für den angegebenen Mitarbeiter.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMehrarbeitsverbuchungForMitarbeiter$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMehrarbeitsverbuchungForMitarbeiter(params: GetMehrarbeitsverbuchungForMitarbeiter$Params, context?: HttpContext): Observable<void> {
    return this.getMehrarbeitsverbuchungForMitarbeiter$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
