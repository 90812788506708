import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromAuswertungLayouts from './auswertung-layouts.reducer';

export const selectAuswertungLayoutsState =
  createFeatureSelector<fromAuswertungLayouts.State>(
    fromAuswertungLayouts.auswertungLayoutsFeatureKey
  );

export const selectMonatsauswertungLayouts = createSelector(
  selectAuswertungLayoutsState,
  (state) => state.monatsauswertungLayouts
);

export const selectMonatsauswertungLayoutsLoading = createSelector(
  selectAuswertungLayoutsState,
  (state) => state.monatsauswertungLayouts === undefined
);

export const selectMonatsauswertungLayoutsSpalten = createSelector(
  selectAuswertungLayoutsState,
  (state) => state.monatsauswertungSpalten
);

export const selectMonatsauswertungLayoutsSpaltenLoading = createSelector(
  selectAuswertungLayoutsState,
  (state) => state.monatsauswertungSpalten === undefined
);
