import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';

interface BadRequestResponse {
  errors: Record<string, string[]>;
}

export const formatBadRequest = (response: HttpErrorResponse) => {
  if (response.status !== HttpStatusCode.BadRequest) {
    throw new Error(
      `Request does not match Status code: expected 400, got ${response.status}`
    );
  }

  const error: BadRequestResponse = response.error;
  let formatedText = '';
  for (const key in error.errors) {
    if (Object.prototype.hasOwnProperty.call(error.errors, key)) {
      const element = error.errors[key];
      formatedText += element + '\n';
    }
  }
  formatedText = formatedText.slice(0, -1);
  if (formatedText === '') {
    formatedText =
      'Ein unbekannter Fehler ist aufgetreten. Die Anfrage konnte nicht verarbeitet werden.';
  }
  return formatedText;
};
