import {
  AfterContentInit,
  Component,
  ContentChild,
  OnDestroy,
} from '@angular/core';
import { DlInputComponent } from '@datenlotse/components/input';
import { DlSelectComponent } from '@datenlotse/components/select';
import { DlInput } from '@datenlotse/components/shared';
import { DlTextareaComponent } from '@datenlotse/components/textarea';
import { Subscription } from 'rxjs';
import { DlFormFieldLabelComponent } from '../form-field-label/form-field-label.component';

@Component({
  selector: 'dl-form-field',
  templateUrl: './form-field.component.html',
  styleUrls: ['./form-field.component.css'],
})
export class DlFormFieldComponent implements AfterContentInit, OnDestroy {
  /**
   * Get the Content Childs (Input, Select, Textarea, Label)
   */
  @ContentChild(DlInputComponent) input: DlInputComponent | undefined;
  @ContentChild(DlSelectComponent) select: DlSelectComponent | undefined;
  @ContentChild(DlTextareaComponent) textarea: DlTextareaComponent | undefined;
  @ContentChild(DlFormFieldLabelComponent) label:
    | DlFormFieldLabelComponent
    | undefined;

  /**
   * Returns the current active control or undefined
   */
  get _control(): DlInput | undefined {
    return this.input || this.select || this.textarea;
  }

  private _labelUpSub: Subscription | undefined;
  private _focusSub: Subscription | undefined;

  /**
   * Cleanup the subsriptions
   */
  ngOnDestroy(): void {
    this._labelUpSub?.unsubscribe();
    this._focusSub?.unsubscribe();
  }

  /**
   * Sub to various events that handle input states
   */
  ngAfterContentInit(): void {
    if (!this.label) {
      return;
    }
    this._labelUpSub = this._control?.labelUp.subscribe((isMovedUp) => {
      if (!this.label) {
        return;
      }
      this.label.movedUp = isMovedUp;
    });
    this._focusSub = this._control?.hasFocus.subscribe((focus) => {
      if (!this.label) {
        return;
      }
      this.label.hasFocus = focus;
    });
  }
}
