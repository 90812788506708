import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'dl-spinner',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class DlSpinner {
  @Input() mode: 'dark' | 'light' = 'dark';
}
