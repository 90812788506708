<div>
  <h3 class="text-lg">Aktive Spalten</h3>
  <div
    #activeColumnsList="cdkDropList"
    (cdkDropListDropped)="onDrop($event, leftColumnItems, rightColumnItems)"
    [cdkDropListConnectedTo]="[rightColumnList]"
    [cdkDropListData]="leftColumnItems()"
    cdkDropList
    class="dnd-list"
  >

    <div *ngFor="let item of leftColumnItems(); let i = index" cdkDrag class="dnd-box">
      <ng-container [ngTemplateOutletContext]="{ $implicit: item, index: i }"
                    [ngTemplateOutlet]="itemTemplate"></ng-container>
    </div>
  </div>
</div>


<div>
  <h3 class="text-lg">Inaktive Spalten</h3>
  <div
    #rightColumnList="cdkDropList"
    (cdkDropListDropped)="onDrop($event, rightColumnItems, leftColumnItems)"
    [cdkDropListConnectedTo]="[activeColumnsList]"
    [cdkDropListData]="rightColumnItems()"
    cdkDropList
    class="dnd-list"
  >
    <div *ngFor="let item of rightColumnItems(); let i = index" cdkDrag class="dnd-box">
      <ng-container [ngTemplateOutletContext]="{ $implicit: item, index: i }"
                    [ngTemplateOutlet]="itemTemplate"></ng-container>
    </div>
  </div>
</div>
