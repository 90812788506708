/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { createTagesplan$Json } from '../fn/tagesplan/create-tagesplan-json';
import { CreateTagesplan$Json$Params } from '../fn/tagesplan/create-tagesplan-json';
import { createTagesplan$Plain } from '../fn/tagesplan/create-tagesplan-plain';
import { CreateTagesplan$Plain$Params } from '../fn/tagesplan/create-tagesplan-plain';
import { deleteTagesplan } from '../fn/tagesplan/delete-tagesplan';
import { DeleteTagesplan$Params } from '../fn/tagesplan/delete-tagesplan';
import { getTagesplaene$Json } from '../fn/tagesplan/get-tagesplaene-json';
import { GetTagesplaene$Json$Params } from '../fn/tagesplan/get-tagesplaene-json';
import { getTagesplaene$Plain } from '../fn/tagesplan/get-tagesplaene-plain';
import { GetTagesplaene$Plain$Params } from '../fn/tagesplan/get-tagesplaene-plain';
import { getTagesplan$Json } from '../fn/tagesplan/get-tagesplan-json';
import { GetTagesplan$Json$Params } from '../fn/tagesplan/get-tagesplan-json';
import { getTagesplan$Plain } from '../fn/tagesplan/get-tagesplan-plain';
import { GetTagesplan$Plain$Params } from '../fn/tagesplan/get-tagesplan-plain';
import { TagesplanDto } from '../models/tagesplan-dto';
import { updateTagesplan } from '../fn/tagesplan/update-tagesplan';
import { UpdateTagesplan$Params } from '../fn/tagesplan/update-tagesplan';

@Injectable({ providedIn: 'root' })
export class TagesplanApiService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getTagesplaene()` */
  static readonly GetTagesplaenePath = '/v1/tagesplaene';

  /**
   * Holt alle Tagespläne, optional mit SoftDeleted Tagesplaenen.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTagesplaene$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTagesplaene$Plain$Response(params?: GetTagesplaene$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<TagesplanDto>>> {
    return getTagesplaene$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Holt alle Tagespläne, optional mit SoftDeleted Tagesplaenen.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTagesplaene$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTagesplaene$Plain(params?: GetTagesplaene$Plain$Params, context?: HttpContext): Observable<Array<TagesplanDto>> {
    return this.getTagesplaene$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<TagesplanDto>>): Array<TagesplanDto> => r.body)
    );
  }

  /**
   * Holt alle Tagespläne, optional mit SoftDeleted Tagesplaenen.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTagesplaene$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTagesplaene$Json$Response(params?: GetTagesplaene$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<TagesplanDto>>> {
    return getTagesplaene$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Holt alle Tagespläne, optional mit SoftDeleted Tagesplaenen.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTagesplaene$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTagesplaene$Json(params?: GetTagesplaene$Json$Params, context?: HttpContext): Observable<Array<TagesplanDto>> {
    return this.getTagesplaene$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<TagesplanDto>>): Array<TagesplanDto> => r.body)
    );
  }

  /** Path part for operation `createTagesplan()` */
  static readonly CreateTagesplanPath = '/v1/tagesplaene';

  /**
   * Erstellt einen neuen Tagesplan.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createTagesplan$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createTagesplan$Plain$Response(params?: CreateTagesplan$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<TagesplanDto>> {
    return createTagesplan$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Erstellt einen neuen Tagesplan.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createTagesplan$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createTagesplan$Plain(params?: CreateTagesplan$Plain$Params, context?: HttpContext): Observable<TagesplanDto> {
    return this.createTagesplan$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<TagesplanDto>): TagesplanDto => r.body)
    );
  }

  /**
   * Erstellt einen neuen Tagesplan.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createTagesplan$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createTagesplan$Json$Response(params?: CreateTagesplan$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<TagesplanDto>> {
    return createTagesplan$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Erstellt einen neuen Tagesplan.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createTagesplan$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createTagesplan$Json(params?: CreateTagesplan$Json$Params, context?: HttpContext): Observable<TagesplanDto> {
    return this.createTagesplan$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<TagesplanDto>): TagesplanDto => r.body)
    );
  }

  /** Path part for operation `getTagesplan()` */
  static readonly GetTagesplanPath = '/v1/tagesplaene/{id}';

  /**
   * Sucht einen Tagesplan mit der angegebenen ID.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTagesplan$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTagesplan$Plain$Response(params: GetTagesplan$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<TagesplanDto>> {
    return getTagesplan$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Sucht einen Tagesplan mit der angegebenen ID.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTagesplan$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTagesplan$Plain(params: GetTagesplan$Plain$Params, context?: HttpContext): Observable<TagesplanDto> {
    return this.getTagesplan$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<TagesplanDto>): TagesplanDto => r.body)
    );
  }

  /**
   * Sucht einen Tagesplan mit der angegebenen ID.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTagesplan$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTagesplan$Json$Response(params: GetTagesplan$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<TagesplanDto>> {
    return getTagesplan$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Sucht einen Tagesplan mit der angegebenen ID.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTagesplan$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTagesplan$Json(params: GetTagesplan$Json$Params, context?: HttpContext): Observable<TagesplanDto> {
    return this.getTagesplan$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<TagesplanDto>): TagesplanDto => r.body)
    );
  }

  /** Path part for operation `updateTagesplan()` */
  static readonly UpdateTagesplanPath = '/v1/tagesplaene/{id}';

  /**
   * Aktualisiert einen Tagesplan.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateTagesplan()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateTagesplan$Response(params: UpdateTagesplan$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return updateTagesplan(this.http, this.rootUrl, params, context);
  }

  /**
   * Aktualisiert einen Tagesplan.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateTagesplan$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateTagesplan(params: UpdateTagesplan$Params, context?: HttpContext): Observable<void> {
    return this.updateTagesplan$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `deleteTagesplan()` */
  static readonly DeleteTagesplanPath = '/v1/tagesplaene/{tagesplanId}';

  /**
   * Setzt einen Tagesplan inaktiv (Soft Delete).
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteTagesplan()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteTagesplan$Response(params: DeleteTagesplan$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteTagesplan(this.http, this.rootUrl, params, context);
  }

  /**
   * Setzt einen Tagesplan inaktiv (Soft Delete).
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteTagesplan$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteTagesplan(params: DeleteTagesplan$Params, context?: HttpContext): Observable<void> {
    return this.deleteTagesplan$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
