import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CdkTableModule } from '@angular/cdk/table';
import { DlTableContainerComponent } from './table-container/table-container.component';
import { DlTableHeaderComponent } from './table-header/table-header.component';
import { DlTableCellComponent } from './table-cell/table-cell.component';
import { DlTableDirective } from './directives/table/dl-table.directive';

@NgModule({
  declarations: [
    DlTableContainerComponent,
    DlTableHeaderComponent,
    DlTableCellComponent,
    DlTableDirective,
  ],
  imports: [CommonModule, CdkTableModule],
  exports: [
    DlTableContainerComponent,
    DlTableHeaderComponent,
    DlTableCellComponent,
    DlTableDirective,
    CdkTableModule,
  ],
})
export class DlTableModule {}
