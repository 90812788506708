import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { ChipDto } from '../../api/models';

export const ChipsActions = createActionGroup({
  source: 'Chips',
  events: {
    'Load Available Chips': emptyProps(),
    'Load Available Chips Success': props<{ availableChips: ChipDto[] }>(),
    'Load Available Chips Failure': props<{ error: unknown }>(),
    'Load All Chips': emptyProps(),
    'Load All Chips Success': props<{ allChips: ChipDto[] }>(),
    'Load All Chips Failure': props<{ error: unknown }>(),
    'Restrict Chip': props<{ chipToBeRestricted: ChipDto }>(),
    'Restrict Chip Success': emptyProps(),
    'Restrict Chip Failure': props<{ error: unknown }>(),
    'Unrestrict Chip': props<{ chipNr: string }>(),
    'Unrestrict Chip Success': props<{ chip: ChipDto }>(),
    'Unrestrict Chip Failure': props<{ error: unknown }>(),
  },
});
