import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { addDays, format } from 'date-fns';
import { de } from 'date-fns/locale';
import { map, of } from 'rxjs';
import {
  catchError,
  concatMap,
  debounceTime,
  filter,
  switchMap,
  tap,
} from 'rxjs/operators';
import { handleHttpError } from '../../../common/httpErrorHandler';
import { BuchungApiService } from '../../api/services/buchung-api.service';
import { PAGINATION_HEADER_NAME } from '../../pagination-header';
import { MitarbeiterActions } from '../mitarbeiter/mitarbeiter.actions';
import * as fromMitarbeiter from '../mitarbeiter/mitarbeiter.selectors';
import { BuchungenActions } from './buchungen.actions';
import * as fromBuchungen from './buchungen.selectors';

@Injectable()
export class BuchungenEffects {
  loadBuchungens$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        BuchungenActions.loadBuchungen,
        BuchungenActions.setPageIndex,
        BuchungenActions.setPageSize,
        MitarbeiterActions.setSelectedMitarbeiterId,
        BuchungenActions.createSingleBuchungSuccess,
        BuchungenActions.createBuchungForTimeframeSuccess,
        BuchungenActions.setSelectedMonthAndYear,
        BuchungenActions.setIncludeDeleted,
        BuchungenActions.updateBuchungSuccess,
        BuchungenActions.deleteBuchungSuccess,
      ),
      debounceTime(50),
      concatLatestFrom(() => [
        this.store.select(fromMitarbeiter.selectSelectedMitarbeiter),
        this.store.select(fromBuchungen.selectSelectedMonth),
        this.store.select(fromBuchungen.selectSelectedYear),
        this.store.select(fromBuchungen.selectIncludeDeleted),
      ]),
      filter(
        ([, selectedMitarbeiter]) =>
          selectedMitarbeiter?.id !== '' &&
          selectedMitarbeiter?.isDeleted === false &&
          !!selectedMitarbeiter?.id,
      ), // Filter out if no mitarbeiter is selected
      switchMap(
        ([
          ,
          selectedMitarbeiterId,
          selectedMonth,
          selectedYear,
          includeDeleted,
        ]) =>
          this.buchungenApi
            .getBuchungenForMitarbeiter$Json$Response({
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              mitarbeiterId: selectedMitarbeiterId!.id, // Guarded by filter
              From: `${selectedYear}-${selectedMonth}-01`,
              To: `${selectedMonth === 12 ? selectedYear + 1 : selectedYear}-${selectedMonth === 12 ? 1 : selectedMonth + 1}-01`,
              IncludeDeleted: includeDeleted,
            })
            .pipe(
              map((response) => {
                return BuchungenActions.loadBuchungenSuccess({
                  data: response.body,
                  totalCount: response.headers.get(PAGINATION_HEADER_NAME)
                    ? JSON.parse(
                        response.headers.get(PAGINATION_HEADER_NAME) as string,
                      ).TotalCount
                    : response.body.length,
                });
              }),
              catchError((error) =>
                of(BuchungenActions.loadBuchungenFailure({ error })),
              ),
            ),
      ),
    );
  });

  loadBuchungenFuerTag$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(BuchungenActions.loadBuchungenFuerTag),
      concatLatestFrom(() =>
        this.store.select(fromMitarbeiter.selectSelectedMitarbeiterId),
      ),
      concatMap(([{ date }, mitarbeiterId]) => {
        const start = format(date, 'yyyy-MM-dd', { locale: de });
        const end = format(addDays(date, 1), 'yyyy-MM-dd', { locale: de });
        return this.buchungenApi
          .getBuchungenForMitarbeiter$Json({
            mitarbeiterId,
            From: start,
            To: end,
          })
          .pipe(
            map((response) =>
              BuchungenActions.loadBuchungenFuerTagSuccess({
                buchungen: response,
              }),
            ),
            catchError((error) =>
              of(BuchungenActions.loadBuchungenFuerTagFailure({ error })),
            ),
          );
      }),
    );
  });

  createBuchung$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(BuchungenActions.createSingleBuchung),
      concatLatestFrom(() =>
        this.store.select(fromMitarbeiter.selectSelectedMitarbeiterId),
      ),
      filter(([, mitarbeiterId]) => mitarbeiterId !== ''),
      concatMap(([{ data }, mitarbeiterId]) =>
        this.buchungenApi
          .createBuchungForMitarbeiter$Json({ mitarbeiterId, body: data })
          .pipe(
            map((res) =>
              BuchungenActions.createSingleBuchungSuccess({ data: res }),
            ),
            catchError((err) =>
              of(BuchungenActions.createSingleBuchungFailure({ error: err })),
            ),
          ),
      ),
    );
  });

  createBuchungForTimeframe$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(BuchungenActions.createBuchungForTimeframe),
      concatLatestFrom(() =>
        this.store.select(fromMitarbeiter.selectSelectedMitarbeiterId),
      ),
      filter(([, mitarbeiterId]) => mitarbeiterId !== ''),
      concatMap(([{ data }, mitarbeiterId]) =>
        this.buchungenApi
          .createBuchungForMitarbeiterInTimeframe$Json({
            mitarbeiterId,
            body: data,
          })
          .pipe(
            map((res) =>
              BuchungenActions.createBuchungForTimeframeSuccess({ data: res }),
            ),
            catchError((err) =>
              of(
                BuchungenActions.createBuchungForTimeframeFailure({
                  error: err,
                }),
              ),
            ),
          ),
      ),
    );
  });

  createBuchungForTimeframeAndTarifAttribut$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(BuchungenActions.createBuchungForTimeframeAndTarifAttribut),
      concatMap(({ tarifAttribut, data }) =>
        this.buchungenApi
          .createBuchungForTarifAttributInTimeframe$Json({
            tarifAttribut,
            body: data,
          })
          .pipe(
            map((res) =>
              BuchungenActions.createBuchungForTimeframeAndTarifAttributSuccess(
                { data: res },
              ),
            ),
            catchError((err) =>
              of(
                BuchungenActions.createBuchungForTimeframeAndTarifAttributFailure(
                  {
                    error: err,
                  },
                ),
              ),
            ),
          ),
      ),
    );
  });

  updateBuchung$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(BuchungenActions.updateBuchung),
      concatMap(({ data, id }) =>
        this.buchungenApi
          .updateOneBuchung$Json({ buchungsId: id, body: data })
          .pipe(
            map((res) => BuchungenActions.updateBuchungSuccess({ data: res })),
            catchError((err) =>
              of(BuchungenActions.updateBuchungFailure({ error: err })),
            ),
          ),
      ),
    );
  });

  // Toast Handling
  createBuchungFailure$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(
          BuchungenActions.createSingleBuchungFailure,
          BuchungenActions.createBuchungForTimeframeFailure,
          BuchungenActions.createBuchungForTimeframeAndTarifAttributFailure,
        ),
        tap(async ({ error }) => {
          await handleHttpError(
            error,
            this.toastController,
            'Beim Erstellen der Buchung(en) ist ein Fehler aufgetreten.',
          );
        }),
      );
    },
    { dispatch: false },
  );

  createBuchungSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(
          BuchungenActions.createSingleBuchungSuccess,
          BuchungenActions.createBuchungForTimeframeSuccess,
        ),
        tap(async () => {
          const toast = await this.toastController.create({
            message: 'Buchung erfolgreich erstellt.',
            duration: 3000,
            color: 'success',
          });
          await toast.present();
        }),
      );
    },
    { dispatch: false },
  );

  updateBuchungFailure$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(BuchungenActions.updateBuchungFailure),
        tap(async ({ error }) => {
          await handleHttpError(
            error,
            this.toastController,
            'Beim Aktualisieren der Buchung ist ein Fehler aufgetreten.',
          );
        }),
      );
    },
    { dispatch: false },
  );

  updateBuchungSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(BuchungenActions.updateBuchungSuccess),
        tap(async () => {
          const toast = await this.toastController.create({
            message: 'Buchung erfolgreich aktualisiert.',
            duration: 3000,
            color: 'success',
          });
          await toast.present();
        }),
      );
    },
    { dispatch: false },
  );
  deleteBuchung$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(BuchungenActions.deleteBuchung),
      concatMap(({ id }) =>
        this.buchungenApi.deleteOneBuchung$Json({ buchungsId: id }).pipe(
          map(() => BuchungenActions.deleteBuchungSuccess({ id })),
          catchError((err) =>
            of(BuchungenActions.deleteBuchungFailure({ error: err })),
          ),
        ),
      ),
    );
  });

  deleteBuchungSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(BuchungenActions.deleteBuchungSuccess),
        switchMap(async () => {
          const toast = await this.toastController.create({
            message: 'Buchung erfolgreich als gelöscht markiert',
            duration: 3000,
            color: 'success',
          });

          await toast.present();
        }),
      );
    },
    { dispatch: false },
  );

  deleteBuchungFailure$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(BuchungenActions.deleteBuchungFailure),
        switchMap(async ({ error }) => {
          await handleHttpError(
            error,
            this.toastController,
            'Die Buchung konnte nicht gelöscht werden',
          );
        }),
      );
    },
    { dispatch: false },
  );

  getBuchungenForTimeframe$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(BuchungenActions.loadBuchungenForTimeframe),
      switchMap(({ start, end, mitarbeiterId }) =>
        this.buchungenApi
          .getBuchungenForMitarbeiter$Json({
            To: end,
            mitarbeiterId,
            From: start,
            IncludeDeleted: true,
          })
          .pipe(
            map((response) =>
              BuchungenActions.loadBuchungenForTimeframeSuccess({
                buchungen: response,
              }),
            ),
            catchError((error) =>
              of(BuchungenActions.loadBuchungenForTimeframeFailure({ error })),
            ),
          ),
      ),
    );
  });

  constructor(
    private actions$: Actions,
    private buchungenApi: BuchungApiService,
    private readonly toastController: ToastController,
    private store: Store,
  ) {}
}
