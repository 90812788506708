import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { handleHttpError } from '../../../common/httpErrorHandler';
import { ZugangApiService } from '../../api/services';
import { PersonenWithZugaengeActions } from '../personen-zugaenge/personen-zugaenge.actions';
import { ZugaengeActions } from './zugaenge.actions';

@Injectable()
export class ZugaengeEffects {
  loadZugaenge$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        ZugaengeActions.loadZugaenge,
        PersonenWithZugaengeActions.loadZutrittskontrolle,
      ),
      switchMap(() =>
        this.api$.getAllZugaenge$Json().pipe(
          map((zugaenge) => ZugaengeActions.loadZugaengeSuccess({ zugaenge })),
          catchError((error) =>
            of(ZugaengeActions.loadZugaengeFailure({ error })),
          ),
        ),
      ),
    );
  });

  loadZugaengeFailure$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(ZugaengeActions.loadZugaengeFailure),
        tap(async ({ error }) => {
          console.error(error);
          await handleHttpError(
            error,
            this.toastCtrl,
            'Beim Laden der Zugänge ist ein Fehler aufgetreten.',
          );
        }),
      );
    },
    { dispatch: false },
  );

  constructor(
    private actions$: Actions,
    private readonly api$: ZugangApiService,
    private readonly toastCtrl: ToastController,
  ) {}
}
