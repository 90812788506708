/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { BuchungsaenderungsAntragDto } from '../models/buchungsaenderungs-antrag-dto';
import { createBuchungsaenderungsAntrag$Json } from '../fn/buchungsaenderungs-antrags/create-buchungsaenderungs-antrag-json';
import { CreateBuchungsaenderungsAntrag$Json$Params } from '../fn/buchungsaenderungs-antrags/create-buchungsaenderungs-antrag-json';
import { createBuchungsaenderungsAntrag$Plain } from '../fn/buchungsaenderungs-antrags/create-buchungsaenderungs-antrag-plain';
import { CreateBuchungsaenderungsAntrag$Plain$Params } from '../fn/buchungsaenderungs-antrags/create-buchungsaenderungs-antrag-plain';
import { getAllAntraegePaginated$Json } from '../fn/buchungsaenderungs-antrags/get-all-antraege-paginated-json';
import { GetAllAntraegePaginated$Json$Params } from '../fn/buchungsaenderungs-antrags/get-all-antraege-paginated-json';
import { getAllAntraegePaginated$Plain } from '../fn/buchungsaenderungs-antrags/get-all-antraege-paginated-plain';
import { GetAllAntraegePaginated$Plain$Params } from '../fn/buchungsaenderungs-antrags/get-all-antraege-paginated-plain';
import { getOffeneBuchungsaenderungsAntraege$Json } from '../fn/buchungsaenderungs-antrags/get-offene-buchungsaenderungs-antraege-json';
import { GetOffeneBuchungsaenderungsAntraege$Json$Params } from '../fn/buchungsaenderungs-antrags/get-offene-buchungsaenderungs-antraege-json';
import { getOffeneBuchungsaenderungsAntraege$Plain } from '../fn/buchungsaenderungs-antrags/get-offene-buchungsaenderungs-antraege-plain';
import { GetOffeneBuchungsaenderungsAntraege$Plain$Params } from '../fn/buchungsaenderungs-antrags/get-offene-buchungsaenderungs-antraege-plain';
import { updateOneBuchungsaenderungsAntrag$Json } from '../fn/buchungsaenderungs-antrags/update-one-buchungsaenderungs-antrag-json';
import { UpdateOneBuchungsaenderungsAntrag$Json$Params } from '../fn/buchungsaenderungs-antrags/update-one-buchungsaenderungs-antrag-json';
import { updateOneBuchungsaenderungsAntrag$Plain } from '../fn/buchungsaenderungs-antrags/update-one-buchungsaenderungs-antrag-plain';
import { UpdateOneBuchungsaenderungsAntrag$Plain$Params } from '../fn/buchungsaenderungs-antrags/update-one-buchungsaenderungs-antrag-plain';

@Injectable({ providedIn: 'root' })
export class BuchungsaenderungsAntragsApiService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getAllAntraegePaginated()` */
  static readonly GetAllAntraegePaginatedPath = '/v1/buchungsaenderungsAntraege';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllAntraegePaginated$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllAntraegePaginated$Plain$Response(params?: GetAllAntraegePaginated$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<BuchungsaenderungsAntragDto>>> {
    return getAllAntraegePaginated$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllAntraegePaginated$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllAntraegePaginated$Plain(params?: GetAllAntraegePaginated$Plain$Params, context?: HttpContext): Observable<Array<BuchungsaenderungsAntragDto>> {
    return this.getAllAntraegePaginated$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<BuchungsaenderungsAntragDto>>): Array<BuchungsaenderungsAntragDto> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllAntraegePaginated$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllAntraegePaginated$Json$Response(params?: GetAllAntraegePaginated$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<BuchungsaenderungsAntragDto>>> {
    return getAllAntraegePaginated$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllAntraegePaginated$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllAntraegePaginated$Json(params?: GetAllAntraegePaginated$Json$Params, context?: HttpContext): Observable<Array<BuchungsaenderungsAntragDto>> {
    return this.getAllAntraegePaginated$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<BuchungsaenderungsAntragDto>>): Array<BuchungsaenderungsAntragDto> => r.body)
    );
  }

  /** Path part for operation `createBuchungsaenderungsAntrag()` */
  static readonly CreateBuchungsaenderungsAntragPath = '/v1/buchungen/{buchungId}/aenderungsAntraege';

  /**
   * Erstellt einen Änderungsantrag für die angegebene Buchung.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createBuchungsaenderungsAntrag$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createBuchungsaenderungsAntrag$Plain$Response(params: CreateBuchungsaenderungsAntrag$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<BuchungsaenderungsAntragDto>> {
    return createBuchungsaenderungsAntrag$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Erstellt einen Änderungsantrag für die angegebene Buchung.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createBuchungsaenderungsAntrag$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createBuchungsaenderungsAntrag$Plain(params: CreateBuchungsaenderungsAntrag$Plain$Params, context?: HttpContext): Observable<BuchungsaenderungsAntragDto> {
    return this.createBuchungsaenderungsAntrag$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<BuchungsaenderungsAntragDto>): BuchungsaenderungsAntragDto => r.body)
    );
  }

  /**
   * Erstellt einen Änderungsantrag für die angegebene Buchung.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createBuchungsaenderungsAntrag$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createBuchungsaenderungsAntrag$Json$Response(params: CreateBuchungsaenderungsAntrag$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<BuchungsaenderungsAntragDto>> {
    return createBuchungsaenderungsAntrag$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Erstellt einen Änderungsantrag für die angegebene Buchung.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createBuchungsaenderungsAntrag$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createBuchungsaenderungsAntrag$Json(params: CreateBuchungsaenderungsAntrag$Json$Params, context?: HttpContext): Observable<BuchungsaenderungsAntragDto> {
    return this.createBuchungsaenderungsAntrag$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<BuchungsaenderungsAntragDto>): BuchungsaenderungsAntragDto => r.body)
    );
  }

  /** Path part for operation `updateOneBuchungsaenderungsAntrag()` */
  static readonly UpdateOneBuchungsaenderungsAntragPath = '/v1/buchungsaenderungsAntraege/{antragsId}';

  /**
   * Aktualisiert einen Buchungsänderungsantrag.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateOneBuchungsaenderungsAntrag$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateOneBuchungsaenderungsAntrag$Plain$Response(params: UpdateOneBuchungsaenderungsAntrag$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<BuchungsaenderungsAntragDto>> {
    return updateOneBuchungsaenderungsAntrag$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Aktualisiert einen Buchungsänderungsantrag.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateOneBuchungsaenderungsAntrag$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateOneBuchungsaenderungsAntrag$Plain(params: UpdateOneBuchungsaenderungsAntrag$Plain$Params, context?: HttpContext): Observable<BuchungsaenderungsAntragDto> {
    return this.updateOneBuchungsaenderungsAntrag$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<BuchungsaenderungsAntragDto>): BuchungsaenderungsAntragDto => r.body)
    );
  }

  /**
   * Aktualisiert einen Buchungsänderungsantrag.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateOneBuchungsaenderungsAntrag$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateOneBuchungsaenderungsAntrag$Json$Response(params: UpdateOneBuchungsaenderungsAntrag$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<BuchungsaenderungsAntragDto>> {
    return updateOneBuchungsaenderungsAntrag$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Aktualisiert einen Buchungsänderungsantrag.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateOneBuchungsaenderungsAntrag$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateOneBuchungsaenderungsAntrag$Json(params: UpdateOneBuchungsaenderungsAntrag$Json$Params, context?: HttpContext): Observable<BuchungsaenderungsAntragDto> {
    return this.updateOneBuchungsaenderungsAntrag$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<BuchungsaenderungsAntragDto>): BuchungsaenderungsAntragDto => r.body)
    );
  }

  /** Path part for operation `getOffeneBuchungsaenderungsAntraege()` */
  static readonly GetOffeneBuchungsaenderungsAntraegePath = '/v1/mitarbeiter/{mitarbeiterId}/aenderungsAntraege/offen';

  /**
   * Holt alle offenen Buchungsaenderungsantraege für einen Mitarbeiter.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOffeneBuchungsaenderungsAntraege$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOffeneBuchungsaenderungsAntraege$Plain$Response(params: GetOffeneBuchungsaenderungsAntraege$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<BuchungsaenderungsAntragDto>>> {
    return getOffeneBuchungsaenderungsAntraege$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Holt alle offenen Buchungsaenderungsantraege für einen Mitarbeiter.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getOffeneBuchungsaenderungsAntraege$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOffeneBuchungsaenderungsAntraege$Plain(params: GetOffeneBuchungsaenderungsAntraege$Plain$Params, context?: HttpContext): Observable<Array<BuchungsaenderungsAntragDto>> {
    return this.getOffeneBuchungsaenderungsAntraege$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<BuchungsaenderungsAntragDto>>): Array<BuchungsaenderungsAntragDto> => r.body)
    );
  }

  /**
   * Holt alle offenen Buchungsaenderungsantraege für einen Mitarbeiter.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOffeneBuchungsaenderungsAntraege$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOffeneBuchungsaenderungsAntraege$Json$Response(params: GetOffeneBuchungsaenderungsAntraege$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<BuchungsaenderungsAntragDto>>> {
    return getOffeneBuchungsaenderungsAntraege$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Holt alle offenen Buchungsaenderungsantraege für einen Mitarbeiter.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getOffeneBuchungsaenderungsAntraege$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOffeneBuchungsaenderungsAntraege$Json(params: GetOffeneBuchungsaenderungsAntraege$Json$Params, context?: HttpContext): Observable<Array<BuchungsaenderungsAntragDto>> {
    return this.getOffeneBuchungsaenderungsAntraege$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<BuchungsaenderungsAntragDto>>): Array<BuchungsaenderungsAntragDto> => r.body)
    );
  }

}
