import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { DatenlotseAngularAuthService } from '@datenlotse/angular-auth';
import { DlChipModule } from '@datenlotse/components/chip';
import { DlPageHeaderModule } from '@datenlotse/components/page-header';
import { Store } from '@ngrx/store';
import packageJson from '../../../package.json';
import * as fromAuth from '../store/auth/auth.selectors';

export interface IUser {
  companyName?: string;
  firstName: string;
  name: string;
  userId: string;
  profilePictureUrl?: string;
  replacedByUserId?: string;
  competenceGroups?: unknown[];
}

@Component({
  selector: 'datenlotse-page-header',
  standalone: true,
  imports: [CommonModule, DlPageHeaderModule, DlChipModule],
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss'],
})
export class PageHeaderComponent implements OnInit {
  @Input() pageTitle = '';
  userId: string | undefined;
  user: IUser | undefined;
  versionNumber = packageJson.version;
  apiVersionNumber = '-';

  public readonly loggedInMitarbeiter$ = this._store.select(
    fromAuth.selectLoggedInMitarbeiter
  );
  public readonly isLoggedIn$ = this._store.select(fromAuth.selectIsLoggedIn);

  constructor(
    private _authService: DatenlotseAngularAuthService,
    private _store: Store
  ) {}

  ngOnInit(): void {
    this.userId = this._authService.userID;
    // this.getApiVersion();
  }

  async logout() {
    await this._authService.logoutUser();
    location.href = location.origin + '/';
  }
}
