import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { TagesplanApiService } from '../../api/services';
import * as TagesplaeneActions from './tagesplaene.actions';

@Injectable()
export class TagesplaeneEffects {
  loadActiveTagesplaene$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TagesplaeneActions.loadActiveTagesplaene),
      switchMap(() =>
        this.api.getTagesplaene$Json().pipe(
          map((activeTagesplaene) =>
            TagesplaeneActions.loadActiveTagesplaeneSuccess({activeTagesplaene})
          ),
          catchError((error) =>
            of(TagesplaeneActions.loadActiveTagesplaeneFailure({error}))
          )
        )
      )
    );
  });

  loadInactiveTagesplaene$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TagesplaeneActions.loadInactiveTagesplaene),
      switchMap(() =>
        this.api.getTagesplaene$Json({onlySoftDeletes: true}).pipe(
          map((inactiveTagesplaene) =>
            TagesplaeneActions.loadInactiveTagesplaeneSuccess({inactiveTagesplaene})
          ),
          catchError((error) =>
            of(TagesplaeneActions.loadInactiveTagesplaeneFailure({error}))
          )
        )
      )
    );
  });

  createTagesplan$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TagesplaeneActions.createTagesplan),
      switchMap(({data}) =>
        this.api
          .createTagesplan$Json({
            body: data,
          })
          .pipe(
            map((tagesplan) =>
              TagesplaeneActions.createTagesplanSuccess({tagesplan})
            ),
            catchError((error) =>
              of(TagesplaeneActions.createTagesplanFailure({error}))
            )
          )
      )
    );
  });

  setInactiveTagesplan$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TagesplaeneActions.setInactiveTagesplan),
      switchMap((data) =>
        this.api
          .deleteTagesplan({
            tagesplanId: data.id,
          })
          .pipe(
            map(() =>
              TagesplaeneActions.setInactiveTagesplanSuccess(data)
            ),
            catchError((error) =>
              of(TagesplaeneActions.setInactiveTagesplanFailure({error}))
            )
          )
      )
    );
  });

  constructor(
    private actions$: Actions,
    private readonly api: TagesplanApiService
  ) {
  }
}
