import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  Input,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { DlPageHeaderDropdownComponent } from '../page-header-dropdown/page-header-dropdown.component';

@Component({
  selector: 'dl-page-header-user',
  templateUrl: './page-header-user.component.html',
  styleUrls: ['./page-header-user.component.css'],
})
export class DlPageHeaderUserComponent implements AfterViewInit {
  @Input() dropdownTemplate: TemplateRef<unknown> | undefined;
  routerSUB: Subscription | undefined;
  private _dropdownVisible = false;
  private _dropdownRef: OverlayRef;
  @ViewChild('container') private _container: ElementRef | undefined;

  constructor(private _overlay: Overlay, private _router: Router) {
    this._dropdownRef = this._overlay.create({
      hasBackdrop: true,
      backdropClass: 'cdk-overlay-transparent-backdrop',
    });
    this.routerSUB = this._router.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        this._dropdownVisible = false;
        this._dropdownRef.detach();
      }
    });
  }

  get dropdownOpen(): boolean {
    return this._dropdownVisible;
  }

  @HostListener('click', ['$event'])
  onHostClick(): void {
    this._dropdownVisible = !this._dropdownVisible;
    if (this._dropdownVisible) {
      const instance = this._dropdownRef.attach(
        new ComponentPortal(DlPageHeaderDropdownComponent)
      );
      instance.instance.entryTemplate = this.dropdownTemplate;
      instance.instance.attachOverlayRef(this._dropdownRef);
      instance.instance.closeEvent
        .pipe(first())
        .subscribe(() => (this._dropdownVisible = false));
    } else {
      this._dropdownRef.detach();
    }
  }

  ngAfterViewInit(): void {
    if (!this._container) {
      throw new Error('Container not found');
    }

    const strategy = this._overlay
      .position()
      .flexibleConnectedTo(this._container)
      .withPositions([
        {
          originX: 'end',
          originY: 'bottom',
          overlayX: 'end',
          overlayY: 'top',
        },
      ]);

    this._dropdownRef.updatePositionStrategy(strategy);
  }
}
