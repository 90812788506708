/* eslint-disable  @typescript-eslint/no-explicit-any */

import scope_items from "./scopes";

export const environment = {
  production: true,
  authLoginUrl:
    (window as any)['env']['AUTH_LOGIN_URL'] ||
    'https://login.langguth.com/auth',
  authApiUrl:
    (window as any)['env']['AUTH_API_URL'] || 'https://login.langguth.com/api',
  authClientId:
    (window as any)['env']['AUTH_CLIENT_ID'] ||
    'E48B26FC-7616-EE11-A303-005056ABFA37',
  authRedirectUrl:
    (window as any)['env']['AUTH_REDIRECT_URL'] ||
    'https://clockin.langguth.com/cb',
  scope_items,
};
