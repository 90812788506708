<span>{{ label }}:</span>
<dl-select
  style="width: 40px"
  [(ngModel)]="pageSize"
  (ngModelChange)="_changePageSize($event)"
>
  <dl-select-option
    [value]="pageSize"
    *ngFor="let pageSize of _displayedPageSizeOptions"
    >{{ pageSize }}</dl-select-option
  >
</dl-select>
<span style="margin-left: 32px"
  >{{ pageSize * pageIndex + 1 }}-{{ pageSize * (pageIndex + 1) }}
  {{ labelOf }} {{ length }}</span
>

<dl-button (click)="previousPage()">
  <img
    src="assets/icons/chevron-back.svg"
    alt="Back one Page"
    class="nav-icon"
    [ngClass]="{ disabled: _previousButtonsDisabled() }"
  />
</dl-button>
<dl-button (click)="nextPage()">
  <img
    src="assets/icons/chevron-forward.svg"
    alt="Forward one Page"
    class="nav-icon"
    [ngClass]="{ disabled: _nextButtonsDisabled() }"
  />
</dl-button>
