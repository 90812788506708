import {AbstractControl, ValidationErrors, ValidatorFn} from '@angular/forms';
import {convertDateInputToDateObject} from './date-input-to-date-object';

/**
 * Validator for date ranges. Checks if the start date is before the end date.
 * @param startDateName The control name of the start date.
 * @param endDateName The control name of the end date.
 * @returns A validator function that returns an error object with the key 'dateRange' if the date range is invalid.
 */
export function dateRangeValidator(
  startDateName: string,
  endDateName: string
): ValidatorFn {
  return (formGroup: AbstractControl): ValidationErrors | null => {
    const startDate = formGroup.get(startDateName)?.value;
    const endDate = formGroup.get(endDateName)?.value;

    if (startDate && endDate) {
      const startDateObject = convertDateInputToDateObject(startDate);
      const endDateObject = convertDateInputToDateObject(endDate);

      if (startDateObject > endDateObject) {
        return { dateRange: true };
      }
    }

    return null; // Return null if the date range is valid
  };
}
