/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { createUrlaubsantrag$Json } from '../fn/urlaubsantrag/create-urlaubsantrag-json';
import { CreateUrlaubsantrag$Json$Params } from '../fn/urlaubsantrag/create-urlaubsantrag-json';
import { createUrlaubsantrag$Plain } from '../fn/urlaubsantrag/create-urlaubsantrag-plain';
import { CreateUrlaubsantrag$Plain$Params } from '../fn/urlaubsantrag/create-urlaubsantrag-plain';
import { getAllUrlaubsantraege$Json } from '../fn/urlaubsantrag/get-all-urlaubsantraege-json';
import { GetAllUrlaubsantraege$Json$Params } from '../fn/urlaubsantrag/get-all-urlaubsantraege-json';
import { getAllUrlaubsantraege$Plain } from '../fn/urlaubsantrag/get-all-urlaubsantraege-plain';
import { GetAllUrlaubsantraege$Plain$Params } from '../fn/urlaubsantrag/get-all-urlaubsantraege-plain';
import { getUrlaubsantraegeForMitarbeiter$Json } from '../fn/urlaubsantrag/get-urlaubsantraege-for-mitarbeiter-json';
import { GetUrlaubsantraegeForMitarbeiter$Json$Params } from '../fn/urlaubsantrag/get-urlaubsantraege-for-mitarbeiter-json';
import { getUrlaubsantraegeForMitarbeiter$Plain } from '../fn/urlaubsantrag/get-urlaubsantraege-for-mitarbeiter-plain';
import { GetUrlaubsantraegeForMitarbeiter$Plain$Params } from '../fn/urlaubsantrag/get-urlaubsantraege-for-mitarbeiter-plain';
import { getUrlaubsantragById$Json } from '../fn/urlaubsantrag/get-urlaubsantrag-by-id-json';
import { GetUrlaubsantragById$Json$Params } from '../fn/urlaubsantrag/get-urlaubsantrag-by-id-json';
import { getUrlaubsantragById$Plain } from '../fn/urlaubsantrag/get-urlaubsantrag-by-id-plain';
import { GetUrlaubsantragById$Plain$Params } from '../fn/urlaubsantrag/get-urlaubsantrag-by-id-plain';
import { updateUrlaubsantrag$Json } from '../fn/urlaubsantrag/update-urlaubsantrag-json';
import { UpdateUrlaubsantrag$Json$Params } from '../fn/urlaubsantrag/update-urlaubsantrag-json';
import { updateUrlaubsantrag$Plain } from '../fn/urlaubsantrag/update-urlaubsantrag-plain';
import { UpdateUrlaubsantrag$Plain$Params } from '../fn/urlaubsantrag/update-urlaubsantrag-plain';
import { UrlaubsantragDto } from '../models/urlaubsantrag-dto';

@Injectable({ providedIn: 'root' })
export class UrlaubsantragApiService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getUrlaubsantragById()` */
  static readonly GetUrlaubsantragByIdPath = '/v1/urlaubsantraege/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUrlaubsantragById$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUrlaubsantragById$Plain$Response(params: GetUrlaubsantragById$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<UrlaubsantragDto>> {
    return getUrlaubsantragById$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getUrlaubsantragById$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUrlaubsantragById$Plain(params: GetUrlaubsantragById$Plain$Params, context?: HttpContext): Observable<UrlaubsantragDto> {
    return this.getUrlaubsantragById$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<UrlaubsantragDto>): UrlaubsantragDto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUrlaubsantragById$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUrlaubsantragById$Json$Response(params: GetUrlaubsantragById$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<UrlaubsantragDto>> {
    return getUrlaubsantragById$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getUrlaubsantragById$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUrlaubsantragById$Json(params: GetUrlaubsantragById$Json$Params, context?: HttpContext): Observable<UrlaubsantragDto> {
    return this.getUrlaubsantragById$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<UrlaubsantragDto>): UrlaubsantragDto => r.body)
    );
  }

  /** Path part for operation `updateUrlaubsantrag()` */
  static readonly UpdateUrlaubsantragPath = '/v1/urlaubsantraege/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateUrlaubsantrag$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateUrlaubsantrag$Plain$Response(params: UpdateUrlaubsantrag$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<UrlaubsantragDto>> {
    return updateUrlaubsantrag$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateUrlaubsantrag$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateUrlaubsantrag$Plain(params: UpdateUrlaubsantrag$Plain$Params, context?: HttpContext): Observable<UrlaubsantragDto> {
    return this.updateUrlaubsantrag$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<UrlaubsantragDto>): UrlaubsantragDto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateUrlaubsantrag$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateUrlaubsantrag$Json$Response(params: UpdateUrlaubsantrag$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<UrlaubsantragDto>> {
    return updateUrlaubsantrag$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateUrlaubsantrag$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateUrlaubsantrag$Json(params: UpdateUrlaubsantrag$Json$Params, context?: HttpContext): Observable<UrlaubsantragDto> {
    return this.updateUrlaubsantrag$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<UrlaubsantragDto>): UrlaubsantragDto => r.body)
    );
  }

  /** Path part for operation `getAllUrlaubsantraege()` */
  static readonly GetAllUrlaubsantraegePath = '/v1/urlaubsantraege';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllUrlaubsantraege$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllUrlaubsantraege$Plain$Response(params?: GetAllUrlaubsantraege$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<UrlaubsantragDto>>> {
    return getAllUrlaubsantraege$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllUrlaubsantraege$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllUrlaubsantraege$Plain(params?: GetAllUrlaubsantraege$Plain$Params, context?: HttpContext): Observable<Array<UrlaubsantragDto>> {
    return this.getAllUrlaubsantraege$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<UrlaubsantragDto>>): Array<UrlaubsantragDto> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllUrlaubsantraege$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllUrlaubsantraege$Json$Response(params?: GetAllUrlaubsantraege$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<UrlaubsantragDto>>> {
    return getAllUrlaubsantraege$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllUrlaubsantraege$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllUrlaubsantraege$Json(params?: GetAllUrlaubsantraege$Json$Params, context?: HttpContext): Observable<Array<UrlaubsantragDto>> {
    return this.getAllUrlaubsantraege$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<UrlaubsantragDto>>): Array<UrlaubsantragDto> => r.body)
    );
  }

  /** Path part for operation `createUrlaubsantrag()` */
  static readonly CreateUrlaubsantragPath = '/v1/urlaubsantraege';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createUrlaubsantrag$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createUrlaubsantrag$Plain$Response(params?: CreateUrlaubsantrag$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<UrlaubsantragDto>> {
    return createUrlaubsantrag$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createUrlaubsantrag$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createUrlaubsantrag$Plain(params?: CreateUrlaubsantrag$Plain$Params, context?: HttpContext): Observable<UrlaubsantragDto> {
    return this.createUrlaubsantrag$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<UrlaubsantragDto>): UrlaubsantragDto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createUrlaubsantrag$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createUrlaubsantrag$Json$Response(params?: CreateUrlaubsantrag$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<UrlaubsantragDto>> {
    return createUrlaubsantrag$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createUrlaubsantrag$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createUrlaubsantrag$Json(params?: CreateUrlaubsantrag$Json$Params, context?: HttpContext): Observable<UrlaubsantragDto> {
    return this.createUrlaubsantrag$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<UrlaubsantragDto>): UrlaubsantragDto => r.body)
    );
  }

  /** Path part for operation `getUrlaubsantraegeForMitarbeiter()` */
  static readonly GetUrlaubsantraegeForMitarbeiterPath = '/v1/urlaubsantraege/mitarbeiter/{mitarbeiterId}';

  /**
   * Holt alle Urlaubsanträge für einen Mitarbeiter.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUrlaubsantraegeForMitarbeiter$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUrlaubsantraegeForMitarbeiter$Plain$Response(params: GetUrlaubsantraegeForMitarbeiter$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<UrlaubsantragDto>>> {
    return getUrlaubsantraegeForMitarbeiter$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Holt alle Urlaubsanträge für einen Mitarbeiter.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getUrlaubsantraegeForMitarbeiter$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUrlaubsantraegeForMitarbeiter$Plain(params: GetUrlaubsantraegeForMitarbeiter$Plain$Params, context?: HttpContext): Observable<Array<UrlaubsantragDto>> {
    return this.getUrlaubsantraegeForMitarbeiter$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<UrlaubsantragDto>>): Array<UrlaubsantragDto> => r.body)
    );
  }

  /**
   * Holt alle Urlaubsanträge für einen Mitarbeiter.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUrlaubsantraegeForMitarbeiter$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUrlaubsantraegeForMitarbeiter$Json$Response(params: GetUrlaubsantraegeForMitarbeiter$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<UrlaubsantragDto>>> {
    return getUrlaubsantraegeForMitarbeiter$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Holt alle Urlaubsanträge für einen Mitarbeiter.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getUrlaubsantraegeForMitarbeiter$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUrlaubsantraegeForMitarbeiter$Json(params: GetUrlaubsantraegeForMitarbeiter$Json$Params, context?: HttpContext): Observable<Array<UrlaubsantragDto>> {
    return this.getUrlaubsantraegeForMitarbeiter$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<UrlaubsantragDto>>): Array<UrlaubsantragDto> => r.body)
    );
  }

}
