/* eslint-disable @angular-eslint/directive-class-suffix */
import { Directive, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  HasInitialized,
  HasInitializedCtor,
  mixinInitialized,
} from '@datenlotse/components/shared';

export interface DlSortableColumn {
  sortKey: string;
}

export interface Sort {
  active: string;
  direction: 'asc' | 'desc' | '';
}

class DlSortBase {}
const _DlSortMixinBase: HasInitializedCtor & typeof DlSortBase =
  mixinInitialized(DlSortBase);

@Directive({
  selector: '[dlSort]',
})
export class DlSort extends _DlSortMixinBase implements OnInit, HasInitialized {
  @Input('dlSortActive') active = '';
  @Output()
  readonly dlSortChange: EventEmitter<Sort> = new EventEmitter<Sort>();
  direction: 'asc' | 'desc' | '' = '';
  sortableColumns = new Map<string, DlSortableColumn>();

  ngOnInit() {
    this._markInitialized();
  }

  constructor() {
    super();
  }

  register(sortableColumn: DlSortableColumn): void {
    this.sortableColumns.set(sortableColumn.sortKey, sortableColumn);
  }

  deregister(sortableColumn: DlSortableColumn): void {
    this.sortableColumns.delete(sortableColumn.sortKey);
  }

  sort(sortableColumn: DlSortableColumn): void {
    if (this.active !== sortableColumn.sortKey) {
      this.active = sortableColumn.sortKey;
      this.direction = 'asc';
    } else {
      this.direction =
        this.direction === 'desc' ? '' : this.direction === '' ? 'asc' : 'desc';
    }
    this.dlSortChange.emit({ active: this.active, direction: this.direction });
  }
}
