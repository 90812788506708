<textarea
  [name]="name!"
  [disabled]="disabled"
  [placeholder]="placeholder || ''"
  [ngModel]="text"
  [maxlength]="maxlength ?? null"
  (ngModelChange)="onInputChange($event)"
  (focus)="hasFocus.next(true)"
  (focusout)="hasFocus.next(false)"
  [rows]="rows"
  [cols]="cols"
  >{{ text }}</textarea
>
