<dl-sidebar-container>
  <dl-sidebar title="Navigatem.ClockIn">
    <dl-sidebar-button
      *ngIf="hasScope('pageAccess:personal-stammdaten')"
      [active]="(currentUrl$ | async) === 'personal-stammdaten'"
      [matchRouterLinkExact]="false"
      [queryParamsHandling]="'preserve'"
      [routerLink]="'/personal-stammdaten'"
    >
      <dl-sidebar-button-image
        src="assets/icons/black/user.svg"
      ></dl-sidebar-button-image>
      <dl-sidebar-button-text>Personal</dl-sidebar-button-text>
    </dl-sidebar-button>
    <dl-sidebar-button
      *ngIf="hasScope('pageAccess:tagesplaene')"
      [active]="(currentUrl$ | async) === 'tagesplaene'"
      [matchRouterLinkExact]="false"
      [routerLink]="'/tagesplaene'"
    >
      <dl-sidebar-button-image
        src="assets/icons/today-outline.svg"
      ></dl-sidebar-button-image>
      <dl-sidebar-button-text>Tagespläne</dl-sidebar-button-text>
    </dl-sidebar-button>
    <dl-sidebar-button
      *ngIf="hasScope('pageAccess:wochenplaene')"
      [active]="(currentUrl$ | async) === 'wochenplaene'"
      [matchRouterLinkExact]="false"
      [routerLink]="'/wochenplaene'"
    >
      <dl-sidebar-button-image
        src="assets/icons/week-outline.svg"
      ></dl-sidebar-button-image>
      <dl-sidebar-button-text>Wochenpläne</dl-sidebar-button-text>
    </dl-sidebar-button>
    <dl-sidebar-button
      *ngIf="hasScope('pageAccess:buchungen')"
      [active]="(currentUrl$ | async) === 'buchungen'"
      [matchRouterLinkExact]="false"
      queryParamsHandling="preserve"
      routerLink="/buchungen"
    >
      <dl-sidebar-button-image
        src="assets/icons/stopwatch-outline.svg"
      ></dl-sidebar-button-image>
      <dl-sidebar-button-text>Buchungen</dl-sidebar-button-text>
    </dl-sidebar-button>
    <dl-sidebar-button
      *ngIf="hasScope('pageAccess:buchungskorrekturen-monteure')"
      [active]="(currentUrl$ | async) === 'buchungskorrekturen-monteure'"
      [matchRouterLinkExact]="false"
      [routerLink]="'/buchungskorrekturen-monteure'"
      queryParamsHandling="preserve"
    >
      <dl-sidebar-button-image
        src="assets/icons/construct-outline.svg"
      ></dl-sidebar-button-image>
      <dl-sidebar-button-text
        >Buchungskorrektur Monteure</dl-sidebar-button-text
      >
    </dl-sidebar-button>
    <dl-sidebar-button
      *ngIf="hasScope('pageAccess:fehlerliste')"
      [active]="(currentUrl$ | async) === 'fehlerliste'"
      [matchRouterLinkExact]="false"
      [routerLink]="'/fehlerliste'"
      queryParamsHandling="preserve"
    >
      <dl-sidebar-button-image
        src="assets/icons/warning-outline.svg"
      ></dl-sidebar-button-image>
      <dl-sidebar-button-text
        >Fehlerliste</dl-sidebar-button-text
      >
    </dl-sidebar-button>
    <dl-sidebar-button
      *ngIf="hasScope('pageAccess:kalender')"
      [active]="(currentUrl$ | async) === 'kalender'"
      [matchRouterLinkExact]="false"
      [routerLink]="'/kalender'"
      queryParamsHandling="preserve"
    >
      <dl-sidebar-button-image
        src="assets/icons/calendar-outline.svg"
      ></dl-sidebar-button-image>
      <dl-sidebar-button-text>Kalenderansicht</dl-sidebar-button-text>
    </dl-sidebar-button>

    <dl-sidebar-button
      *ngIf="hasScope('pageAccess:auswertungen')"
      [routerLink]="'/auswertungen'"
      routerLinkActive="active"
    >
      <dl-sidebar-button-image
        src="assets/icons/black/users.svg"
      ></dl-sidebar-button-image>
      <dl-sidebar-button-text>Auswertungen</dl-sidebar-button-text>
    </dl-sidebar-button>

    <dl-sidebar-button
      *ngIf="hasScope('pageAccess:auswertungen:erstellen')"
      [routerLink]="'/auswertungen/erstellen'"
      routerLinkActive="active"
    >
      <dl-sidebar-button-image
        src="assets/icons/document-text-outline.svg"
      ></dl-sidebar-button-image>
      <dl-sidebar-button-text>Auswertung erstellen</dl-sidebar-button-text>
    </dl-sidebar-button>

    <dl-sidebar-button
      *ngIf="hasScope('pageAccess:umbuchungen')"
      [routerLink]="'/umbuchungen'"
      [active]="(currentUrl$ | async) === 'umbuchungen'"
      routerLinkActive="active"
    >
      <dl-sidebar-button-image
        src="assets/icons/swap-horizontal-outline.svg"
      ></dl-sidebar-button-image>
      <dl-sidebar-button-text>Umbuchungen</dl-sidebar-button-text>
    </dl-sidebar-button>

    <dl-sidebar-button
      *ngIf="hasScope('pageAccess:layouts')"
      [matchRouterLinkExact]="false"
      [routerLink]="'/layouts'"
      [active]="(currentUrl$ | async) === 'layouts'"
      routerLinkActive="active"
    >
      <dl-sidebar-button-image
        src="assets/icons/table-chart.svg"
      ></dl-sidebar-button-image>
      <dl-sidebar-button-text>Layouts</dl-sidebar-button-text>
    </dl-sidebar-button>
    <dl-sidebar-button
      *ngIf="hasScope('pageAccess:urlaubsantraege')"
      [matchRouterLinkExact]="false"
      [routerLink]="'/urlaubsantraege'"
      routerLinkActive="active"
    >
      <dl-sidebar-button-image
        src="assets/icons/plane.svg"
      ></dl-sidebar-button-image>
      <dl-sidebar-button-text> Urlaubsanträge </dl-sidebar-button-text>
    </dl-sidebar-button>
    <dl-sidebar-button
      *ngIf="hasScope('pageAccess:zutrittskontrolle')"
      [matchRouterLinkExact]="false"
      [routerLink]="'/zutrittskontrolle'"
      routerLinkActive="active"
    >
      <dl-sidebar-button-image
        src="assets/icons/shield-half-outline.svg"
      ></dl-sidebar-button-image>
      <dl-sidebar-button-text> Zutrittskontrolle </dl-sidebar-button-text>
    </dl-sidebar-button>
  </dl-sidebar>

  <dl-sidebar-content>
    <ion-router-outlet
      style="margin-left: 60px"
      [animated]="false"
    ></ion-router-outlet>
  </dl-sidebar-content>
</dl-sidebar-container>
