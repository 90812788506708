import { createFeature, createReducer, on } from '@ngrx/store';
import { produce } from 'immer';
import { ZugangDto } from '../../api/models';
import { ZugaengeActions } from './zugaenge.actions';

export const zugaengeFeatureKey = 'zugaenge';

export type State = {
  zugaenge: { [id: string]: ZugangDto | undefined };
  loading: boolean;
};

export const initialState: State = {
  zugaenge: {},
  loading: false,
};

export const reducer = createReducer(
  initialState,

  on(
    ZugaengeActions.loadZugaenge,
    (state): State => ({ ...state, loading: true }),
  ),
  on(ZugaengeActions.loadZugaengeSuccess, (state, { zugaenge }) =>
    produce(state, (draft) => {
      zugaenge.forEach((zu) => {
        draft.zugaenge[zu.id] = zu;
      });
      draft.loading = false;
    }),
  ),
  on(
    ZugaengeActions.loadZugaengeFailure,
    (state): State => ({
      ...state,
      loading: false,
    }),
  ),
);

export const zugaengeFeature = createFeature({
  name: zugaengeFeatureKey,
  reducer,
});
