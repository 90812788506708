import { createFeature, createReducer, on } from '@ngrx/store';
import { produce } from 'immer';
import { ChipDto } from '../../api/models';
import { ChipsActions } from './chips.actions';

export const chipsFeatureKey = 'chips';

export type State = {
  availableChips: { [ChipNr: string]: ChipDto | undefined };
  allChips: { [ChipNr: string]: ChipDto | undefined };
  loading: boolean;
};

export const initialState: State = {
  availableChips: {},
  allChips: {},
  loading: false,
};

export const reducer = createReducer(
  initialState,

  on(
    ChipsActions.loadAvailableChips,
    (state): State => ({ ...state, loading: true }),
  ),
  on(ChipsActions.loadAvailableChipsSuccess, (state, { availableChips }) =>
    produce(state, (draft) => {
      draft.availableChips = {};
      availableChips.forEach((ch) => {
        draft.availableChips[ch.chipNr] = ch;
      });
      draft.loading = false;
    }),
  ),
  on(
    ChipsActions.loadAvailableChipsFailure,
    (state): State => ({
      ...state,
      loading: false,
    }),
  ),
  on(
    ChipsActions.loadAllChips,
    (state): State => ({ ...state, loading: true }),
  ),
  on(ChipsActions.loadAllChipsSuccess, (state, { allChips }) =>
    produce(state, (draft) => {
      allChips.forEach((ch) => {
        draft.allChips[ch.chipNr] = ch;
      });
      draft.loading = false;
    }),
  ),
  on(
    ChipsActions.loadAllChipsFailure,
    (state): State => ({
      ...state,
      loading: false,
    }),
  ),
  on(
    ChipsActions.unrestrictChipSuccess,
    (state, { chip }): State =>
      produce(state, (draft) => {
        draft.allChips[chip.chipNr] = chip;
        draft.availableChips[chip.chipNr] = chip;
      }),
  ),
);

export const chipsFeature = createFeature({
  name: chipsFeatureKey,
  reducer,
});
