import { Component } from '@angular/core';
import { DlSidebarService } from '../sidebar.service';

@Component({
  selector: 'dl-sidebar-button-text',
  templateUrl: './sidebar-button-text.component.html',
  styleUrls: ['./sidebar-button-text.component.css'],
})
export class DlSidebarButtonTextComponent {
  constructor(public sidebarService: DlSidebarService) {}
}
