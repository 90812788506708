/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { AbteilungDto } from '../models/abteilung-dto';
import { getAbteilungen$Json } from '../fn/abteilung/get-abteilungen-json';
import { GetAbteilungen$Json$Params } from '../fn/abteilung/get-abteilungen-json';
import { getAbteilungen$Plain } from '../fn/abteilung/get-abteilungen-plain';
import { GetAbteilungen$Plain$Params } from '../fn/abteilung/get-abteilungen-plain';

@Injectable({ providedIn: 'root' })
export class AbteilungApiService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getAbteilungen()` */
  static readonly GetAbteilungenPath = '/v1/abteilungen';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAbteilungen$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAbteilungen$Plain$Response(params?: GetAbteilungen$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<AbteilungDto>>> {
    return getAbteilungen$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAbteilungen$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAbteilungen$Plain(params?: GetAbteilungen$Plain$Params, context?: HttpContext): Observable<Array<AbteilungDto>> {
    return this.getAbteilungen$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AbteilungDto>>): Array<AbteilungDto> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAbteilungen$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAbteilungen$Json$Response(params?: GetAbteilungen$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<AbteilungDto>>> {
    return getAbteilungen$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAbteilungen$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAbteilungen$Json(params?: GetAbteilungen$Json$Params, context?: HttpContext): Observable<Array<AbteilungDto>> {
    return this.getAbteilungen$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AbteilungDto>>): Array<AbteilungDto> => r.body)
    );
  }

}
