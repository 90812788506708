<a
  class="dl-sidebar-button"
  [routerLink]="routerLink"
  [routerLinkActive]="linkEnabled ? 'active' : 'disabled'"
  [routerLinkActiveOptions]="{ exact: matchRouterLinkExact }"
  [queryParamsHandling]="queryParamsHandling"
  [ngClass]="{ active: active }"
  [class.markYellow]="markYellow"
>
  <ng-content></ng-content>
</a>
