/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { BuchungserstellAntragDto } from '../models/buchungserstell-antrag-dto';
import { createBuchungserstellAntrag$Json } from '../fn/buchungserstell-antrags/create-buchungserstell-antrag-json';
import { CreateBuchungserstellAntrag$Json$Params } from '../fn/buchungserstell-antrags/create-buchungserstell-antrag-json';
import { createBuchungserstellAntrag$Plain } from '../fn/buchungserstell-antrags/create-buchungserstell-antrag-plain';
import { CreateBuchungserstellAntrag$Plain$Params } from '../fn/buchungserstell-antrags/create-buchungserstell-antrag-plain';
import { getAllErstellAntraegePaginated$Json } from '../fn/buchungserstell-antrags/get-all-erstell-antraege-paginated-json';
import { GetAllErstellAntraegePaginated$Json$Params } from '../fn/buchungserstell-antrags/get-all-erstell-antraege-paginated-json';
import { getAllErstellAntraegePaginated$Plain } from '../fn/buchungserstell-antrags/get-all-erstell-antraege-paginated-plain';
import { GetAllErstellAntraegePaginated$Plain$Params } from '../fn/buchungserstell-antrags/get-all-erstell-antraege-paginated-plain';
import { getOffeneBuchungserstellAntraege$Json } from '../fn/buchungserstell-antrags/get-offene-buchungserstell-antraege-json';
import { GetOffeneBuchungserstellAntraege$Json$Params } from '../fn/buchungserstell-antrags/get-offene-buchungserstell-antraege-json';
import { getOffeneBuchungserstellAntraege$Plain } from '../fn/buchungserstell-antrags/get-offene-buchungserstell-antraege-plain';
import { GetOffeneBuchungserstellAntraege$Plain$Params } from '../fn/buchungserstell-antrags/get-offene-buchungserstell-antraege-plain';
import { updateOneBuchungserstellAntrag$Json } from '../fn/buchungserstell-antrags/update-one-buchungserstell-antrag-json';
import { UpdateOneBuchungserstellAntrag$Json$Params } from '../fn/buchungserstell-antrags/update-one-buchungserstell-antrag-json';
import { updateOneBuchungserstellAntrag$Plain } from '../fn/buchungserstell-antrags/update-one-buchungserstell-antrag-plain';
import { UpdateOneBuchungserstellAntrag$Plain$Params } from '../fn/buchungserstell-antrags/update-one-buchungserstell-antrag-plain';

@Injectable({ providedIn: 'root' })
export class BuchungserstellAntragsApiService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getAllErstellAntraegePaginated()` */
  static readonly GetAllErstellAntraegePaginatedPath = '/v1/buchungserstellAntraege';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllErstellAntraegePaginated$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllErstellAntraegePaginated$Plain$Response(params?: GetAllErstellAntraegePaginated$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<BuchungserstellAntragDto>>> {
    return getAllErstellAntraegePaginated$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllErstellAntraegePaginated$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllErstellAntraegePaginated$Plain(params?: GetAllErstellAntraegePaginated$Plain$Params, context?: HttpContext): Observable<Array<BuchungserstellAntragDto>> {
    return this.getAllErstellAntraegePaginated$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<BuchungserstellAntragDto>>): Array<BuchungserstellAntragDto> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllErstellAntraegePaginated$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllErstellAntraegePaginated$Json$Response(params?: GetAllErstellAntraegePaginated$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<BuchungserstellAntragDto>>> {
    return getAllErstellAntraegePaginated$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllErstellAntraegePaginated$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllErstellAntraegePaginated$Json(params?: GetAllErstellAntraegePaginated$Json$Params, context?: HttpContext): Observable<Array<BuchungserstellAntragDto>> {
    return this.getAllErstellAntraegePaginated$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<BuchungserstellAntragDto>>): Array<BuchungserstellAntragDto> => r.body)
    );
  }

  /** Path part for operation `createBuchungserstellAntrag()` */
  static readonly CreateBuchungserstellAntragPath = '/v1/buchungen/{mitarbeiterId}/erstellAntrag';

  /**
   * Erstellt einen BuchungserstellAntrag für den angebenen Benutzer.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createBuchungserstellAntrag$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createBuchungserstellAntrag$Plain$Response(params: CreateBuchungserstellAntrag$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<BuchungserstellAntragDto>> {
    return createBuchungserstellAntrag$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Erstellt einen BuchungserstellAntrag für den angebenen Benutzer.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createBuchungserstellAntrag$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createBuchungserstellAntrag$Plain(params: CreateBuchungserstellAntrag$Plain$Params, context?: HttpContext): Observable<BuchungserstellAntragDto> {
    return this.createBuchungserstellAntrag$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<BuchungserstellAntragDto>): BuchungserstellAntragDto => r.body)
    );
  }

  /**
   * Erstellt einen BuchungserstellAntrag für den angebenen Benutzer.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createBuchungserstellAntrag$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createBuchungserstellAntrag$Json$Response(params: CreateBuchungserstellAntrag$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<BuchungserstellAntragDto>> {
    return createBuchungserstellAntrag$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Erstellt einen BuchungserstellAntrag für den angebenen Benutzer.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createBuchungserstellAntrag$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createBuchungserstellAntrag$Json(params: CreateBuchungserstellAntrag$Json$Params, context?: HttpContext): Observable<BuchungserstellAntragDto> {
    return this.createBuchungserstellAntrag$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<BuchungserstellAntragDto>): BuchungserstellAntragDto => r.body)
    );
  }

  /** Path part for operation `updateOneBuchungserstellAntrag()` */
  static readonly UpdateOneBuchungserstellAntragPath = '/v1/buchungserstellAntraege/{antragsId}';

  /**
   * Aktualisiert einen BuchungserstellAntrag.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateOneBuchungserstellAntrag$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateOneBuchungserstellAntrag$Plain$Response(params: UpdateOneBuchungserstellAntrag$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<BuchungserstellAntragDto>> {
    return updateOneBuchungserstellAntrag$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Aktualisiert einen BuchungserstellAntrag.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateOneBuchungserstellAntrag$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateOneBuchungserstellAntrag$Plain(params: UpdateOneBuchungserstellAntrag$Plain$Params, context?: HttpContext): Observable<BuchungserstellAntragDto> {
    return this.updateOneBuchungserstellAntrag$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<BuchungserstellAntragDto>): BuchungserstellAntragDto => r.body)
    );
  }

  /**
   * Aktualisiert einen BuchungserstellAntrag.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateOneBuchungserstellAntrag$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateOneBuchungserstellAntrag$Json$Response(params: UpdateOneBuchungserstellAntrag$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<BuchungserstellAntragDto>> {
    return updateOneBuchungserstellAntrag$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Aktualisiert einen BuchungserstellAntrag.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateOneBuchungserstellAntrag$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateOneBuchungserstellAntrag$Json(params: UpdateOneBuchungserstellAntrag$Json$Params, context?: HttpContext): Observable<BuchungserstellAntragDto> {
    return this.updateOneBuchungserstellAntrag$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<BuchungserstellAntragDto>): BuchungserstellAntragDto => r.body)
    );
  }

  /** Path part for operation `getOffeneBuchungserstellAntraege()` */
  static readonly GetOffeneBuchungserstellAntraegePath = '/v1/mitarbeiter/{mitarbeiterId}/erstellAntraege/offen';

  /**
   * Holt alle offenen BuchungserstellAntraege für einen Mitarbeiter.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOffeneBuchungserstellAntraege$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOffeneBuchungserstellAntraege$Plain$Response(params: GetOffeneBuchungserstellAntraege$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<BuchungserstellAntragDto>>> {
    return getOffeneBuchungserstellAntraege$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Holt alle offenen BuchungserstellAntraege für einen Mitarbeiter.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getOffeneBuchungserstellAntraege$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOffeneBuchungserstellAntraege$Plain(params: GetOffeneBuchungserstellAntraege$Plain$Params, context?: HttpContext): Observable<Array<BuchungserstellAntragDto>> {
    return this.getOffeneBuchungserstellAntraege$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<BuchungserstellAntragDto>>): Array<BuchungserstellAntragDto> => r.body)
    );
  }

  /**
   * Holt alle offenen BuchungserstellAntraege für einen Mitarbeiter.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOffeneBuchungserstellAntraege$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOffeneBuchungserstellAntraege$Json$Response(params: GetOffeneBuchungserstellAntraege$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<BuchungserstellAntragDto>>> {
    return getOffeneBuchungserstellAntraege$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Holt alle offenen BuchungserstellAntraege für einen Mitarbeiter.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getOffeneBuchungserstellAntraege$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOffeneBuchungserstellAntraege$Json(params: GetOffeneBuchungserstellAntraege$Json$Params, context?: HttpContext): Observable<Array<BuchungserstellAntragDto>> {
    return this.getOffeneBuchungserstellAntraege$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<BuchungserstellAntragDto>>): Array<BuchungserstellAntragDto> => r.body)
    );
  }

}
