import { timer } from 'rxjs';
import { take } from 'rxjs/operators';

export class ScrollIntoViewOptions {
  animationResolution = 20; // Stepsize in milliseconds
  animationDuration = 100; // Duration in milliseconds
}

export async function scrollIntoView(
  parent: HTMLElement,
  child: HTMLElement,
  options = new ScrollIntoViewOptions()
) {
  return new Promise<void>((resolve, reject) => {
    // Get the the parent position
    const parentRect = parent.getBoundingClientRect();
    const parentViewableArea = {
      height: parent.clientHeight,
      width: parent.clientWidth,
    };

    // Where is the child relative to parent
    const childRect = child.getBoundingClientRect();
    const isViewable =
      childRect.top >= parentRect.top &&
      childRect.bottom <= parentRect.top + parentViewableArea.height;
    if (!isViewable) {
      // Calculate step size for smooth scroll
      const currentScrollPosition = parent.scrollTop;
      const newScrollPosition =
        childRect.top + parent.scrollTop - parentRect.top;
      const difference = newScrollPosition - currentScrollPosition;
      const stepAmount =
        options.animationDuration / options.animationResolution;
      const stepSize = difference / stepAmount;

      timer(0, options.animationResolution)
        .pipe(take(stepAmount))
        .subscribe(
          () => {
            parent.scrollTop += stepSize;
          },
          error => {
            reject(error);
          },
          () => {
            resolve();
          }
        );
    }
  });
}
