import { Component, Input } from '@angular/core';

@Component({
  selector: 'dl-page-header-dropdown-entry',
  templateUrl: './page-header-dropdown-entry.component.html',
  styleUrls: ['./page-header-dropdown-entry.component.css'],
})
export class DlPageHeaderDropdownEntryComponent {
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('src') imageSrc: string | undefined;
  @Input() routerLink: string | null = null;
}
