import { createFeature, createReducer, on } from '@ngrx/store';
import { produce } from 'immer';
import { MehrarbeitAuszahlungDto } from '../../api/models';
import { MitarbeiterActions } from '../mitarbeiter/mitarbeiter.actions';
import { MehrarbeitAuszahlungenActions } from './mehrarbeitauszahlungen.actions';

export const mehrarbeitAuszahlungenFeatureKey = 'mehrarbeitAuszahlungen';

export interface State {
  mehrarbeitAuszahlungen: { [id: string]: MehrarbeitAuszahlungDto | undefined };
  mehrarbeitAuszahlungenPageData: string[] | null;
  page: number;
  pageSize: number;
  totalCount: number | undefined;
  selectedMonth: number | undefined;
  selectedYear: number | undefined;
  loading: boolean;
}

export const initialState: State = {
  mehrarbeitAuszahlungen: {},
  mehrarbeitAuszahlungenPageData: null,
  page: 0,
  pageSize: 15,
  totalCount: undefined,
  selectedMonth: new Date().getMonth() + 1,
  selectedYear: new Date().getFullYear(),
  loading: false,
};

export const reducer = createReducer(
  initialState,
  on(
    MehrarbeitAuszahlungenActions.loadMehrarbeitauszahlungen,
    (state): State => ({
      ...state,
      loading: true,
    }),
  ),
  on(
    MehrarbeitAuszahlungenActions.loadMehrarbeitauszahlungenSuccess,
    (state, { data, totalCount }): State =>
      produce(state, (draft) => {
        data.forEach((mau) => {
          draft.mehrarbeitAuszahlungen[mau.id] = mau;
        });
        draft.mehrarbeitAuszahlungenPageData = data.map((mau) => mau.id);
        draft.totalCount = totalCount;
        draft.loading = false;
      }),
  ),
  on(
    MehrarbeitAuszahlungenActions.loadMehrarbeitauszahlungenFailure,
    (state): State => ({
      ...state,
      loading: false,
    }),
  ),
  on(
    MehrarbeitAuszahlungenActions.createMehrarbeitauszahlung,
    (state): State => ({
      ...state,
      loading: true,
    }),
  ),
  on(
    MehrarbeitAuszahlungenActions.createMehrarbeitauszahlungFailure,
    (state): State => ({
      ...state,
      loading: false,
    }),
  ),
  on(
    MehrarbeitAuszahlungenActions.createMehrarbeitauszahlungSuccess,
    (state, { data }): State =>
      produce(state, (draft) => {
        draft.mehrarbeitAuszahlungen[data.id] = data;
        draft.mehrarbeitAuszahlungenPageData?.push(data.id)
        draft.loading = false;
      }),
  ),
  on(
    MitarbeiterActions.setSelectedMitarbeiterId,
    (state): State => ({
      ...state,
      mehrarbeitAuszahlungenPageData: null,
      mehrarbeitAuszahlungen: {},
      totalCount: undefined,
    }),
  ),
  on(
    MehrarbeitAuszahlungenActions.setPageIndex,
    (state, { pageIndex }): State => ({
      ...state,
      page: pageIndex,
      mehrarbeitAuszahlungenPageData: null,
    }),
  ),
  on(
    MehrarbeitAuszahlungenActions.setPageSize,
    (state, { pageSize }): State => ({
      ...state,
      pageSize,
    }),
  ),
  on(
    MehrarbeitAuszahlungenActions.setSelectedMonthAndYear,
    (state, { selectedMonth, selectedYear }): State => ({
      ...state,
      selectedMonth,
      selectedYear,
    }),
  ),
);

export const mehrarbeitAuszahlungenFeature = createFeature({
  name: mehrarbeitAuszahlungenFeatureKey,
  reducer: reducer,
});
