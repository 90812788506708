import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SparzeitUmbuchungDto } from '../../api/models';
import { selectSelectedMitarbeiterId } from '../mitarbeiter/mitarbeiter.selectors';
import * as fromsparzeitUmbuchungen from './sparzeitumbuchungen.reducer';

export const selectSparzeitUmbuchungenState =
  createFeatureSelector<fromsparzeitUmbuchungen.State>(
    fromsparzeitUmbuchungen.sparzeitUmbuchungFeatureKey,
  );

export const selectSparzeitUmbuchungen = createSelector(
  selectSparzeitUmbuchungenState,
  (state) => state.sparzeitUmbuchungen,
);

export const selectSparzeitUmbuchungenArray = createSelector(
  selectSparzeitUmbuchungen,
  (state) => {
    const sparzeitUmbuchungenArray = Object.values(state);
    return sparzeitUmbuchungenArray.filter(
      (sparzeitUmbuchungen): sparzeitUmbuchungen is SparzeitUmbuchungDto =>
        !!sparzeitUmbuchungen,
    );
  },
);

export const selectTotalCount = createSelector(
  selectSparzeitUmbuchungenState,
  (state) => state.totalCount ?? 0,
);

export const selectSparzeitUmbuchungenPage = createSelector(
  selectSparzeitUmbuchungenState,
  (state) => {
    if (!state.sparzeitUmbuchungPageData) {
      return undefined;
    }
    return state.sparzeitUmbuchungPageData
      .map((id) => state.sparzeitUmbuchungen[id] as SparzeitUmbuchungDto)
      .filter((mau) => !!mau);
  },
);

export type SparzeitUmbuchungenDtoGroupedByDate = {
  sparzeitUmbuchungen: SparzeitUmbuchungDto[];
  monat: number;
  jahr: number;
};

export type SparzeitUmbuchungenDtoGroupedByDateMap = {
  [jahrMonat: string]: SparzeitUmbuchungenDtoGroupedByDate;
};

export const selectSparzeitUmbuchungenGroupedByDatum = createSelector(
  selectSparzeitUmbuchungenPage,
  (sparzeitUmbuchungen) => {
    if (!sparzeitUmbuchungen) {
      return undefined;
    }
    return sparzeitUmbuchungen.reduce((acc, sparzeitUmbuchung) => {
      const key = sparzeitUmbuchung.jahr + sparzeitUmbuchung.monat;
      if (!acc[key]) {
        acc[key] = {
          sparzeitUmbuchungen: [sparzeitUmbuchung],
          monat: sparzeitUmbuchung.monat,
          jahr: sparzeitUmbuchung.jahr,
        };
      } else {
        acc[key].sparzeitUmbuchungen.push(sparzeitUmbuchung);
      }
      return acc;
    }, {} as SparzeitUmbuchungenDtoGroupedByDateMap);
  },
);

export const selectSparzeitUmbuchungenDesMonats = (
  monat: number,
  jahr: number,
) =>
  createSelector(selectSparzeitUmbuchungenPage, (sparzeitUmbuchungen) => {
    if (!sparzeitUmbuchungen) {
      return undefined;
    }
    // Filtern der Buchungen des übergebenen Monats und Jahres
    const sparzeitUmbuchungenDesMonats = sparzeitUmbuchungen.filter(
      (sparzeitUmbuchung) => {
        return (
          sparzeitUmbuchung.monat === monat && sparzeitUmbuchung.jahr === jahr
        );
      },
    );

    sparzeitUmbuchungenDesMonats.sort(
      (a, b) =>
        new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(),
    );
    return sparzeitUmbuchungenDesMonats;
  });

export const selectPage = createSelector(
  selectSparzeitUmbuchungenState,
  (state) => state.page,
);

export const selectPageSize = createSelector(
  selectSparzeitUmbuchungenState,
  (state) => state.pageSize,
);

export const selectLoading = createSelector(
  selectSparzeitUmbuchungenState,
  selectSelectedMitarbeiterId,
  (state, mitarbeiterId) => mitarbeiterId && state.loading,
);

export const selectSelectedMonth = createSelector(
  selectSparzeitUmbuchungenState,
  (state) => state.selectedMonth ?? new Date().getMonth() + 1,
);

export const selectSelectedYear = createSelector(
  selectSparzeitUmbuchungenState,
  (state) => state.selectedYear ?? new Date().getFullYear(),
);

export const selectSelectedYearMonth = createSelector(
  selectSparzeitUmbuchungenState,
  (state) =>
    [state.selectedYear, state.selectedMonth] ?? new Date().getFullYear(),
);
