import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { map, of } from 'rxjs';
import {
  catchError,
  concatMap,
  debounceTime,
  filter,
  switchMap,
  tap,
} from 'rxjs/operators';
import { handleHttpError } from '../../../common/httpErrorHandler';
import { MehrarbeitUmbuchungApiService } from '../../api/services';
import { PAGINATION_HEADER_NAME } from '../../pagination-header';
import * as fromAuth from '../auth/auth.selectors';
import { MitarbeiterActions } from '../mitarbeiter/mitarbeiter.actions';
import * as fromMitarbeiter from '../mitarbeiter/mitarbeiter.selectors';
import { MehrarbeitUmbuchungenActions } from './mehrarbeitumbuchungen.actions';
import * as fromMehrarbeitUmbuchungen from './mehrarbeitumbuchungen.selectors';

@Injectable()
export class MehrarbeitUmbuchungenEffects {
  loadMehrarbeitUmbuchungens$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        MehrarbeitUmbuchungenActions.loadMehrarbeitumbuchungen,
        MehrarbeitUmbuchungenActions.setPageIndex,
        MehrarbeitUmbuchungenActions.setPageSize,
        MitarbeiterActions.setSelectedMitarbeiterId,
        MehrarbeitUmbuchungenActions.setSelectedMonthAndYear,
      ),
      concatLatestFrom(() => [
        this.store.select(fromMitarbeiter.selectSelectedMitarbeiterId),
        this.store.select(fromMehrarbeitUmbuchungen.selectSelectedMonth),
        this.store.select(fromMehrarbeitUmbuchungen.selectSelectedYear),
        this.store.select(fromAuth.selectHasScope("pageAccess:umbuchungen"))
      ]),
      debounceTime(50),
      filter(([, , , , canAccessUmbuchungen]) => canAccessUmbuchungen),
      filter(([, selectedMitarbeiterId]) => selectedMitarbeiterId !== ''), // Filter out if no mitarbeiter is selected
      switchMap(([, selectedMitarbeiterId, selectedMonth, selectedYear]) =>
        this._mehrarbeitumbuchungApi
          .getAllMehrarbeitUmbuchungen$Json$Response({
            date: `${selectedYear}-${selectedMonth}-01`,
            mitarbeiterId: selectedMitarbeiterId,
          })
          .pipe(
            map((response) => {
              return MehrarbeitUmbuchungenActions.loadMehrarbeitumbuchungenSuccess(
                {
                  data: response.body,
                  totalCount: response.headers.get(PAGINATION_HEADER_NAME)
                    ? JSON.parse(
                        response.headers.get(PAGINATION_HEADER_NAME) as string,
                      ).TotalCount
                    : response.body.length,
                },
              );
            }),
            catchError((error) =>
              of(
                MehrarbeitUmbuchungenActions.loadMehrarbeitumbuchungenFailure({
                  error,
                }),
              ),
            ),
          ),
      ),
    );
  });

  createMehrarbeitUmbuchung$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MehrarbeitUmbuchungenActions.createMehrarbeitumbuchung),
      concatLatestFrom(() => [
        this.store.select(fromMitarbeiter.selectSelectedMitarbeiterId),
      ]),
      filter(([, mitarbeiterId]) => mitarbeiterId !== ''),
      concatMap(([{ umbuchungInSekunden, umbuchungDatum }, mitarbeiterId ]) =>
        this._mehrarbeitumbuchungApi
          .createMehrarbeitUmbuchung$Json({
            body: {
              umbuchungDatum: umbuchungDatum,
              mitarbeiterId: mitarbeiterId,
              umbuchungInSekunden: umbuchungInSekunden,
            },
          })
          .pipe(
            map((res) =>
              MehrarbeitUmbuchungenActions.createMehrarbeitumbuchungSuccess({
                data: res,
              }),
            ),
            catchError((err) =>
              of(
                MehrarbeitUmbuchungenActions.createMehrarbeitumbuchungFailure({
                  error: err,
                }),
              ),
            ),
          ),
      ),
    );
  });

  // Toast Handling
  createMehrarbeitUmbuchungFailure$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(MehrarbeitUmbuchungenActions.createMehrarbeitumbuchungFailure),
        tap(async ({ error }) => {
          console.error(error);
          await handleHttpError(
            error,
            this.toastController,
            'Beim Erstellen der Mehrarbeitumbuchung ist ein Fehler aufgetreten.',
          );
        }),
      );
    },
    { dispatch: false },
  );

  createMehrarbeitUmbuchungSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(MehrarbeitUmbuchungenActions.createMehrarbeitumbuchungSuccess),
        tap(async () => {
          const toast = await this.toastController.create({
            message: 'Mehrarbeit Umbuchung erfolgreich erstellt.',
            duration: 3000,
            color: 'success',
          });
          await toast.present();
        }),
      );
    },
    { dispatch: false },
  );

  constructor(
    private actions$: Actions,
    private _mehrarbeitumbuchungApi: MehrarbeitUmbuchungApiService,
    private readonly toastController: ToastController,
    private store: Store,
  ) {}
}
