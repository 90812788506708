import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { concatMap, map, of, switchMap, tap } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { extractPaginationHeader } from '../../../common/extract-pagination-header';
import { handleHttpError } from '../../../common/httpErrorHandler';
import { UrlaubsantragApiService } from '../../api/services/urlaubsantrag-api.service';
import * as fromAuth from '../auth/auth.selectors';
import * as fromMitarbeiter from '../mitarbeiter/mitarbeiter.selectors';
import { UrlaubsantraegeActions } from './urlaubsantraege.actions';
import * as fromUrlaubsantraege from './urlaubsantraege.selectors';

@Injectable()
export class UrlaubsantraegeEffects {
  loadAllUrlaubsantraegePage$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        UrlaubsantraegeActions.loadAllUrlaubsantraege,
        UrlaubsantraegeActions.createUrlaubsantragSuccess,
        UrlaubsantraegeActions.processUrlaubsantragSuccess,
        UrlaubsantraegeActions.setIncludeProcessed,
      ),
      concatLatestFrom(() => [
        this.store.select(
          fromUrlaubsantraege.selectUrlaubsantraegePaginationData,
        ),
        this.store.select(
          fromUrlaubsantraege.selectUrlaubsantraegeIncludeProcessed,
        ),
      ]),
      switchMap(([, { index, size }, includeProcessed]) =>
        this.urlaubsantraegeApi
          .getAllUrlaubsantraege$Json$Response({
            IncludeGenehmigt: includeProcessed,
            PageNumber: index + 1,
            PageSize: size,
          })
          .pipe(
            map((response) => {
              const paginationHeader = extractPaginationHeader(response);
              return UrlaubsantraegeActions.loadAllUrlaubsantraegeSuccess({
                urlaubsantraege: response.body,
                totalCount: paginationHeader.TotalCount,
              });
            }),
            catchError((error) =>
              of(
                UrlaubsantraegeActions.loadAllUrlaubsantraegeFailure({ error }),
              ),
            ),
          ),
      ),
    );
  });

  loadUnitUrlaubsantraegePage$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        UrlaubsantraegeActions.loadUnitUrlaubsantraege,
        UrlaubsantraegeActions.createUrlaubsantragSuccess,
        UrlaubsantraegeActions.processUrlaubsantragSuccess,
        UrlaubsantraegeActions.setIncludeProcessed,
      ),
      concatLatestFrom(() => [
        this.store.select(
          fromUrlaubsantraege.selectUrlaubsantraegePaginationDataUnit,
        ),
        this.store.select(
          fromUrlaubsantraege.selectUrlaubsantraegeIncludeProcessed,
        ),
        this.store.select(fromAuth.selectLoggedInMitarbeiterUnit),
      ]),
      switchMap(([, { index, size }, includeProcessed, unit]) =>
        this.urlaubsantraegeApi
          .getAllUrlaubsantraege$Json$Response({
            IncludeGenehmigt: includeProcessed,
            PageNumber: index + 1,
            PageSize: size,
            Unit: unit ?? undefined,
          })
          .pipe(
            map((response) => {
              const paginationHeader = extractPaginationHeader(response);
              return UrlaubsantraegeActions.loadUnitUrlaubsantraegeSuccess({
                urlaubsantraege: response.body,
                totalCount: paginationHeader.TotalCount,
              });
            }),
            catchError((error) =>
              of(
                UrlaubsantraegeActions.loadUnitUrlaubsantraegeFailure({
                  error,
                }),
              ),
            ),
          ),
      ),
    );
  });

  loadMitarbeiterPageData = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        UrlaubsantraegeActions.loadUserUrlaubsantraege,
        UrlaubsantraegeActions.createUrlaubsantragSuccess,
        UrlaubsantraegeActions.processUrlaubsantragSuccess,
        UrlaubsantraegeActions.setIncludeProcessed,
      ),
      concatLatestFrom(() => [
        this.store.select(
          fromUrlaubsantraege.selectUrlaubsantraegePaginationDataMitarbeiter,
        ),
        this.store.select(
          fromUrlaubsantraege.selectUrlaubsantraegeIncludeProcessed,
        ),
        this.store.select(fromMitarbeiter.selectSelectedMitarbeiterId),
      ]),
      switchMap(
        ([, { index, size }, includeProcessed, selectedMitarbeiterId]) =>
          this.urlaubsantraegeApi
            .getAllUrlaubsantraege$Json$Response({
              IncludeGenehmigt: includeProcessed,
              PageNumber: index + 1,
              PageSize: size,
              MitarbeiterId: selectedMitarbeiterId,
            })
            .pipe(
              map((response) => {
                const paginationHeader = extractPaginationHeader(response);
                return UrlaubsantraegeActions.loadUserUrlaubsantraegeSuccess({
                  urlaubsantraege: response.body,
                  totalCount: paginationHeader.TotalCount,
                });
              }),
              catchError((error) =>
                of(
                  UrlaubsantraegeActions.loadUserUrlaubsantraegeFailure({
                    error,
                  }),
                ),
              ),
            ),
      ),
    );
  });

  createUrlaubsantrag$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UrlaubsantraegeActions.createUrlaubsantrag),
      concatMap(({ urlaubsantrag }) =>
        this.urlaubsantraegeApi
          .createUrlaubsantrag$Json({ body: urlaubsantrag })
          .pipe(
            map((createdUrlaubsantrag) =>
              UrlaubsantraegeActions.createUrlaubsantragSuccess({
                urlaubsantrag: createdUrlaubsantrag,
              }),
            ),
            catchError((err) =>
              of(
                UrlaubsantraegeActions.createUrlaubsantragFailure({
                  error: err,
                }),
              ),
            ),
          ),
      ),
    );
  });

  processUrlaubsantrag$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UrlaubsantraegeActions.processUrlaubsantrag),
      concatMap(({ id, genehmigt }) =>
        this.urlaubsantraegeApi
          .updateUrlaubsantrag$Json({
            id,
            body: { genehmigt },
          })
          .pipe(
            map((updatedUrlaubsantrag) =>
              UrlaubsantraegeActions.processUrlaubsantragSuccess({
                urlaubsantrag: updatedUrlaubsantrag,
              }),
            ),
            catchError((err) =>
              of(
                UrlaubsantraegeActions.processUrlaubsantragFailure({
                  error: err,
                }),
              ),
            ),
          ),
      ),
    );
  });

  displaySuccessToast$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(
          UrlaubsantraegeActions.createUrlaubsantragSuccess,
          UrlaubsantraegeActions.processUrlaubsantragSuccess,
        ),
        tap(async () => {
          const toast = await this.toastCtrl.create({
            message: 'Urlaubsantrag erfolgreich aktualisiert.',
            duration: 5000,
            color: 'success',
          });
          await toast.present();
        }),
      );
    },
    { dispatch: false },
  );

  displayErrorToast$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(
          UrlaubsantraegeActions.createUrlaubsantragFailure,
          UrlaubsantraegeActions.processUrlaubsantragFailure,
        ),
        tap(async ({ error }) => {
          await handleHttpError(
            error,
            this.toastCtrl,
            'Es ist ein unerwarter Fehler aufgetreten',
          );
        }),
      );
    },
    { dispatch: false },
  );

  constructor(
    private actions$: Actions,
    private urlaubsantraegeApi: UrlaubsantragApiService,
    private store: Store,
    private toastCtrl: ToastController,
  ) {}
}
