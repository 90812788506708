import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DlPaginator } from './paginator/paginator.component';
import { DlButtonModule } from '@datenlotse/components/button';
import { DlSelectModule } from '@datenlotse/components/select';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [DlPaginator],
  imports: [CommonModule, DlButtonModule, DlSelectModule, FormsModule],
  exports: [DlPaginator],
})
export class DlPaginatorModule {}
