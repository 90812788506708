type sortDirection = 'asc' | 'desc';

export class SortHelper<T> {
  private sorts: {
    field: string | number | symbol;
    direction: sortDirection;
  }[] = [];

  addSorting(property: keyof T, direction: sortDirection = 'asc') {
    if (this.sorts.find((x) => x.field === property)) {
      console.warn(
        `You tried to add property ${String(
          property
        )} which is already added to the sorting. Ignoring`
      );
      return this;
    }

    this.sorts.push({
      field: property,
      direction,
    });
    return this;
  }

  toString(): string | undefined {
    if (this.sorts.length == 0) {
      return undefined;
    }
    let str = '';

    this.sorts.forEach((sort) => {
      str += `${String(sort.field)} ${sort.direction},`;
    });
    // Remove last ,
    str = str.slice(0, str.length - 1);
    return str;
  }
}
