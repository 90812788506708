/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { createUrlaubsanspruecheForMitarbeiter } from '../fn/urlaubsanspruch/create-urlaubsansprueche-for-mitarbeiter';
import { CreateUrlaubsanspruecheForMitarbeiter$Params } from '../fn/urlaubsanspruch/create-urlaubsansprueche-for-mitarbeiter';
import { deleteRecurringUrlaubsansprueche } from '../fn/urlaubsanspruch/delete-recurring-urlaubsansprueche';
import { DeleteRecurringUrlaubsansprueche$Params } from '../fn/urlaubsanspruch/delete-recurring-urlaubsansprueche';
import { deleteUrlaubsanspruch } from '../fn/urlaubsanspruch/delete-urlaubsanspruch';
import { DeleteUrlaubsanspruch$Params } from '../fn/urlaubsanspruch/delete-urlaubsanspruch';
import { deleteUrlaubsansprueche } from '../fn/urlaubsanspruch/delete-urlaubsansprueche';
import { DeleteUrlaubsansprueche$Params } from '../fn/urlaubsanspruch/delete-urlaubsansprueche';
import { getUrlaubsanspruecheForMitarbeiter$Json } from '../fn/urlaubsanspruch/get-urlaubsansprueche-for-mitarbeiter-json';
import { GetUrlaubsanspruecheForMitarbeiter$Json$Params } from '../fn/urlaubsanspruch/get-urlaubsansprueche-for-mitarbeiter-json';
import { getUrlaubsanspruecheForMitarbeiter$Plain } from '../fn/urlaubsanspruch/get-urlaubsansprueche-for-mitarbeiter-plain';
import { GetUrlaubsanspruecheForMitarbeiter$Plain$Params } from '../fn/urlaubsanspruch/get-urlaubsansprueche-for-mitarbeiter-plain';
import { updateUrlaubsanspruch$Json } from '../fn/urlaubsanspruch/update-urlaubsanspruch-json';
import { UpdateUrlaubsanspruch$Json$Params } from '../fn/urlaubsanspruch/update-urlaubsanspruch-json';
import { updateUrlaubsanspruch$Plain } from '../fn/urlaubsanspruch/update-urlaubsanspruch-plain';
import { UpdateUrlaubsanspruch$Plain$Params } from '../fn/urlaubsanspruch/update-urlaubsanspruch-plain';
import { UrlaubsanspruchDto } from '../models/urlaubsanspruch-dto';
import { UrlaubsanspruecheWithUebertragDto } from '../models/urlaubsansprueche-with-uebertrag-dto';

@Injectable({ providedIn: 'root' })
export class UrlaubsanspruchApiService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getUrlaubsanspruecheForMitarbeiter()` */
  static readonly GetUrlaubsanspruecheForMitarbeiterPath = '/v1/mitarbeiter/{mitarbeiterId}/urlaubsansprueche';

  /**
   * Holt alle Urlaubsansprüche für einen Mitarbeiter.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUrlaubsanspruecheForMitarbeiter$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUrlaubsanspruecheForMitarbeiter$Plain$Response(params: GetUrlaubsanspruecheForMitarbeiter$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<UrlaubsanspruecheWithUebertragDto>> {
    return getUrlaubsanspruecheForMitarbeiter$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Holt alle Urlaubsansprüche für einen Mitarbeiter.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getUrlaubsanspruecheForMitarbeiter$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUrlaubsanspruecheForMitarbeiter$Plain(params: GetUrlaubsanspruecheForMitarbeiter$Plain$Params, context?: HttpContext): Observable<UrlaubsanspruecheWithUebertragDto> {
    return this.getUrlaubsanspruecheForMitarbeiter$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<UrlaubsanspruecheWithUebertragDto>): UrlaubsanspruecheWithUebertragDto => r.body)
    );
  }

  /**
   * Holt alle Urlaubsansprüche für einen Mitarbeiter.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUrlaubsanspruecheForMitarbeiter$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUrlaubsanspruecheForMitarbeiter$Json$Response(params: GetUrlaubsanspruecheForMitarbeiter$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<UrlaubsanspruecheWithUebertragDto>> {
    return getUrlaubsanspruecheForMitarbeiter$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Holt alle Urlaubsansprüche für einen Mitarbeiter.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getUrlaubsanspruecheForMitarbeiter$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUrlaubsanspruecheForMitarbeiter$Json(params: GetUrlaubsanspruecheForMitarbeiter$Json$Params, context?: HttpContext): Observable<UrlaubsanspruecheWithUebertragDto> {
    return this.getUrlaubsanspruecheForMitarbeiter$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<UrlaubsanspruecheWithUebertragDto>): UrlaubsanspruecheWithUebertragDto => r.body)
    );
  }

  /** Path part for operation `createUrlaubsanspruecheForMitarbeiter()` */
  static readonly CreateUrlaubsanspruecheForMitarbeiterPath = '/v1/mitarbeiter/{mitarbeiterId}/urlaubsansprueche';

  /**
   * Erstellt mehrere Urlaubsansprüche für einen Mitarbeiter.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createUrlaubsanspruecheForMitarbeiter()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createUrlaubsanspruecheForMitarbeiter$Response(params: CreateUrlaubsanspruecheForMitarbeiter$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return createUrlaubsanspruecheForMitarbeiter(this.http, this.rootUrl, params, context);
  }

  /**
   * Erstellt mehrere Urlaubsansprüche für einen Mitarbeiter.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createUrlaubsanspruecheForMitarbeiter$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createUrlaubsanspruecheForMitarbeiter(params: CreateUrlaubsanspruecheForMitarbeiter$Params, context?: HttpContext): Observable<void> {
    return this.createUrlaubsanspruecheForMitarbeiter$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `updateUrlaubsanspruch()` */
  static readonly UpdateUrlaubsanspruchPath = '/v1/urlaubsansprueche/{id}';

  /**
   * Aktualisiert einen Urlaubsanspruch.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateUrlaubsanspruch$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateUrlaubsanspruch$Plain$Response(params: UpdateUrlaubsanspruch$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<UrlaubsanspruchDto>> {
    return updateUrlaubsanspruch$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Aktualisiert einen Urlaubsanspruch.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateUrlaubsanspruch$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateUrlaubsanspruch$Plain(params: UpdateUrlaubsanspruch$Plain$Params, context?: HttpContext): Observable<UrlaubsanspruchDto> {
    return this.updateUrlaubsanspruch$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<UrlaubsanspruchDto>): UrlaubsanspruchDto => r.body)
    );
  }

  /**
   * Aktualisiert einen Urlaubsanspruch.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateUrlaubsanspruch$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateUrlaubsanspruch$Json$Response(params: UpdateUrlaubsanspruch$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<UrlaubsanspruchDto>> {
    return updateUrlaubsanspruch$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Aktualisiert einen Urlaubsanspruch.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateUrlaubsanspruch$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateUrlaubsanspruch$Json(params: UpdateUrlaubsanspruch$Json$Params, context?: HttpContext): Observable<UrlaubsanspruchDto> {
    return this.updateUrlaubsanspruch$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<UrlaubsanspruchDto>): UrlaubsanspruchDto => r.body)
    );
  }

  /** Path part for operation `deleteUrlaubsanspruch()` */
  static readonly DeleteUrlaubsanspruchPath = '/v1/urlaubsansprueche/{id}';

  /**
   * Soft Deletes einen Urlaubsanspruch.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteUrlaubsanspruch()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteUrlaubsanspruch$Response(params: DeleteUrlaubsanspruch$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteUrlaubsanspruch(this.http, this.rootUrl, params, context);
  }

  /**
   * Soft Deletes einen Urlaubsanspruch.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteUrlaubsanspruch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteUrlaubsanspruch(params: DeleteUrlaubsanspruch$Params, context?: HttpContext): Observable<void> {
    return this.deleteUrlaubsanspruch$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `deleteRecurringUrlaubsansprueche()` */
  static readonly DeleteRecurringUrlaubsanspruechePath = '/v1/recurringurlaubsansprueche';

  /**
   * Soft Deletes mehrerer recurring Urlaubsansprueche.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteRecurringUrlaubsansprueche()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteRecurringUrlaubsansprueche$Response(params: DeleteRecurringUrlaubsansprueche$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteRecurringUrlaubsansprueche(this.http, this.rootUrl, params, context);
  }

  /**
   * Soft Deletes mehrerer recurring Urlaubsansprueche.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteRecurringUrlaubsansprueche$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteRecurringUrlaubsansprueche(params: DeleteRecurringUrlaubsansprueche$Params, context?: HttpContext): Observable<void> {
    return this.deleteRecurringUrlaubsansprueche$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `deleteUrlaubsansprueche()` */
  static readonly DeleteUrlaubsanspruechePath = '/v1/urlaubsansprueche';

  /**
   * Soft Deletes mehrere Urlaubsansprueche.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteUrlaubsansprueche()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteUrlaubsansprueche$Response(params: DeleteUrlaubsansprueche$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteUrlaubsansprueche(this.http, this.rootUrl, params, context);
  }

  /**
   * Soft Deletes mehrere Urlaubsansprueche.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteUrlaubsansprueche$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteUrlaubsansprueche(params: DeleteUrlaubsansprueche$Params, context?: HttpContext): Observable<void> {
    return this.deleteUrlaubsansprueche$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
