/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { ChipDto } from '../models/chip-dto';
import { getAllAvailableChips$Json } from '../fn/chip/get-all-available-chips-json';
import { GetAllAvailableChips$Json$Params } from '../fn/chip/get-all-available-chips-json';
import { getAllAvailableChips$Plain } from '../fn/chip/get-all-available-chips-plain';
import { GetAllAvailableChips$Plain$Params } from '../fn/chip/get-all-available-chips-plain';
import { getAllChips$Json } from '../fn/chip/get-all-chips-json';
import { GetAllChips$Json$Params } from '../fn/chip/get-all-chips-json';
import { getAllChips$Plain } from '../fn/chip/get-all-chips-plain';
import { GetAllChips$Plain$Params } from '../fn/chip/get-all-chips-plain';
import { restrictChip } from '../fn/chip/restrict-chip';
import { RestrictChip$Params } from '../fn/chip/restrict-chip';
import { unrestrictChip$Json } from '../fn/chip/unrestrict-chip-json';
import { UnrestrictChip$Json$Params } from '../fn/chip/unrestrict-chip-json';
import { unrestrictChip$Plain } from '../fn/chip/unrestrict-chip-plain';
import { UnrestrictChip$Plain$Params } from '../fn/chip/unrestrict-chip-plain';

@Injectable({ providedIn: 'root' })
export class ChipApiService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getAllAvailableChips()` */
  static readonly GetAllAvailableChipsPath = '/v1/chips/available';

  /**
   * Holt alle verfügbaren Chips.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllAvailableChips$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllAvailableChips$Plain$Response(params?: GetAllAvailableChips$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ChipDto>>> {
    return getAllAvailableChips$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Holt alle verfügbaren Chips.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllAvailableChips$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllAvailableChips$Plain(params?: GetAllAvailableChips$Plain$Params, context?: HttpContext): Observable<Array<ChipDto>> {
    return this.getAllAvailableChips$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ChipDto>>): Array<ChipDto> => r.body)
    );
  }

  /**
   * Holt alle verfügbaren Chips.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllAvailableChips$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllAvailableChips$Json$Response(params?: GetAllAvailableChips$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ChipDto>>> {
    return getAllAvailableChips$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Holt alle verfügbaren Chips.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllAvailableChips$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllAvailableChips$Json(params?: GetAllAvailableChips$Json$Params, context?: HttpContext): Observable<Array<ChipDto>> {
    return this.getAllAvailableChips$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ChipDto>>): Array<ChipDto> => r.body)
    );
  }

  /** Path part for operation `getAllChips()` */
  static readonly GetAllChipsPath = '/v1/chips/all';

  /**
   * Holt alle Chips.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllChips$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllChips$Plain$Response(params?: GetAllChips$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ChipDto>>> {
    return getAllChips$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Holt alle Chips.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllChips$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllChips$Plain(params?: GetAllChips$Plain$Params, context?: HttpContext): Observable<Array<ChipDto>> {
    return this.getAllChips$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ChipDto>>): Array<ChipDto> => r.body)
    );
  }

  /**
   * Holt alle Chips.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllChips$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllChips$Json$Response(params?: GetAllChips$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ChipDto>>> {
    return getAllChips$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Holt alle Chips.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllChips$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllChips$Json(params?: GetAllChips$Json$Params, context?: HttpContext): Observable<Array<ChipDto>> {
    return this.getAllChips$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ChipDto>>): Array<ChipDto> => r.body)
    );
  }

  /** Path part for operation `restrictChip()` */
  static readonly RestrictChipPath = '/v1/chips/restrict';

  /**
   * Sperrt einen Chip.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `restrictChip()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  restrictChip$Response(params?: RestrictChip$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return restrictChip(this.http, this.rootUrl, params, context);
  }

  /**
   * Sperrt einen Chip.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `restrictChip$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  restrictChip(params?: RestrictChip$Params, context?: HttpContext): Observable<void> {
    return this.restrictChip$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `unrestrictChip()` */
  static readonly UnrestrictChipPath = '/v1/chips/{chipNr}/unrestrict';

  /**
   * Entsperrt einen Chip.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `unrestrictChip$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  unrestrictChip$Plain$Response(params: UnrestrictChip$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ChipDto>> {
    return unrestrictChip$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Entsperrt einen Chip.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `unrestrictChip$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  unrestrictChip$Plain(params: UnrestrictChip$Plain$Params, context?: HttpContext): Observable<ChipDto> {
    return this.unrestrictChip$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ChipDto>): ChipDto => r.body)
    );
  }

  /**
   * Entsperrt einen Chip.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `unrestrictChip$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  unrestrictChip$Json$Response(params: UnrestrictChip$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ChipDto>> {
    return unrestrictChip$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Entsperrt einen Chip.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `unrestrictChip$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  unrestrictChip$Json(params: UnrestrictChip$Json$Params, context?: HttpContext): Observable<ChipDto> {
    return this.unrestrictChip$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ChipDto>): ChipDto => r.body)
    );
  }

}
