import { OverlayRef } from '@angular/cdk/overlay';
import { Component, EventEmitter, TemplateRef } from '@angular/core';

@Component({
  selector: 'dl-page-header-dropdown',
  templateUrl: './page-header-dropdown.component.html',
  styleUrls: ['./page-header-dropdown.component.css'],
})
export class DlPageHeaderDropdownComponent {
  entryTemplate: TemplateRef<unknown> | undefined;

  private _overlayRef: OverlayRef | undefined;
  closeEvent = new EventEmitter<null>();

  attachOverlayRef(_overlayRef: OverlayRef): void {
    this._overlayRef = _overlayRef;
    this._overlayRef.backdropClick().subscribe(() => this.close());
  }

  close() {
    this.closeEvent.emit();
    this._overlayRef?.detach();
  }
}
