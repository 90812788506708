/* eslint-disable @angular-eslint/component-class-suffix */
import {
  ChangeDetectorRef,
  Component,
  Inject,
  InjectionToken,
  Input,
  Optional,
} from '@angular/core';
import { _DlPaginatorBase } from './paginator.directive';

export interface DlPaginatorDefaultOptions {
  /** Number of items to display on a page. By default set to 50. */
  pageSize?: number;

  /** The set of provided page size options to display to the user. */
  pageSizeOptions?: number[];

  /** Whether to hide the page size selection UI from the user. */
  hidePageSize?: boolean;

  /** Whether to show the first/last buttons UI to the user. */
  showFirstLastButtons?: boolean;
}

export const DL_PAGINATOR_DEFAULT_OPTIONS =
  new InjectionToken<DlPaginatorDefaultOptions>('DL_PAGINATOR_DEFAULT_OPTIONS');

@Component({
  selector: 'dl-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.css'],
})
export class DlPaginator extends _DlPaginatorBase<DlPaginatorDefaultOptions> {
  @Input() label = 'Items per page';
  @Input() labelOf = 'of';

  constructor(
    changeDetectorRef: ChangeDetectorRef,
    @Optional()
    @Inject(DL_PAGINATOR_DEFAULT_OPTIONS)
    defaults?: DlPaginatorDefaultOptions
  ) {
    super(changeDetectorRef, defaults);
  }
}
