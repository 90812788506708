import { HttpInterceptor } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Platform } from '@ionic/angular';
// eslint-disable-next-line @nx/enforce-module-boundaries
import {
  AngularAuthInterceptor,
  DatenlotseAngularAuthService,
} from '@datenlotse/angular-auth';

@Injectable({
  providedIn: 'root',
})
export class IonicAuthInterceptor
  extends AngularAuthInterceptor
  implements HttpInterceptor
{
  constructor(injector: Injector, private platform: Platform) {
    super(injector);
  }

  protected override async getService() {
    await this.platform.ready();
    this._authService = this.injector.get(DatenlotseAngularAuthService);
  }
}
