/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { createMitarbeiter$Json } from '../fn/mitarbeiter/create-mitarbeiter-json';
import { CreateMitarbeiter$Json$Params } from '../fn/mitarbeiter/create-mitarbeiter-json';
import { createMitarbeiter$Plain } from '../fn/mitarbeiter/create-mitarbeiter-plain';
import { CreateMitarbeiter$Plain$Params } from '../fn/mitarbeiter/create-mitarbeiter-plain';
import { getAllMitarbeiter$Json } from '../fn/mitarbeiter/get-all-mitarbeiter-json';
import { GetAllMitarbeiter$Json$Params } from '../fn/mitarbeiter/get-all-mitarbeiter-json';
import { getAllMitarbeiter$Plain } from '../fn/mitarbeiter/get-all-mitarbeiter-plain';
import { GetAllMitarbeiter$Plain$Params } from '../fn/mitarbeiter/get-all-mitarbeiter-plain';
import { getAllMitarbeiterAggregated$Json } from '../fn/mitarbeiter/get-all-mitarbeiter-aggregated-json';
import { GetAllMitarbeiterAggregated$Json$Params } from '../fn/mitarbeiter/get-all-mitarbeiter-aggregated-json';
import { getAllMitarbeiterAggregated$Plain } from '../fn/mitarbeiter/get-all-mitarbeiter-aggregated-plain';
import { GetAllMitarbeiterAggregated$Plain$Params } from '../fn/mitarbeiter/get-all-mitarbeiter-aggregated-plain';
import { getMitarbeiterById$Json } from '../fn/mitarbeiter/get-mitarbeiter-by-id-json';
import { GetMitarbeiterById$Json$Params } from '../fn/mitarbeiter/get-mitarbeiter-by-id-json';
import { getMitarbeiterById$Plain } from '../fn/mitarbeiter/get-mitarbeiter-by-id-plain';
import { GetMitarbeiterById$Plain$Params } from '../fn/mitarbeiter/get-mitarbeiter-by-id-plain';
import { getMitarbeiterByUserId$Json } from '../fn/mitarbeiter/get-mitarbeiter-by-user-id-json';
import { GetMitarbeiterByUserId$Json$Params } from '../fn/mitarbeiter/get-mitarbeiter-by-user-id-json';
import { getMitarbeiterByUserId$Plain } from '../fn/mitarbeiter/get-mitarbeiter-by-user-id-plain';
import { GetMitarbeiterByUserId$Plain$Params } from '../fn/mitarbeiter/get-mitarbeiter-by-user-id-plain';
import { getSingleMitarbeiterInfos$Json } from '../fn/mitarbeiter/get-single-mitarbeiter-infos-json';
import { GetSingleMitarbeiterInfos$Json$Params } from '../fn/mitarbeiter/get-single-mitarbeiter-infos-json';
import { getSingleMitarbeiterInfos$Plain } from '../fn/mitarbeiter/get-single-mitarbeiter-infos-plain';
import { GetSingleMitarbeiterInfos$Plain$Params } from '../fn/mitarbeiter/get-single-mitarbeiter-infos-plain';
import { MitarbeiterDto } from '../models/mitarbeiter-dto';
import { MitarbeiterInfosDto } from '../models/mitarbeiter-infos-dto';
import { NurMitarbeiterInfosDto } from '../models/nur-mitarbeiter-infos-dto';
import { restoreMitarbeiter$Json } from '../fn/mitarbeiter/restore-mitarbeiter-json';
import { RestoreMitarbeiter$Json$Params } from '../fn/mitarbeiter/restore-mitarbeiter-json';
import { restoreMitarbeiter$Plain } from '../fn/mitarbeiter/restore-mitarbeiter-plain';
import { RestoreMitarbeiter$Plain$Params } from '../fn/mitarbeiter/restore-mitarbeiter-plain';
import { setMitarbeiterInactive$Json } from '../fn/mitarbeiter/set-mitarbeiter-inactive-json';
import { SetMitarbeiterInactive$Json$Params } from '../fn/mitarbeiter/set-mitarbeiter-inactive-json';
import { setMitarbeiterInactive$Plain } from '../fn/mitarbeiter/set-mitarbeiter-inactive-plain';
import { SetMitarbeiterInactive$Plain$Params } from '../fn/mitarbeiter/set-mitarbeiter-inactive-plain';
import { updateMitarbeiter$Json } from '../fn/mitarbeiter/update-mitarbeiter-json';
import { UpdateMitarbeiter$Json$Params } from '../fn/mitarbeiter/update-mitarbeiter-json';
import { updateMitarbeiter$Plain } from '../fn/mitarbeiter/update-mitarbeiter-plain';
import { UpdateMitarbeiter$Plain$Params } from '../fn/mitarbeiter/update-mitarbeiter-plain';

@Injectable({ providedIn: 'root' })
export class MitarbeiterApiService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getAllMitarbeiter()` */
  static readonly GetAllMitarbeiterPath = '/v1/mitarbeiter';

  /**
   * Holt alle Mitarbeiter.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllMitarbeiter$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllMitarbeiter$Plain$Response(params?: GetAllMitarbeiter$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<MitarbeiterDto>>> {
    return getAllMitarbeiter$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Holt alle Mitarbeiter.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllMitarbeiter$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllMitarbeiter$Plain(params?: GetAllMitarbeiter$Plain$Params, context?: HttpContext): Observable<Array<MitarbeiterDto>> {
    return this.getAllMitarbeiter$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<MitarbeiterDto>>): Array<MitarbeiterDto> => r.body)
    );
  }

  /**
   * Holt alle Mitarbeiter.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllMitarbeiter$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllMitarbeiter$Json$Response(params?: GetAllMitarbeiter$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<MitarbeiterDto>>> {
    return getAllMitarbeiter$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Holt alle Mitarbeiter.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllMitarbeiter$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllMitarbeiter$Json(params?: GetAllMitarbeiter$Json$Params, context?: HttpContext): Observable<Array<MitarbeiterDto>> {
    return this.getAllMitarbeiter$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<MitarbeiterDto>>): Array<MitarbeiterDto> => r.body)
    );
  }

  /** Path part for operation `createMitarbeiter()` */
  static readonly CreateMitarbeiterPath = '/v1/mitarbeiter';

  /**
   * Erstellt einen Mitarbeiter.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createMitarbeiter$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createMitarbeiter$Plain$Response(params?: CreateMitarbeiter$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<MitarbeiterDto>> {
    return createMitarbeiter$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Erstellt einen Mitarbeiter.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createMitarbeiter$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createMitarbeiter$Plain(params?: CreateMitarbeiter$Plain$Params, context?: HttpContext): Observable<MitarbeiterDto> {
    return this.createMitarbeiter$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<MitarbeiterDto>): MitarbeiterDto => r.body)
    );
  }

  /**
   * Erstellt einen Mitarbeiter.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createMitarbeiter$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createMitarbeiter$Json$Response(params?: CreateMitarbeiter$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<MitarbeiterDto>> {
    return createMitarbeiter$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Erstellt einen Mitarbeiter.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createMitarbeiter$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createMitarbeiter$Json(params?: CreateMitarbeiter$Json$Params, context?: HttpContext): Observable<MitarbeiterDto> {
    return this.createMitarbeiter$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<MitarbeiterDto>): MitarbeiterDto => r.body)
    );
  }

  /** Path part for operation `getAllMitarbeiterAggregated()` */
  static readonly GetAllMitarbeiterAggregatedPath = '/v1/mitarbeiter/aggregated';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllMitarbeiterAggregated$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllMitarbeiterAggregated$Plain$Response(params?: GetAllMitarbeiterAggregated$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<MitarbeiterInfosDto>> {
    return getAllMitarbeiterAggregated$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllMitarbeiterAggregated$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllMitarbeiterAggregated$Plain(params?: GetAllMitarbeiterAggregated$Plain$Params, context?: HttpContext): Observable<MitarbeiterInfosDto> {
    return this.getAllMitarbeiterAggregated$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<MitarbeiterInfosDto>): MitarbeiterInfosDto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllMitarbeiterAggregated$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllMitarbeiterAggregated$Json$Response(params?: GetAllMitarbeiterAggregated$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<MitarbeiterInfosDto>> {
    return getAllMitarbeiterAggregated$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllMitarbeiterAggregated$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllMitarbeiterAggregated$Json(params?: GetAllMitarbeiterAggregated$Json$Params, context?: HttpContext): Observable<MitarbeiterInfosDto> {
    return this.getAllMitarbeiterAggregated$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<MitarbeiterInfosDto>): MitarbeiterInfosDto => r.body)
    );
  }

  /** Path part for operation `getSingleMitarbeiterInfos()` */
  static readonly GetSingleMitarbeiterInfosPath = '/v1/mitarbeiter/{mitarbeiterId}/infos';

  /**
   * Holt die MitarbeiterInfos mit der angegeben ID.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSingleMitarbeiterInfos$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSingleMitarbeiterInfos$Plain$Response(params: GetSingleMitarbeiterInfos$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<NurMitarbeiterInfosDto>> {
    return getSingleMitarbeiterInfos$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Holt die MitarbeiterInfos mit der angegeben ID.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSingleMitarbeiterInfos$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSingleMitarbeiterInfos$Plain(params: GetSingleMitarbeiterInfos$Plain$Params, context?: HttpContext): Observable<NurMitarbeiterInfosDto> {
    return this.getSingleMitarbeiterInfos$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<NurMitarbeiterInfosDto>): NurMitarbeiterInfosDto => r.body)
    );
  }

  /**
   * Holt die MitarbeiterInfos mit der angegeben ID.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSingleMitarbeiterInfos$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSingleMitarbeiterInfos$Json$Response(params: GetSingleMitarbeiterInfos$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<NurMitarbeiterInfosDto>> {
    return getSingleMitarbeiterInfos$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Holt die MitarbeiterInfos mit der angegeben ID.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSingleMitarbeiterInfos$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSingleMitarbeiterInfos$Json(params: GetSingleMitarbeiterInfos$Json$Params, context?: HttpContext): Observable<NurMitarbeiterInfosDto> {
    return this.getSingleMitarbeiterInfos$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<NurMitarbeiterInfosDto>): NurMitarbeiterInfosDto => r.body)
    );
  }

  /** Path part for operation `getMitarbeiterById()` */
  static readonly GetMitarbeiterByIdPath = '/v1/mitarbeiter/{mitarbeiterId}';

  /**
   * Sucht einen Mitarbeiter mit der angegeben ID.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMitarbeiterById$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMitarbeiterById$Plain$Response(params: GetMitarbeiterById$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<MitarbeiterDto>> {
    return getMitarbeiterById$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Sucht einen Mitarbeiter mit der angegeben ID.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMitarbeiterById$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMitarbeiterById$Plain(params: GetMitarbeiterById$Plain$Params, context?: HttpContext): Observable<MitarbeiterDto> {
    return this.getMitarbeiterById$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<MitarbeiterDto>): MitarbeiterDto => r.body)
    );
  }

  /**
   * Sucht einen Mitarbeiter mit der angegeben ID.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMitarbeiterById$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMitarbeiterById$Json$Response(params: GetMitarbeiterById$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<MitarbeiterDto>> {
    return getMitarbeiterById$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Sucht einen Mitarbeiter mit der angegeben ID.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMitarbeiterById$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMitarbeiterById$Json(params: GetMitarbeiterById$Json$Params, context?: HttpContext): Observable<MitarbeiterDto> {
    return this.getMitarbeiterById$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<MitarbeiterDto>): MitarbeiterDto => r.body)
    );
  }

  /** Path part for operation `updateMitarbeiter()` */
  static readonly UpdateMitarbeiterPath = '/v1/mitarbeiter/{mitarbeiterId}';

  /**
   * Aktualisiert einen Mitarbeiter.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateMitarbeiter$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateMitarbeiter$Plain$Response(params: UpdateMitarbeiter$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<MitarbeiterDto>> {
    return updateMitarbeiter$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Aktualisiert einen Mitarbeiter.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateMitarbeiter$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateMitarbeiter$Plain(params: UpdateMitarbeiter$Plain$Params, context?: HttpContext): Observable<MitarbeiterDto> {
    return this.updateMitarbeiter$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<MitarbeiterDto>): MitarbeiterDto => r.body)
    );
  }

  /**
   * Aktualisiert einen Mitarbeiter.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateMitarbeiter$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateMitarbeiter$Json$Response(params: UpdateMitarbeiter$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<MitarbeiterDto>> {
    return updateMitarbeiter$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Aktualisiert einen Mitarbeiter.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateMitarbeiter$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateMitarbeiter$Json(params: UpdateMitarbeiter$Json$Params, context?: HttpContext): Observable<MitarbeiterDto> {
    return this.updateMitarbeiter$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<MitarbeiterDto>): MitarbeiterDto => r.body)
    );
  }

  /** Path part for operation `getMitarbeiterByUserId()` */
  static readonly GetMitarbeiterByUserIdPath = '/v1/mitarbeiter/userId/{userId}';

  /**
   * Holt einen Mitarbeiter anhand seiner User Id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMitarbeiterByUserId$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMitarbeiterByUserId$Plain$Response(params: GetMitarbeiterByUserId$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<MitarbeiterDto>> {
    return getMitarbeiterByUserId$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Holt einen Mitarbeiter anhand seiner User Id.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMitarbeiterByUserId$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMitarbeiterByUserId$Plain(params: GetMitarbeiterByUserId$Plain$Params, context?: HttpContext): Observable<MitarbeiterDto> {
    return this.getMitarbeiterByUserId$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<MitarbeiterDto>): MitarbeiterDto => r.body)
    );
  }

  /**
   * Holt einen Mitarbeiter anhand seiner User Id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMitarbeiterByUserId$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMitarbeiterByUserId$Json$Response(params: GetMitarbeiterByUserId$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<MitarbeiterDto>> {
    return getMitarbeiterByUserId$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Holt einen Mitarbeiter anhand seiner User Id.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMitarbeiterByUserId$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMitarbeiterByUserId$Json(params: GetMitarbeiterByUserId$Json$Params, context?: HttpContext): Observable<MitarbeiterDto> {
    return this.getMitarbeiterByUserId$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<MitarbeiterDto>): MitarbeiterDto => r.body)
    );
  }

  /** Path part for operation `setMitarbeiterInactive()` */
  static readonly SetMitarbeiterInactivePath = '/v1/mitarbeiter/delete/{mitarbeiterId}';

  /**
   * Setzt einen Mitarbeiter inaktiv (Soft Delete).
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `setMitarbeiterInactive$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  setMitarbeiterInactive$Plain$Response(params: SetMitarbeiterInactive$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<MitarbeiterDto>> {
    return setMitarbeiterInactive$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Setzt einen Mitarbeiter inaktiv (Soft Delete).
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `setMitarbeiterInactive$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  setMitarbeiterInactive$Plain(params: SetMitarbeiterInactive$Plain$Params, context?: HttpContext): Observable<MitarbeiterDto> {
    return this.setMitarbeiterInactive$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<MitarbeiterDto>): MitarbeiterDto => r.body)
    );
  }

  /**
   * Setzt einen Mitarbeiter inaktiv (Soft Delete).
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `setMitarbeiterInactive$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  setMitarbeiterInactive$Json$Response(params: SetMitarbeiterInactive$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<MitarbeiterDto>> {
    return setMitarbeiterInactive$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Setzt einen Mitarbeiter inaktiv (Soft Delete).
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `setMitarbeiterInactive$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  setMitarbeiterInactive$Json(params: SetMitarbeiterInactive$Json$Params, context?: HttpContext): Observable<MitarbeiterDto> {
    return this.setMitarbeiterInactive$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<MitarbeiterDto>): MitarbeiterDto => r.body)
    );
  }

  /** Path part for operation `restoreMitarbeiter()` */
  static readonly RestoreMitarbeiterPath = '/v1/mitarbeiter/restore/{mitarbeiterId}';

  /**
   * Setzt einen Mitarbeiter wieder auf aktiv.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `restoreMitarbeiter$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  restoreMitarbeiter$Plain$Response(params: RestoreMitarbeiter$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<MitarbeiterDto>> {
    return restoreMitarbeiter$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Setzt einen Mitarbeiter wieder auf aktiv.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `restoreMitarbeiter$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  restoreMitarbeiter$Plain(params: RestoreMitarbeiter$Plain$Params, context?: HttpContext): Observable<MitarbeiterDto> {
    return this.restoreMitarbeiter$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<MitarbeiterDto>): MitarbeiterDto => r.body)
    );
  }

  /**
   * Setzt einen Mitarbeiter wieder auf aktiv.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `restoreMitarbeiter$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  restoreMitarbeiter$Json$Response(params: RestoreMitarbeiter$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<MitarbeiterDto>> {
    return restoreMitarbeiter$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Setzt einen Mitarbeiter wieder auf aktiv.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `restoreMitarbeiter$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  restoreMitarbeiter$Json(params: RestoreMitarbeiter$Json$Params, context?: HttpContext): Observable<MitarbeiterDto> {
    return this.restoreMitarbeiter$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<MitarbeiterDto>): MitarbeiterDto => r.body)
    );
  }

}
