import { createFeature, createReducer } from '@ngrx/store';

export const monatsauswertungenFeatureKey = 'monatsauswertungen';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface State {}

export const initialState: State = {

};

export const reducer = createReducer(
  initialState,
);

export const monatsauswertungenFeature = createFeature({
  name: monatsauswertungenFeatureKey,
  reducer,
});

