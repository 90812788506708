/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { createMehrarbeitAuszahlung$Json } from '../fn/mehrarbeit-auszahlung/create-mehrarbeit-auszahlung-json';
import { CreateMehrarbeitAuszahlung$Json$Params } from '../fn/mehrarbeit-auszahlung/create-mehrarbeit-auszahlung-json';
import { createMehrarbeitAuszahlung$Plain } from '../fn/mehrarbeit-auszahlung/create-mehrarbeit-auszahlung-plain';
import { CreateMehrarbeitAuszahlung$Plain$Params } from '../fn/mehrarbeit-auszahlung/create-mehrarbeit-auszahlung-plain';
import { getAllMehrarbeitAuszahlungen$Json } from '../fn/mehrarbeit-auszahlung/get-all-mehrarbeit-auszahlungen-json';
import { GetAllMehrarbeitAuszahlungen$Json$Params } from '../fn/mehrarbeit-auszahlung/get-all-mehrarbeit-auszahlungen-json';
import { getAllMehrarbeitAuszahlungen$Plain } from '../fn/mehrarbeit-auszahlung/get-all-mehrarbeit-auszahlungen-plain';
import { GetAllMehrarbeitAuszahlungen$Plain$Params } from '../fn/mehrarbeit-auszahlung/get-all-mehrarbeit-auszahlungen-plain';
import { MehrarbeitAuszahlungDto } from '../models/mehrarbeit-auszahlung-dto';

@Injectable({ providedIn: 'root' })
export class MehrarbeitAuszahlungApiService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getAllMehrarbeitAuszahlungen()` */
  static readonly GetAllMehrarbeitAuszahlungenPath = '/v1/mehrarbeitauszahlung';

  /**
   * Holt alle Mehrarbeit Auszahlungen des Monats, Jahr.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllMehrarbeitAuszahlungen$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllMehrarbeitAuszahlungen$Plain$Response(params?: GetAllMehrarbeitAuszahlungen$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<MehrarbeitAuszahlungDto>>> {
    return getAllMehrarbeitAuszahlungen$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Holt alle Mehrarbeit Auszahlungen des Monats, Jahr.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllMehrarbeitAuszahlungen$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllMehrarbeitAuszahlungen$Plain(params?: GetAllMehrarbeitAuszahlungen$Plain$Params, context?: HttpContext): Observable<Array<MehrarbeitAuszahlungDto>> {
    return this.getAllMehrarbeitAuszahlungen$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<MehrarbeitAuszahlungDto>>): Array<MehrarbeitAuszahlungDto> => r.body)
    );
  }

  /**
   * Holt alle Mehrarbeit Auszahlungen des Monats, Jahr.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllMehrarbeitAuszahlungen$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllMehrarbeitAuszahlungen$Json$Response(params?: GetAllMehrarbeitAuszahlungen$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<MehrarbeitAuszahlungDto>>> {
    return getAllMehrarbeitAuszahlungen$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Holt alle Mehrarbeit Auszahlungen des Monats, Jahr.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllMehrarbeitAuszahlungen$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllMehrarbeitAuszahlungen$Json(params?: GetAllMehrarbeitAuszahlungen$Json$Params, context?: HttpContext): Observable<Array<MehrarbeitAuszahlungDto>> {
    return this.getAllMehrarbeitAuszahlungen$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<MehrarbeitAuszahlungDto>>): Array<MehrarbeitAuszahlungDto> => r.body)
    );
  }

  /** Path part for operation `createMehrarbeitAuszahlung()` */
  static readonly CreateMehrarbeitAuszahlungPath = '/v1/mehrarbeitauszahlung/create';

  /**
   * Erstellt eine Mehrarbeit Auszahlung.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createMehrarbeitAuszahlung$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createMehrarbeitAuszahlung$Plain$Response(params?: CreateMehrarbeitAuszahlung$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<MehrarbeitAuszahlungDto>> {
    return createMehrarbeitAuszahlung$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Erstellt eine Mehrarbeit Auszahlung.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createMehrarbeitAuszahlung$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createMehrarbeitAuszahlung$Plain(params?: CreateMehrarbeitAuszahlung$Plain$Params, context?: HttpContext): Observable<MehrarbeitAuszahlungDto> {
    return this.createMehrarbeitAuszahlung$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<MehrarbeitAuszahlungDto>): MehrarbeitAuszahlungDto => r.body)
    );
  }

  /**
   * Erstellt eine Mehrarbeit Auszahlung.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createMehrarbeitAuszahlung$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createMehrarbeitAuszahlung$Json$Response(params?: CreateMehrarbeitAuszahlung$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<MehrarbeitAuszahlungDto>> {
    return createMehrarbeitAuszahlung$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Erstellt eine Mehrarbeit Auszahlung.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createMehrarbeitAuszahlung$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createMehrarbeitAuszahlung$Json(params?: CreateMehrarbeitAuszahlung$Json$Params, context?: HttpContext): Observable<MehrarbeitAuszahlungDto> {
    return this.createMehrarbeitAuszahlung$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<MehrarbeitAuszahlungDto>): MehrarbeitAuszahlungDto => r.body)
    );
  }

}
