import { AfterViewInit, Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[dlBrightButton]',
})
export class DlBrightDirective implements AfterViewInit {
  constructor(private elr: ElementRef) {}

  ngAfterViewInit() {
    const nodeName = this.elr.nativeElement.nodeName;

    if (
      nodeName !== 'DL-SIDEBAR-OPEN-BUTTON' &&
      nodeName !== 'DL-SIDEBAR-BUTTON'
    ) {
      throw new Error(
        'The "dlBrightButton" directive can only be used on Sidebar Buttons'
      );
    }

    let children = this.elr.nativeElement.children.item(0).children;

    if (nodeName === 'DL-SIDEBAR-OPEN-BUTTON') {
      children = children.item(0).children;
    }

    for (const child of children) {
      const node = child.children.item(0);
      const nodeName = node.nodeName;
      if (nodeName === 'IMG') {
        node.style.filter = 'invert(100%)';
      } else if (nodeName === 'SPAN') {
        node.style.color = '#ffffff';
      }
    }
  }
}
