import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { MehrarbeitAuszahlungDto } from '../../api/models';

export const MehrarbeitAuszahlungenActions = createActionGroup({
  source: 'MehrarbeitAuszahlungen',
  events: {
    'Load Mehrarbeitauszahlungen': emptyProps(),
    'Load Mehrarbeitauszahlungen Success': props<{
      data: MehrarbeitAuszahlungDto[];
      totalCount: number;
    }>(),
    'Load Mehrarbeitauszahlungen Failure': props<{ error: unknown }>(),
    'Create Mehrarbeitauszahlung': props<{ auszahlungInSekunden: number }>(),
    'Create Mehrarbeitauszahlung Success': props<{ data: MehrarbeitAuszahlungDto }>(),
    'Create Mehrarbeitauszahlung Failure': props<{ error: unknown }>(),
    'Set Page Size': props<{ pageSize: number }>(),
    'Set Page Index': props<{ pageIndex: number }>(),
    'Set Selected Month And Year': props<{
      selectedMonth: number;
      selectedYear: number;
    }>(),
  },
});
