import { AfterViewInit, Directive, ElementRef } from '@angular/core';

@Directive()
export class DlButtonStylingDirective implements AfterViewInit {
  protected primaryColor = '#34B2B5';
  protected fontColor = '#FFFFFF';
  protected hoverColor = '#298b8d';
  protected disabledColor = '#BDBDBD';
  protected disabledFontColor = '#E0E0E0';

  constructor(protected elr: ElementRef) {}

  ngAfterViewInit(): void {
    this.applyStyling();
  }

  protected applyStyling(): void {
    const button = this.elr.nativeElement.children[0];
    button.style.setProperty('--background-color', this.primaryColor);
    button.style.setProperty('--font-color', this.fontColor);
    button.style.setProperty('--hover-color', this.hoverColor);
    button.style.setProperty('--disabled-color', this.disabledColor);
    button.style.setProperty('--disabled-font-color', this.disabledFontColor);
  }
}
