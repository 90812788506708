/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { FeiertagDto } from '../models/feiertag-dto';
import { getFeiertage$Json } from '../fn/feiertag/get-feiertage-json';
import { GetFeiertage$Json$Params } from '../fn/feiertag/get-feiertage-json';
import { getFeiertage$Plain } from '../fn/feiertag/get-feiertage-plain';
import { GetFeiertage$Plain$Params } from '../fn/feiertag/get-feiertage-plain';
import { getFeiertageByYear$Json } from '../fn/feiertag/get-feiertage-by-year-json';
import { GetFeiertageByYear$Json$Params } from '../fn/feiertag/get-feiertage-by-year-json';
import { getFeiertageByYear$Plain } from '../fn/feiertag/get-feiertage-by-year-plain';
import { GetFeiertageByYear$Plain$Params } from '../fn/feiertag/get-feiertage-by-year-plain';

@Injectable({ providedIn: 'root' })
export class FeiertagApiService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getFeiertage()` */
  static readonly GetFeiertagePath = '/v1/feiertage';

  /**
   * Holt alle Feiertage.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFeiertage$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFeiertage$Plain$Response(params?: GetFeiertage$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<FeiertagDto>>> {
    return getFeiertage$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Holt alle Feiertage.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getFeiertage$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFeiertage$Plain(params?: GetFeiertage$Plain$Params, context?: HttpContext): Observable<Array<FeiertagDto>> {
    return this.getFeiertage$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<FeiertagDto>>): Array<FeiertagDto> => r.body)
    );
  }

  /**
   * Holt alle Feiertage.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFeiertage$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFeiertage$Json$Response(params?: GetFeiertage$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<FeiertagDto>>> {
    return getFeiertage$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Holt alle Feiertage.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getFeiertage$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFeiertage$Json(params?: GetFeiertage$Json$Params, context?: HttpContext): Observable<Array<FeiertagDto>> {
    return this.getFeiertage$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<FeiertagDto>>): Array<FeiertagDto> => r.body)
    );
  }

  /** Path part for operation `getFeiertageByYear()` */
  static readonly GetFeiertageByYearPath = '/v1/feiertage/{jahr}';

  /**
   * Holt alle Feiertage eines angegebenen Jahres.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFeiertageByYear$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFeiertageByYear$Plain$Response(params: GetFeiertageByYear$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<FeiertagDto>>> {
    return getFeiertageByYear$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Holt alle Feiertage eines angegebenen Jahres.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getFeiertageByYear$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFeiertageByYear$Plain(params: GetFeiertageByYear$Plain$Params, context?: HttpContext): Observable<Array<FeiertagDto>> {
    return this.getFeiertageByYear$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<FeiertagDto>>): Array<FeiertagDto> => r.body)
    );
  }

  /**
   * Holt alle Feiertage eines angegebenen Jahres.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFeiertageByYear$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFeiertageByYear$Json$Response(params: GetFeiertageByYear$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<FeiertagDto>>> {
    return getFeiertageByYear$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Holt alle Feiertage eines angegebenen Jahres.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getFeiertageByYear$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFeiertageByYear$Json(params: GetFeiertageByYear$Json$Params, context?: HttpContext): Observable<Array<FeiertagDto>> {
    return this.getFeiertageByYear$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<FeiertagDto>>): Array<FeiertagDto> => r.body)
    );
  }

}
