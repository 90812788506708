import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DlSelectComponent } from './select/select.component';
import { DlSelectPlaceholderComponent } from './select/select-placeholder/select-placeholder.component';
import { DlSelectOptionComponent } from './select/select-option/select-option.component';

@NgModule({
  declarations: [
    DlSelectComponent,
    DlSelectPlaceholderComponent,
    DlSelectOptionComponent,
  ],
  imports: [CommonModule],
  exports: [
    DlSelectComponent,
    DlSelectPlaceholderComponent,
    DlSelectOptionComponent,
  ],
})
export class DlSelectModule {}
