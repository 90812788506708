import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DlChipComponent } from './chip/chip.component';
import { RedChipDirective } from './directives/styling/red-chip.directive';
import { OrangeChipDirective } from './directives/styling/orange-chip.directive';
import { YellowChipDirective } from './directives/styling/yellow-chip.directive';
import { AccentChipDirective } from './directives/styling/accent-chip.directive';
import { LightChipDirective } from './directives/styling/light-chip.directive';
import { PurpleChipDirective } from './directives/styling/purple-chip.directive';
import { DarkGreenChipDirective } from './directives/styling/dark-green-chip.directive';
import { LightGreenChipDirective } from './directives/styling/light-green-chip.directive';
import { BlueChipDirective } from './directives/styling/blue-chip.directive';

@NgModule({
  declarations: [
    DlChipComponent,
    RedChipDirective,
    OrangeChipDirective,
    YellowChipDirective,
    AccentChipDirective,
    LightChipDirective,
    PurpleChipDirective,
    DarkGreenChipDirective,
    LightGreenChipDirective,
    BlueChipDirective,
  ],
  imports: [CommonModule],
  exports: [
    DlChipComponent,
    RedChipDirective,
    OrangeChipDirective,
    YellowChipDirective,
    AccentChipDirective,
    LightChipDirective,
    PurpleChipDirective,
    DarkGreenChipDirective,
    LightGreenChipDirective,
    BlueChipDirective,
  ],
})
export class DlChipModule {}
