<input
  [type]="type"
  [name]="name!"
  [disabled]="disabled"
  [min]="min"
  [max]="max"
  [step]="step"
  [maxlength]="maxlength ?? null"
  [placeholder]="placeholder || ''"
  [ngModel]="inputText"
  [autocomplete]="autocomplete"
  [readOnly]="readOnly"
  (ngModelChange)="onInputChange($event)"
  (focus)="hasFocus.next(true)"
  (focusout)="hasFocus.next(false)"
  #input
/>
