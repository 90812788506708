import { createFeature, createReducer, on } from '@ngrx/store';
import { AuthActions } from './auth.actions';

export const authFeatureKey = 'auth';

export interface State {
  loggedInUserId: string | null;
  scopes: string[];
}

export const initialState: State = {
  loggedInUserId: null,
  scopes: [],
};

export const reducer = createReducer(
  initialState,
  on(
    AuthActions.setLoggedInUser,
    (state, { userId, scopes }): State => ({
      ...state,
      loggedInUserId: userId,
      scopes,
    })
  )
);

export const authFeature = createFeature({
  name: authFeatureKey,
  reducer,
});
