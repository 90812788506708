/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { createMehrarbeitUmbuchung$Json } from '../fn/mehrarbeit-umbuchung/create-mehrarbeit-umbuchung-json';
import { CreateMehrarbeitUmbuchung$Json$Params } from '../fn/mehrarbeit-umbuchung/create-mehrarbeit-umbuchung-json';
import { createMehrarbeitUmbuchung$Plain } from '../fn/mehrarbeit-umbuchung/create-mehrarbeit-umbuchung-plain';
import { CreateMehrarbeitUmbuchung$Plain$Params } from '../fn/mehrarbeit-umbuchung/create-mehrarbeit-umbuchung-plain';
import { getAllMehrarbeitUmbuchungen$Json } from '../fn/mehrarbeit-umbuchung/get-all-mehrarbeit-umbuchungen-json';
import { GetAllMehrarbeitUmbuchungen$Json$Params } from '../fn/mehrarbeit-umbuchung/get-all-mehrarbeit-umbuchungen-json';
import { getAllMehrarbeitUmbuchungen$Plain } from '../fn/mehrarbeit-umbuchung/get-all-mehrarbeit-umbuchungen-plain';
import { GetAllMehrarbeitUmbuchungen$Plain$Params } from '../fn/mehrarbeit-umbuchung/get-all-mehrarbeit-umbuchungen-plain';
import { MehrarbeitUmbuchungDto } from '../models/mehrarbeit-umbuchung-dto';

@Injectable({ providedIn: 'root' })
export class MehrarbeitUmbuchungApiService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getAllMehrarbeitUmbuchungen()` */
  static readonly GetAllMehrarbeitUmbuchungenPath = '/v1/mehrarbeitumbuchung';

  /**
   * Holt alle Mehrarbeit Umbuchungen des Monats, Jahr.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllMehrarbeitUmbuchungen$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllMehrarbeitUmbuchungen$Plain$Response(params?: GetAllMehrarbeitUmbuchungen$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<MehrarbeitUmbuchungDto>>> {
    return getAllMehrarbeitUmbuchungen$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Holt alle Mehrarbeit Umbuchungen des Monats, Jahr.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllMehrarbeitUmbuchungen$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllMehrarbeitUmbuchungen$Plain(params?: GetAllMehrarbeitUmbuchungen$Plain$Params, context?: HttpContext): Observable<Array<MehrarbeitUmbuchungDto>> {
    return this.getAllMehrarbeitUmbuchungen$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<MehrarbeitUmbuchungDto>>): Array<MehrarbeitUmbuchungDto> => r.body)
    );
  }

  /**
   * Holt alle Mehrarbeit Umbuchungen des Monats, Jahr.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllMehrarbeitUmbuchungen$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllMehrarbeitUmbuchungen$Json$Response(params?: GetAllMehrarbeitUmbuchungen$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<MehrarbeitUmbuchungDto>>> {
    return getAllMehrarbeitUmbuchungen$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Holt alle Mehrarbeit Umbuchungen des Monats, Jahr.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllMehrarbeitUmbuchungen$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllMehrarbeitUmbuchungen$Json(params?: GetAllMehrarbeitUmbuchungen$Json$Params, context?: HttpContext): Observable<Array<MehrarbeitUmbuchungDto>> {
    return this.getAllMehrarbeitUmbuchungen$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<MehrarbeitUmbuchungDto>>): Array<MehrarbeitUmbuchungDto> => r.body)
    );
  }

  /** Path part for operation `createMehrarbeitUmbuchung()` */
  static readonly CreateMehrarbeitUmbuchungPath = '/v1/mehrarbeitumbuchung/create';

  /**
   * Erstellt eine Mehrarbeit Umbuchung.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createMehrarbeitUmbuchung$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createMehrarbeitUmbuchung$Plain$Response(params?: CreateMehrarbeitUmbuchung$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<MehrarbeitUmbuchungDto>> {
    return createMehrarbeitUmbuchung$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Erstellt eine Mehrarbeit Umbuchung.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createMehrarbeitUmbuchung$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createMehrarbeitUmbuchung$Plain(params?: CreateMehrarbeitUmbuchung$Plain$Params, context?: HttpContext): Observable<MehrarbeitUmbuchungDto> {
    return this.createMehrarbeitUmbuchung$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<MehrarbeitUmbuchungDto>): MehrarbeitUmbuchungDto => r.body)
    );
  }

  /**
   * Erstellt eine Mehrarbeit Umbuchung.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createMehrarbeitUmbuchung$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createMehrarbeitUmbuchung$Json$Response(params?: CreateMehrarbeitUmbuchung$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<MehrarbeitUmbuchungDto>> {
    return createMehrarbeitUmbuchung$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Erstellt eine Mehrarbeit Umbuchung.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createMehrarbeitUmbuchung$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createMehrarbeitUmbuchung$Json(params?: CreateMehrarbeitUmbuchung$Json$Params, context?: HttpContext): Observable<MehrarbeitUmbuchungDto> {
    return this.createMehrarbeitUmbuchung$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<MehrarbeitUmbuchungDto>): MehrarbeitUmbuchungDto => r.body)
    );
  }

}
