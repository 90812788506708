import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { CreateSchnellauswahlDto, SchnellauswahlDto } from '../../api/models';

export const SchnellauswahlenActions = createActionGroup({
  source: 'Schnellauswahlen',
  events: {
    "Load Schnellauswahlen": emptyProps(),
    "Load Schnellauswahlen Success": props<{schnellauswahlen: SchnellauswahlDto[]}>(),
    "Load Schnellauswahlen Failure": props<{error: unknown}>(),
    'Load Schnellauswahl By Schnellauswahl Id': props<{ schnellauswahlId: string }>(),
    'Load Schnellauswahl By Schnellauswahl Id Success': props<{
      schnellauswahl: SchnellauswahlDto;
    }>(),
    'Load Schnellauswahl By Schnellauswahl Id Failure': props<{ error: unknown }>(),
    'Create Schnellauswahl': props<{ data: CreateSchnellauswahlDto }>(),
    'Create Schnellauswahl Success': props<{
      createdSchnellauswahl: SchnellauswahlDto;
    }>(),
    'Create Schnellauswahl Failure': props<{ error: unknown }>(),
    'Delete Schnellauswahl': props<{ schnellauswahlId: string }>(),
    'Delete Schnellauswahl Success': props<{ schnellauswahlId: string }>(),
    'Delete Schnellauswahl Failure': props<{ error: unknown }>(),
  },
});
