import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, map, switchMap, tap } from 'rxjs/operators';
import { handleHttpError } from '../../../common/httpErrorHandler';
import { PersonZugangApiService } from '../../api/services';
import * as fromAuth from '../auth/auth.selectors';
import { ChipsActions } from '../chips/chips.actions';
import { PersonenWithZugaengeActions } from './personen-zugaenge.actions';

@Injectable()
export class PersonenWithZugaengeEffects {
  loadPersonenWithZugaenge$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        PersonenWithZugaengeActions.loadPersonenWithZugaenge,
        ChipsActions.restrictChipSuccess,
        PersonenWithZugaengeActions.loadZutrittskontrolle,
        PersonenWithZugaengeActions.updatePersonenWithZugaengeSuccess,
      ),
      switchMap(() =>
        this.api$.getAllPersonenWithZugaenge$Json().pipe(
          map((personenWithZugaenge) =>
            PersonenWithZugaengeActions.loadPersonenWithZugaengeSuccess({
              personenWithZugaenge,
            }),
          ),
          catchError((error) =>
            of(
              PersonenWithZugaengeActions.loadPersonenWithZugaengeFailure({
                error,
              }),
            ),
          ),
        ),
      ),
    );
  });

  updatePersonenWithZugaenge$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PersonenWithZugaengeActions.updatePersonenWithZugaenge),
      concatLatestFrom(() =>
        this.store.select(fromAuth.selectLoggedInMitarbeiter),
      ),
      concatMap(([data, updater]) =>
        this.api$
          .updatePersonenWithZugaenge$Json({
            body: data.updatePersonenWithZugaenge,
            updaterId: updater?.id,
          })
          .pipe(
            map((updatedPersonenWithZugaenge) =>
              PersonenWithZugaengeActions.updatePersonenWithZugaengeSuccess({
                updatedPersonenWithZugaenge,
              }),
            ),
            catchError((error) =>
              of(
                PersonenWithZugaengeActions.updatePersonenWithZugaengeFailure({
                  error,
                }),
              ),
            ),
          ),
      ),
    );
  });

  updatePersonsnWithZugaengeSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(PersonenWithZugaengeActions.updatePersonenWithZugaengeSuccess),
        tap(async () => {
          const toast = await this.toastCtrl.create({
            color: 'success',
            duration: 3000,
            message: 'Zugänge erfolgreich aktualisiert.',
          });
          await toast.present();
        }),
      );
    },
    { dispatch: false },
  );

  updatePersonsnWithZugaengeFailure$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(PersonenWithZugaengeActions.updatePersonenWithZugaengeFailure),
        tap(async ({ error }) => {
          await handleHttpError(
            error,
            this.toastCtrl,
            'Fehler beim Aktualisieren der Zugänge.',
          );
        }),
      );
    },
    { dispatch: false },
  );

  createOrUpdateExternePerson$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PersonenWithZugaengeActions.createOrUpdateExternWithZugaenge),
      concatMap((data) =>
        this.api$
          .createExtern$Json({
            body: data.createOrUpdateExternWithZugaenge,
          })
          .pipe(
            map((externWithZugaenge) =>
              PersonenWithZugaengeActions.createOrUpdateExternWithZugaengeSuccess(
                {
                  externWithZugaenge,
                },
              ),
            ),
            catchError((error) =>
              of(
                PersonenWithZugaengeActions.createOrUpdateExternWithZugaengeFailure(
                  { error },
                ),
              ),
            ),
          ),
      ),
    );
  });

  deleteExternePerson$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PersonenWithZugaengeActions.deleteExternWithZugaenge),
      concatMap((data) =>
        this.api$
          .deleteExterne$Json({
            chipNr: data.chipNr,
          })
          .pipe(
            map((emptyChip) =>
              PersonenWithZugaengeActions.deleteExternWithZugaengeSuccess({
                emptyChip,
              }),
            ),
            catchError((error) =>
              of(
                PersonenWithZugaengeActions.deleteExternWithZugaengeFailure({
                  error,
                }),
              ),
            ),
          ),
      ),
    );
  });

  loadZugaengeFailure$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(PersonenWithZugaengeActions.loadPersonenWithZugaengeFailure),
        tap(async ({ error }) => {
          console.error(error);
          await handleHttpError(
            error,
            this.toastCtrl,
            'Beim Laden der Personenzugänge ist ein Fehler aufgetreten.',
          );
        }),
      );
    },
    { dispatch: false },
  );

  constructor(
    private actions$: Actions,
    private readonly api$: PersonZugangApiService,
    private readonly toastCtrl: ToastController,
    private readonly store: Store,
  ) {}
}
