/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { getBeanspruchteUrlaubeForMitarbeiter$Json } from '../fn/beanspruchte-urlaube/get-beanspruchte-urlaube-for-mitarbeiter-json';
import { GetBeanspruchteUrlaubeForMitarbeiter$Json$Params } from '../fn/beanspruchte-urlaube/get-beanspruchte-urlaube-for-mitarbeiter-json';
import { getBeanspruchteUrlaubeForMitarbeiter$Plain } from '../fn/beanspruchte-urlaube/get-beanspruchte-urlaube-for-mitarbeiter-plain';
import { GetBeanspruchteUrlaubeForMitarbeiter$Plain$Params } from '../fn/beanspruchte-urlaube/get-beanspruchte-urlaube-for-mitarbeiter-plain';
import { UrlaubsanspruchDto } from '../models/urlaubsanspruch-dto';

@Injectable({ providedIn: 'root' })
export class BeanspruchteUrlaubeApiService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getBeanspruchteUrlaubeForMitarbeiter()` */
  static readonly GetBeanspruchteUrlaubeForMitarbeiterPath = '/v1/mitarbeiter/{mitarbeiterId}/beanspruchteurlaube/{year}';

  /**
   * Holt alle beanspruchten Urlaube für einen Mitarbeiter in einem Jahr.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getBeanspruchteUrlaubeForMitarbeiter$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBeanspruchteUrlaubeForMitarbeiter$Plain$Response(params: GetBeanspruchteUrlaubeForMitarbeiter$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<UrlaubsanspruchDto>>> {
    return getBeanspruchteUrlaubeForMitarbeiter$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Holt alle beanspruchten Urlaube für einen Mitarbeiter in einem Jahr.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getBeanspruchteUrlaubeForMitarbeiter$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBeanspruchteUrlaubeForMitarbeiter$Plain(params: GetBeanspruchteUrlaubeForMitarbeiter$Plain$Params, context?: HttpContext): Observable<Array<UrlaubsanspruchDto>> {
    return this.getBeanspruchteUrlaubeForMitarbeiter$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<UrlaubsanspruchDto>>): Array<UrlaubsanspruchDto> => r.body)
    );
  }

  /**
   * Holt alle beanspruchten Urlaube für einen Mitarbeiter in einem Jahr.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getBeanspruchteUrlaubeForMitarbeiter$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBeanspruchteUrlaubeForMitarbeiter$Json$Response(params: GetBeanspruchteUrlaubeForMitarbeiter$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<UrlaubsanspruchDto>>> {
    return getBeanspruchteUrlaubeForMitarbeiter$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Holt alle beanspruchten Urlaube für einen Mitarbeiter in einem Jahr.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getBeanspruchteUrlaubeForMitarbeiter$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBeanspruchteUrlaubeForMitarbeiter$Json(params: GetBeanspruchteUrlaubeForMitarbeiter$Json$Params, context?: HttpContext): Observable<Array<UrlaubsanspruchDto>> {
    return this.getBeanspruchteUrlaubeForMitarbeiter$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<UrlaubsanspruchDto>>): Array<UrlaubsanspruchDto> => r.body)
    );
  }

}
