import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { format } from 'date-fns';
import { filter, of } from 'rxjs';
import { catchError, concatMap, map, switchMap, tap } from 'rxjs/operators';
import { handleHttpError } from '../../../common/httpErrorHandler';
import {
  KalendereintragApiService,
  WochenplanApiService,
} from '../../api/services';
import * as fromMitarbeiter from '../mitarbeiter/mitarbeiter.selectors';
import * as ZuordnungenActions from './zuordnungen.actions';
import * as fromZuordnungen from './zuordnungen.selectors';

@Injectable()
export class ZuordnungenEffects {
  loadZuordnungen$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        ZuordnungenActions.loadZuordnungen,
        ZuordnungenActions.increaseYear,
        ZuordnungenActions.decreaseYear,
      ),
      concatLatestFrom(() => [
        this.store.select(fromMitarbeiter.selectSelectedMitarbeiterId),
        this.store.select(fromZuordnungen.selectSelectedYear),
      ]),
      filter(
        ([, selctedMitarbeiterId, selectedYear]) =>
          selctedMitarbeiterId !== '' && selectedYear > 0,
      ),
      switchMap(([, selectedMitarbeiterId, selectedYear]) =>
        this.kalenderApi
          .getKalendereintraegeForMitarbeiter$Json({
            mitarbeiterId: selectedMitarbeiterId,
            From: `${selectedYear}-01-01`,
            To: `${selectedYear}-12-31`,
          })
          .pipe(
            map((zuordnungen) =>
              ZuordnungenActions.loadZuordnungenSuccess({ zuordnungen }),
            ),
            catchError((error) =>
              of(ZuordnungenActions.loadZuordnungenFailure({ error })),
            ),
          ),
      ),
    );
  });

  createZuordnung$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ZuordnungenActions.createTagesZuordnung),
      concatLatestFrom(() =>
        this.store.select(fromMitarbeiter.selectSelectedMitarbeiterId),
      ),
      switchMap(([{ data }, selectedMitarbeiterId]) => {
        return this.kalenderApi
          .createKalendereintragForMitarbeiter$Json({
            mitarbeiterId: selectedMitarbeiterId,
            body: data,
          })
          .pipe(
            map((zuordnung) =>
              ZuordnungenActions.createTagesZuordnungSuccess({ zuordnung }),
            ),
            catchError((error) =>
              of(ZuordnungenActions.createTagesZuordnungeFailure({ error })),
            ),
          );
      }),
    );
  });

  createZuordnungenFuerZeitraum$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ZuordnungenActions.createTagesZuordnungFuerZeitraum),
      concatLatestFrom(() =>
        this.store.select(fromMitarbeiter.selectSelectedMitarbeiterId),
      ),
      switchMap(([{ data }, selectedMitarbeiterId]) => {
        return this.kalenderApi
          .createKalendereintraegeFuerMitarbeiterUndZeitraum$Json({
            mitarbeiterId: selectedMitarbeiterId,
            body: data,
          })
          .pipe(
            map((zuordnungen) =>
              ZuordnungenActions.createTagesZuordnungFuerZeitraumSuccess({ zuordnungen }),
            ),
            catchError((error) =>
              of(ZuordnungenActions.createTagesZuordnungFuerZeitraumFailure({ error })),
            ),
          );
      }),
    );
  });

  createWochenplanZuordnung$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ZuordnungenActions.createWochenplanZuordnung),
      concatLatestFrom(() =>
        this.store.select(fromMitarbeiter.selectSelectedMitarbeiterId),
      ),
      switchMap(([{ data }, selectedMitarbeiterId]) => {
        return this.wochenplanApi
          .createWochenplanZuordnungForMitarbeiter$Json({
            mitarbeiterId: selectedMitarbeiterId,
            body: data,
          })
          .pipe(
            map((response) =>
              ZuordnungenActions.createWochenplanZuordnungSuccess({
                zuordnung: response,
              }),
            ),
            catchError((error) =>
              of(
                ZuordnungenActions.createWochenplanZuordnungFailure({ error }),
              ),
            ),
          );
      }),
    );
  });

  deleteZuordnung$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ZuordnungenActions.deleteZuordnung),
      switchMap(({ id }) => {
        return this.kalenderApi
          .deleteOneKalendereintrag({ kalendereintragId: id })
          .pipe(
            map(() => ZuordnungenActions.deleteZuordnungSuccess({ id })),
            catchError((error) =>
              of(ZuordnungenActions.deleteZuordnungFailure({ error })),
            ),
          );
      }),
    );
  });

  deleteMultipleZuordnungen$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ZuordnungenActions.deleteMultipleZuordnungen),
      switchMap(({ mitarbeiterId, from, to }) => {
        const fromParam = format(from, 'yyyy-MM-dd');
        const toParam = format(to, 'yyyy-MM-dd');
        return this.kalenderApi
          .deleteKalendereintraegeForMitarbeiter({
            mitarbeiterId,
            From: fromParam,
            To: toParam,
          })
          .pipe(
            map(() =>
              ZuordnungenActions.deleteMultipleZuordnungenSuccess({
                from,
                to,
                mitarbeiterId,
              }),
            ),
            catchError((error) =>
              of(
                ZuordnungenActions.deleteMultipleZuordnungenFailure({ error }),
              ),
            ),
          );
      }),
    );
  });

  loadZuordnungFuerTag$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ZuordnungenActions.loadZuordnungFuerTag),
      concatLatestFrom(() =>
        this.store.select(fromMitarbeiter.selectSelectedMitarbeiterId),
      ),
      concatMap(([{ date }, mitarbeiterId]) => {
        const start = format(date, 'yyyy-MM-dd');
        return this.kalenderApi
          .getKalendereintraegeForMitarbeiter$Json({
            mitarbeiterId,
            From: start,
            To: start,
          })
          .pipe(
            map((response) =>
              ZuordnungenActions.loadZuordnungFuerTagSuccess({
                zuordnung: response[0],
              }),
            ),
            catchError((error) =>
              of(ZuordnungenActions.loadZuordnungFuerTagFailure({ error })),
            ),
          );
      }),
    );
  });
  // Toast Handling
  createZuordnungFailure$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(ZuordnungenActions.createTagesZuordnungeFailure),
        tap(async ({ error }) => {
          console.error(error);
          await handleHttpError(
            error,
            this.toastCtrl,
            'Beim Erstellen der Zuordnung ist ein Fehler aufgetreten.',
          );
        }),
      );
    },
    { dispatch: false },
  );

  createZuordnungSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(ZuordnungenActions.createTagesZuordnungSuccess),
        tap(async () => {
          const toast = await this.toastCtrl.create({
            message: 'Zuordnung erfolgreich erstellt.',
            duration: 3003,
            color: 'success',
          });
          await toast.present();
        }),
      );
    },
    { dispatch: false },
  );

  loadZuordnungFuerTagFailure$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(ZuordnungenActions.loadZuordnungFuerTagFailure),
        tap(async ({ error }) => {
          console.error(error);
          await handleHttpError(
            error,
            this.toastCtrl,
            'Beim Laden der Zuordnung für das gewählte Datum ist ein Fehler aufgetreten.',
          );
        }),
      );
    },
    { dispatch: false },
  );

  loadZuordnungenFailure$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(ZuordnungenActions.loadZuordnungenFailure),
        tap(async ({ error }) => {
          console.error(error);
          await handleHttpError(
            error,
            this.toastCtrl,
            'Beim Laden der Zuordnungen ist ein Fehler aufgetreten.',
          );
        }),
      );
    },
    { dispatch: false },
  );

  constructor(
    private actions$: Actions,
    private store: Store,
    private kalenderApi: KalendereintragApiService,
    private readonly toastCtrl: ToastController,
    private wochenplanApi: WochenplanApiService,
  ) {}
}
