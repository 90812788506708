import { createFeature, createReducer, on } from '@ngrx/store';
import { produce } from 'immer';
import { WochenplanDto } from '../../api/models';
import * as WochenplaeneActions from './wochenplaene.actions';

export const wochenplaeneFeatureKey = 'wochenplaene';

export interface State {
  loading: boolean;
  activeWochenplaene: WochenplanDto[];
  inactiveWochenplaene: WochenplanDto[];
}

export const initialState: State = {
  loading: false,
  activeWochenplaene: [],
  inactiveWochenplaene: [],
};

function moveWochenplanFromActiveToInactive(
  activeWochenplaene: State['activeWochenplaene'],
  inactiveWochenplaene: State['inactiveWochenplaene'],
  id: string
) {
  const index = activeWochenplaene.findIndex((v) => v.id === id);
  if (index >= 0) {
    inactiveWochenplaene.push(...activeWochenplaene.splice(index, 1));
  }
}

function addWochenplanToActiveWochenplaeneAfterCreate(
  activeWochenplaene: State['activeWochenplaene'],
  wochenplan: WochenplanDto
) {
  activeWochenplaene.push(wochenplan);
}

export const reducer = createReducer(
  initialState,
  on(
    WochenplaeneActions.loadActiveWochenplaene,
    (state): State => ({
      ...state,
      loading: true,
    })
  ),
  on(
    WochenplaeneActions.loadActiveWochenplaeneSuccess,
    (state, { activeWochenplaene }): State => ({
      ...state,
      activeWochenplaene,
      loading: false,
    })
  ),
  on(
    WochenplaeneActions.loadActiveWochenplaeneFailure,
    (state): State => ({
      ...state,
      loading: false,
    })
  ),
  on(
    WochenplaeneActions.loadInactiveWochenplaene,
    (state): State => ({
      ...state,
      loading: true,
    })
  ),
  on(
    WochenplaeneActions.loadInactiveWochenplaeneSuccess,
    (state, { inactiveWochenplaene }): State => ({
      ...state,
      inactiveWochenplaene,
      loading: false,
    })
  ),
  on(
    WochenplaeneActions.loadInactiveWochenplaeneFailure,
    (state): State => ({
      ...state,
      loading: false,
    })
  ),
  on(
    WochenplaeneActions.createWochenplan,
    (state): State => ({
      ...state,
      loading: true,
    })
  ),
  on(
    WochenplaeneActions.createWochenplanFailure,
    (state): State => ({
      ...state,
      loading: false,
    })
  ),
  on(WochenplaeneActions.createWochenplanSuccess, (state, { wochenplan }) =>
    produce(state, (draft) => {
      addWochenplanToActiveWochenplaeneAfterCreate(
        draft.activeWochenplaene,
        wochenplan
      );
      draft.loading = false;
    })
  ),
  on(
    WochenplaeneActions.setInactiveWochenplan,
    (state): State => ({
      ...state,
      loading: true,
    })
  ),
  on(
    WochenplaeneActions.setInactiveWochenplanFailure,
    (state): State => ({
      ...state,
      loading: false,
    })
  ),
  on(WochenplaeneActions.setInactiveWochenplanSuccess, (state, { id }) =>
    produce(state, (draft) => {
      moveWochenplanFromActiveToInactive(
        draft.activeWochenplaene,
        draft.inactiveWochenplaene,
        id
      );
      draft.loading = false;
    })
  )
);

export const wochenplaeneFeature = createFeature({
  name: wochenplaeneFeatureKey,
  reducer,
});
