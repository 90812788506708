import { Sort } from "@datenlotse/components/sort";
import { createFeature, createReducer, on } from "@ngrx/store";
import { produce } from "immer";
import { FehlerlistenEintrag } from "../../api/models";
import { FehlerlisteActions } from "./fehlerliste.actions";

export const fehlerlistenFeatureKey = 'fehlerlisten';

export interface State {
    fehlerliste: Map<string, FehlerlistenEintrag>;
    fehlerlistenPage: string[] | undefined;
    page: number;
    pageSize: number;
    totalCount: number | undefined;
    sort: Sort;
    filter: string;
}

export const initialState: State = {
    fehlerliste: new Map<string, FehlerlistenEintrag>(),
    fehlerlistenPage: undefined,
    page: 0,
    pageSize: 15,
    totalCount: undefined,
    sort: {
      active: 'Datum',
      direction: 'desc',
    },
    filter: '',
};

export const reducer = createReducer(
    initialState,
    on(
        FehlerlisteActions.loadFehlerlisten,
        (state): State => ({
            ...state,
            fehlerlistenPage: undefined,
        }),
    ),
    on(
        FehlerlisteActions.loadFehlerlistenSuccess,
        (state, { data, totalCount }): State => {
            const mapCopy = structuredClone(state.fehlerliste);

            if (!data) data = [];

            data.forEach((fehlerlisteEintrag) => {
                mapCopy.set(fehlerlisteEintrag.datum+fehlerlisteEintrag.mitarbeiterId, fehlerlisteEintrag);
            });

            return {
                ...state,
                fehlerlistenPage: data.map((fehlerlisteEintrag) => fehlerlisteEintrag.datum+fehlerlisteEintrag.mitarbeiterId),
                totalCount,
                fehlerliste: mapCopy,
            };
        },
    ),
    on(
      FehlerlisteActions.loadNextPage,
      (state): State => ({
        ...state,
        page: state.page + 1,
      }),
    ),
    on(
      FehlerlisteActions.loadPreviousPage,
      (state): State => ({
        ...state,
        page: state.page === 0 ? 0 : state.page - 1,
      }),
    ),
    on(
      FehlerlisteActions.setFilter,
      (state, { filter }): State => ({
        ...state,
        filter,
      }),
    ),
    on(
      FehlerlisteActions.setSort,
      (state, { sort }): State => ({
        ...state,
        sort,
      }),
    ),
    on(
        FehlerlisteActions.setFehlerlistenSortFilterPageNumberPageSize,
        (state, { sort, filter, pageNumber, pageSize }) =>
          produce(state, (draft) => {
            draft.sort = sort ?? draft.sort;
            draft.filter = filter ?? draft.filter;
            draft.page = pageNumber ?? draft.page;
            draft.pageSize = pageSize ?? draft.pageSize;
          }),
      ),
)

export const fehlerlistenFeature = createFeature({
    name: fehlerlistenFeatureKey,
    reducer: reducer,
});