import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of, tap } from 'rxjs';
import {
  catchError,
  concatMap,
  debounceTime,
  filter,
  map,
  switchMap,
} from 'rxjs/operators';
import { handleHttpError } from '../../../common/httpErrorHandler';
import { SortHelper } from '../../../common/sortStringHelper';
import { MitarbeiterDto } from '../../api/models';
import {
  ArbeitszeitkontoApiService,
  MitarbeiterApiService,
  UnifiedLoginApiService,
} from '../../api/services';
import {
  PAGINATION_HEADER_NAME,
  PaginationHeader,
} from '../../pagination-header';
import { AuthActions } from '../auth/auth.actions';
import * as fromAuth from '../auth/auth.selectors';
import { BuchungenActions } from '../buchungen/buchungen.actions';
import { ChipsActions } from '../chips/chips.actions';
import * as ZurodnungenActions from '../zuordnungen/zuordnungen.actions';
import { MitarbeiterActions } from './mitarbeiter.actions';
import * as fromMitarbeiter from './mitarbeiter.selectors';

@Injectable()
export class MitarbeiterEffects {
  loadMitarbeiters$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        MitarbeiterActions.setMitarbeiterFilter,
        MitarbeiterActions.setMitarbeiterSort,
        MitarbeiterActions.setMitarbeiterSortFilterPageNumberPageSize,
        MitarbeiterActions.loadMitarbeiter,
        MitarbeiterActions.loadMitarbeiterInfos,
        MitarbeiterActions.loadNextPage,
        MitarbeiterActions.loadPreviousPage,
        MitarbeiterActions.setUnitFilter,
        MitarbeiterActions.setPageSize,
        MitarbeiterActions.includeInactiveMitarbeiter,
        MitarbeiterActions.restoreMitarbeiterSuccess,
        MitarbeiterActions.setInactiveMitarbeiterSuccess,
        ChipsActions.restrictChipSuccess,
      ),
      debounceTime(100),
      concatLatestFrom(() => [
        this.store.select(fromMitarbeiter.selectMitarbeiterPaginationData),
        this.store.select(fromMitarbeiter.selectMitarbeiterUnitFilter),
        this.store.select(fromMitarbeiter.selectIncludeInactive),
      ]),
      switchMap(
        ([, { index, size, filter, sort }, unitFilter, includeInactive]) => {
          const direction = sort.direction === '' ? 'asc' : sort.direction;
          const sortString = new SortHelper<MitarbeiterDto>()
            .addSorting(sort.active as keyof MitarbeiterDto, direction)
            .toString();
          const filterString = filter === '' ? undefined : filter;

          return this.api$
            .getAllMitarbeiter$Json$Response({
              PageNumber: index + 1,
              PageSize: size,
              OrderBy: sortString,
              PersonalNr: filterString,
              Attribut: filterString,
              Nachname: filterString,
              Vorname: filterString,
              TarifAttribut: filterString,
              Kostenstelle: filterString,
              ChipNr: filterString,
              AbteilungSearchFilter: filterString,
              AbteilungUnitFilter: unitFilter ?? undefined,
              IncludeInactive: includeInactive,
            })
            .pipe(
              map((response) => {
                const header = response.headers.get(PAGINATION_HEADER_NAME);
                if (header == null) {
                  throw new Error('No pagination headers present');
                }
                const parsedHeader = JSON.parse(header) as PaginationHeader;
                return MitarbeiterActions.loadMitarbeiterSuccess({
                  mitarbeiter: response.body,
                  totalCount: parsedHeader.TotalCount,
                });
              }),
            );
        },
      ),
    );
  });

  createMitarbeiter$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MitarbeiterActions.createMitarbeiter),
      concatMap(({ data }) =>
        this.api$
          .createMitarbeiter$Json({
            body: data,
          })
          .pipe(
            map((createdMitarbeiter) =>
              MitarbeiterActions.createMitarbeiterSuccess({
                createdMitarbeiter,
              }),
            ),
            catchError((error) =>
              of(MitarbeiterActions.createMitarbeiterFailure({ error })),
            ),
          ),
      ),
    );
  });

  updateMitarbeiter$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MitarbeiterActions.updateMitarbeiter),
      concatLatestFrom(() =>
        this.store.select(fromMitarbeiter.selectSelectedMitarbeiterId),
      ),
      concatMap(([{ data }, selectedMitarbeiterId]) =>
        this.api$
          .updateMitarbeiter$Json({
            mitarbeiterId: selectedMitarbeiterId,
            body: data,
          })
          .pipe(
            map((updatedMitarbeiter) =>
              MitarbeiterActions.updateMitarbeiterSuccess({
                updatedMitarbeiter,
              }),
            ),
            catchError((error) =>
              of(MitarbeiterActions.updateMitarbeiterFailure({ error })),
            ),
          ),
      ),
    );
  });

  updateMitarbeiterFailure$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(MitarbeiterActions.updateMitarbeiterFailure),
        tap(async ({ error }) => {
          console.error(error);
          await handleHttpError(
            error,
            this.toastCtrl,
            'Beim Aktualisieren des Mitarbeiters ist ein Fehler aufgetreten.',
          );
        }),
      );
    },
    { dispatch: false },
  );

  loadArbeitszeitkonto$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        MitarbeiterActions.setSelectedMitarbeiterId,
        ZurodnungenActions.createTagesZuordnungSuccess,
        ZurodnungenActions.createWochenplanZuordnungSuccess,
        ZurodnungenActions.deleteZuordnungSuccess,
        ZurodnungenActions.deleteMultipleZuordnungenSuccess,
        BuchungenActions.createSingleBuchungSuccess,
        BuchungenActions.createBuchungForTimeframeSuccess,
        BuchungenActions.updateBuchungSuccess,
      ),
      concatLatestFrom(() =>
        this.store.select(fromMitarbeiter.selectSelectedMitarbeiter),
      ),
      filter(([, mitarbeiter]) => !!mitarbeiter && !mitarbeiter.isDeleted),
      switchMap(([, mitarbeiter]) => {
        if (!mitarbeiter || mitarbeiter.id == '' || mitarbeiter.isDeleted) {
          return of(
            MitarbeiterActions.loadArbeitszeitkontoFailure({
              error: 'Kein Mitarbeiter ausgewählt',
            }),
          );
        }
        return this.arbeitszeitKontoApi$
          .getAggregiertesArbeitszeitkontoForMitarbeiter$Json({
            mitarbeiterId: mitarbeiter.id,
            monat: new Date().toISOString(),
          })
          .pipe(
            map((arbeitszeitkonto) =>
              MitarbeiterActions.loadArbeitszeitkontoSuccess({
                arbeitszeitkonto,
              }),
            ),
            catchError((error) =>
              of(MitarbeiterActions.loadArbeitszeitkontoFailure({ error })),
            ),
          );
      }),
    );
  });

  getMitarbeiterByUserId$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MitarbeiterActions.loadMitarbeiterByUserId),
      concatMap(({ userId }) =>
        this.api$.getMitarbeiterByUserId$Json({ userId }).pipe(
          map((mitarbeiter) =>
            MitarbeiterActions.loadMitarbeiterByUserIdSuccess({ mitarbeiter }),
          ),
          catchError((error) =>
            of(MitarbeiterActions.loadMitarbeiterByUserIdFailure({ error })),
          ),
        ),
      ),
    );
  });

  loadMissingMitarbeiter$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.setLoggedInUser),
      filter((x) => x.userId !== null),
      concatLatestFrom(() =>
        this.store.select(fromAuth.selectLoggedInMitarbeiter),
      ),
      filter(([, mitarbeiter]) => null === mitarbeiter),
      switchMap(([{ userId }]) => {
        // Filtered out above
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        if (userId !== null) {
          return of(
            MitarbeiterActions.loadMitarbeiterByUserId({
              userId: userId,
            }),
          );
        }
        return of();
      }),
    );
  });

  loadMitarbeiterInfos$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MitarbeiterActions.loadMitarbeiterInfos),
      switchMap(({ abteilung, attributId, kostenstelle, monat }) =>
        this.api$
          .getAllMitarbeiterAggregated$Json({
            Abteilung: abteilung,
            AttributId: attributId,
            Kostenstelle: kostenstelle,
            Monat: monat,
          })
          .pipe(
            map((response) =>
              MitarbeiterActions.loadMitarbeiterInfosSuccess({
                mitarbeiterInfos: response,
                monat,
              }),
            ),
            catchError((error) =>
              of(MitarbeiterActions.loadMitarbeiterInfosFailure({ error })),
            ),
          ),
      ),
    );
  });

  loadMitarbeiterInfosFailure$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(MitarbeiterActions.loadMitarbeiterInfosFailure),
        tap(async ({ error }) => {
          console.error(error);
          await handleHttpError(
            error,
            this.toastCtrl,
            'Beim Laden der Mitarbeiterinfos ist ein Fehler aufgetreten.',
          );
        }),
      );
    },
    { dispatch: false },
  );

  loadSingleMitarbeiterInfos$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        MitarbeiterActions.loadSingleMitarbeiterInfos,
        MitarbeiterActions.setSelectedMitarbeiterId,
        BuchungenActions.createSingleBuchungSuccess,
        BuchungenActions.createBuchungForTimeframeSuccess,
        BuchungenActions.createBuchungForTimeframeAndTarifAttributSuccess,
        BuchungenActions.updateBuchungSuccess,
        BuchungenActions.deleteBuchungSuccess,
        ZurodnungenActions.createTagesZuordnungSuccess,
        ZurodnungenActions.createWochenplanZuordnungSuccess,
        ZurodnungenActions.deleteZuordnungSuccess,
        ZurodnungenActions.deleteMultipleZuordnungenSuccess,
      ),
      concatLatestFrom(() =>
        this.store.select(fromMitarbeiter.selectSelectedMitarbeiter),
      ),
      filter(([, mitarbeiter]) => !!mitarbeiter && !mitarbeiter.isDeleted),
      switchMap(([, mitarbeiter]) => {
        if (mitarbeiter && mitarbeiter.id !== '') {
          return this.api$
            .getSingleMitarbeiterInfos$Json({ mitarbeiterId: mitarbeiter.id })
            .pipe(
              map((response) =>
                MitarbeiterActions.loadSingleMitarbeiterInfosSuccess({
                  mitarbeiterInfos: response,
                }),
              ),
              catchError((error) =>
                of(
                  MitarbeiterActions.loadSingleMitarbeiterInfosFailure({
                    error,
                  }),
                ),
              ),
            );
        } else {
          return of();
        }
      }),
    );
  });

  loadSingleMitarbeiterInfosFailure$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(MitarbeiterActions.loadSingleMitarbeiterInfosFailure),
        tap(async ({ error }) => {
          console.error(error);
          await handleHttpError(
            error,
            this.toastCtrl,
            'Beim Laden der Mitarbeiterinfos ist ein Fehler aufgetreten.',
          );
        }),
      );
    },
    { dispatch: false },
  );

  setInactiveMitarbeiter$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MitarbeiterActions.setInactiveMitarbeiter),
      switchMap((data) =>
        this.api$
          .setMitarbeiterInactive$Json({
            mitarbeiterId: data.mitarbeiterId,
          })
          .pipe(
            map((data) =>
              MitarbeiterActions.setInactiveMitarbeiterSuccess({
                inactiveMitarbeiter: data,
              }),
            ),
            catchError((error) =>
              of(MitarbeiterActions.setInactiveMitarbeiterFailure({ error })),
            ),
          ),
      ),
    );
  });

  setInactiveMitarbeiterFailure$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(MitarbeiterActions.setInactiveMitarbeiterFailure),
        tap(async ({ error }) => {
          console.error(error);
          await handleHttpError(
            error,
            this.toastCtrl,
            'Beim Deaktivieren des Mitarbeiters ist ein Fehler aufgetreten.',
          );
        }),
      );
    },
    { dispatch: false },
  );

  restoreMitarbeiter$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MitarbeiterActions.restoreMitarbeiter),
      switchMap((data) =>
        this.api$
          .restoreMitarbeiter$Json({
            mitarbeiterId: data.mitarbeiterId,
          })
          .pipe(
            map((data) =>
              MitarbeiterActions.restoreMitarbeiterSuccess({
                restoredMitarbeiter: data,
              }),
            ),
            catchError((error) =>
              of(MitarbeiterActions.restoreMitarbeiterFailure({ error })),
            ),
          ),
      ),
    );
  });

  restoreMitarbeiterFailure$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(MitarbeiterActions.restoreMitarbeiterFailure),
        tap(async ({ error }) => {
          console.error(error);
          await handleHttpError(
            error,
            this.toastCtrl,
            'Beim Wiederherstellen des Mitarbeiters ist ein Fehler aufgetreten.',
          );
        }),
      );
    },
    { dispatch: false },
  );

  loadUnifiedLoginMitarbeiter$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MitarbeiterActions.loadUnifiedLoginMitarbeiter),
      switchMap(() =>
        this.unifiedLoginApi$.getUsers$Json().pipe(
          map((unifiedLoginMitarbeiter) =>
            MitarbeiterActions.loadUnifiedLoginMitarbeiterSuccess({
              unifiedLoginMitarbeiter,
            }),
          ),
          catchError((error) =>
            of(
              MitarbeiterActions.loadUnifiedLoginMitarbeiterFailure({ error }),
            ),
          ),
        ),
      ),
    );
  });

  loadMitarbeiterById$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MitarbeiterActions.setSelectedMitarbeiterId),
      filter(({ id }) => !!id),
      switchMap(({ id }) =>
        this.api$.getMitarbeiterById$Json({ mitarbeiterId: id }).pipe(
          map((mitarbeiter) =>
            MitarbeiterActions.loadMitarbeiterByIdSuccess({ mitarbeiter }),
          ),
          catchError((error) =>
            of(MitarbeiterActions.loadMitarbeiterByIdFailure({ error })),
          ),
        ),
      ),
    );
  });

  loadMitarbeiterByIdFailure$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(MitarbeiterActions.loadMitarbeiterByIdFailure),
        tap(async ({ error }) => {
          console.error(error);
          await handleHttpError(
            error,
            this.toastCtrl,
            'Beim Laden des Mitarbeiters ist ein Fehler aufgetreten.',
          );
        }),
      );
    },
    { dispatch: false },
  );

  constructor(
    private actions$: Actions,
    private readonly api$: MitarbeiterApiService,
    private readonly unifiedLoginApi$: UnifiedLoginApiService,
    private readonly store: Store,
    private readonly arbeitszeitKontoApi$: ArbeitszeitkontoApiService,
    private readonly toastCtrl: ToastController,
  ) {}
}
