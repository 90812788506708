import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { lastValueFrom, switchMap } from "rxjs";
import { BuchungsaenderungsAntragsApiService, BuchungserstellAntragsApiService } from "../../api/services";
import { PAGINATION_HEADER_NAME } from "../../pagination-header";
import { BuchungskorrekturenFuerMonteureActions } from "./buchungskorrektur-monteure.actions";


@Injectable()
export class BuchungskorrekturMonteureEffects {

  constructor(
    private actions$: Actions,
    private readonly buchungsAenderungsAntragApi: BuchungsaenderungsAntragsApiService,
    private readonly buchungsErstellAntraegeApi: BuchungserstellAntragsApiService,
  ) { }

  loadBuchungskorrekturMonteure$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        BuchungskorrekturenFuerMonteureActions.loadBuchungskorrekturen,
        BuchungskorrekturenFuerMonteureActions.setPageIndex,
        BuchungskorrekturenFuerMonteureActions.setPageSize,
      ),
      switchMap(async () => {
        try {
          const aenderungsAntraegeResponse = await lastValueFrom(this.buchungsAenderungsAntragApi.getAllAntraegePaginated$Json$Response({FuerMonteure: 'true'}));
          const erstellAntraegeResponse = await lastValueFrom(this.buchungsErstellAntraegeApi.getAllErstellAntraegePaginated$Json$Response({FuerMonteure: 'true'}));
          const aenderungsAntraegeTotalCount = aenderungsAntraegeResponse.headers.get(PAGINATION_HEADER_NAME)
            ? JSON.parse(
              aenderungsAntraegeResponse.headers.get(PAGINATION_HEADER_NAME) as string,
            ).TotalCount
            : aenderungsAntraegeResponse.body.length;
            const erstellAntraegeTotalCount = erstellAntraegeResponse.headers.get(PAGINATION_HEADER_NAME)
            ? JSON.parse(
              erstellAntraegeResponse.headers.get(PAGINATION_HEADER_NAME) as string,
            ).TotalCount
            : erstellAntraegeResponse.body.length;

          return BuchungskorrekturenFuerMonteureActions.loadBuchungskorrekturenSuccess({
            aenderungsAntraege: aenderungsAntraegeResponse.body,
            erstellAntraege: erstellAntraegeResponse.body,
            totalCount: aenderungsAntraegeTotalCount + erstellAntraegeTotalCount,
          });
        } catch (error) {
          return BuchungskorrekturenFuerMonteureActions.loadBuchungskorrekturenFailure({ error });
        }

      })
    );
  });
}
