import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { handleHttpError } from '../../../common/httpErrorHandler';
import { ChipApiService } from '../../api/services';
import { MitarbeiterActions } from '../mitarbeiter/mitarbeiter.actions';
import { PersonenWithZugaengeActions } from '../personen-zugaenge/personen-zugaenge.actions';
import { ChipsActions } from './chips.actions';

@Injectable()
export class ChipsEffects {
  loadAvailableChips$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        ChipsActions.loadAvailableChips,
        ChipsActions.restrictChipSuccess,
        ChipsActions.unrestrictChipSuccess,
        MitarbeiterActions.setInactiveMitarbeiterSuccess,
        MitarbeiterActions.updateMitarbeiterSuccess,
        MitarbeiterActions.createMitarbeiterSuccess,
        MitarbeiterActions.restoreMitarbeiterSuccess,
        PersonenWithZugaengeActions.updatePersonenWithZugaengeSuccess,
        PersonenWithZugaengeActions.createOrUpdateExternWithZugaengeSuccess,
        PersonenWithZugaengeActions.deleteExternWithZugaengeSuccess,
      ),
      switchMap(() =>
        this.api$.getAllAvailableChips$Json().pipe(
          map((availableChips) =>
            ChipsActions.loadAvailableChipsSuccess({ availableChips }),
          ),
          catchError((error) =>
            of(ChipsActions.loadAvailableChipsFailure({ error })),
          ),
        ),
      ),
    );
  });

  loadAvailableChipsFailure$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(ChipsActions.loadAvailableChipsFailure),
        tap(async ({ error }) => {
          console.error(error);
          await handleHttpError(
            error,
            this.toastCtrl,
            'Beim Laden der verfügbaren Chips ist ein Fehler aufgetreten.',
          );
        }),
      );
    },
    { dispatch: false },
  );

  loadAllChips$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        ChipsActions.loadAllChips,
        ChipsActions.restrictChipSuccess,
        ChipsActions.unrestrictChipSuccess,
        PersonenWithZugaengeActions.loadZutrittskontrolle,
        MitarbeiterActions.setInactiveMitarbeiterSuccess,
        MitarbeiterActions.updateMitarbeiterSuccess,
        MitarbeiterActions.createMitarbeiterSuccess,
        MitarbeiterActions.restoreMitarbeiterSuccess,
        PersonenWithZugaengeActions.updatePersonenWithZugaengeSuccess,
        PersonenWithZugaengeActions.createOrUpdateExternWithZugaengeSuccess,
        PersonenWithZugaengeActions.deleteExternWithZugaengeSuccess,
      ),
      switchMap(() =>
        this.api$.getAllChips$Json().pipe(
          map((allChips) => ChipsActions.loadAllChipsSuccess({ allChips })),
          catchError((error) =>
            of(ChipsActions.loadAllChipsFailure({ error })),
          ),
        ),
      ),
    );
  });

  loadAllChipsFailure$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(ChipsActions.loadAllChipsFailure),
        tap(async ({ error }) => {
          console.error(error);
          await handleHttpError(
            error,
            this.toastCtrl,
            'Beim Laden der verfügbaren Chips ist ein Fehler aufgetreten.',
          );
        }),
      );
    },
    { dispatch: false },
  );

  restrictChip$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ChipsActions.restrictChip),
      switchMap(({ chipToBeRestricted }) =>
        this.api$.restrictChip({ body: chipToBeRestricted }).pipe(
          map(() => ChipsActions.restrictChipSuccess()),
          catchError((error) =>
            of(ChipsActions.restrictChipFailure({ error })),
          ),
        ),
      ),
    );
  });

  restrictChipSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(ChipsActions.restrictChipSuccess),
        tap(async () => {
          const toast = await this.toastCtrl.create({
            message: 'Chip erfolreich gesperrt',
            duration: 3003,
            color: 'success',
          });
          await toast.present();
        }),
      );
    },
    { dispatch: false },
  );

  restrictChipFailure$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(ChipsActions.restrictChipFailure),
        tap(async ({ error }) => {
          console.error(error);
          await handleHttpError(
            error,
            this.toastCtrl,
            'Beim Sperren des Chips ist ein Fehler aufgetreten.',
          );
        }),
      );
    },
    { dispatch: false },
  );

  unrestrictChip$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ChipsActions.unrestrictChip),
      switchMap(({ chipNr }) =>
        this.api$.unrestrictChip$Json({ chipNr: +chipNr }).pipe(
          map((chip) => ChipsActions.unrestrictChipSuccess({ chip })),
          catchError((error) =>
            of(ChipsActions.unrestrictChipFailure({ error })),
          ),
        ),
      ),
    );
  });

  unrestrictChipSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(ChipsActions.unrestrictChipSuccess),
        tap(async () => {
          const toast = await this.toastCtrl.create({
            message: 'Chip erfolreich entsperrt',
            duration: 3003,
            color: 'success',
          });
          await toast.present();
        }),
      );
    },
    { dispatch: false },
  );

  unrestrictChipFailure$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(ChipsActions.restrictChipFailure),
        tap(async ({ error }) => {
          console.error(error);
          await handleHttpError(
            error,
            this.toastCtrl,
            'Beim Entsperren des Chips ist ein Fehler aufgetreten.',
          );
        }),
      );
    },
    { dispatch: false },
  );

  constructor(
    private actions$: Actions,
    private readonly api$: ChipApiService,
    private readonly toastCtrl: ToastController,
  ) {}
}
