import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { map, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { MonatsauswertungLayoutApiService } from '../../api/services/monatsauswertung-layout-api.service';
import { AuswertungLayoutsActions } from './auswertung-layouts.actions';

@Injectable()
export class AuswertungLayoutsEffects {
  loadAuswertungLayouts$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        AuswertungLayoutsActions.loadMonatsauswertungLayouts,
        AuswertungLayoutsActions.loadMonatsauswertungLayoutsUndSpalten,
      ),
      /** An EMPTY observable only emits completion. Replace with your own observable API request */
      switchMap(() =>
        this.api$.getAllMonatsauswertungLayouts$Json().pipe(
          map((layouts) =>
            AuswertungLayoutsActions.loadMonatsauswertungLayoutsSuccess({
              monatsauswertungLayouts: layouts,
            }),
          ),
          catchError((error) =>
            of(
              AuswertungLayoutsActions.loadMonatsauswertungLayoutsFailure({
                error,
              }),
            ),
          ),
        ),
      ),
    );
  });

  loadAuswertungSpalten$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        AuswertungLayoutsActions.loadMonatsauswertungSpalten,
        AuswertungLayoutsActions.loadMonatsauswertungLayoutsUndSpalten,
      ),
      switchMap(() =>
        this.api$.getAllMonatsauswertungSpalten$Json().pipe(
          map((spalten) =>
            AuswertungLayoutsActions.loadMonatsauswertungSpaltenSuccess({
              monatsauswertungSpalten: spalten,
            }),
          ),
          catchError((error) =>
            of(
              AuswertungLayoutsActions.loadMonatsauswertungSpaltenFailure({
                error,
              }),
            ),
          ),
        ),
      ),
    );
  });

  createMonatsauswertungLayout$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuswertungLayoutsActions.createMonatsauswertungLayout),
      switchMap(({ monatsauswertungLayout }) =>
        this.api$
          .createMonatsauswertungLayout$Json({
            body: monatsauswertungLayout,
          })
          .pipe(
            map((layout) =>
              AuswertungLayoutsActions.createMonatsauswertungLayoutSuccess({
                monatsauswertungLayout: layout,
              }),
            ),
            catchError((error) =>
              of(
                AuswertungLayoutsActions.createMonatsauswertungLayoutFailure({
                  error,
                }),
              ),
            ),
          ),
      ),
    );
  });

  updateMonatsauswertungLayout$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuswertungLayoutsActions.updateMonatsauswertungLayout),
      switchMap(({ monatsauswertungLayout, id }) =>
        this.api$
          .updateMonatsauswertungLayout$Json({
            id,
            body: monatsauswertungLayout,
          })
          .pipe(
            map((layout) =>
              AuswertungLayoutsActions.updateMonatsauswertungLayoutSuccess({
                monatsauswertungLayout: layout,
              }),
            ),
            catchError((error) =>
              of(
                AuswertungLayoutsActions.updateMonatsauswertungLayoutFailure({
                  error,
                }),
              ),
            ),
          ),
      ),
    );
  });

  constructor(
    private actions$: Actions,
    private api$: MonatsauswertungLayoutApiService,
  ) {}
}
