import { enableProdMode, importProvidersFrom, LOCALE_ID } from '@angular/core';

import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import '@angular/common/locales/global/de';
import { bootstrapApplication } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';
import { DatenlotseAngularAuthModule } from '@datenlotse/angular-auth';
import { SidebarModule } from '@datenlotse/components/sidebar';
import { IonicAuthInterceptor } from '@datenlotse/ionic-auth';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { provideEffects } from '@ngrx/effects';
import { provideRouterStore, routerReducer } from '@ngrx/router-store';
import { provideStore } from '@ngrx/store';
import { provideStoreDevtools } from '@ngrx/store-devtools';
import { ApiModule as PzeApiModule } from './app/api/api.module';
import { AppRoutingModule } from './app/app-routing.module';
import { AppComponent } from './app/app.component';
import { AuswertungLayoutsEffects } from './app/store/auswertung-layouts/auswertung-layouts.effects';
import { auswertungLayoutsFeature } from './app/store/auswertung-layouts/auswertung-layouts.reducer';
import { AuthEffects } from './app/store/auth/auth.effects';
import { authFeature } from './app/store/auth/auth.reducer';
import { BuchungenEffects } from './app/store/buchungen/buchungen.effects';
import { buchungenFeature } from './app/store/buchungen/buchungen.reducer';
import { BuchungskorrekturMonteureEffects } from './app/store/buchungskorrektur-monteure/buchungskorrektur-monteure.effects';
import { buchungskorrekturMonteureFeature } from './app/store/buchungskorrektur-monteure/buchungskorrektur-monteure.reducer';
import { ChipsEffects } from './app/store/chips/chips.effects';
import { chipsFeature } from './app/store/chips/chips.reducer';
import { FehlerlistenEffects } from './app/store/fehlerliste/fehlerliste.effects';
import { fehlerlistenFeature } from './app/store/fehlerliste/fehlerliste.reducer';
import { MehrarbeitAuszahlungenEffects } from './app/store/mehrarbeitauszahlungen/mehrarbeitauszahlungen.effects';
import { mehrarbeitAuszahlungenFeature } from './app/store/mehrarbeitauszahlungen/mehrarbeitauszahlungen.reducer';
import { MehrarbeitUmbuchungenEffects } from './app/store/mehrarbeitumbuchungen/mehrarbeitumbuchungen.effects';
import { mehrarbeitUmbuchungenFeature } from './app/store/mehrarbeitumbuchungen/mehrarbeitumbuchungen.reducer';
import { MitarbeiterEffects } from './app/store/mitarbeiter/mitarbeiter.effects';
import { mitarbeiterFeature } from './app/store/mitarbeiter/mitarbeiter.reducer';
import { MonatsauswertungenEffects } from './app/store/monatsauswertungen/monatsauswertungen.effects';
import { monatsauswertungenFeature } from './app/store/monatsauswertungen/monatsauswertungen.reducer';
import { PersonenWithZugaengeEffects } from './app/store/personen-zugaenge/personen-zugaenge.effects';
import { personenWithZugaengeFeature } from './app/store/personen-zugaenge/personen-zugaenge.reducer';
import { SchnellauswahlenEffects } from './app/store/schnellauswahlen/schnellauswahlen.effects';
import { schnellauswahlenFeature } from './app/store/schnellauswahlen/schnellauswahlen.reducer';
import { SparzeitUmbuchungenEffects } from './app/store/sparzeitumbuchungen/sparzeitumbuchungen.effects';
import { sparzeitUmbuchungFeature } from './app/store/sparzeitumbuchungen/sparzeitumbuchungen.reducer';
import { TagesplaeneEffects } from './app/store/tagesplaene/tagesplaene.effects';
import { tagesplaeneFeature } from './app/store/tagesplaene/tagesplaene.reducer';
import { UrlaubsantraegeEffects } from './app/store/urlaubsantraege/urlaubsantraege.effects';
import { urlaubsantraegeFeature } from './app/store/urlaubsantraege/urlaubsantraege.reducer';
import { WochenplaeneEffects } from './app/store/wochenplaene/wochenplaene.effects';
import { wochenplaeneFeature } from './app/store/wochenplaene/wochenplaene.reducer';
import { ZugaengeEffects } from './app/store/zugaenge/zugaenge.effects';
import { zugaengeFeature } from './app/store/zugaenge/zugaenge.reducer';
import { ZuordnungenEffects } from './app/store/zuordnungen/zuordnungen.effects';
import { zuordnungenFeature } from './app/store/zuordnungen/zuordnungen.reducer';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(
      IonicModule.forRoot(),
      DatenlotseAngularAuthModule.forRoot({
        loginUrl: environment.authLoginUrl,
        clientId: environment.authClientId,
        redirectUrl: environment.authRedirectUrl,
        apiUrl: environment.authApiUrl,
      }),
      AppRoutingModule,
      SidebarModule,
      PzeApiModule.forRoot({
        rootUrl: `${location.origin}/api`,
      }),
    ),
    {
      provide: HTTP_INTERCEPTORS,
      multi: true,
      useClass: IonicAuthInterceptor,
    },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: LOCALE_ID, useValue: 'de-DE' },
    provideAnimations(),
    provideStore(
      {
        [auswertungLayoutsFeature.name]: auswertungLayoutsFeature.reducer,
        [authFeature.name]: authFeature.reducer,
        [buchungenFeature.name]: buchungenFeature.reducer,
        [fehlerlistenFeature.name]: fehlerlistenFeature.reducer,
        [mitarbeiterFeature.name]: mitarbeiterFeature.reducer,
        [personenWithZugaengeFeature.name]: personenWithZugaengeFeature.reducer,
        [chipsFeature.name]: chipsFeature.reducer,
        [monatsauswertungenFeature.name]: monatsauswertungenFeature.reducer,
        [mehrarbeitAuszahlungenFeature.name]:
          mehrarbeitAuszahlungenFeature.reducer,
        [mehrarbeitUmbuchungenFeature.name]:
          mehrarbeitUmbuchungenFeature.reducer,
        [sparzeitUmbuchungFeature.name]: sparzeitUmbuchungFeature.reducer,
        router: routerReducer,
        [tagesplaeneFeature.name]: tagesplaeneFeature.reducer,
        [schnellauswahlenFeature.name]: schnellauswahlenFeature.reducer,
        [urlaubsantraegeFeature.name]: urlaubsantraegeFeature.reducer,
        [wochenplaeneFeature.name]: wochenplaeneFeature.reducer,
        [zuordnungenFeature.name]: zuordnungenFeature.reducer,
        [zugaengeFeature.name]: zugaengeFeature.reducer,
        [buchungskorrekturMonteureFeature.name]:
          buchungskorrekturMonteureFeature.reducer,
      },
      {
        metaReducers: !environment.production ? [] : [],
        runtimeChecks: {
          strictActionImmutability: true,
          strictStateImmutability: true,
        },
      },
    ),
    provideRouterStore({
      stateKey: 'router',
    }),
    provideStoreDevtools({
      logOnly: environment.production,
      connectInZone: true,
    }),
    provideEffects([
      AuswertungLayoutsEffects,
      AuthEffects,
      BuchungenEffects,
      FehlerlistenEffects,
      MitarbeiterEffects,
      PersonenWithZugaengeEffects,
      MonatsauswertungenEffects,
      SchnellauswahlenEffects,
      ChipsEffects,
      TagesplaeneEffects,
      UrlaubsantraegeEffects,
      WochenplaeneEffects,
      ZugaengeEffects,
      ZuordnungenEffects,
      BuchungskorrekturMonteureEffects,
      MehrarbeitAuszahlungenEffects,
      MehrarbeitUmbuchungenEffects,
      SparzeitUmbuchungenEffects,
    ]),
    provideHttpClient(withInterceptorsFromDi()),
  ],
}).catch((err) => console.error(err));
