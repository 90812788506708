import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { ToastController } from '@ionic/angular';
import { formatBadRequest } from '../app/bad-request';

type PzeApiError = {
  Title: string;
  Status: number;
  Detail: string;
  Instance: string;
  traceId: string;
};

export const isPzeApiError = (error: unknown): error is PzeApiError => {
  return (
    typeof error === 'object' &&
    error !== null &&
    ('Title' in error || 'title' in error) &&
    ('Status' in error || 'status' in error) &&
    ('Detail' in error || 'detail' in error) &&
    ('Instance' in error || 'instance' in error) &&
    'traceId' in error
  );
};

export const handleHttpError = async (
  error: unknown,
  toastCtrl: ToastController,
  defaultMessage = 'Ein unbekannter Fehler ist aufgetreten',
) => {
  console.error(error);
  if (error instanceof HttpErrorResponse) {
    defaultMessage = parseHttpError(error, defaultMessage);
    const toast = await toastCtrl.create({
      message: defaultMessage,
      duration: 5000,
      color: 'danger',
    });
    await toast.present();
  }
};

export const parseHttpError = (error: Error, defaultMessage: string) => {
  let message = defaultMessage;
  if (error instanceof HttpErrorResponse) {
    let json = null;

    if (typeof error.error === 'string') {
      try {
        json = JSON.parse(error.error);
      } catch (err) {
        // ignore
      }
    }

    if (error.status === HttpStatusCode.BadRequest) {
      message = formatBadRequest(error);
    } else {
      message = error.error.detail ?? message;
    }

    if (json !== null && isPzeApiError(json)) {
      message = json.Detail ?? message;
    }

    if (typeof error.error === 'object' && isPzeApiError(error.error)) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      message = error.error.Detail ?? (error.error as any)['detail'];
    }

    // Create a copy of the message
    message = structuredClone(message);
  }

  return message;
};
