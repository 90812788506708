/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { UrlaubsantragDto } from '../../models/urlaubsantrag-dto';

export interface GetUrlaubsantraegeForMitarbeiter$Json$Params {

/**
 * ID des Mitarbeiter für den die Urlaubsanträge geholt werden sollen
 */
  mitarbeiterId: string;

/**
 * Jahr für das die Urlaubsanträge geholt werden sollen
 */
  year?: number;
}

export function getUrlaubsantraegeForMitarbeiter$Json(http: HttpClient, rootUrl: string, params: GetUrlaubsantraegeForMitarbeiter$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<UrlaubsantragDto>>> {
  const rb = new RequestBuilder(rootUrl, getUrlaubsantraegeForMitarbeiter$Json.PATH, 'get');
  if (params) {
    rb.path('mitarbeiterId', params.mitarbeiterId, {});
    rb.query('year', params.year, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'text/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<Array<UrlaubsantragDto>>;
    })
  );
}

getUrlaubsantraegeForMitarbeiter$Json.PATH = '/v1/urlaubsantraege/mitarbeiter/{mitarbeiterId}';
