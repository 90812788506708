/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { createMonatsauswertungLayout$Json } from '../fn/monatsauswertung-layout/create-monatsauswertung-layout-json';
import { CreateMonatsauswertungLayout$Json$Params } from '../fn/monatsauswertung-layout/create-monatsauswertung-layout-json';
import { createMonatsauswertungLayout$Plain } from '../fn/monatsauswertung-layout/create-monatsauswertung-layout-plain';
import { CreateMonatsauswertungLayout$Plain$Params } from '../fn/monatsauswertung-layout/create-monatsauswertung-layout-plain';
import { deleteMonatsauswertungLayout } from '../fn/monatsauswertung-layout/delete-monatsauswertung-layout';
import { DeleteMonatsauswertungLayout$Params } from '../fn/monatsauswertung-layout/delete-monatsauswertung-layout';
import { getAllMonatsauswertungLayouts$Json } from '../fn/monatsauswertung-layout/get-all-monatsauswertung-layouts-json';
import { GetAllMonatsauswertungLayouts$Json$Params } from '../fn/monatsauswertung-layout/get-all-monatsauswertung-layouts-json';
import { getAllMonatsauswertungLayouts$Plain } from '../fn/monatsauswertung-layout/get-all-monatsauswertung-layouts-plain';
import { GetAllMonatsauswertungLayouts$Plain$Params } from '../fn/monatsauswertung-layout/get-all-monatsauswertung-layouts-plain';
import { getAllMonatsauswertungSpalten$Json } from '../fn/monatsauswertung-layout/get-all-monatsauswertung-spalten-json';
import { GetAllMonatsauswertungSpalten$Json$Params } from '../fn/monatsauswertung-layout/get-all-monatsauswertung-spalten-json';
import { getAllMonatsauswertungSpalten$Plain } from '../fn/monatsauswertung-layout/get-all-monatsauswertung-spalten-plain';
import { GetAllMonatsauswertungSpalten$Plain$Params } from '../fn/monatsauswertung-layout/get-all-monatsauswertung-spalten-plain';
import { getMonatsauswertungLayoutById$Json } from '../fn/monatsauswertung-layout/get-monatsauswertung-layout-by-id-json';
import { GetMonatsauswertungLayoutById$Json$Params } from '../fn/monatsauswertung-layout/get-monatsauswertung-layout-by-id-json';
import { getMonatsauswertungLayoutById$Plain } from '../fn/monatsauswertung-layout/get-monatsauswertung-layout-by-id-plain';
import { GetMonatsauswertungLayoutById$Plain$Params } from '../fn/monatsauswertung-layout/get-monatsauswertung-layout-by-id-plain';
import { MonatsauswertungLayoutDto } from '../models/monatsauswertung-layout-dto';
import { MonatsauswertungSpalteDto } from '../models/monatsauswertung-spalte-dto';
import { updateMonatsauswertungLayout$Json } from '../fn/monatsauswertung-layout/update-monatsauswertung-layout-json';
import { UpdateMonatsauswertungLayout$Json$Params } from '../fn/monatsauswertung-layout/update-monatsauswertung-layout-json';
import { updateMonatsauswertungLayout$Plain } from '../fn/monatsauswertung-layout/update-monatsauswertung-layout-plain';
import { UpdateMonatsauswertungLayout$Plain$Params } from '../fn/monatsauswertung-layout/update-monatsauswertung-layout-plain';

@Injectable({ providedIn: 'root' })
export class MonatsauswertungLayoutApiService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getAllMonatsauswertungLayouts()` */
  static readonly GetAllMonatsauswertungLayoutsPath = '/v1/monatsauswertungen/layouts';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllMonatsauswertungLayouts$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllMonatsauswertungLayouts$Plain$Response(params?: GetAllMonatsauswertungLayouts$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<MonatsauswertungLayoutDto>>> {
    return getAllMonatsauswertungLayouts$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllMonatsauswertungLayouts$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllMonatsauswertungLayouts$Plain(params?: GetAllMonatsauswertungLayouts$Plain$Params, context?: HttpContext): Observable<Array<MonatsauswertungLayoutDto>> {
    return this.getAllMonatsauswertungLayouts$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<MonatsauswertungLayoutDto>>): Array<MonatsauswertungLayoutDto> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllMonatsauswertungLayouts$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllMonatsauswertungLayouts$Json$Response(params?: GetAllMonatsauswertungLayouts$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<MonatsauswertungLayoutDto>>> {
    return getAllMonatsauswertungLayouts$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllMonatsauswertungLayouts$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllMonatsauswertungLayouts$Json(params?: GetAllMonatsauswertungLayouts$Json$Params, context?: HttpContext): Observable<Array<MonatsauswertungLayoutDto>> {
    return this.getAllMonatsauswertungLayouts$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<MonatsauswertungLayoutDto>>): Array<MonatsauswertungLayoutDto> => r.body)
    );
  }

  /** Path part for operation `createMonatsauswertungLayout()` */
  static readonly CreateMonatsauswertungLayoutPath = '/v1/monatsauswertungen/layouts';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createMonatsauswertungLayout$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createMonatsauswertungLayout$Plain$Response(params?: CreateMonatsauswertungLayout$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<MonatsauswertungLayoutDto>> {
    return createMonatsauswertungLayout$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createMonatsauswertungLayout$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createMonatsauswertungLayout$Plain(params?: CreateMonatsauswertungLayout$Plain$Params, context?: HttpContext): Observable<MonatsauswertungLayoutDto> {
    return this.createMonatsauswertungLayout$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<MonatsauswertungLayoutDto>): MonatsauswertungLayoutDto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createMonatsauswertungLayout$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createMonatsauswertungLayout$Json$Response(params?: CreateMonatsauswertungLayout$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<MonatsauswertungLayoutDto>> {
    return createMonatsauswertungLayout$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createMonatsauswertungLayout$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createMonatsauswertungLayout$Json(params?: CreateMonatsauswertungLayout$Json$Params, context?: HttpContext): Observable<MonatsauswertungLayoutDto> {
    return this.createMonatsauswertungLayout$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<MonatsauswertungLayoutDto>): MonatsauswertungLayoutDto => r.body)
    );
  }

  /** Path part for operation `getMonatsauswertungLayoutById()` */
  static readonly GetMonatsauswertungLayoutByIdPath = '/v1/monatsauswertungen/layouts/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMonatsauswertungLayoutById$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMonatsauswertungLayoutById$Plain$Response(params: GetMonatsauswertungLayoutById$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<MonatsauswertungLayoutDto>> {
    return getMonatsauswertungLayoutById$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMonatsauswertungLayoutById$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMonatsauswertungLayoutById$Plain(params: GetMonatsauswertungLayoutById$Plain$Params, context?: HttpContext): Observable<MonatsauswertungLayoutDto> {
    return this.getMonatsauswertungLayoutById$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<MonatsauswertungLayoutDto>): MonatsauswertungLayoutDto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMonatsauswertungLayoutById$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMonatsauswertungLayoutById$Json$Response(params: GetMonatsauswertungLayoutById$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<MonatsauswertungLayoutDto>> {
    return getMonatsauswertungLayoutById$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMonatsauswertungLayoutById$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMonatsauswertungLayoutById$Json(params: GetMonatsauswertungLayoutById$Json$Params, context?: HttpContext): Observable<MonatsauswertungLayoutDto> {
    return this.getMonatsauswertungLayoutById$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<MonatsauswertungLayoutDto>): MonatsauswertungLayoutDto => r.body)
    );
  }

  /** Path part for operation `updateMonatsauswertungLayout()` */
  static readonly UpdateMonatsauswertungLayoutPath = '/v1/monatsauswertungen/layouts/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateMonatsauswertungLayout$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateMonatsauswertungLayout$Plain$Response(params: UpdateMonatsauswertungLayout$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<MonatsauswertungLayoutDto>> {
    return updateMonatsauswertungLayout$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateMonatsauswertungLayout$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateMonatsauswertungLayout$Plain(params: UpdateMonatsauswertungLayout$Plain$Params, context?: HttpContext): Observable<MonatsauswertungLayoutDto> {
    return this.updateMonatsauswertungLayout$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<MonatsauswertungLayoutDto>): MonatsauswertungLayoutDto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateMonatsauswertungLayout$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateMonatsauswertungLayout$Json$Response(params: UpdateMonatsauswertungLayout$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<MonatsauswertungLayoutDto>> {
    return updateMonatsauswertungLayout$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateMonatsauswertungLayout$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateMonatsauswertungLayout$Json(params: UpdateMonatsauswertungLayout$Json$Params, context?: HttpContext): Observable<MonatsauswertungLayoutDto> {
    return this.updateMonatsauswertungLayout$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<MonatsauswertungLayoutDto>): MonatsauswertungLayoutDto => r.body)
    );
  }

  /** Path part for operation `deleteMonatsauswertungLayout()` */
  static readonly DeleteMonatsauswertungLayoutPath = '/v1/monatsauswertungen/layouts/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteMonatsauswertungLayout()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteMonatsauswertungLayout$Response(params: DeleteMonatsauswertungLayout$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteMonatsauswertungLayout(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteMonatsauswertungLayout$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteMonatsauswertungLayout(params: DeleteMonatsauswertungLayout$Params, context?: HttpContext): Observable<void> {
    return this.deleteMonatsauswertungLayout$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getAllMonatsauswertungSpalten()` */
  static readonly GetAllMonatsauswertungSpaltenPath = '/v1/monatsauswertungen/layouts/spalten';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllMonatsauswertungSpalten$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllMonatsauswertungSpalten$Plain$Response(params?: GetAllMonatsauswertungSpalten$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<MonatsauswertungSpalteDto>>> {
    return getAllMonatsauswertungSpalten$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllMonatsauswertungSpalten$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllMonatsauswertungSpalten$Plain(params?: GetAllMonatsauswertungSpalten$Plain$Params, context?: HttpContext): Observable<Array<MonatsauswertungSpalteDto>> {
    return this.getAllMonatsauswertungSpalten$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<MonatsauswertungSpalteDto>>): Array<MonatsauswertungSpalteDto> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllMonatsauswertungSpalten$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllMonatsauswertungSpalten$Json$Response(params?: GetAllMonatsauswertungSpalten$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<MonatsauswertungSpalteDto>>> {
    return getAllMonatsauswertungSpalten$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllMonatsauswertungSpalten$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllMonatsauswertungSpalten$Json(params?: GetAllMonatsauswertungSpalten$Json$Params, context?: HttpContext): Observable<Array<MonatsauswertungSpalteDto>> {
    return this.getAllMonatsauswertungSpalten$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<MonatsauswertungSpalteDto>>): Array<MonatsauswertungSpalteDto> => r.body)
    );
  }

}
