import { map, Observable } from 'rxjs';
import { sortByStringPropertyHelper } from '../../../src';

export function sortByStringProperty<T>(
  propertyName: keyof T,
  direction: 'asc' | 'desc' = 'asc'
) {
  return function (source: Observable<Array<T>>) {
    return source.pipe(
      map((x) => {
        const copy = [...x];
        return copy.sort(sortByStringPropertyHelper(propertyName, direction));
      })
    );
  };
}
