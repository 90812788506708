import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DlFormFieldComponent } from './form-field/form-field.component';
import { DlFormFieldLabelComponent } from './form-field-label/form-field-label.component';

@NgModule({
  declarations: [DlFormFieldComponent, DlFormFieldLabelComponent],
  imports: [CommonModule],
  exports: [DlFormFieldComponent, DlFormFieldLabelComponent],
})
export class DlFormFieldModule {}
