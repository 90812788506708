import * as fromRouter from '@ngrx/router-store';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { MitarbeiterDto, MitarbeiterInfosDto } from '../../api/models';
import * as fromMitarbeiter from './mitarbeiter.reducer';

export const selectMitarbeiterState =
  createFeatureSelector<fromMitarbeiter.State>(
    fromMitarbeiter.mitarbeiterFeatureKey,
  );

export const selectRouterState = fromRouter.getRouterSelectors(); // Use the provided selectors

export const selectMitarbeiterPageIndex = createSelector(
  selectMitarbeiterState,
  (state) => state.pageNumber,
);

export const selectMitarbeiterPageSize = createSelector(
  selectMitarbeiterState,
  (state) => state.pageSize,
);

export const selectMitarbeiterArray = createSelector(
  selectMitarbeiterState,
  (state) => {
    const mitarbeiterArray = Object.values(state.mitarbeiter);
    return mitarbeiterArray.filter(
      (mitarbeiter): mitarbeiter is MitarbeiterDto => !!mitarbeiter,
    );
  },
);

export const selectTotalCount = createSelector(
  selectMitarbeiterState,
  (state) => state.totalCount,
);

export const selectSort = createSelector(
  selectMitarbeiterState,
  (state) => state.sort,
);

export const selectFilter = createSelector(
  selectMitarbeiterState,
  (state) => state.filter,
);

export const selectIncludeInactive = createSelector(
  selectMitarbeiterState,
  (state) => state.includeInactive,
);

export const selectSelectedMitarbeiterId = createSelector(
  selectMitarbeiterState,
  (state) => state.selectedMitarbeiterId,
);

export const selectAllMitarbeiter = createSelector(
  selectMitarbeiterState,
  (state) => state.mitarbeiter,
);

export const selectMitarbeiterPageData = createSelector(
  selectMitarbeiterState,
  (state) => state.mitarbeiterPageData,
);

export const selectSelectedMitarbeiter = createSelector(
  selectSelectedMitarbeiterId,
  selectAllMitarbeiter,
  (id, mitarbeiter) => mitarbeiter[id],
);

export const selectSelectedMitarbeiterFullName = createSelector(
  selectSelectedMitarbeiterId,
  selectAllMitarbeiter,
  (id, mitarbeiter) => {
    const selectedMa = mitarbeiter[id];
    if (selectedMa === undefined) return "";
    return selectedMa.vorname + " " + selectedMa.nachname;
  },
);

export const selectMitarbeiterPaginationData = createSelector(
  selectMitarbeiterPageIndex,
  selectMitarbeiterPageSize,
  selectSort,
  selectFilter,
  (index, size, sort, filter) => {
    return {
      index,
      size,
      sort,
      filter,
    };
  },
);

export const selectMitarbeiterArbeitszeitkonto = createSelector(
  selectMitarbeiterState,
  (state) => state.arbeitszeitkonto,
);

export const selectPageSize = createSelector(
  selectMitarbeiterState,
  (state) => state.pageSize,
);

export const selectMitarbeiterUnitFilter = createSelector(
  selectMitarbeiterState,
  (state) => state.unitFilter,
);

export const selectMitarbeiterListData = createSelector(
  selectAllMitarbeiter,
  selectMitarbeiterPageData,
  (mitarbeiter, mitarbeiterPageData) =>
    mitarbeiterPageData
      ?.map((id) => mitarbeiter[id])
      .filter((ma): ma is MitarbeiterDto => !!ma) ?? [],
);

export const selectMitarbeiterInfos = createSelector(
  selectMitarbeiterState,
  (state) => state.mitarbeiterInfos,
);

export const selectMitarbeiterInfosToday = createSelector(
  selectMitarbeiterInfos,
  (state) => state['today'],
);

export const selectMitarbeiterInfosForMonth = (month: string) =>
  createSelector(selectMitarbeiterInfos, (state) => state[month]);

export const selectMitarbeiterInfosPageData = createSelector(
  selectMitarbeiterState,
  (state) => state.mitarbeiterInfosPageData,
);

export const selectMitarbeiter = createSelector(
  selectMitarbeiterState,
  (state) => state.mitarbeiter,
);

export const selectMitarbeiterInfosListData = (month: string) =>
  createSelector(
    selectMitarbeiterInfosForMonth(month),
    selectMitarbeiterInfosPageData,
    selectMitarbeiterState,
    (mitarbeiterInfos, mitarbeiterInfosPageData, mitarbeiter) => {
      if (!mitarbeiterInfosPageData) return null;
      const data: MitarbeiterInfosDto & { count: number } = {
        mitarbeiter: [],
        resturlaubstage: 0,
        saldoInSekunden: 0,
        genommeneUrlaubstage: 0,
        geplanteUrlaubstage: 0,
        gesamturlaub: 0,
        sonderurlaub: 0,
        abgelaufeneUrlaubstage: 0,
        gleittag: 0,
        kranktag: 0,
        elternzeit: 0,
        urlaubsansprueche: 0,
        urlaubsanspruecheAusLetztemJahr: 0,
        mehrarbeit: 0,
        sparzeit: 0,
        count: mitarbeiterInfosPageData.length,
      };
      if (!mitarbeiterInfos) return data;

      mitarbeiterInfosPageData.forEach((id) => {
        const mitarbeiterInfo = mitarbeiterInfos[id];
        const mitarbeiterData = mitarbeiter.mitarbeiter[id];
        if (mitarbeiterInfo && mitarbeiterData) {
          data.mitarbeiter.push({
            ...mitarbeiterInfo,
            ...mitarbeiterData,
          });
        }
      });
      data.resturlaubstage = data.mitarbeiter.reduce(
        (acc, curr) => acc + curr.resturlaubstage,
        0,
      );
      data.saldoInSekunden = data.mitarbeiter.reduce(
        (acc, curr) => acc + curr.saldoInSekunden,
        0,
      );
      return data;
    },
  );

export const selectSelectedMitarbeiterInfos = createSelector(
  selectSelectedMitarbeiterId,
  selectMitarbeiterInfos,
  (id, mitarbeiterInfos) => {
    if (!mitarbeiterInfos) return null;

    const currentInfos = mitarbeiterInfos['today'];
    if (!currentInfos) return null;

    const mitarbeiterData = currentInfos[id];
    return mitarbeiterData ?? null;
  },
);

export const selectUnifiedLoginMitarbeiter = createSelector(
  selectMitarbeiterState,
  (state) => state.unifiedLoginMitarbeiter,
);

export const selectUnifiedLoginMitarbeitersLoading = createSelector(
  selectMitarbeiterState,
  (state) => state.unifiedLoginMitarbeiter === null,
);
