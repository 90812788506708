import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DlSidebarComponent } from './sidebar/sidebar.component';
import { DlSidebarButtonComponent } from './sidebar-button/sidebar-button.component';
import { DlSidebarButtonImageComponent } from './sidebar-button-image/sidebar-button-image.component';
import { DlSidebarButtonTextComponent } from './sidebar-button-text/sidebar-button-text.component';
import { DlSidebarOpenButtonComponent } from './sidebar-open-button/sidebar-open-button.component';
import { DlBrightDirective } from './sidebar-button/bright.directive';
import { DlSidebarContentComponent } from './sidebar-content/sidebar-content.component';
import { DlSidebarContainerComponent } from './sidebar-container/sidebar-container.component';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [
    DlSidebarComponent,
    DlSidebarButtonComponent,
    DlSidebarButtonImageComponent,
    DlSidebarButtonTextComponent,
    DlSidebarOpenButtonComponent,
    DlBrightDirective,
    DlSidebarContentComponent,
    DlSidebarContainerComponent,
  ],
  imports: [CommonModule, RouterModule],
  exports: [
    DlSidebarComponent,
    DlSidebarButtonComponent,
    DlSidebarButtonImageComponent,
    DlSidebarButtonTextComponent,
    DlSidebarContentComponent,
    DlSidebarContainerComponent,
    DlBrightDirective,
  ],
})
export class SidebarModule {}
