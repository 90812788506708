import { Sort } from '@datenlotse/components/sort';
import { createFeature, createReducer, on } from '@ngrx/store';
import { produce } from 'immer';
import {
  ArbeitszeitkontoDto,
  MitarbeiterDto,
  MitarbeiterMitInfosDto,
  UserDto,
} from '../../api/models';
import { MitarbeiterActions } from './mitarbeiter.actions';

export const mitarbeiterFeatureKey = 'mitarbeiter';

export type MitarbeiterInfosOnly = Pick<
  MitarbeiterMitInfosDto,
  | 'saldoInSekunden'
  | 'resturlaubstage'
  | 'genommeneUrlaubstage'
  | 'geplanteUrlaubstage'
  | 'gesamturlaub'
  | 'sonderurlaub'
  | 'abgelaufeneUrlaubstage'
  | 'gleittag'
  | 'kranktag'
  | 'elternzeit'
  | 'urlaubsansprueche'
  | 'urlaubsanspruecheAusLetztemJahr'
  | 'mehrarbeit'
  | 'sparzeit'
>;

type MitarbeiterInfoMap = { [id: string]: MitarbeiterInfosOnly | undefined };

export type State = {
  mitarbeiter: { [id: string]: MitarbeiterDto | undefined };
  mitarbeiterInfos: {
    [month: string | 'today']: MitarbeiterInfoMap | undefined;
  };
  mitarbeiterInfosPageData: string[] | null;
  mitarbeiterPageData: string[] | null;
  includeInactive: boolean;
  selectedMitarbeiterId: string;
  pageNumber: number;
  pageSize: number;
  totalCount: number;
  sort: Sort;
  filter: string;
  arbeitszeitkonto: ArbeitszeitkontoDto | undefined;
  unitFilter: string | null;
  unifiedLoginMitarbeiter: UserDto[] | null;
};

export const initialState: State = {
  selectedMitarbeiterId: '',
  mitarbeiterInfos: {},
  mitarbeiterInfosPageData: null,
  mitarbeiterPageData: null,
  includeInactive: false,
  mitarbeiter: {},
  pageNumber: 0,
  pageSize: 12,
  totalCount: 0,
  sort: {
    active: 'PersonalNr',
    direction: 'asc',
  },
  filter: '',
  arbeitszeitkonto: undefined,
  unitFilter: null,
  unifiedLoginMitarbeiter: null,
};

export const reducer = createReducer(
  initialState,

  on(
    MitarbeiterActions.loadMitarbeiterSuccess,
    (state, { mitarbeiter: pageData, totalCount }) =>
      produce(state, (draft) => {
        pageData.forEach((mitarbeiter) => {
          draft.mitarbeiter[mitarbeiter.id] = mitarbeiter;
        });
        draft.mitarbeiterPageData = pageData.map(
          (mitarbeiter) => mitarbeiter.id,
        );
        draft.totalCount = totalCount;
      }),
  ),
  on(
    MitarbeiterActions.loadNextPage,
    (state): State => ({
      ...state,
      pageNumber: state.pageNumber + 1,
    }),
  ),
  on(
    MitarbeiterActions.loadPreviousPage,
    (state): State => ({
      ...state,
      pageNumber: state.pageNumber === 0 ? 0 : state.pageNumber - 1,
    }),
  ),
  on(
    MitarbeiterActions.setMitarbeiterFilter,
    (state, { filter }): State => ({
      ...state,
      filter,
    }),
  ),
  on(
    MitarbeiterActions.setMitarbeiterSort,
    (state, { sort }): State => ({
      ...state,
      sort,
    }),
  ),
  on(
    MitarbeiterActions.includeInactiveMitarbeiter,
    (state, { include }): State => ({
      ...state,
      includeInactive: include,
    }),
  ),
  on(
    MitarbeiterActions.setMitarbeiterSortFilterPageNumberPageSize,
    (state, { sort, filter, pageNumber, pageSize }) =>
      produce(state, (draft) => {
        draft.sort = sort ?? draft.sort;
        draft.filter = filter ?? draft.filter;
        draft.pageNumber = pageNumber ?? draft.pageNumber;
        draft.pageSize = pageSize ?? draft.pageSize;
      }),
  ),
  on(
    MitarbeiterActions.setSelectedMitarbeiterId,
    (state, { id }): State => ({
      ...state,
      selectedMitarbeiterId: id,
      arbeitszeitkonto: undefined,
    }),
  ),
  on(
    MitarbeiterActions.createMitarbeiterSuccess,
    (state, { createdMitarbeiter }) =>
      produce(state, (draft) => {
        draft.mitarbeiter[createdMitarbeiter.id] = createdMitarbeiter;
      }),
  ),
  on(
    MitarbeiterActions.updateMitarbeiterSuccess,
    (state, { updatedMitarbeiter }) =>
      produce(state, (draft) => {
        draft.mitarbeiter[updatedMitarbeiter.id] = updatedMitarbeiter;
      }),
  ),
  on(
    MitarbeiterActions.loadArbeitszeitkontoSuccess,
    (state, { arbeitszeitkonto }): State => ({
      ...state,
      arbeitszeitkonto,
    }),
  ),

  on(
    MitarbeiterActions.loadArbeitszeitkontoFailure,
    (state): State => ({
      ...state,
      arbeitszeitkonto: undefined,
    }),
  ),

  on(
    MitarbeiterActions.loadMitarbeiterByUserIdSuccess,
    MitarbeiterActions.loadMitarbeiterByIdSuccess,
    (state, { mitarbeiter }) =>
      produce(state, (draft) => {
        draft.mitarbeiter[mitarbeiter.id] = mitarbeiter;
      }),
  ),

  on(
    MitarbeiterActions.setUnitFilter,
    (state, { unit }): State => ({
      ...state,
      unitFilter: unit,
    }),
  ),

  on(
    MitarbeiterActions.setPageSize,
    (state, { pageSize }): State => ({
      ...state,
      pageSize,
    }),
  ),

  on(
    MitarbeiterActions.deselectMitarbeiter,
    (state): State => ({
      ...state,
      selectedMitarbeiterId: '',
    }),
  ),

  on(
    MitarbeiterActions.loadMitarbeiterInfosSuccess,
    (state, { mitarbeiterInfos, monat }) =>
      produce(state, (draft) => {
        const month = monat ?? 'today';
        const existingInfos = draft.mitarbeiterInfos[month] ?? {};
        const existingMitarbeiter = draft.mitarbeiter ?? {};

        mitarbeiterInfos.mitarbeiter.forEach((mitarbeiter) => {
          existingInfos[mitarbeiter.id] = mitarbeiter;
          existingMitarbeiter[mitarbeiter.id] = {
            abteilung: mitarbeiter.abteilung,
            attribut: mitarbeiter.attribut,
            alarmanlagenberechtigt: mitarbeiter.alarmanlagenberechtigt,
            geburtsDatum: mitarbeiter.geburtsDatum,
            geschlecht: mitarbeiter.geschlecht,
            id: mitarbeiter.id,
            isDeleted: mitarbeiter.isDeleted,
            isUnitleiter: mitarbeiter.isUnitleiter,
            kostenstelle: mitarbeiter.kostenstelle,
            nachname: mitarbeiter.nachname,
            personalNr: mitarbeiter.personalNr,
            standardwochenplan: mitarbeiter.standardwochenplan,
            standardwochenplanId: mitarbeiter.standardwochenplanId,
            tarifAttribut: mitarbeiter.tarifAttribut,
            userId: mitarbeiter.userId,
            vorname: mitarbeiter.vorname,
            austrittsDatum: mitarbeiter.austrittsDatum,
            chipNr: mitarbeiter.chipNr,
            eintrittsDatum: mitarbeiter.eintrittsDatum,
            notiz: mitarbeiter.notiz,
            privateEmail: mitarbeiter.privateEmail,
            titel: mitarbeiter.titel,
            zugangKommentar: mitarbeiter.zugangKommentar,
          } as MitarbeiterDto;
        });

        draft.mitarbeiterInfos[month] = existingInfos;

        draft.mitarbeiterInfosPageData = mitarbeiterInfos.mitarbeiter.map(
          (m) => m.id,
        );
      }),
  ),

  on(
    MitarbeiterActions.loadSingleMitarbeiterInfosSuccess,
    (state, { mitarbeiterInfos }) =>
      produce(state, (draft) => {
        const existing = draft.mitarbeiterInfos['today'] ?? {};
        existing[mitarbeiterInfos.mitarbeiterId] = {
          mehrarbeit: mitarbeiterInfos.mehrarbeit,
          sparzeit: mitarbeiterInfos.sparzeit,
          resturlaubstage: mitarbeiterInfos.resturlaubstage,
          saldoInSekunden: mitarbeiterInfos.saldoInSekunden,
          genommeneUrlaubstage: mitarbeiterInfos.genommeneUrlaubstage,
          geplanteUrlaubstage: mitarbeiterInfos.geplanteUrlaubstage,
          gesamturlaub: mitarbeiterInfos.gesamturlaub,
          sonderurlaub: mitarbeiterInfos.sonderurlaub,
          abgelaufeneUrlaubstage: mitarbeiterInfos.abgelaufeneUrlaubstage,
          gleittag: mitarbeiterInfos.gleittag,
          kranktag: mitarbeiterInfos.kranktag,
          elternzeit: mitarbeiterInfos.elternzeit,
          urlaubsansprueche: mitarbeiterInfos.urlaubsansprueche,
          urlaubsanspruecheAusLetztemJahr:
            mitarbeiterInfos.urlaubsanspruecheAusLetztemJahr,
        };

        draft.mitarbeiterInfos['today'] = existing;
      }),
  ),
  on(
    MitarbeiterActions.loadUnifiedLoginMitarbeiterSuccess,
    (state, { unifiedLoginMitarbeiter }): State => ({
      ...state,
      unifiedLoginMitarbeiter,
    }),
  ),
  on(
    MitarbeiterActions.loadUnifiedLoginMitarbeiterFailure,
    (state): State => ({
      ...state,
      unifiedLoginMitarbeiter: null,
    }),
  ),
);

export const mitarbeiterFeature = createFeature({
  name: mitarbeiterFeatureKey,
  reducer,
});
