import { createFeatureSelector, createSelector } from '@ngrx/store';
import { format } from 'date-fns';
import * as fromZuordnungen from './zuordnungen.reducer';

export const selectZuordnungenState =
  createFeatureSelector<fromZuordnungen.State>(
    fromZuordnungen.zuordnungenFeatureKey
  );

export const selectSelectedYear = createSelector(
  selectZuordnungenState,
  (state) => state.selectedYear
);

export const selectZuordnungen = createSelector(
  selectZuordnungenState,
  (state) => state.zuordnungen
);

export const selectIsLoading = createSelector(
  selectZuordnungenState,
  (state) => state.loading
);

export const selectZuordnungFuerTag = (tag: Date) =>
  createSelector(selectZuordnungen, (zuordnungen) => {
    const formattedDate = format(tag, 'yyyy-MM-dd');
    return zuordnungen.find((zu) => zu.datum === formattedDate);
  });
