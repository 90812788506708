/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { MitarbeiterDto } from '../../models/mitarbeiter-dto';

export interface GetAllMitarbeiter$Plain$Params {
  Vorname?: string;
  Nachname?: string;
  Titel?: string;
  PersonalNr?: string;
  Attribut?: string;
  Kostenstelle?: string;
  TarifAttribut?: string;
  ChipNr?: string;
  AbteilungSearchFilter?: string;
  AbteilungUnitFilter?: string;
  IncludeInactive?: boolean;

/**
 * Nummer der Seite die angezeigt werden soll.
 */
  PageNumber?: number;

/**
 * Anzahl der Einträge pro Seite. Maximal 100
 */
  PageSize?: number;

/**
 * Sortierung der Ergebnisse vor dem Filtern im Format: <br></br>(attribut) [(asc | desc)], ...(attribut) [(asc |
 * desc)],
 */
  OrderBy?: string;
}

export function getAllMitarbeiter$Plain(http: HttpClient, rootUrl: string, params?: GetAllMitarbeiter$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<MitarbeiterDto>>> {
  const rb = new RequestBuilder(rootUrl, getAllMitarbeiter$Plain.PATH, 'get');
  if (params) {
    rb.query('Vorname', params.Vorname, {});
    rb.query('Nachname', params.Nachname, {});
    rb.query('Titel', params.Titel, {});
    rb.query('PersonalNr', params.PersonalNr, {});
    rb.query('Attribut', params.Attribut, {});
    rb.query('Kostenstelle', params.Kostenstelle, {});
    rb.query('TarifAttribut', params.TarifAttribut, {});
    rb.query('ChipNr', params.ChipNr, {});
    rb.query('AbteilungSearchFilter', params.AbteilungSearchFilter, {});
    rb.query('AbteilungUnitFilter', params.AbteilungUnitFilter, {});
    rb.query('IncludeInactive', params.IncludeInactive, {});
    rb.query('PageNumber', params.PageNumber, {});
    rb.query('PageSize', params.PageSize, {});
    rb.query('OrderBy', params.OrderBy, {});
  }

  return http.request(
    rb.build({ responseType: 'text', accept: 'text/plain', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<Array<MitarbeiterDto>>;
    })
  );
}

getAllMitarbeiter$Plain.PATH = '/v1/mitarbeiter';
