import { createFeature, createReducer, on } from '@ngrx/store';
import { produce } from 'immer';
import { SparzeitUmbuchungDto } from '../../api/models';
import { MitarbeiterActions } from '../mitarbeiter/mitarbeiter.actions';
import { SparzeitUmbuchungenActions } from './sparzeitumbuchungen.actions';

export const sparzeitUmbuchungFeatureKey = 'sparzeitUmbuchung';

export interface State {
  sparzeitUmbuchungen: { [id: string]: SparzeitUmbuchungDto | undefined };
  sparzeitUmbuchungPageData: string[] | null;
  page: number;
  pageSize: number;
  totalCount: number | undefined;
  selectedMonth: number | undefined;
  selectedYear: number | undefined;
  loading: boolean;
}

export const initialState: State = {
  sparzeitUmbuchungen: {},
  sparzeitUmbuchungPageData: null,
  page: 0,
  pageSize: 15,
  totalCount: undefined,
  selectedMonth: new Date().getMonth() + 1,
  selectedYear: new Date().getFullYear(),
  loading: false,
};

export const reducer = createReducer(
  initialState,
  on(
    SparzeitUmbuchungenActions.loadSparzeitumbuchungen,
    (state): State => ({
      ...state,
      loading: true,
    }),
  ),
  on(
    SparzeitUmbuchungenActions.loadSparzeitumbuchungenSuccess,
    (state, { data, totalCount }): State =>
      produce(state, (draft) => {
        data.forEach((mau) => {
          draft.sparzeitUmbuchungen[mau.id] = mau;
        });
        draft.sparzeitUmbuchungPageData = data.map((mau) => mau.id);
        draft.totalCount = totalCount;
        draft.loading = false;
      }),
  ),
  on(
    SparzeitUmbuchungenActions.loadSparzeitumbuchungenFailure,
    (state): State => ({
      ...state,
      loading: false,
    }),
  ),
  on(
    SparzeitUmbuchungenActions.createSparzeitumbuchung,
    (state): State => ({
      ...state,
      loading: true,
    }),
  ),
  on(
    SparzeitUmbuchungenActions.createSparzeitumbuchungFailure,
    (state): State => ({
      ...state,
      loading: false,
    }),
  ),
  on(
    SparzeitUmbuchungenActions.createSparzeitumbuchungSuccess,
    (state, { data }): State =>
      produce(state, (draft) => {
        draft.sparzeitUmbuchungen[data.id] = data;
        draft.sparzeitUmbuchungPageData?.push(data.id)
        draft.loading = false;
      }),
  ),
  on(
    MitarbeiterActions.setSelectedMitarbeiterId,
    (state): State => ({
      ...state,
      sparzeitUmbuchungPageData: null,
      sparzeitUmbuchungen: {},
      totalCount: undefined,
    }),
  ),
  on(
    SparzeitUmbuchungenActions.setPageIndex,
    (state, { pageIndex }): State => ({
      ...state,
      page: pageIndex,
      sparzeitUmbuchungPageData: null,
    }),
  ),
  on(
    SparzeitUmbuchungenActions.setPageSize,
    (state, { pageSize }): State => ({
      ...state,
      pageSize,
    }),
  ),
  on(
    SparzeitUmbuchungenActions.setSelectedMonthAndYear,
    (state, { selectedMonth, selectedYear }): State => ({
      ...state,
      selectedMonth,
      selectedYear,
    }),
  ),
);

export const sparzeitUmbuchungFeature = createFeature({
  name: sparzeitUmbuchungFeatureKey,
  reducer: reducer,
});
