/* tslint:disable */
/* eslint-disable */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfiguration, ApiConfigurationParams } from './api-configuration';

import { AbteilungApiService } from './services/abteilung-api.service';
import { ArbeitszeitkontoApiService } from './services/arbeitszeitkonto-api.service';
import { AuswertungApiService } from './services/auswertung-api.service';
import { BeanspruchteUrlaubeApiService } from './services/beanspruchte-urlaube-api.service';
import { BuchungApiService } from './services/buchung-api.service';
import { BuchungsaenderungsAntragsApiService } from './services/buchungsaenderungs-antrags-api.service';
import { BuchungscodeApiService } from './services/buchungscode-api.service';
import { BuchungserstellAntragsApiService } from './services/buchungserstell-antrags-api.service';
import { BuchungsschluesselApiService } from './services/buchungsschluessel-api.service';
import { ChipApiService } from './services/chip-api.service';
import { DatafoxApiService } from './services/datafox-api.service';
import { FeiertagApiService } from './services/feiertag-api.service';
import { FeiertagOverrideApiService } from './services/feiertag-override-api.service';
import { KalendereintragApiService } from './services/kalendereintrag-api.service';
import { KostenstelleApiService } from './services/kostenstelle-api.service';
import { MehrarbeitAuszahlungApiService } from './services/mehrarbeit-auszahlung-api.service';
import { MehrarbeitUmbuchungApiService } from './services/mehrarbeit-umbuchung-api.service';
import { MitarbeiterApiService } from './services/mitarbeiter-api.service';
import { MitarbeiterAttributApiService } from './services/mitarbeiter-attribut-api.service';
import { MonatsauswertungLayoutApiService } from './services/monatsauswertung-layout-api.service';
import { PausenzeitenApiService } from './services/pausenzeiten-api.service';
import { PersonZugangApiService } from './services/person-zugang-api.service';
import { SchnellauswahlApiService } from './services/schnellauswahl-api.service';
import { SparzeitUmbuchungApiService } from './services/sparzeit-umbuchung-api.service';
import { TagesplanApiService } from './services/tagesplan-api.service';
import { UnifiedLoginApiService } from './services/unified-login-api.service';
import { UrlaubsanspruchApiService } from './services/urlaubsanspruch-api.service';
import { UrlaubsantragApiService } from './services/urlaubsantrag-api.service';
import { WochenplanApiService } from './services/wochenplan-api.service';
import { ZugangApiService } from './services/zugang-api.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    AbteilungApiService,
    ArbeitszeitkontoApiService,
    AuswertungApiService,
    BeanspruchteUrlaubeApiService,
    BuchungApiService,
    BuchungsaenderungsAntragsApiService,
    BuchungscodeApiService,
    BuchungserstellAntragsApiService,
    BuchungsschluesselApiService,
    ChipApiService,
    DatafoxApiService,
    FeiertagApiService,
    FeiertagOverrideApiService,
    KalendereintragApiService,
    KostenstelleApiService,
    MehrarbeitAuszahlungApiService,
    MehrarbeitUmbuchungApiService,
    MitarbeiterApiService,
    MitarbeiterAttributApiService,
    MonatsauswertungLayoutApiService,
    PausenzeitenApiService,
    PersonZugangApiService,
    SchnellauswahlApiService,
    SparzeitUmbuchungApiService,
    TagesplanApiService,
    UnifiedLoginApiService,
    UrlaubsanspruchApiService,
    UrlaubsantragApiService,
    WochenplanApiService,
    ZugangApiService,
    ApiConfiguration
  ],
})
export class ApiModule {
  static forRoot(params: ApiConfigurationParams): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [
        {
          provide: ApiConfiguration,
          useValue: params
        }
      ]
    }
  }

  constructor( 
    @Optional() @SkipSelf() parentModule: ApiModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
      'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
