import { Component, Input } from '@angular/core';

@Component({
  selector: 'dl-table-cell',
  templateUrl: './table-cell.component.html',
  styleUrls: ['./table-cell.component.css'],
})
export class DlTableCellComponent {
  @Input() hierarchie: string[] = [];
  @Input() priority = false;
}
