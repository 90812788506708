import { inject, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ComponentStore } from '@ngrx/component-store';
import { Store } from '@ngrx/store';
import { tap } from 'rxjs';
import { MonatsauswertungLayoutDto } from '../api/models/monatsauswertung-layout-dto';
import * as fromMonatsauswertungenLayouts from '../store/auswertung-layouts/auswertung-layouts.selectors';
import * as fromRouter from '../store/router/router.selectors';

export type Mode = 'list' | 'create' | 'edit';

interface MonatsauswertungLayoutsPageComponentState {
  readonly monatsauswertungenLayouts: MonatsauswertungLayoutDto[] | undefined;
  readonly loading: boolean;
  readonly selectedMonatsauswertungLayoutId: string | undefined;
  readonly mode: Mode;
}

@Injectable()
export class MonatsauswertungLayoutsPageComponentStore extends ComponentStore<MonatsauswertungLayoutsPageComponentState> {
  private readonly store = inject(Store);

  readonly setSelectMonatsauswertungLayoutId = this.updater(
    (
      state,
      selectedMonatsauswertungLayoutId: string | undefined,
    ): MonatsauswertungLayoutsPageComponentState => ({
      ...state,
      selectedMonatsauswertungLayoutId,
    }),
  );

  readonly loading$ = this.select((state) => state.loading);
  readonly mode$ = this.select((state) => state.mode);
  readonly monatsauswertungenLayouts = this.select(
    (state) => state.monatsauswertungenLayouts,
  );
  readonly selectedMonatsauswertungLayoutId$ = this.select(
    (state) => state.selectedMonatsauswertungLayoutId,
  );
  readonly selectSelectedMonatsauswertungLayout$ = this.select((state) =>
    state.monatsauswertungenLayouts?.find(
      (layout) => layout.id === state.selectedMonatsauswertungLayoutId,
    ),
  );
  readonly selectMode$ = this.select((state) => state.mode);

  constructor() {
    super({
      loading: false,
      mode: 'list',
      monatsauswertungenLayouts: undefined,
      selectedMonatsauswertungLayoutId: undefined,
    });
    this.subscribeToLoading();
    this.subscribeToLayouts();
    this.subscribeToRouteData();
  }

  private subscribeToRouteData() {
    this.store
      .select(fromRouter.selectRouteData)
      .pipe(
        takeUntilDestroyed(),
        tap((data) => {
          const mode = data['mode'] as Mode | undefined;
          if (mode) {
            this.patchState({ mode });
          }
        }),
      )
      .subscribe();

    this.store
      .select(fromRouter.selectRouteParam('id'))
      .pipe(
        takeUntilDestroyed(),
        tap((id) => {
          this.setSelectMonatsauswertungLayoutId(id);
        }),
      )
      .subscribe();
  }

  private subscribeToLayouts() {
    this.store
      .select(fromMonatsauswertungenLayouts.selectMonatsauswertungLayouts)
      .pipe(
        takeUntilDestroyed(),
        tap((monatsauswertungenLayouts) =>
          this.patchState({ monatsauswertungenLayouts }),
        ),
      )
      .subscribe();
  }

  private subscribeToLoading() {
    this.store
      .select(
        fromMonatsauswertungenLayouts.selectMonatsauswertungLayoutsLoading,
      )
      .pipe(
        takeUntilDestroyed(),
        tap((loading) => this.patchState({ loading })),
      )
      .subscribe();
  }
}
