import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { format } from 'date-fns';
import { catchError, map, of, switchMap, tap } from 'rxjs';
import { handleHttpError } from '../../../common/httpErrorHandler';
import { AuswertungApiService } from '../../api/services';
import { MonatsauswertungenActions } from './monatsauswertungen.actions';

@Injectable()
export class MonatsauswertungenEffects {
  sendToAllMitarbeiter$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MonatsauswertungenActions.sendToAllMitarbeiter),
      switchMap(({ monat, layoutId }) =>
        this.api
          .sendMonatsauswertungForAllMitarbeiter({
            body: {
              monat: format(monat, 'yyyy-MM-dd'),
              layoutId,
            },
          })
          .pipe(
            map(() => MonatsauswertungenActions.sendToAllMitarbeiterSuccess()),
            catchError((err) =>
              of(
                MonatsauswertungenActions.sendToAllMitarbeiterFailure({
                  error: err,
                })
              )
            )
          )
      )
    );
  });

  sendToAllMitarbeiterSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(MonatsauswertungenActions.sendToAllMitarbeiterSuccess),
        tap(() =>
          this.toastCtrl
            .create({
              message:
                'Monatsauswertung wurde erfolgreich für den Versand vorbereitet.',
              duration: 3000,
              color: 'success',
            })
            .then((toast) => toast.present())
        )
      );
    },
    { dispatch: false }
  );

  sendToAllMitarbeiterFailure$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(MonatsauswertungenActions.sendToAllMitarbeiterFailure),
        tap((err) => console.error(err)),
        tap(({ error }) =>
          handleHttpError(
            error,
            this.toastCtrl,
            'Beim Versenden der Monatsauswertungen ist ein Fehler aufgetreten.'
          )
        )
      );
    },
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private api: AuswertungApiService,
    private toastCtrl: ToastController
  ) {}
}
