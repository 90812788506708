/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { createExtern$Json } from '../fn/person-zugang/create-extern-json';
import { CreateExtern$Json$Params } from '../fn/person-zugang/create-extern-json';
import { createExtern$Plain } from '../fn/person-zugang/create-extern-plain';
import { CreateExtern$Plain$Params } from '../fn/person-zugang/create-extern-plain';
import { deleteExterne$Json } from '../fn/person-zugang/delete-externe-json';
import { DeleteExterne$Json$Params } from '../fn/person-zugang/delete-externe-json';
import { deleteExterne$Plain } from '../fn/person-zugang/delete-externe-plain';
import { DeleteExterne$Plain$Params } from '../fn/person-zugang/delete-externe-plain';
import { getAllPersonenWithZugaenge$Json } from '../fn/person-zugang/get-all-personen-with-zugaenge-json';
import { GetAllPersonenWithZugaenge$Json$Params } from '../fn/person-zugang/get-all-personen-with-zugaenge-json';
import { getAllPersonenWithZugaenge$Plain } from '../fn/person-zugang/get-all-personen-with-zugaenge-plain';
import { GetAllPersonenWithZugaenge$Plain$Params } from '../fn/person-zugang/get-all-personen-with-zugaenge-plain';
import { PersonWithZugaengeDto } from '../models/person-with-zugaenge-dto';
import { updatePersonenWithZugaenge$Json } from '../fn/person-zugang/update-personen-with-zugaenge-json';
import { UpdatePersonenWithZugaenge$Json$Params } from '../fn/person-zugang/update-personen-with-zugaenge-json';
import { updatePersonenWithZugaenge$Plain } from '../fn/person-zugang/update-personen-with-zugaenge-plain';
import { UpdatePersonenWithZugaenge$Plain$Params } from '../fn/person-zugang/update-personen-with-zugaenge-plain';

@Injectable({ providedIn: 'root' })
export class PersonZugangApiService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getAllPersonenWithZugaenge()` */
  static readonly GetAllPersonenWithZugaengePath = '/v1/personzugaenge';

  /**
   * Holt alle Personen mit ihren Zugaengen, Chipnummern alleine falls keine zugewiesene Person.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllPersonenWithZugaenge$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllPersonenWithZugaenge$Plain$Response(params?: GetAllPersonenWithZugaenge$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<PersonWithZugaengeDto>>> {
    return getAllPersonenWithZugaenge$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Holt alle Personen mit ihren Zugaengen, Chipnummern alleine falls keine zugewiesene Person.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllPersonenWithZugaenge$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllPersonenWithZugaenge$Plain(params?: GetAllPersonenWithZugaenge$Plain$Params, context?: HttpContext): Observable<Array<PersonWithZugaengeDto>> {
    return this.getAllPersonenWithZugaenge$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<PersonWithZugaengeDto>>): Array<PersonWithZugaengeDto> => r.body)
    );
  }

  /**
   * Holt alle Personen mit ihren Zugaengen, Chipnummern alleine falls keine zugewiesene Person.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllPersonenWithZugaenge$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllPersonenWithZugaenge$Json$Response(params?: GetAllPersonenWithZugaenge$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<PersonWithZugaengeDto>>> {
    return getAllPersonenWithZugaenge$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Holt alle Personen mit ihren Zugaengen, Chipnummern alleine falls keine zugewiesene Person.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllPersonenWithZugaenge$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllPersonenWithZugaenge$Json(params?: GetAllPersonenWithZugaenge$Json$Params, context?: HttpContext): Observable<Array<PersonWithZugaengeDto>> {
    return this.getAllPersonenWithZugaenge$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<PersonWithZugaengeDto>>): Array<PersonWithZugaengeDto> => r.body)
    );
  }

  /** Path part for operation `createExtern()` */
  static readonly CreateExternPath = '/v1/personzugaenge';

  /**
   * Erstellt eine externe Entität.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createExtern$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createExtern$Plain$Response(params?: CreateExtern$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<PersonWithZugaengeDto>>> {
    return createExtern$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Erstellt eine externe Entität.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createExtern$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createExtern$Plain(params?: CreateExtern$Plain$Params, context?: HttpContext): Observable<Array<PersonWithZugaengeDto>> {
    return this.createExtern$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<PersonWithZugaengeDto>>): Array<PersonWithZugaengeDto> => r.body)
    );
  }

  /**
   * Erstellt eine externe Entität.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createExtern$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createExtern$Json$Response(params?: CreateExtern$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<PersonWithZugaengeDto>>> {
    return createExtern$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Erstellt eine externe Entität.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createExtern$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createExtern$Json(params?: CreateExtern$Json$Params, context?: HttpContext): Observable<Array<PersonWithZugaengeDto>> {
    return this.createExtern$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<PersonWithZugaengeDto>>): Array<PersonWithZugaengeDto> => r.body)
    );
  }

  /** Path part for operation `updatePersonenWithZugaenge()` */
  static readonly UpdatePersonenWithZugaengePath = '/v1/personzugaenge/updatepersonenwithzugaenge';

  /**
   * Aktualisiert Personenzugaenge.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updatePersonenWithZugaenge$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updatePersonenWithZugaenge$Plain$Response(params?: UpdatePersonenWithZugaenge$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<PersonWithZugaengeDto>>> {
    return updatePersonenWithZugaenge$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Aktualisiert Personenzugaenge.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updatePersonenWithZugaenge$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updatePersonenWithZugaenge$Plain(params?: UpdatePersonenWithZugaenge$Plain$Params, context?: HttpContext): Observable<Array<PersonWithZugaengeDto>> {
    return this.updatePersonenWithZugaenge$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<PersonWithZugaengeDto>>): Array<PersonWithZugaengeDto> => r.body)
    );
  }

  /**
   * Aktualisiert Personenzugaenge.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updatePersonenWithZugaenge$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updatePersonenWithZugaenge$Json$Response(params?: UpdatePersonenWithZugaenge$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<PersonWithZugaengeDto>>> {
    return updatePersonenWithZugaenge$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Aktualisiert Personenzugaenge.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updatePersonenWithZugaenge$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updatePersonenWithZugaenge$Json(params?: UpdatePersonenWithZugaenge$Json$Params, context?: HttpContext): Observable<Array<PersonWithZugaengeDto>> {
    return this.updatePersonenWithZugaenge$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<PersonWithZugaengeDto>>): Array<PersonWithZugaengeDto> => r.body)
    );
  }

  /** Path part for operation `deleteExterne()` */
  static readonly DeleteExternePath = '/v1/personzugaenge/{chipNr}';

  /**
   * Löscht eine externe Entität ung gibt die leere ChipNr wieder.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteExterne$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteExterne$Plain$Response(params: DeleteExterne$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<PersonWithZugaengeDto>> {
    return deleteExterne$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Löscht eine externe Entität ung gibt die leere ChipNr wieder.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteExterne$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteExterne$Plain(params: DeleteExterne$Plain$Params, context?: HttpContext): Observable<PersonWithZugaengeDto> {
    return this.deleteExterne$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<PersonWithZugaengeDto>): PersonWithZugaengeDto => r.body)
    );
  }

  /**
   * Löscht eine externe Entität ung gibt die leere ChipNr wieder.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteExterne$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteExterne$Json$Response(params: DeleteExterne$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<PersonWithZugaengeDto>> {
    return deleteExterne$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Löscht eine externe Entität ung gibt die leere ChipNr wieder.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteExterne$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteExterne$Json(params: DeleteExterne$Json$Params, context?: HttpContext): Observable<PersonWithZugaengeDto> {
    return this.deleteExterne$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<PersonWithZugaengeDto>): PersonWithZugaengeDto => r.body)
    );
  }

}
