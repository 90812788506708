import { Directive, ElementRef } from '@angular/core';
import { DlButtonStylingDirective } from './button-styling.class';

@Directive({
  selector: '[dlPrimaryButton]',
})
export class DlPrimaryButtonDirective extends DlButtonStylingDirective {
  override primaryColor = '#34B2B5';
  override fontColor = '#FFFFFF';
  override hoverColor = '#298b8d';

  constructor(elr: ElementRef) {
    super(elr);
  }
}
