/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { getKostenstellen$Json } from '../fn/kostenstelle/get-kostenstellen-json';
import { GetKostenstellen$Json$Params } from '../fn/kostenstelle/get-kostenstellen-json';
import { getKostenstellen$Plain } from '../fn/kostenstelle/get-kostenstellen-plain';
import { GetKostenstellen$Plain$Params } from '../fn/kostenstelle/get-kostenstellen-plain';
import { KostenstelleDto } from '../models/kostenstelle-dto';

@Injectable({ providedIn: 'root' })
export class KostenstelleApiService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getKostenstellen()` */
  static readonly GetKostenstellenPath = '/v1/kostenstellen';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getKostenstellen$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getKostenstellen$Plain$Response(params?: GetKostenstellen$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<KostenstelleDto>>> {
    return getKostenstellen$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getKostenstellen$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getKostenstellen$Plain(params?: GetKostenstellen$Plain$Params, context?: HttpContext): Observable<Array<KostenstelleDto>> {
    return this.getKostenstellen$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<KostenstelleDto>>): Array<KostenstelleDto> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getKostenstellen$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getKostenstellen$Json$Response(params?: GetKostenstellen$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<KostenstelleDto>>> {
    return getKostenstellen$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getKostenstellen$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getKostenstellen$Json(params?: GetKostenstellen$Json$Params, context?: HttpContext): Observable<Array<KostenstelleDto>> {
    return this.getKostenstellen$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<KostenstelleDto>>): Array<KostenstelleDto> => r.body)
    );
  }

}
