import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { DlSidebarService } from '../sidebar.service';

@Component({
  selector: 'dl-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css'],
})
export class DlSidebarComponent implements OnInit, OnDestroy {
  @Input() title = 'THDS';
  @Input() defaultOpen = false;

  private _sidebarServiceSub: Subscription | undefined;

  constructor(public sidebarService: DlSidebarService) {}

  ngOnInit(): void {
    if (this.defaultOpen) {
      this.sidebarService.openSidebar();
    }
  }

  ngOnDestroy(): void {
    this._sidebarServiceSub?.unsubscribe();
  }
}
