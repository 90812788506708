import { createFeature, createReducer, on } from '@ngrx/store';
import { produce } from 'immer';
import { SchnellauswahlDto } from '../../api/models';
import { SchnellauswahlenActions } from './schnellauswahlen.actions';

export const zugaengeFeatureKey = 'schnellauswahlen';

export type State = {
  schnellauswahlen: { [id: string]: SchnellauswahlDto | undefined };
  loading: boolean;
};

export const initialState: State = {
  schnellauswahlen: {},
  loading: false,
};

export const reducer = createReducer(
  initialState,
  on(
    SchnellauswahlenActions.loadSchnellauswahlen,
    (state): State => ({ ...state, loading: true })
  ),
  on(
    SchnellauswahlenActions.loadSchnellauswahlenSuccess,
    (state, { schnellauswahlen }) =>
      produce(state, (draft) => {
        schnellauswahlen.forEach((mzu) => {
          draft.schnellauswahlen[mzu.id] = mzu;
        });
        draft.loading = false;
      })
  ),
  on(
    SchnellauswahlenActions.loadSchnellauswahlenFailure,
    (state): State => ({ ...state, loading: false })
  ),
  on(
    SchnellauswahlenActions.createSchnellauswahl,
    (state): State => ({ ...state, loading: true })
  ),
  on(
    SchnellauswahlenActions.createSchnellauswahlSuccess,
    (state, { createdSchnellauswahl }) =>
      produce(state, (draft) => {
        draft.schnellauswahlen[createdSchnellauswahl.id] =
          createdSchnellauswahl;
        draft.loading = false;
      })
  ),
  on(
    SchnellauswahlenActions.createSchnellauswahlFailure,
    (state): State => ({ ...state, loading: false })
  ),
  on(
    SchnellauswahlenActions.deleteSchnellauswahl,
    (state): State => ({ ...state, loading: true })
  ),
  on(
    SchnellauswahlenActions.deleteSchnellauswahlSuccess,
    (state, { schnellauswahlId }) =>
      produce(state, (draft) => {
        delete draft.schnellauswahlen[schnellauswahlId];
        draft.loading = false;
      })
  ),
  on(
    SchnellauswahlenActions.deleteSchnellauswahlFailure,
    (state): State => ({ ...state, loading: false })
  )
);

export const schnellauswahlenFeature = createFeature({
  name: zugaengeFeatureKey,
  reducer,
});
