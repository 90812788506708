<dl-page-header>
    <dl-page-header-title>
      <span>{{pageTitle}}</span>
    </dl-page-header-title>
    <div class="right-block">
      <ng-content></ng-content>
      <ng-content select="[tour]"></ng-content>
      <dl-page-header-user *ngIf="(isLoggedIn$ | async)" [dropdownTemplate]="userDropdown">
        {{((loggedInMitarbeiter$ | async)?.vorname || '') + ' ' + ((loggedInMitarbeiter$ | async)?.nachname || '')}}
        <dl-page-header-user-image width="33px" [src]="'assets/pictures/standard-profile-picture.png'"></dl-page-header-user-image>
      </dl-page-header-user>
    </div>
  </dl-page-header>
  <ng-template #userDropdown>
    <!-- <dl-page-header-dropdown-entry src="../assets/icons/settings-sharp.svg"  routerLink="/settings">Einstellungen</dl-page-header-dropdown-entry> -->
    <dl-page-header-dropdown-entry src="../assets/icons/log-out-outline.svg" (click)="logout()" >Abmelden</dl-page-header-dropdown-entry>
    <dl-page-header-dropdown-entry class="no-hover" style="cursor: default;">Client: {{versionNumber}}<br>API: {{apiVersionNumber}}</dl-page-header-dropdown-entry>
  </ng-template>
  