/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Observable, Subject, catchError, from, lastValueFrom, switchMap, tap, throwError, } from 'rxjs';
import { DatenlotseAngularAuthService } from './datenlotse-angular-auth.service';

@Injectable({
  providedIn: 'root',
})
export class AngularAuthInterceptor implements HttpInterceptor {
  protected _authService?: DatenlotseAngularAuthService;

  protected tokenRefreshedSource = new Subject<void>();
  protected tokenRefreshed$ = this.tokenRefreshedSource.asObservable();

  constructor(protected injector: Injector) {}

  protected get refreshTokenInProgress(): boolean {
    // Get from sessionStorage
    return sessionStorage.getItem('refreshTokenInProgress') === 'true';
  }

  protected set refreshTokenInProgress(value: boolean) {
    // Save to sessionStorage
    sessionStorage.setItem('refreshTokenInProgress', String(value));
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return from(this.handle(req, next));
  }

  refreshToken(): Observable<any> {
    if (!this._authService) {
      throw new Error('AuthService not found');
    }

    if (this.refreshTokenInProgress) {
      return new Observable((observer) => {
        this.tokenRefreshed$.subscribe(() => {
          observer.next();
          observer.complete();
        });
      });
    } else {
      this.refreshTokenInProgress = true;

      return this._authService.getNewAccessToken().pipe(
        tap(() => {
          this.refreshTokenInProgress = false;
          this.tokenRefreshedSource.next();
        }),
        catchError((err, caught) => {
          this.refreshTokenInProgress = false;
          this._authService?.logoutUser();
          return caught;
        })
      );
    }
  }

  handleResponseError(
    error: any,
    request?: HttpRequest<unknown>,
    next?: HttpHandler
  ): Observable<any> {
    // Business error
    if (error.status === 400) {
      // Show message
      return throwError(() => error);
    }

    // Invalid token error
    else if (error.status === 401) {
      return this.refreshToken().pipe(
        switchMap(() => {
          if (!request || !next) {
            return throwError(() => error);
          }
          request = this.addAuthHeader(request);
          return next.handle(request);
        }),
        catchError((e, caught) => {
          if (e.status !== 401) {
            return this.handleResponseError(e);
          } else {
            this._authService?.logoutUser();
          }
          return caught;
        })
      );
    }

    // Access denied error
    else if (error.status === 403) {
      // Show message
      console.error('Access denied', error);
      return throwError(() => error);
      // Logout
      // this._authService?.logoutUser();
    }

    // Conflict Error
    else if (error.status === 409) {
      // Show message
      console.error('Conflict', error);
      return throwError(() => error);
      // Logout
      // this._authService?.logoutUser();
    }

    // Server error
    else if (error.status === 500) {
      // Show message
      console.error('Server error', error);
      return throwError(() => error);
    }

    // Maintenance error
    else if (error.status === 503) {
      // Show message
      // Redirect to the maintenance page
      console.error('Maintenance error', error);
      return throwError(() => error);
    } else if (error.status === 0) {
      // No response from the server
      console.error('No response from the server', error);
      return throwError(() => error);
    }

    console.error('Unknown error in interceptor', error);
    return throwError(() => error);
  }

  protected addAuthHeader<T>(request: HttpRequest<T>): HttpRequest<T> {
    if (!this._authService) {
      return request;
    }
    const accessToken = this._authService.accessToken;
    if (accessToken) {
      return request.clone({
        setHeaders: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
    }
    return request;
  }

  protected async getService() {
    this._authService = this.injector.get(DatenlotseAngularAuthService);
  }

  private async handle(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Promise<HttpEvent<any>> {
    /**
     * If the route is from the authService
     * we don't need to add the auth header
     * Urls: /tokens
     *     /tokens/refresh
     */

    if (req.url.includes('/tokens') || req.url.includes('/tokens/refresh')) {
      return lastValueFrom(next.handle(req));
    }

    await this.getService();

    const request = this.addAuthHeader(req);

    return lastValueFrom(
      next.handle(request).pipe(
        catchError((error) => {
          return this.handleResponseError(error, request, next);
        })
      )
    );
  }
}
