/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { UrlaubsantragDto } from '../../models/urlaubsantrag-dto';

export interface GetAllUrlaubsantraege$Json$Params {
  IncludeGenehmigt?: boolean;
  MitarbeiterId?: string;
  Unit?: string;

/**
 * Nummer der Seite die angezeigt werden soll.
 */
  PageNumber?: number;

/**
 * Anzahl der Einträge pro Seite. Maximal 100
 */
  PageSize?: number;

/**
 * Sortierung der Ergebnisse vor dem Filtern im Format: <br></br>(attribut) [(asc | desc)], ...(attribut) [(asc |
 * desc)],
 */
  OrderBy?: string;
}

export function getAllUrlaubsantraege$Json(http: HttpClient, rootUrl: string, params?: GetAllUrlaubsantraege$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<UrlaubsantragDto>>> {
  const rb = new RequestBuilder(rootUrl, getAllUrlaubsantraege$Json.PATH, 'get');
  if (params) {
    rb.query('IncludeGenehmigt', params.IncludeGenehmigt, {});
    rb.query('MitarbeiterId', params.MitarbeiterId, {});
    rb.query('Unit', params.Unit, {});
    rb.query('PageNumber', params.PageNumber, {});
    rb.query('PageSize', params.PageSize, {});
    rb.query('OrderBy', params.OrderBy, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'text/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<Array<UrlaubsantragDto>>;
    })
  );
}

getAllUrlaubsantraege$Json.PATH = '/v1/urlaubsantraege';
