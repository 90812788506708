/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { FehlerlistenEintrag } from '../../models/fehlerlisten-eintrag';

export interface GetAllFehlerlistenEintraege$Json$Params {
  Datum?: string;
  IsNeedsCorrection?: string;
  Vorname?: string;
  Nachname?: string;

/**
 * Nummer der Seite die angezeigt werden soll.
 */
  PageNumber?: number;

/**
 * Anzahl der Einträge pro Seite. Maximal 100
 */
  PageSize?: number;

/**
 * Sortierung der Ergebnisse vor dem Filtern im Format: <br></br>(attribut) [(asc | desc)], ...(attribut) [(asc |
 * desc)],
 */
  OrderBy?: string;
}

export function getAllFehlerlistenEintraege$Json(http: HttpClient, rootUrl: string, params?: GetAllFehlerlistenEintraege$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<FehlerlistenEintrag>>> {
  const rb = new RequestBuilder(rootUrl, getAllFehlerlistenEintraege$Json.PATH, 'get');
  if (params) {
    rb.query('Datum', params.Datum, {});
    rb.query('IsNeedsCorrection', params.IsNeedsCorrection, {});
    rb.query('Vorname', params.Vorname, {});
    rb.query('Nachname', params.Nachname, {});
    rb.query('PageNumber', params.PageNumber, {});
    rb.query('PageSize', params.PageSize, {});
    rb.query('OrderBy', params.OrderBy, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'text/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<Array<FehlerlistenEintrag>>;
    })
  );
}

getAllFehlerlistenEintraege$Json.PATH = '/v1/buchungen/fehlerliste';
