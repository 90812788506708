<form (ngSubmit)="onSubmit()" [formGroup]="form" class="flex flex-col max-h-full overflow-hidden">
  <div class="flex justify-between items-center">
    <h2 class="text-xl">{{form.getRawValue().name}}</h2>
    <dl-button [disabled]="!form.valid || (hasChanges$ | async) === false || !hasScope('monatsauswertungLayout:write')" dlPrimaryButton type="submit">Änderungen
      speichern
    </dl-button>
  </div>

  <div class="flex flex-col mt-8 max-h-full overflow-hidden">
    <div class="flex gap-8">
      <dl-form-field class="w-64">
        <dl-form-field-label required>Name</dl-form-field-label>
        <dl-input formControlName="name" type="text"></dl-input>
      </dl-form-field>
      <dl-form-field class="w-32">
        <dl-form-field-label required>Kürzel</dl-form-field-label>
        <dl-input formControlName="kuerzel" type="text"></dl-input>
      </dl-form-field>
    </div>

    <datenlotse-drag-drop-list
      [itemTemplate]="itemTemplate"
      [items]="spalten()"
      [leftColumnTitle]="'Aktive Spalten'"
      [rightColumnTitle]="'Inaktive Spalten'"
      class="mt-4 h-full"
      formControlName="aktiveSpalten"
    ></datenlotse-drag-drop-list>
  </div>
</form>

<ng-template #itemTemplate let-index="index" let-item>
  <span class="w-8 text-slate-500">
    {{ index + 1 }}
  </span>
  <span>
    {{ item.name }}
  </span>
</ng-template>
