import { createAction, props } from '@ngrx/store';
import { CreateWochenplanDto, WochenplanDto } from '../../api/models';

export const loadActiveWochenplaene = createAction(
  '[Wochenplaene] Load Active Wochenplaene'
);

export const loadActiveWochenplaeneSuccess = createAction(
  '[Wochenplaene] Load Active Wochenplaene Success',
  props<{ activeWochenplaene: WochenplanDto[] }>()
);

export const loadActiveWochenplaeneFailure = createAction(
  '[Wochenplaene] Load Active Wochenplaene Failure',
  props<{ error: unknown }>()
);
export const loadInactiveWochenplaene = createAction(
  '[Wochenplaene] Load Inactive Wochenplaene'
);

export const loadInactiveWochenplaeneSuccess = createAction(
  '[Wochenplaene] Load Inactive Wochenplaene Success',
  props<{ inactiveWochenplaene: WochenplanDto[] }>()
);

export const loadInactiveWochenplaeneFailure = createAction(
  '[Wochenplaene] Load Inactive Wochenplaene Failure',
  props<{ error: unknown }>()
);

export const createWochenplan = createAction(
  '[Wochenplaene] Create Wochenplan',
  props<{ data: CreateWochenplanDto }>()
);

export const createWochenplanSuccess = createAction(
  '[Wochenplaene] Create Wochenplan Success',
  props<{ wochenplan: WochenplanDto }>()
);

export const createWochenplanFailure = createAction(
  '[Wochenplaene] Create Wochenplan Failure',
  props<{ error: unknown }>()
);
export const setInactiveWochenplan = createAction(
  '[Wochenplaene] Set Inactive Wochenplan',
  props<{ id: string }>()
);

export const setInactiveWochenplanSuccess = createAction(
  '[Wochenplaene] Set Inactive Wochenplan Success',
  props<{ id: string }>()
);

export const setInactiveWochenplanFailure = createAction(
  '[Wochenplaene] Set Inactive Wochenplan Failure',
  props<{ error: unknown }>()
);
