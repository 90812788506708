import { ModuleWithProviders, NgModule } from '@angular/core';
import {
  AuthConfigToken,
  DatenlotseAngularAuthService,
  IAuthModuleConfig,
} from './datenlotse-angular-auth.service';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';

@NgModule({ declarations: [],
    exports: [], imports: [], providers: [provideHttpClient(withInterceptorsFromDi())] })
// @dynamic
export class DatenlotseAngularAuthModule {
  static forRoot(
    config: IAuthModuleConfig
  ): ModuleWithProviders<DatenlotseAngularAuthModule> {
    return {
      ngModule: DatenlotseAngularAuthModule,
      providers: [
        DatenlotseAngularAuthService,
        {
          provide: AuthConfigToken,
          useValue: config,
        },
      ],
    };
  }
}
