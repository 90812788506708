import { createAction, props } from '@ngrx/store';
import { CreateTagesplanDto, TagesplanDto } from '../../api/models';

export const loadActiveTagesplaene = createAction(
  '[Tagesplaene] Load Active Tagesplaene'
);

export const loadInactiveTagesplaene = createAction(
  '[Tagesplaene] Load Inactive Tagesplaene'
);

export const loadActiveTagesplaeneSuccess = createAction(
  '[Tagesplaene] Load Active Tagesplaene Success',
  props<{ activeTagesplaene: TagesplanDto[] }>()
);
export const loadInactiveTagesplaeneSuccess = createAction(
  '[Tagesplaene] Load Inactive Tagesplaene Success',
  props<{ inactiveTagesplaene: TagesplanDto[] }>()
);

export const loadActiveTagesplaeneFailure = createAction(
  '[Tagesplaene] Load Active Tagesplaene Failure',
  props<{ error: unknown }>()
);
export const loadInactiveTagesplaeneFailure = createAction(
  '[Tagesplaene] Load Inactive Tagesplaene Failure',
  props<{ error: unknown }>()
);

export const createTagesplan = createAction(
  '[Tagesplaene] Create Tagesplan',
  props<{ data: CreateTagesplanDto }>()
);

export const createTagesplanSuccess = createAction(
  '[Tagesplaene] Create Tagesplan Success',
  props<{ tagesplan: TagesplanDto }>()
);

export const createTagesplanFailure = createAction(
  '[Tagesplaene] Create Tagesplan Failure',
  props<{ error: unknown }>()
);
export const setInactiveTagesplan = createAction(
  '[Tagesplaene] Set Inactive Tagesplan',
  props<{ id: string }>()
);

export const setInactiveTagesplanSuccess = createAction(
  '[Tagesplaene] Set Inactive Tagesplan Success',
  props<{ id: string }>()
);

export const setInactiveTagesplanFailure = createAction(
  '[Tagesplaene] Set Inactive Tagesplan Failure',
  props<{ error: unknown }>()
);
