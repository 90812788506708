import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, debounceTime, map, of, switchMap, tap } from 'rxjs';
import { handleHttpError } from '../../../common/httpErrorHandler';
import { BuchungApiService } from '../../api/services/buchung-api.service';
import { PAGINATION_HEADER_NAME } from '../../pagination-header';
import { FehlerlisteActions } from './fehlerliste.actions';
import * as fromFehlerlisten from './fehlerliste.selector';

@Injectable()
export class FehlerlistenEffects {
  loadFehlerliste$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        FehlerlisteActions.loadFehlerlisten,
        FehlerlisteActions.loadNextPage,
        FehlerlisteActions.loadPreviousPage,
        FehlerlisteActions.setFehlerlistenSortFilterPageNumberPageSize
      ),
      debounceTime(100),
      concatLatestFrom(() => [
        this.store.select(fromFehlerlisten.selectMitarbeiterPaginationData),
      ]),
      switchMap(([, { index, size, filter, sort }]) => {
        return this.buchungenApiService
          .getAllFehlerlistenEintraege$Json$Response({
            PageNumber: index,
            PageSize: size,
            OrderBy: sort.active + ' ' + sort.direction.toLocaleUpperCase(),
            Datum: filter.length > 0 ? filter : undefined,
            Vorname: filter.length > 0 ? filter : undefined,
            Nachname: filter.length > 0 ? filter : undefined,
          })
          .pipe(
            map((response) =>
              FehlerlisteActions.loadFehlerlistenSuccess({
                data: response.body,
                totalCount: response.headers.get(PAGINATION_HEADER_NAME)
                ? JSON.parse(
                    response.headers.get(PAGINATION_HEADER_NAME) as string,
                  ).TotalCount
                : response.body.length,
              }),
            ),
            catchError((error) =>
              of(FehlerlisteActions.loadFehlerlistenFailure({ error })),
            ),
          );
      }),
    );
  });

  getFehlerlisteFailure$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(
          FehlerlisteActions.loadFehlerlistenFailure,
        ),
        tap(async ({ error }) => {
          console.error(error);
          await handleHttpError(
            error,
            this.toastController,
            'Beim Laden der Fehlerliste ist ein Fehler aufgetreten.',
          );
        }),
      );
    },
    { dispatch: false },
  );

  constructor(
    private actions$: Actions,
    private buchungenApiService: BuchungApiService,
    private readonly toastController: ToastController,
    private store: Store,
  ) {}
}
