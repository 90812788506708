/* eslint-disable @angular-eslint/no-host-metadata-property */
/* eslint-disable @angular-eslint/component-selector */
import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  Optional,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { DlSort, DlSortableColumn, Sort } from './sort.directive';

@Component({
  selector: '[dlSortHeader]',
  exportAs: 'dlSortHeader',
  templateUrl: 'sort-header.html',
  styleUrls: ['sort-header.css'],
  host: {
    '(click)': 'handleClick()',
  },
})
export class DlSortComponent implements OnInit, OnDestroy, DlSortableColumn {
  @Input('dlSortHeader') sortKey = '';
  direction: 'asc' | 'desc' | '' = '';
  updateSubscription: Subscription | undefined;
  arrowUrl: 'assets/icons/chevron-up.svg' | 'assets/icons/chevron-down.svg' =
    'assets/icons/chevron-up.svg';

  constructor(el: ElementRef, @Optional() public _sort: DlSort) {
    el.nativeElement.style.cursor = 'pointer';
    this._handleUpdates();
  }

  ngOnInit(): void | Error {
    if (this.sortKey === '') {
      throw Error('DlSortHeader: No sortKey provided!');
    }
    this._sort.register(this);
  }

  ngOnDestroy() {
    this._sort.deregister(this);
  }

  handleClick() {
    this._sort.sort(this);
  }

  amSorted(): boolean {
    return (
      this._sort.active === this.sortKey &&
      (this._sort.direction === 'asc' || this._sort.direction === 'desc')
    );
  }

  private _handleUpdates() {
    this.updateSubscription = this._sort.dlSortChange.subscribe((e: Sort) => {
      this.direction = this.amSorted() ? e.direction : '';
      this.arrowUrl = this.amSorted()
        ? this.direction === 'asc'
          ? 'assets/icons/chevron-up.svg'
          : 'assets/icons/chevron-down.svg'
        : 'assets/icons/chevron-up.svg';
    });
  }
}
