import { Component, Input } from '@angular/core';

@Component({
  selector: 'dl-page-header-user-image',
  templateUrl: './page-header-user-image.component.html',
  styleUrls: ['./page-header-user-image.component.css'],
})
export class DlPageHeaderUserImageComponent {
  @Input() src: string | undefined;
}
