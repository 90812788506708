import { createFeature, createReducer, on } from '@ngrx/store';
import { produce } from 'immer';
import { PersonWithZugaengeDto } from '../../api/models';
import { ChipsActions } from '../chips/chips.actions';
import { PersonenWithZugaengeActions } from './personen-zugaenge.actions';

export const zugaengeFeatureKey = 'personenWithZugaenge';

export type State = {
  personenWithZugaenge: { [chipNr: string]: PersonWithZugaengeDto | undefined };
  loading: boolean;
};

export const initialState: State = {
  personenWithZugaenge: {},
  loading: false,
};

export const reducer = createReducer(
  initialState,

  on(
    PersonenWithZugaengeActions.loadPersonenWithZugaenge,
    (state): State => ({
      ...state,
      loading: true,
    }),
  ),
  on(
    PersonenWithZugaengeActions.loadPersonenWithZugaengeSuccess,
    (state, { personenWithZugaenge }) =>
      produce(state, (draft) => {
        personenWithZugaenge.forEach((pzu) => {
          draft.personenWithZugaenge[pzu.chipNr] = pzu;
        });
        draft.loading = false;
      }),
  ),
  on(
    PersonenWithZugaengeActions.loadPersonenWithZugaengeFailure,
    (state): State => ({
      ...state,
      loading: false,
    }),
  ),
  on(
    PersonenWithZugaengeActions.updatePersonenWithZugaenge,
    (state): State => ({
      ...state,
      loading: true,
    }),
  ),
  on(
    PersonenWithZugaengeActions.updatePersonenWithZugaengeSuccess,
    (state, { updatedPersonenWithZugaenge }) =>
      produce(state, (draft) => {
        updatedPersonenWithZugaenge.forEach((up) => {
          draft.personenWithZugaenge[up.chipNr] = up;
        });
        draft.loading = false;
      }),
  ),
  on(
    PersonenWithZugaengeActions.updatePersonenWithZugaengeFailure,
    (state): State => ({
      ...state,
      loading: false,
    }),
  ),
  on(
    PersonenWithZugaengeActions.createOrUpdateExternWithZugaenge,
    (state): State => ({
      ...state,
      loading: true,
    }),
  ),
  on(
    PersonenWithZugaengeActions.createOrUpdateExternWithZugaengeSuccess,
    (state, { externWithZugaenge }) =>
      produce(state, (draft) => {
        externWithZugaenge.forEach((eZ) => {
          draft.personenWithZugaenge[eZ.chipNr] = eZ;
        });
        draft.loading = false;
      }),
  ),
  on(
    PersonenWithZugaengeActions.createOrUpdateExternWithZugaengeFailure,
    (state): State => ({
      ...state,
      loading: false,
    }),
  ),
  on(
    PersonenWithZugaengeActions.deleteExternWithZugaenge,
    (state): State => ({
      ...state,
      loading: true,
    }),
  ),
  on(
    PersonenWithZugaengeActions.deleteExternWithZugaengeSuccess,
    (state, { emptyChip }) =>
      produce(state, (draft) => {
        draft.personenWithZugaenge[emptyChip.chipNr] = emptyChip;
        draft.loading = false;
      }),
  ),
  on(
    PersonenWithZugaengeActions.deleteExternWithZugaengeFailure,
    (state): State => ({
      ...state,
      loading: false,
    }),
  ),
  on(
    ChipsActions.unrestrictChipSuccess,
    (state, { chip }): State =>
      produce(state, (draft) => {
        const existing = draft.personenWithZugaenge[chip.chipNr];
        if (!existing) {
          return;
        }

        draft.personenWithZugaenge[chip.chipNr] = {
          ...existing,
          ...chip,
        };
      }),
  ),
);

export const personenWithZugaengeFeature = createFeature({
  name: zugaengeFeatureKey,
  reducer,
});
