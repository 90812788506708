import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DlSidebarService {
  sidebarOpen = new BehaviorSubject(false);

  // Used for span animation delay
  sidebarHasOpend = new BehaviorSubject(false);

  routerSUB: Subscription;

  constructor(private _router: Router) {
    this.routerSUB = this._router.events.subscribe(() => {
      this.closeSidebar();
    });
  }

  openSidebar(): void {
    if (this.sidebarOpen.getValue() !== true) {
      this.sidebarOpen.next(true);
      setTimeout(() => {
        this.sidebarHasOpend.next(true);
      }, 200);
    }
  }

  closeSidebar(): void {
    if (this.sidebarOpen.getValue() !== false) {
      this.sidebarOpen.next(false);
      this.sidebarHasOpend.next(false);
    }
  }
}
