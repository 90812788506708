import { createFeature, createReducer, on } from '@ngrx/store';
import { produce } from 'immer';
import { BuchungDto } from '../../api/models/buchung-dto';
import { MitarbeiterActions } from '../mitarbeiter/mitarbeiter.actions';
import { BuchungenActions } from './buchungen.actions';

export const buchungenFeatureKey = 'buchungen';

export interface State {
  buchungen: Map<string, BuchungDto>;
  buchungenPage: string[] | undefined;
  page: number;
  pageSize: number;
  totalCount: number | undefined;
  selectedMonth: number | undefined;
  selectedYear: number | undefined;
  includeDeleted: boolean;
}

export const initialState: State = {
  buchungen: new Map<string, BuchungDto>(),
  buchungenPage: undefined,
  page: 0,
  pageSize: 15,
  totalCount: undefined,
  selectedMonth: new Date().getMonth() + 1,
  selectedYear: new Date().getFullYear(),
  includeDeleted: false,
};

export const reducer = createReducer(
  initialState,
  on(
    BuchungenActions.loadBuchungen,
    (state): State => ({
      ...state,
      buchungenPage: undefined,
    }),
  ),
  on(
    BuchungenActions.loadBuchungenSuccess,
    (state, { data, totalCount }): State => {
      const mapCopy = structuredClone(state.buchungen);

      data.forEach((buchung) => {
        mapCopy.set(buchung.id, buchung);
      });

      return {
        ...state,
        buchungenPage: data.map((buchung) => buchung.id),
        totalCount,
        buchungen: mapCopy,
      };
    },
  ),
  on(
    BuchungenActions.createBuchungForTimeframeSuccess,
    BuchungenActions.createBuchungForTimeframeAndTarifAttributSuccess,
    (state, { data }): State =>
      produce(state, (draft) => {
        data.forEach((buchung) => {
          draft.buchungen?.set(buchung.id, buchung);
        });
      }),
  ),
  on(
    BuchungenActions.loadBuchungenForTimeframeSuccess,
    (state, { buchungen }): State => {
      const mapCopy = structuredClone(state.buchungen);
      buchungen.forEach((buchung) => {
        mapCopy.set(buchung.id, buchung);
      });

      return {
        ...state,
        buchungen: mapCopy,
      };
    },
  ),
  on(
    MitarbeiterActions.setSelectedMitarbeiterId,
    (state): State => ({
      ...state,
      buchungenPage: undefined,
      buchungen: new Map<string, BuchungDto>(),
      totalCount: undefined,
    }),
  ),
  on(
    BuchungenActions.setPageIndex,
    (state, { pageIndex }): State => ({
      ...state,
      page: pageIndex,
      buchungenPage: undefined,
    }),
  ),
  on(
    BuchungenActions.setPageSize,
    (state, { pageSize }): State => ({
      ...state,
      pageSize,
    }),
  ),
  on(BuchungenActions.updateBuchungSuccess, (state, { data }) =>
    produce(state, (draft) => {
      draft.buchungen?.set(data.id, data);
    }),
  ),
  on(BuchungenActions.deleteBuchungSuccess, (state, { id }) =>
    produce(state, (draft) => {
      const buchung = draft.buchungen.get(id);
      if (!buchung) return;
      buchung.isDeleted = true;
    }),
  ),
  on(
    BuchungenActions.setSelectedMonthAndYear,
    (state, { selectedMonth, selectedYear }): State => ({
      ...state,
      selectedMonth,
      selectedYear,
    }),
  ),
  on(
    BuchungenActions.setIncludeDeleted,
    (state, { includeDeleted }): State => ({
      ...state,
      includeDeleted,
    }),
  ),
);

export const buchungenFeature = createFeature({
  name: buchungenFeatureKey,
  reducer: reducer,
});
