const scopeItemsObject = {
  'pageAccess:personal-stammdaten': {
    description: 'Zugriff auf die Seite Personal-Stammdaten',
  },
  'pageAccess:tagesplaene': {
    description: 'Zugriff auf die Seite Tagespläne',
  },
  'pageAccess:wochenplaene': {
    description: 'Zugriff auf die Seite Wochenpläne',
  },
  'pageAccess:buchungen': {
    description: 'Zugriff auf die Seite Buchungen',
  },
  'pageAccess:buchungs-aenderungen-antraege': {
    description: 'Zugriff auf die Seite Buchungsänderungenanträge',
  },
  'pageAccess:buchungs-erstell-antraege': {
    description: 'Zugriff auf die Seite Buchungserstellanträge',
  },
  'pageAccess:kalender': {
    description: 'Zugriff auf die Seite Kalender',
  },
  'pageAccess:auswertungen': {
    description: 'Zugriff auf die Seite Auswertungen',
  },
  'pageAccess:auswertungen:erstellen': {
    description: 'Zugriff auf die Seite fürs Erstellen von Auswertungen',
  },
  'pageAccess:umbuchungen': {
    description: 'Zugriff auf die Seite Umbuchungen',
  },
  'pageAccess:zutrittskontrolle': {
    description: 'Zugriff auf die Seite Zutrittskontrolle',
  },
  'pageAccess:layouts': {
    description: 'Zugriff auf die Seite Layouts',
  },
  'pageAccess:urlaubsantraege': {
    description: 'Zugriff auf die Seite Urlaubsanträge',
  },
  'pageAccess:buchungskorrekturen-monteure': {
    description: 'Zugriff auf die Seite Buchungskorrekturen Monteure',
  },
  'pageAccess:fehlerliste': {
    description: 'Zugriff auf die Seite Fehlerliste',
  },
  'arbeitszeitkonto:read:unit': {
    description: 'Darf Arbeitszeitkonten der eigenen Abteilung einsehen',
  },
  'arbeitszeitkonto:read:all': {
    description: 'Darf Arbeitszeitkonten aller Mitarbeiter einsehen',
  },
  'monatsauswertung:read:unit': {
    description: 'Darf Monatsauswertungen der eigenen Abteilung einsehen',
  },
  'monatsauswertung:read:all': {
    description: 'Darf Monatsauswertungen aller Mitarbeiter einsehen',
  },
  'beanspruchteUrlaube:read:unit': {
    description: 'Darf beanspruchte Urlaube der eigenen Abteilung einsehen',
  },
  'beanspruchteUrlaube:read:all': {
    description: 'Darf beanspruchte Urlaube aller Mitarbeiter einsehen',
  },
  'buchungen:read:unit': {
    description: 'Darf Buchungen der eigenen Abteilung einsehen',
  },
  'buchungen:read:all': {
    description: 'Darf Buchungen aller Mitarbeiter einsehen',
  },
  'buchungen:write': {
    description: 'Darf Buchungen anlegen und bearbeiten',
  },
  'mehrarbeitUmbuchungen:write': {
    description: 'Darf Mehrarbeitumbuchungen anlegen',
  },
  'sparzeitUmbuchungen:write': {
    description: 'Darf Sparzeitumbuchungen anlegen',
  },
  'mehrarbeitAuszahlungen:write': {
    description: 'Darf Mehhrarbeit auszahlen',
  },
  'buchungen:delete': {
    description: 'Darf Buchungen als gelöscht markieren',
  },
  'buchungsAenderungsAntrag:read:all': {
    description: 'Darf Buchungsänderungsanträge aller Mitarbeiter einsehen',
  },
  'buchungsAenderungsAntrag:read:unit': {
    description:
      'Darf Buchungsänderungsanträge aller Mitarbeiter der zugehörigen Unit einsehen',
  },
  'buchungsAenderungsAntrag:create': {
    description: 'Darf Buchungsänderungsanträge anlegen',
  },
  'buchungsAenderungsAntrag:approve:all': {
    description: 'Darf Buchungsänderungsanträge aller Mitarbeiter genehmigen',
  },
  'buchungsAenderungsAntrag:approve:unit': {
    description:
      'Darf Buchungsänderungsanträge aller Mitarbeiter der zugehörigen Unit genehmigen',
  },
  'buchungsErstellAntrag:read:all': {
    description: 'Darf Buchungserstellanträge aller Mitarbeiter einsehen',
  },
  'buchungsErstellAntrag:read:unit': {
    description:
      'Darf Buchungserstellanträge aller Mitarbeiter der zugehörigen Unit einsehen',
  },
  'buchungsErstellAntrag:create': {
    description: 'Darf Buchungserstellanträge anlegen',
  },
  'buchungsErstellAntrag:approve:all': {
    description: 'Darf Buchungserstellanträge aller Mitarbeiter genehmigen',
  },
  'buchungsErstellAntrag:approve:unit': {
    description:
      'Darf Buchungserstellanträge aller Mitarbeiter der zugehörigen Unit genehmigen',
  },
  'kalendereintraege:read:unit': {
    description: 'Darf Kalendereinträge der eigenen Abteilung einsehen',
  },
  'kalendereintraege:read:all': {
    description: 'Darf Kalendereinträge aller Mitarbeiter einsehen',
  },
  'kalendereintraege:write': {
    description: 'Darf Kalendereinträge anlegen, bearbeiten und löschen',
  },
  'mitarbeiter:read:unit': {
    description: 'Darf Mitarbeiter der eigenen Abteilung einsehen',
  },
  'mitarbeiter:read:all': {
    description: 'Darf Mitarbeiter aller Abteilungen einsehen',
  },
  'mitarbeiter:write': {
    description: 'Darf Mitarbeiter anlegen, bearbeiten und löschen',
  },
  'monatsauswertungLayout:read': {
    description: 'Darf Monatsauswertungs-Layouts einsehen',
  },
  'monatsauswertungLayout:write': {
    description:
      'Darf Monatsauswertungs-Layouts anlegen, bearbeiten und löschen',
  },
  'tagesplan:write': {
    description: 'Darf Tagespläne anlegen und bearbeiten',
  },
  'tagesplan:activation': {
    description: 'Darf Tagespläne aktivieren und deaktivieren',
  },
  'wochenplan:write': {
    description: 'Darf Wochenpläne anlegen und bearbeiten',
  },
  'wochenplan:activation': {
    description: 'Darf Wochenpläne aktivieren und deaktivieren',
  },
  'wochenplan:assign': {
    description: 'Darf Wochenpläne zuweisen',
  },
  'urlaubsansprueche:read:unit': {
    description: 'Darf Urlaubsansprüche der eigenen Abteilung einsehen',
  },
  'urlaubsansprueche:read:all': {
    description: 'Darf Urlaubsansprüche aller Mitarbeiter einsehen',
  },
  'urlaubsansprueche:write': {
    description: 'Darf Urlaubsansprüche anlegen, bearbeiten und löschen',
  },
  'urlaubsantraege:read:unit': {
    description: 'Darf Urlaubsanträge der eigenen Abteilung einsehen',
  },
  'urlaubsantraege:read:all': {
    description: 'Darf Urlaubsanträge aller Mitarbeiter einsehen',
  },
  'urlaubsantraege:resolve': {
    description: 'Darf Urlaubsanträge genehmigen und ablehnen',
  },
  'urlaubsantraege:create': {
    description: 'Darf Urlaubsanträge für sich anlegen',
  },
  'monatsauswertung:email': {
    description: 'Darf Monatsauswertungen per E-Mail versenden',
  },
  'monatsauswertung:send_all': {
    description: 'Darf Monatsauswertungen an alle Mitarbeiter versenden',
  },
  'zutrittskontrolle:verwaltung:all': {
    description: 'Darf die Zutrittskontrolle vollständig nutzen',
  },
  'zutrittskontrolle:einkaufsleiter': {
    description:
      'Darf die Zutrittskontrolle als Einkaufsleiter mit reduzierten Privilegien nutzen',
  },
  'monatsauswertung:pausenzeitenUeberpruefen': {
    description: 'Darf manuell die Pausenzeitenüberprüfung anstoßen',
  },
  'feiertage:ueberschreiben': {
    description: 'Darf Feiertage für Mitarbeiter setzen oder entfernen',
  },
} as const;

export type ScopeKey = keyof typeof scopeItemsObject;

const scopeItemsArray = Object.keys(scopeItemsObject).map((scope) => ({
  scope,
  description: scopeItemsObject[scope as unknown as ScopeKey].description,
}));

export { scopeItemsObject as scopes };
export default scopeItemsArray;
