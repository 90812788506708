import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const MonatsauswertungenActions = createActionGroup({
  source: 'Monatsauswertungen',
  events: {
    'Send To All Mitarbeiter': props<{ monat: Date; layoutId?: string }>(),
    'Send To All Mitarbeiter Success': emptyProps(),
    'Send To All Mitarbeiter Failure': props<{ error: unknown }>(),
  },
});
