import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DlCardComponent } from './card/card.component';
import { DlCardTitleComponent } from './card-title/card-title.component';
import { DlCardBodyComponent } from './card-body/card-body.component';

@NgModule({
  declarations: [DlCardComponent, DlCardTitleComponent, DlCardBodyComponent],
  imports: [CommonModule],
  exports: [DlCardComponent, DlCardTitleComponent, DlCardBodyComponent],
})
export class DlCardModule {}
