/**
 * Moves an item in an array from one index to another.
 * This method is a pure function that returns a new copy of the array
 * @param array The array to move in
 * @param oldIndex The index of the item to move
 * @param newIndex The index to move the item to
 */
export function moveItemInArray<T>(
  array: T[],
  oldIndex: number,
  newIndex: number
): T[] {
  if (
    oldIndex < 0 ||
    oldIndex >= array.length ||
    newIndex < 0 ||
    newIndex >= array.length
  ) {
    throw new Error('Invalid index');
  }
  const copy = array.slice();

  const element = copy.splice(oldIndex, 1)[0];
  copy.splice(newIndex, 0, element);

  return copy;
}
