import { createActionGroup, emptyProps, props } from '@ngrx/store';
import {
  CreateUpdateExternePersonDto,
  PersonWithZugaengeDto,
  UpdatePersonenWithZugaengeDto,
} from '../../api/models';

export const PersonenWithZugaengeActions = createActionGroup({
  source: 'PersonenWithZugaenge',
  events: {
    'Load Personen With Zugaenge': emptyProps(),
    'Load Personen With Zugaenge Success': props<{
      personenWithZugaenge: PersonWithZugaengeDto[];
    }>(),
    'Load Personen With Zugaenge Failure': props<{ error: unknown }>(),
    'Create Or Update Extern With Zugaenge': props<{
      createOrUpdateExternWithZugaenge: CreateUpdateExternePersonDto;
    }>(),
    'Create Or Update Extern With Zugaenge Success': props<{
      externWithZugaenge: PersonWithZugaengeDto[];
    }>(),
    'Create Or Update Extern With Zugaenge Failure': props<{
      error: unknown;
    }>(),
    'Delete Extern With Zugaenge': props<{ chipNr: string }>(),
    'Delete Extern With Zugaenge Success': props<{
      emptyChip: PersonWithZugaengeDto;
    }>(),
    'Delete Extern With Zugaenge Failure': props<{ error: unknown }>(),
    'Update Personen With Zugaenge': props<{
      updatePersonenWithZugaenge: UpdatePersonenWithZugaengeDto[];
    }>(),
    'Update Personen With Zugaenge Success': props<{
      updatedPersonenWithZugaenge: PersonWithZugaengeDto[];
    }>(),
    'Update Personen With Zugaenge Failure': props<{ error: unknown }>(),
    'Load Zutrittskontrolle': emptyProps(),
  },
});
