<datenlotse-page-header pageTitle="Layouts"></datenlotse-page-header>

<div class="grid rounded-2xl h-[calc(100%-4rem)] bg-white mt-4 relative grid-cols-edit-list">
  <div class="bg-neutral-50 p-4 rounded-l-2xl">
    <datenlotse-monatsauswertungen-layouts-list
      (create)="onCreate()"
      *ngLet="(monatsauswertungenLayouts$ | async) as monatsauswertungenLayouts"
      [monatsauswertungenLayouts]="monatsauswertungenLayouts ?? []"
      [selectedLayoutId]="componentStore.selectedMonatsauswertungLayoutId$ | ngrxPush"
    >
    </datenlotse-monatsauswertungen-layouts-list>
  </div>
  <dl-spinner
    *ngIf="componentStore.loading$ | async"
    class="absolute top-0 left-0 right-0 bottom-0 rounded-2xl bg-neutral-700 bg-opacity-40"></dl-spinner>
  <datenlotse-monatsauswertungen-layout-editor
    (submitForm)="onSubmit($event)"
    *ngIf="(componentStore.mode$ | async) === 'edit' || (componentStore.mode$ | async) === 'create'; else placeholder"
    [monatsauswertungLayout]="componentStore.selectSelectedMonatsauswertungLayout$ | ngrxPush"
  >

  </datenlotse-monatsauswertungen-layout-editor>
</div>


<ng-template #placeholder>
  <div class="flex justify-center items-center">
    <span class="text-xl text-center">
      Bitte wählen Sie ein Monatsauswertungen Layout aus der Liste aus
    </span>
  </div>
</ng-template>
