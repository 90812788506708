import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
} from '@angular/core';

@Component({
  selector: 'dl-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.css'],
})
export class DlButtonComponent {
  // eslint-disable-next-line @angular-eslint/no-output-rename, @angular-eslint/no-output-native
  @Output('click') clickEvent = new EventEmitter<MouseEvent>();

  @Input() disabled: string | boolean = false;
  @Input() type: 'button' | 'submit' | 'reset' = 'button';

  @HostBinding('class.disabled') get isDisabled() {
    return this.disabled === '' || this.disabled === true;
  }

  onClick(event: MouseEvent) {
    event.stopPropagation();
    if (!this.disabled) {
      this.clickEvent.emit(event);
    }
  }
}
