import { AfterViewChecked, Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[dlTable]',
})
export class DlTableDirective implements AfterViewChecked {
  header: HTMLTableSectionElement | undefined;
  headerTR: HTMLElement | undefined;
  body: HTMLTableSectionElement | undefined;
  bodyTR: HTMLElement | undefined;
  footer: HTMLTableSectionElement | undefined;
  native: HTMLElement | undefined;

  constructor(private elr: ElementRef<HTMLElement>) {}

  ngAfterViewChecked() {
    this.applyStyles();
  }

  applyStyles() {
    this.header = this.elr.nativeElement.children[0] as HTMLTableSectionElement;
    this.headerTR = this.elr.nativeElement.children[0]
      .children[0] as HTMLElement;
    this.body = this.elr.nativeElement.children[1] as HTMLTableSectionElement;
    this.bodyTR = this.elr.nativeElement.children[1].children[0] as HTMLElement;
    this.footer = this.elr.nativeElement.children[2] as HTMLTableSectionElement;
    this.native = this.elr.nativeElement as HTMLElement;

    this.native.style.setProperty('width', '100%');
    this.native.style.setProperty('border-collapse', 'collapse');
    this.native.style.setProperty('table-layout', 'fixed');

    this.header.style.setProperty('color', '#9fa2b4');
    this.header.style.setProperty('font-family', "'Montserrat', sans-serif");
    this.header.style.setProperty('font-weight', '400', 'important');
    this.header.style.setProperty('text-align', 'left');

    this.allChildren(this.headerTR.children, 'padding', '8px 16px 8px 16px');
    this.allChildren(this.headerTR.children, 'font-weight', '500');
    this.allChildren(this.headerTR.children, 'font-size', '14px');
    this.allChildren(this.headerTR.children, 'word-wrap', 'break-word');

    this.body.style.setProperty('color', '#464b6a');
    this.body.style.setProperty('font-family', "'Montserrat', sans-serif");

    // Alle Body Rows
    for (let i = 0; i < this.body.children.length; i++) {
      const child = this.body.children[i] as HTMLElement;

      // All cells of Body row
      this.allChildren(child.children, 'border-top', '1px solid #DFE0EB');
      this.allChildren(child.children, 'padding', '8px 16px 8px 16px');
      this.allChildren(child.children, 'word-wrap', 'break-word');
      this.allChildren(child.children, 'font-size', '13px');
    }
  }

  private allChildren(
    children: HTMLCollection,
    property: string,
    value: string,
    priority?: string
  ): void {
    for (let i = 0; i < children.length; i++) {
      const child = children[i] as HTMLElement;
      child.style.setProperty(property, value, priority);
    }
  }
}
