<div class="flex justify-between items-center">
  <dl-button (click)="create.emit()" dlLightButton *ngIf="hasScope('monatsauswertungLayout:write')">
    Layout erstellen
  </dl-button>
  <dl-search-bar
    [formControl]="filterControl"
    style="max-width: 210px"
  ></dl-search-bar>
</div>
<table
  [dataSource]="tableData"
  cdk-table
  dlSort
  dlTable
>
  <ng-container cdkColumnDef="name">
    <th *cdkHeaderCellDef cdk-header-cell dlSortHeader="name">Name</th>
    <td *cdkCellDef="let row" cdk-cell>
      <dl-table-cell>
        {{ row.name }}
      </dl-table-cell>
    </td>
  </ng-container>
  <ng-container cdkColumnDef="kuerzel">
    <th *cdkHeaderCellDef cdk-header-cell dlSortHeader="kuerzel">Kürzel</th>
    <td *cdkCellDef="let row" cdk-cell>
      <dl-table-cell> {{ row.kuerzel }}</dl-table-cell>
    </td>
  </ng-container>

  <tr *cdkHeaderRowDef="columns" cdk-header-row></tr>
  <tr
    *cdkRowDef="let row; columns: columns"
    [class.selected]="selectedLayoutId === row.id"
    [routerLink]="['/layouts', row.id]"
    cdk-row
    class="cursor-pointer"
  ></tr>
</table>
<dl-paginator
  [pageSizeOptions]="[5, 10, 15]"
  [pageSize]="15"
  label="Einträge pro Seite"
  labelOf="von"
></dl-paginator>
