/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { BuchungDto } from '../../models/buchung-dto';

export interface GetBuchungenForMitarbeiter$Plain$Params {

/**
 * ID des Mitarbeiters
 */
  mitarbeiterId: string;
  From?: string;
  To?: string;
  IncludeDeleted?: boolean;
}

export function getBuchungenForMitarbeiter$Plain(http: HttpClient, rootUrl: string, params: GetBuchungenForMitarbeiter$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<BuchungDto>>> {
  const rb = new RequestBuilder(rootUrl, getBuchungenForMitarbeiter$Plain.PATH, 'get');
  if (params) {
    rb.path('mitarbeiterId', params.mitarbeiterId, {});
    rb.query('From', params.From, {});
    rb.query('To', params.To, {});
    rb.query('IncludeDeleted', params.IncludeDeleted, {});
  }

  return http.request(
    rb.build({ responseType: 'text', accept: 'text/plain', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<Array<BuchungDto>>;
    })
  );
}

getBuchungenForMitarbeiter$Plain.PATH = '/v1/mitarbeiter/{mitarbeiterId}/buchungen';
