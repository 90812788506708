import { createFeature, createReducer, on } from '@ngrx/store';
import { produce } from 'immer';
import { UrlaubsantragDto } from '../../api/models/urlaubsantrag-dto';
import { UrlaubsantraegeActions } from './urlaubsantraege.actions';

export const urlaubsantraegeFeatureKey = 'urlaubsantraege';

export const FILTER = {
  ALL: 'all',
  UNIT: 'unit',
  USER: 'user',
} as const;

export type Filter = (typeof FILTER)[keyof typeof FILTER];

export interface State {
  urlaubsantraege: {
    [key: string]: UrlaubsantragDto;
  };
  totalCount: number;
  totalCountMitarbeiter: number;
  totalCountUnit: number;
  pageData: string[] | null;
  pageDataUnit: string[] | null;
  pageDataMitarbeiter: string[] | null;
  paginationData: {
    size: number;
    index: number;
  };
  paginationDataUnit: {
    size: number;
    index: number;
  };
  paginationDataMitarbeiter: {
    size: number;
    index: number;
  };
  includeProcessed: boolean;
}

export const initialState: State = {
  urlaubsantraege: {},
  totalCount: 0,
  totalCountMitarbeiter: 0,
  totalCountUnit: 0,
  pageData: null,
  pageDataUnit: null,
  pageDataMitarbeiter: null,
  includeProcessed: false,
  paginationData: {
    size: 15,
    index: 0,
  },
  paginationDataUnit: {
    size: 15,
    index: 0,
  },
  paginationDataMitarbeiter: {
    size: 15,
    index: 0,
  },
};

export const reducer = createReducer(
  initialState,
  on(UrlaubsantraegeActions.setPaginationData, (state, { size, index }) =>
    produce(state, (draft) => {
      draft.paginationData.size = size ?? draft.paginationData.size;
      draft.paginationData.index = index ?? draft.paginationData.index;
    })
  ),
  on(UrlaubsantraegeActions.setUnitPaginationData, (state, { size, index }) =>
    produce(state, (draft) => {
      draft.paginationDataUnit.size = size ?? draft.paginationDataUnit.size;
      draft.paginationDataUnit.index = index ?? draft.paginationDataUnit.index;
    })
  ),
  on(
    UrlaubsantraegeActions.setMitarbeiterPaginationData,
    (state, { size, index }) =>
      produce(state, (draft) => {
        draft.paginationDataMitarbeiter.size =
          size ?? draft.paginationDataMitarbeiter.size;
        draft.paginationDataMitarbeiter.index =
          index ?? draft.paginationDataMitarbeiter.index;
      })
  ),
  on(
    UrlaubsantraegeActions.setIncludeProcessed,
    (state, { includeProcessed }): State => ({
      ...state,
      includeProcessed,
    })
  ),
  on(
    UrlaubsantraegeActions.loadAllUrlaubsantraegeSuccess,
    (state, { urlaubsantraege, totalCount }) =>
      produce(state, (draft) => {
        urlaubsantraege.forEach((urlaubsantrag) => {
          draft.urlaubsantraege[urlaubsantrag.id] = urlaubsantrag;
        });
        draft.pageData = urlaubsantraege.map(
          (urlaubsantrag) => urlaubsantrag.id
        );
        draft.totalCount = totalCount;
      })
  ),
  on(
    UrlaubsantraegeActions.loadUnitUrlaubsantraegeSuccess,
    (state, { urlaubsantraege, totalCount }) =>
      produce(state, (draft) => {
        urlaubsantraege.forEach((urlaubsantrag) => {
          draft.urlaubsantraege[urlaubsantrag.id] = urlaubsantrag;
        });
        draft.pageDataUnit = urlaubsantraege.map(
          (urlaubsantrag) => urlaubsantrag.id
        );
        draft.totalCountUnit = totalCount;
      })
  ),
  on(
    UrlaubsantraegeActions.loadUserUrlaubsantraegeSuccess,
    (state, { urlaubsantraege, totalCount }) =>
      produce(state, (draft) => {
        urlaubsantraege.forEach((urlaubsantrag) => {
          draft.urlaubsantraege[urlaubsantrag.id] = urlaubsantrag;
        });
        draft.pageDataMitarbeiter = urlaubsantraege.map(
          (urlaubsantrag) => urlaubsantrag.id
        );
        draft.totalCountMitarbeiter = totalCount;
      })
  ),
  on(
    UrlaubsantraegeActions.loadAllUrlaubsantraegeFailure,
    (state): State => ({
      ...state,
      pageData: null,
      totalCount: 0,
    })
  ),
  on(
    UrlaubsantraegeActions.loadUnitUrlaubsantraegeFailure,
    (state): State => ({
      ...state,
      pageDataUnit: null,
      totalCountUnit: 0,
    })
  ),
  on(
    UrlaubsantraegeActions.loadUserUrlaubsantraegeFailure,
    (state): State => ({
      ...state,
      pageDataMitarbeiter: null,
      totalCountMitarbeiter: 0,
    })
  ),
  on(
    UrlaubsantraegeActions.createUrlaubsantragSuccess,
    (state, { urlaubsantrag }): State => ({
      ...state,
      urlaubsantraege: {
        ...state.urlaubsantraege,
        [urlaubsantrag.id]: urlaubsantrag,
      },
    })
  ),
  on(
    UrlaubsantraegeActions.processUrlaubsantragSuccess,
    (state, { urlaubsantrag }) =>
      produce(state, (draft) => {
        draft.urlaubsantraege[urlaubsantrag.id] = urlaubsantrag;
      })
  )
);

export const urlaubsantraegeFeature = createFeature({
  name: urlaubsantraegeFeatureKey,
  reducer: reducer,
});
