import { createAction, props } from '@ngrx/store';
import {
  CreateKalendereintragDto,
  CreateMultipleKalendereintraegeDto,
  CreateWochenplanZuordnungDto,
  KalendereintragDto,
} from '../../api/models';

export const loadZuordnungen = createAction('[Zuordnungen] Load Zuordnungen');

export const loadZuordnungenSuccess = createAction(
  '[Zuordnungen] Load Zuordnungen Success',
  props<{ zuordnungen: KalendereintragDto[] }>()
);

export const loadZuordnungenFailure = createAction(
  '[Zuordnungen] Load Zuordnungen Failure',
  props<{ error: unknown }>()
);

export const loadZuordnungFuerTag = createAction(
  '[Zuordnungen] Load Zuordnung Für Tag', 
  props<{date: Date}>()
  );

export const loadZuordnungFuerTagSuccess = createAction(
  '[Zuordnungen] Load Zuordnung Für Tag Success', 
  props<{ zuordnung?: KalendereintragDto }>()
  );

export const loadZuordnungFuerTagFailure = createAction(
  '[Zuordnungen] Load Zuordnung Für Tag Failure', 
  props<{error: unknown}>()
  );

export const increaseYear = createAction('[Zuordnungen] Increase Year');

export const decreaseYear = createAction('[Zuordnungen] Decrease Year');

export const resetYear = createAction('[Zuordnungen] Reset Year');

export const createTagesZuordnung = createAction(
  '[Zuordnungen] Create Tages Zuordnung',
  props<{ data: CreateKalendereintragDto }>()
);

export const createTagesZuordnungSuccess = createAction(
  '[Zuordnungen] Create Tages Zuordnung Success',
  props<{ zuordnung: KalendereintragDto }>()
);

export const createTagesZuordnungeFailure = createAction(
  '[Zuordnungen] Create Tages Zuordnung Failure',
  props<{ error: unknown }>()
);

export const createTagesZuordnungFuerZeitraum = createAction(
  '[Zuordnungen] Create Tages Zuordnung Für Zeitraum',
  props<{ data: CreateMultipleKalendereintraegeDto }>()
);

export const createTagesZuordnungFuerZeitraumSuccess = createAction(
  '[Zuordnungen] Create Tages Zuordnung Für Zeitraum Success',
  props<{ zuordnungen: KalendereintragDto[] }>()
);

export const createTagesZuordnungFuerZeitraumFailure = createAction(
  '[Zuordnungen] Create Tages Zuordnung Für Zeitraum Failure',
  props<{ error: unknown }>()
);

export const createWochenplanZuordnung = createAction(
  '[Zuordnungen] Create Wochenplan Zuordnung',
  props<{ data: CreateWochenplanZuordnungDto }>()
);

export const createWochenplanZuordnungSuccess = createAction(
  '[Zuordnungen] Create Wochenplan Zuordnung Success',
  props<{ zuordnung: KalendereintragDto[] }>()
);

export const createWochenplanZuordnungFailure = createAction(
  '[Zuordnungen] Create Wochenplan Zuordnung Failure',
  props<{ error: unknown }>()
);

export const deleteZuordnung = createAction(
  '[Zuordnungen] Delete Zuordnung',
  props<{ id: string }>()
);

export const deleteZuordnungSuccess = createAction(
  '[Zuordnungen] Delete Zuordnung Success',
  props<{ id: string }>()
);

export const deleteZuordnungFailure = createAction(
  '[Zuordnungen] Delete Zuordnung Failure',
  props<{ error: unknown }>()
);

export const deleteMultipleZuordnungen = createAction(
  '[Zuordnungen] Delete Multiple Zuordnungen',
  props<{ mitarbeiterId: string; from: Date; to: Date }>()
);

export const deleteMultipleZuordnungenSuccess = createAction(
  '[Zuordnungen] Delete Multiple Zuordnungen Success',
  props<{ mitarbeiterId: string; from: Date; to: Date }>()
);

export const deleteMultipleZuordnungenFailure = createAction(
  '[Zuordnungen] Delete Multiple Zuordnungen Failure',
  props<{ error: unknown }>()
);
