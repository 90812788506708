/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { BuchungDto } from '../models/buchung-dto';
import { BuchungsaenderungDto } from '../models/buchungsaenderung-dto';
import { createBuchungForMitarbeiter$Json } from '../fn/buchung/create-buchung-for-mitarbeiter-json';
import { CreateBuchungForMitarbeiter$Json$Params } from '../fn/buchung/create-buchung-for-mitarbeiter-json';
import { createBuchungForMitarbeiter$Plain } from '../fn/buchung/create-buchung-for-mitarbeiter-plain';
import { CreateBuchungForMitarbeiter$Plain$Params } from '../fn/buchung/create-buchung-for-mitarbeiter-plain';
import { createBuchungForMitarbeiterInTimeframe$Json } from '../fn/buchung/create-buchung-for-mitarbeiter-in-timeframe-json';
import { CreateBuchungForMitarbeiterInTimeframe$Json$Params } from '../fn/buchung/create-buchung-for-mitarbeiter-in-timeframe-json';
import { createBuchungForMitarbeiterInTimeframe$Plain } from '../fn/buchung/create-buchung-for-mitarbeiter-in-timeframe-plain';
import { CreateBuchungForMitarbeiterInTimeframe$Plain$Params } from '../fn/buchung/create-buchung-for-mitarbeiter-in-timeframe-plain';
import { createBuchungForTarifAttributInTimeframe$Json } from '../fn/buchung/create-buchung-for-tarif-attribut-in-timeframe-json';
import { CreateBuchungForTarifAttributInTimeframe$Json$Params } from '../fn/buchung/create-buchung-for-tarif-attribut-in-timeframe-json';
import { createBuchungForTarifAttributInTimeframe$Plain } from '../fn/buchung/create-buchung-for-tarif-attribut-in-timeframe-plain';
import { CreateBuchungForTarifAttributInTimeframe$Plain$Params } from '../fn/buchung/create-buchung-for-tarif-attribut-in-timeframe-plain';
import { deleteOneBuchung$Json } from '../fn/buchung/delete-one-buchung-json';
import { DeleteOneBuchung$Json$Params } from '../fn/buchung/delete-one-buchung-json';
import { deleteOneBuchung$Plain } from '../fn/buchung/delete-one-buchung-plain';
import { DeleteOneBuchung$Plain$Params } from '../fn/buchung/delete-one-buchung-plain';
import { FehlerlistenEintrag } from '../models/fehlerlisten-eintrag';
import { getAenderungenForBuchung$Json } from '../fn/buchung/get-aenderungen-for-buchung-json';
import { GetAenderungenForBuchung$Json$Params } from '../fn/buchung/get-aenderungen-for-buchung-json';
import { getAenderungenForBuchung$Plain } from '../fn/buchung/get-aenderungen-for-buchung-plain';
import { GetAenderungenForBuchung$Plain$Params } from '../fn/buchung/get-aenderungen-for-buchung-plain';
import { getAllFehlerlistenEintraege$Json } from '../fn/buchung/get-all-fehlerlisten-eintraege-json';
import { GetAllFehlerlistenEintraege$Json$Params } from '../fn/buchung/get-all-fehlerlisten-eintraege-json';
import { getAllFehlerlistenEintraege$Plain } from '../fn/buchung/get-all-fehlerlisten-eintraege-plain';
import { GetAllFehlerlistenEintraege$Plain$Params } from '../fn/buchung/get-all-fehlerlisten-eintraege-plain';
import { getBuchungenForMitarbeiter$Json } from '../fn/buchung/get-buchungen-for-mitarbeiter-json';
import { GetBuchungenForMitarbeiter$Json$Params } from '../fn/buchung/get-buchungen-for-mitarbeiter-json';
import { getBuchungenForMitarbeiter$Plain } from '../fn/buchung/get-buchungen-for-mitarbeiter-plain';
import { GetBuchungenForMitarbeiter$Plain$Params } from '../fn/buchung/get-buchungen-for-mitarbeiter-plain';
import { getOneBuchung$Json } from '../fn/buchung/get-one-buchung-json';
import { GetOneBuchung$Json$Params } from '../fn/buchung/get-one-buchung-json';
import { getOneBuchung$Plain } from '../fn/buchung/get-one-buchung-plain';
import { GetOneBuchung$Plain$Params } from '../fn/buchung/get-one-buchung-plain';
import { updateOneBuchung$Json } from '../fn/buchung/update-one-buchung-json';
import { UpdateOneBuchung$Json$Params } from '../fn/buchung/update-one-buchung-json';
import { updateOneBuchung$Plain } from '../fn/buchung/update-one-buchung-plain';
import { UpdateOneBuchung$Plain$Params } from '../fn/buchung/update-one-buchung-plain';

@Injectable({ providedIn: 'root' })
export class BuchungApiService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getOneBuchung()` */
  static readonly GetOneBuchungPath = '/v1/buchungen/{buchungsId}';

  /**
   * Holt eine Buchung.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOneBuchung$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOneBuchung$Plain$Response(params: GetOneBuchung$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<BuchungDto>> {
    return getOneBuchung$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Holt eine Buchung.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getOneBuchung$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOneBuchung$Plain(params: GetOneBuchung$Plain$Params, context?: HttpContext): Observable<BuchungDto> {
    return this.getOneBuchung$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<BuchungDto>): BuchungDto => r.body)
    );
  }

  /**
   * Holt eine Buchung.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOneBuchung$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOneBuchung$Json$Response(params: GetOneBuchung$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<BuchungDto>> {
    return getOneBuchung$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Holt eine Buchung.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getOneBuchung$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOneBuchung$Json(params: GetOneBuchung$Json$Params, context?: HttpContext): Observable<BuchungDto> {
    return this.getOneBuchung$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<BuchungDto>): BuchungDto => r.body)
    );
  }

  /** Path part for operation `updateOneBuchung()` */
  static readonly UpdateOneBuchungPath = '/v1/buchungen/{buchungsId}';

  /**
   * Aktualisiert die angegebene Buchung.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateOneBuchung$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateOneBuchung$Plain$Response(params: UpdateOneBuchung$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<BuchungDto>> {
    return updateOneBuchung$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Aktualisiert die angegebene Buchung.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateOneBuchung$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateOneBuchung$Plain(params: UpdateOneBuchung$Plain$Params, context?: HttpContext): Observable<BuchungDto> {
    return this.updateOneBuchung$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<BuchungDto>): BuchungDto => r.body)
    );
  }

  /**
   * Aktualisiert die angegebene Buchung.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateOneBuchung$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateOneBuchung$Json$Response(params: UpdateOneBuchung$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<BuchungDto>> {
    return updateOneBuchung$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Aktualisiert die angegebene Buchung.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateOneBuchung$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateOneBuchung$Json(params: UpdateOneBuchung$Json$Params, context?: HttpContext): Observable<BuchungDto> {
    return this.updateOneBuchung$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<BuchungDto>): BuchungDto => r.body)
    );
  }

  /** Path part for operation `deleteOneBuchung()` */
  static readonly DeleteOneBuchungPath = '/v1/buchungen/{buchungsId}';

  /**
   * Löscht die angegebene Buchung.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteOneBuchung$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteOneBuchung$Plain$Response(params: DeleteOneBuchung$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<BuchungDto>> {
    return deleteOneBuchung$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Löscht die angegebene Buchung.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteOneBuchung$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteOneBuchung$Plain(params: DeleteOneBuchung$Plain$Params, context?: HttpContext): Observable<BuchungDto> {
    return this.deleteOneBuchung$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<BuchungDto>): BuchungDto => r.body)
    );
  }

  /**
   * Löscht die angegebene Buchung.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteOneBuchung$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteOneBuchung$Json$Response(params: DeleteOneBuchung$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<BuchungDto>> {
    return deleteOneBuchung$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Löscht die angegebene Buchung.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteOneBuchung$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteOneBuchung$Json(params: DeleteOneBuchung$Json$Params, context?: HttpContext): Observable<BuchungDto> {
    return this.deleteOneBuchung$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<BuchungDto>): BuchungDto => r.body)
    );
  }

  /** Path part for operation `getAenderungenForBuchung()` */
  static readonly GetAenderungenForBuchungPath = '/v1/buchungen/{buchungsId}/aenderungen';

  /**
   * Holt alle Änderungen für die Buchung.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAenderungenForBuchung$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAenderungenForBuchung$Plain$Response(params: GetAenderungenForBuchung$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<BuchungsaenderungDto>>> {
    return getAenderungenForBuchung$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Holt alle Änderungen für die Buchung.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAenderungenForBuchung$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAenderungenForBuchung$Plain(params: GetAenderungenForBuchung$Plain$Params, context?: HttpContext): Observable<Array<BuchungsaenderungDto>> {
    return this.getAenderungenForBuchung$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<BuchungsaenderungDto>>): Array<BuchungsaenderungDto> => r.body)
    );
  }

  /**
   * Holt alle Änderungen für die Buchung.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAenderungenForBuchung$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAenderungenForBuchung$Json$Response(params: GetAenderungenForBuchung$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<BuchungsaenderungDto>>> {
    return getAenderungenForBuchung$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Holt alle Änderungen für die Buchung.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAenderungenForBuchung$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAenderungenForBuchung$Json(params: GetAenderungenForBuchung$Json$Params, context?: HttpContext): Observable<Array<BuchungsaenderungDto>> {
    return this.getAenderungenForBuchung$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<BuchungsaenderungDto>>): Array<BuchungsaenderungDto> => r.body)
    );
  }

  /** Path part for operation `getBuchungenForMitarbeiter()` */
  static readonly GetBuchungenForMitarbeiterPath = '/v1/mitarbeiter/{mitarbeiterId}/buchungen';

  /**
   * Holt alle Buchungen für einen Mitarbeiter in dem angegebenen Zeitraum.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getBuchungenForMitarbeiter$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBuchungenForMitarbeiter$Plain$Response(params: GetBuchungenForMitarbeiter$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<BuchungDto>>> {
    return getBuchungenForMitarbeiter$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Holt alle Buchungen für einen Mitarbeiter in dem angegebenen Zeitraum.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getBuchungenForMitarbeiter$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBuchungenForMitarbeiter$Plain(params: GetBuchungenForMitarbeiter$Plain$Params, context?: HttpContext): Observable<Array<BuchungDto>> {
    return this.getBuchungenForMitarbeiter$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<BuchungDto>>): Array<BuchungDto> => r.body)
    );
  }

  /**
   * Holt alle Buchungen für einen Mitarbeiter in dem angegebenen Zeitraum.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getBuchungenForMitarbeiter$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBuchungenForMitarbeiter$Json$Response(params: GetBuchungenForMitarbeiter$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<BuchungDto>>> {
    return getBuchungenForMitarbeiter$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Holt alle Buchungen für einen Mitarbeiter in dem angegebenen Zeitraum.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getBuchungenForMitarbeiter$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBuchungenForMitarbeiter$Json(params: GetBuchungenForMitarbeiter$Json$Params, context?: HttpContext): Observable<Array<BuchungDto>> {
    return this.getBuchungenForMitarbeiter$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<BuchungDto>>): Array<BuchungDto> => r.body)
    );
  }

  /** Path part for operation `createBuchungForMitarbeiter()` */
  static readonly CreateBuchungForMitarbeiterPath = '/v1/mitarbeiter/{mitarbeiterId}/buchungen';

  /**
   * Erstellt eine Buchung für den Mitarbeiter.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createBuchungForMitarbeiter$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createBuchungForMitarbeiter$Plain$Response(params: CreateBuchungForMitarbeiter$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<BuchungDto>> {
    return createBuchungForMitarbeiter$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Erstellt eine Buchung für den Mitarbeiter.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createBuchungForMitarbeiter$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createBuchungForMitarbeiter$Plain(params: CreateBuchungForMitarbeiter$Plain$Params, context?: HttpContext): Observable<BuchungDto> {
    return this.createBuchungForMitarbeiter$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<BuchungDto>): BuchungDto => r.body)
    );
  }

  /**
   * Erstellt eine Buchung für den Mitarbeiter.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createBuchungForMitarbeiter$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createBuchungForMitarbeiter$Json$Response(params: CreateBuchungForMitarbeiter$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<BuchungDto>> {
    return createBuchungForMitarbeiter$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Erstellt eine Buchung für den Mitarbeiter.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createBuchungForMitarbeiter$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createBuchungForMitarbeiter$Json(params: CreateBuchungForMitarbeiter$Json$Params, context?: HttpContext): Observable<BuchungDto> {
    return this.createBuchungForMitarbeiter$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<BuchungDto>): BuchungDto => r.body)
    );
  }

  /** Path part for operation `createBuchungForMitarbeiterInTimeframe()` */
  static readonly CreateBuchungForMitarbeiterInTimeframePath = '/v1/mitarbeiter/{mitarbeiterId}/buchungen/timeframe';

  /**
   * Erstellt eine Buchung für den Mitarbeiter über einen Zeitraum.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createBuchungForMitarbeiterInTimeframe$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createBuchungForMitarbeiterInTimeframe$Plain$Response(params: CreateBuchungForMitarbeiterInTimeframe$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<BuchungDto>>> {
    return createBuchungForMitarbeiterInTimeframe$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Erstellt eine Buchung für den Mitarbeiter über einen Zeitraum.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createBuchungForMitarbeiterInTimeframe$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createBuchungForMitarbeiterInTimeframe$Plain(params: CreateBuchungForMitarbeiterInTimeframe$Plain$Params, context?: HttpContext): Observable<Array<BuchungDto>> {
    return this.createBuchungForMitarbeiterInTimeframe$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<BuchungDto>>): Array<BuchungDto> => r.body)
    );
  }

  /**
   * Erstellt eine Buchung für den Mitarbeiter über einen Zeitraum.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createBuchungForMitarbeiterInTimeframe$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createBuchungForMitarbeiterInTimeframe$Json$Response(params: CreateBuchungForMitarbeiterInTimeframe$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<BuchungDto>>> {
    return createBuchungForMitarbeiterInTimeframe$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Erstellt eine Buchung für den Mitarbeiter über einen Zeitraum.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createBuchungForMitarbeiterInTimeframe$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createBuchungForMitarbeiterInTimeframe$Json(params: CreateBuchungForMitarbeiterInTimeframe$Json$Params, context?: HttpContext): Observable<Array<BuchungDto>> {
    return this.createBuchungForMitarbeiterInTimeframe$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<BuchungDto>>): Array<BuchungDto> => r.body)
    );
  }

  /** Path part for operation `createBuchungForTarifAttributInTimeframe()` */
  static readonly CreateBuchungForTarifAttributInTimeframePath = '/v1/mitarbeiter/tarifattribut/{tarifAttribut}/buchungen/timeframe';

  /**
   * Erstellt eine Buchung für alle Mitarbeiter des Tarif Attribut über einen Zeitraum.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createBuchungForTarifAttributInTimeframe$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createBuchungForTarifAttributInTimeframe$Plain$Response(params: CreateBuchungForTarifAttributInTimeframe$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<BuchungDto>>> {
    return createBuchungForTarifAttributInTimeframe$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Erstellt eine Buchung für alle Mitarbeiter des Tarif Attribut über einen Zeitraum.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createBuchungForTarifAttributInTimeframe$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createBuchungForTarifAttributInTimeframe$Plain(params: CreateBuchungForTarifAttributInTimeframe$Plain$Params, context?: HttpContext): Observable<Array<BuchungDto>> {
    return this.createBuchungForTarifAttributInTimeframe$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<BuchungDto>>): Array<BuchungDto> => r.body)
    );
  }

  /**
   * Erstellt eine Buchung für alle Mitarbeiter des Tarif Attribut über einen Zeitraum.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createBuchungForTarifAttributInTimeframe$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createBuchungForTarifAttributInTimeframe$Json$Response(params: CreateBuchungForTarifAttributInTimeframe$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<BuchungDto>>> {
    return createBuchungForTarifAttributInTimeframe$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Erstellt eine Buchung für alle Mitarbeiter des Tarif Attribut über einen Zeitraum.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createBuchungForTarifAttributInTimeframe$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createBuchungForTarifAttributInTimeframe$Json(params: CreateBuchungForTarifAttributInTimeframe$Json$Params, context?: HttpContext): Observable<Array<BuchungDto>> {
    return this.createBuchungForTarifAttributInTimeframe$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<BuchungDto>>): Array<BuchungDto> => r.body)
    );
  }

  /** Path part for operation `getAllFehlerlistenEintraege()` */
  static readonly GetAllFehlerlistenEintraegePath = '/v1/buchungen/fehlerliste';

  /**
   * Holt alle Tage wo Buchungen korrigiert werden müssen oder noch keine Buchungen vorhanden sind obwohl die Sollarbeitszeit != 0 ist.
   *
   * Die Tage werden absteigend sortiert zurückgegeben
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllFehlerlistenEintraege$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllFehlerlistenEintraege$Plain$Response(params?: GetAllFehlerlistenEintraege$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<FehlerlistenEintrag>>> {
    return getAllFehlerlistenEintraege$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Holt alle Tage wo Buchungen korrigiert werden müssen oder noch keine Buchungen vorhanden sind obwohl die Sollarbeitszeit != 0 ist.
   *
   * Die Tage werden absteigend sortiert zurückgegeben
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllFehlerlistenEintraege$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllFehlerlistenEintraege$Plain(params?: GetAllFehlerlistenEintraege$Plain$Params, context?: HttpContext): Observable<Array<FehlerlistenEintrag>> {
    return this.getAllFehlerlistenEintraege$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<FehlerlistenEintrag>>): Array<FehlerlistenEintrag> => r.body)
    );
  }

  /**
   * Holt alle Tage wo Buchungen korrigiert werden müssen oder noch keine Buchungen vorhanden sind obwohl die Sollarbeitszeit != 0 ist.
   *
   * Die Tage werden absteigend sortiert zurückgegeben
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllFehlerlistenEintraege$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllFehlerlistenEintraege$Json$Response(params?: GetAllFehlerlistenEintraege$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<FehlerlistenEintrag>>> {
    return getAllFehlerlistenEintraege$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Holt alle Tage wo Buchungen korrigiert werden müssen oder noch keine Buchungen vorhanden sind obwohl die Sollarbeitszeit != 0 ist.
   *
   * Die Tage werden absteigend sortiert zurückgegeben
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllFehlerlistenEintraege$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllFehlerlistenEintraege$Json(params?: GetAllFehlerlistenEintraege$Json$Params, context?: HttpContext): Observable<Array<FehlerlistenEintrag>> {
    return this.getAllFehlerlistenEintraege$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<FehlerlistenEintrag>>): Array<FehlerlistenEintrag> => r.body)
    );
  }

}
