import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { handleHttpError } from '../../../common/httpErrorHandler';
import { SchnellauswahlApiService } from '../../api/services';
import { PersonenWithZugaengeActions } from '../personen-zugaenge/personen-zugaenge.actions';
import { SchnellauswahlenActions } from './schnellauswahlen.actions';

@Injectable()
export class SchnellauswahlenEffects {
  loadSchnellauswahlen$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        SchnellauswahlenActions.loadSchnellauswahlen,
        PersonenWithZugaengeActions.loadZutrittskontrolle
      ),
      switchMap(() =>
        this.api$.getAllSchnellauswahlmoeglichkeiten$Json().pipe(
          map((schnellauswahlen) =>
            SchnellauswahlenActions.loadSchnellauswahlenSuccess({
              schnellauswahlen,
            })
          ),
          catchError((error) =>
            of(SchnellauswahlenActions.loadSchnellauswahlenFailure({ error }))
          )
        )
      )
    );
  });

  loadSchnellauswahlenFailure$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(SchnellauswahlenActions.loadSchnellauswahlenFailure),
        tap(async ({ error }) => {
          console.error(error);
          await handleHttpError(
            error,
            this.toastCtrl,
            'Beim Laden der Schnellauswahl(en) ist ein Fehler aufgetreten.'
          );
        })
      );
    },
    { dispatch: false }
  );

  deleteSchnellauswahlById$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SchnellauswahlenActions.deleteSchnellauswahl),
      switchMap(({ schnellauswahlId }) =>
        this.api$.deleteSchnellauswahl({ schnellauswahlId }).pipe(
          map(() =>
            SchnellauswahlenActions.deleteSchnellauswahlSuccess({
              schnellauswahlId,
            })
          ),
          catchError((error) =>
            of(SchnellauswahlenActions.loadSchnellauswahlenFailure({ error }))
          )
        )
      )
    );
  });

  deleteSchnellauswahlSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(SchnellauswahlenActions.deleteSchnellauswahlSuccess),
        tap(async () => {
          const toast = await this.toastCtrl.create({
            message: 'Schnellauswahl erfolgreich gelöscht.',
            duration: 2000,
            color: 'success',
          });

          await toast.present();
        })
      );
    },
    { dispatch: false }
  );

  deleteSchnellauswahlenFailure$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(SchnellauswahlenActions.deleteSchnellauswahlFailure),
        tap(async ({ error }) => {
          console.error(error);
          await handleHttpError(
            error,
            this.toastCtrl,
            'Beim Löschen der Schnellauswahl ist ein Fehler aufgetreten.'
          );
        })
      );
    },
    { dispatch: false }
  );

  createSchnellauswahl$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SchnellauswahlenActions.createSchnellauswahl),
      switchMap(({ data }) =>
        this.api$.createSchnellauswahl$Json({ body: data }).pipe(
          map((createdSchnellauswahl) =>
            SchnellauswahlenActions.createSchnellauswahlSuccess({
              createdSchnellauswahl,
            })
          ),
          catchError((error) =>
            of(SchnellauswahlenActions.createSchnellauswahlFailure({ error }))
          )
        )
      )
    );
  });

  createSchnellauswahlSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(SchnellauswahlenActions.createSchnellauswahlSuccess),
        tap(async () => {
          const toast = await this.toastCtrl.create({
            message: 'Schnellauswahl erfolgreich erstellt.',
            duration: 2000,
            color: 'success',
          });

          await toast.present();
        })
      );
    },
    { dispatch: false }
  );

  createSchnellauswahlenFailure$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(SchnellauswahlenActions.createSchnellauswahlFailure),
        tap(async ({ error }) => {
          console.error(error);
          await handleHttpError(
            error,
            this.toastCtrl,
            'Beim Erstellen der Schnellauswahl ist ein Fehler aufgetreten.'
          );
        })
      );
    },
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private readonly api$: SchnellauswahlApiService,
    private readonly toastCtrl: ToastController
  ) {}
}
