import { Component } from '@angular/core';
import { DlSidebarService } from '../sidebar.service';

@Component({
  selector: 'dl-sidebar-open-button',
  templateUrl: './sidebar-open-button.component.html',
  styleUrls: ['./sidebar-open-button.component.css'],
})
export class DlSidebarOpenButtonComponent {
  constructor(private _sidebarService: DlSidebarService) {}

  toggleSidebar(): void {
    const currentStatus = this._sidebarService.sidebarOpen.getValue();
    const newStatus = !currentStatus;
    if (newStatus === true) {
      this._sidebarService.openSidebar();
    } else {
      this._sidebarService.closeSidebar();
    }
  }
}
