import { createFeature, createReducer, on } from '@ngrx/store';
import { produce } from 'immer';
import { MehrarbeitUmbuchungDto } from '../../api/models';
import { MitarbeiterActions } from '../mitarbeiter/mitarbeiter.actions';
import { MehrarbeitUmbuchungenActions } from './mehrarbeitumbuchungen.actions';

export const mehrarbeitUmbuchungenFeatureKey = 'mehrarbeitUmbuchungen';

export interface State {
  mehrarbeitUmbuchungen: { [id: string]: MehrarbeitUmbuchungDto | undefined };
  mehrarbeitUmbuchungenPageData: string[] | null;
  page: number;
  pageSize: number;
  totalCount: number | undefined;
  selectedMonth: number | undefined;
  selectedYear: number | undefined;
  loading: boolean;
}

export const initialState: State = {
  mehrarbeitUmbuchungen: {},
  mehrarbeitUmbuchungenPageData: null,
  page: 0,
  pageSize: 15,
  totalCount: undefined,
  selectedMonth: new Date().getMonth() + 1,
  selectedYear: new Date().getFullYear(),
  loading: false,
};

export const reducer = createReducer(
  initialState,
  on(
    MehrarbeitUmbuchungenActions.loadMehrarbeitumbuchungen,
    MehrarbeitUmbuchungenActions.createMehrarbeitumbuchung,
    (state): State => ({
      ...state,
      loading: true,
    }),
  ),
  on(
    MehrarbeitUmbuchungenActions.loadMehrarbeitumbuchungenSuccess,
    (state, { data, totalCount }): State =>
      produce(state, (draft) => {
        data.forEach((mau) => {
          draft.mehrarbeitUmbuchungen[mau.id] = mau;
        });
        draft.mehrarbeitUmbuchungenPageData = data.map((mau) => mau.id);
        draft.totalCount = totalCount;
        draft.loading = false;
      }),
  ),
  on(
    MehrarbeitUmbuchungenActions.loadMehrarbeitumbuchungenFailure,
    MehrarbeitUmbuchungenActions.createMehrarbeitumbuchungFailure,
    (state): State => ({
      ...state,
      loading: false,
    }),
  ),
  on(
    MehrarbeitUmbuchungenActions.createMehrarbeitumbuchungSuccess,
    (state, { data }): State =>
      produce(state, (draft) => {
        draft.mehrarbeitUmbuchungen[data.id] = data;
        draft.mehrarbeitUmbuchungenPageData?.push(data.id);
        draft.loading = false;
      }),
  ),
  on(
    MitarbeiterActions.setSelectedMitarbeiterId,
    (state): State => ({
      ...state,
      mehrarbeitUmbuchungenPageData: null,
      mehrarbeitUmbuchungen: {},
      totalCount: undefined,
    }),
  ),
  on(
    MehrarbeitUmbuchungenActions.setPageIndex,
    (state, { pageIndex }): State => ({
      ...state,
      page: pageIndex,
      mehrarbeitUmbuchungenPageData: null,
    }),
  ),
  on(
    MehrarbeitUmbuchungenActions.setPageSize,
    (state, { pageSize }): State => ({
      ...state,
      pageSize,
    }),
  ),
  on(
    MehrarbeitUmbuchungenActions.setSelectedMonthAndYear,
    (state, { selectedMonth, selectedYear }): State => ({
      ...state,
      selectedMonth,
      selectedYear,
    }),
  ),
);

export const mehrarbeitUmbuchungenFeature = createFeature({
  name: mehrarbeitUmbuchungenFeatureKey,
  reducer: reducer,
});
