import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DlSortComponent } from './sort/sort-header';
import { DlSort } from './sort/sort.directive';

@NgModule({
  declarations: [DlSortComponent, DlSort],
  imports: [CommonModule],
  exports: [DlSortComponent, DlSort],
})
export class DlSortModule {}
