import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DlPageHeaderComponent } from './page-header/page-header.component';
import { DlPageHeaderTitleComponent } from './page-header/page-header-title/page-header-title.component';
import { DlPageHeaderUserComponent } from './page-header/page-header-user/page-header-user.component';
import { DlPageHeaderUserImageComponent } from './page-header/page-header-user-image/page-header-user-image.component';
import { DlPageHeaderDropdownComponent } from './page-header/page-header-dropdown/page-header-dropdown.component';
import { OverlayModule } from '@angular/cdk/overlay';
import { DlPageHeaderDropdownEntryComponent } from './page-header/page-header-dropdown/page-header-dropdown-entry/page-header-dropdown-entry.component';
import { RouterModule } from '@angular/router';
import { DlCardModule } from '@datenlotse/components/card';

@NgModule({
  declarations: [
    DlPageHeaderComponent,
    DlPageHeaderTitleComponent,
    DlPageHeaderUserComponent,
    DlPageHeaderUserImageComponent,
    DlPageHeaderDropdownComponent,
    DlPageHeaderDropdownEntryComponent,
  ],
  imports: [CommonModule, OverlayModule, RouterModule, DlCardModule],
  exports: [
    DlPageHeaderComponent,
    DlPageHeaderTitleComponent,
    DlPageHeaderUserComponent,
    DlPageHeaderUserImageComponent,
    DlPageHeaderDropdownEntryComponent,
  ],
})
export class DlPageHeaderModule {}
