import { PaginationHeader } from '../app/pagination-header';
import { HttpResponse } from '@angular/common/http';

export const extractPaginationHeader = (
  response: HttpResponse<unknown>
): PaginationHeader => {
  const paginationHeader = response.headers.get('X-Pagination');
  if (!paginationHeader) {
    console.error('No pagination header found');
    throw new Error('No pagination header found');
  }
  return JSON.parse(paginationHeader) as PaginationHeader;
};
