import {
  AfterContentInit,
  AfterViewChecked,
  Component,
  ContentChild,
  ContentChildren,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  OnDestroy,
  QueryList,
  ViewChild
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DlInput, scrollIntoView } from '@datenlotse/components/shared';
import { BehaviorSubject, Subscription, timer } from 'rxjs';
import { first } from 'rxjs/operators';
import { DlSelectOptionClickEvent } from './select-option-click-event';
import { DlSelectOptionComponent } from './select-option/select-option.component';
import { DlSelectPlaceholderComponent } from './select-placeholder/select-placeholder.component';

interface ValueOptionEntry {
  option: DlSelectOptionComponent;
  subscription: Subscription;
}

@Component({
  selector: 'dl-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => DlSelectComponent),
    },
  ],
})
export class DlSelectComponent
  extends DlInput
  implements
    AfterContentInit,
    OnDestroy,
    ControlValueAccessor,
    OnChanges,
    AfterViewChecked
{
  isOpen = new BehaviorSubject<boolean>(false);

  @ContentChildren(DlSelectOptionComponent) options:
    | QueryList<DlSelectOptionComponent>
    | undefined;
  selectedValue = new BehaviorSubject<unknown>(null);
  selectedText = new BehaviorSubject<string | undefined>(undefined);
  selectedElement: ElementRef | undefined;
  afterViewChecked = new EventEmitter<{ length: number }>();
  @ViewChild('placeholderContent') placeholderContent: unknown;
  container: ElementRef | undefined;
  @ViewChild('container') set containerSet(container: ElementRef) {
    this.container = container;
    if (this._selectedHTML && this.container) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      this.container.nativeElement.innerHTML = this._selectedHTML;
    }
  }
  @ViewChild('options') optionsContainer:
    | ElementRef<HTMLDivElement>
    | undefined;
  @ContentChild(DlSelectPlaceholderComponent) placeholder:
    | DlSelectPlaceholderComponent
    | undefined;
  @Input() disabled = false;
  private _valuesMap: ValueOptionEntry[] = [];
  private _selectedValueSub: Subscription | undefined;
  private _isTouched = false;
  private _selectedHTML: string | undefined;

  private _textSubscription?: Subscription;
  private _htmlSubscription?: Subscription;

  constructor() {
    super();
  }

  // Control value accessor functions
  // eslint-disable-next-line @typescript-eslint/ban-types, @typescript-eslint/no-empty-function
  _onChange: Function = () => {};

  // eslint-disable-next-line @typescript-eslint/ban-types, @typescript-eslint/no-empty-function
  _onTouch: Function = () => {};

  ngAfterViewChecked(): void {
    this.afterViewChecked.emit(this.options);
  }

  /**
   * Subscribes to all options click events and invokes the onOptionClick handler
   */
  ngAfterContentInit(): void {
    if (!this.options) {
      return;
    }

    this._optionChanged();

    this.options.changes.subscribe(() => {
      this._optionChanged();
    });
  }

  ngOnChanges(): void {
    this._positionLabel();
  }

  /**
   * Cleans up any subscrptions
   */
  ngOnDestroy(): void {
    // Unsub from option clicks
    for (const map of this._valuesMap) {
      map.subscription?.unsubscribe();
    }
    this._selectedValueSub?.unsubscribe();
  }

  /**
   * Setter for setting the disabled state
   * This is used by angular reactive forms
   */
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  /**
   * Handles opening and closing of the options
   * On first open it sets touched and invokes the callback
   */
  toggleOpen(): void {
    if (this.disabled) {
      console.log('DL Select: Select is disabled...');
      return;
    }
    if (!this._isTouched) {
      this._isTouched = true;
      this._onTouch();
    }
    const currentStatus = this.isOpen.getValue();
    this.isOpen.next(!currentStatus);
    if (currentStatus === false) {
      timer(400)
        .pipe(first())
        .subscribe(() => {
          this.scrollActiveOptionIntoView();
        });
    }
  }

  scrollActiveOptionIntoView(): void {
    if (this.selectedElement && this.optionsContainer) {
      scrollIntoView(
        this.optionsContainer.nativeElement,
        this.selectedElement.nativeElement
      );
    }
  }

  /**
   * Handles a click on a option
   * @param value value
   */
  onOptionClick<T>(event: DlSelectOptionClickEvent<T>): void {
    this._setValue(event.value);
    this.selectedElement = event.elementRef;
    // Close menu again
    this.isOpen.next(false);
    this._onChange(this.selectedValue.value);
  }

  /**
   * Used for angular forms to write a value to the select
   * @param value value to be set. Needs to be a valid option.
   */
  writeValue<T>(value: T): void {
    this._setValue(value);
  }

  /**
   * Registers the onChange Callback. That is called, when the value changes.
   * @param fn Callback
   */
  registerOnChange(fn: (_: unknown) => void): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: (_: unknown) => void): void {
    this._onTouch = fn;
  }

  private _optionChanged() {
    // Subscribe to options click events
    const options = this.options?.toArray();

    if (!options) {
      return;
    }

    // Check if options got removed
    for (let i = 0; i < this._valuesMap.length; i++) {
      const mapEntry = this._valuesMap[i];
      // Check if mapEntry does not exists in options array
      if (!options.find((option) => option === mapEntry.option)) {
        // Does not exists
        // Unsubscribe from click event and remove from map
        mapEntry.subscription?.unsubscribe();
        this._valuesMap.splice(i, 1);
      }
    }

    // Check if there are new options added
    for (const option of options) {
      // Check if it is not in the map already
      if (!this._valuesMap.find((map) => map.option === option)) {
        // If its not in the map add it to the map
        // Also subscribe to the click event
        this._valuesMap.push({
          option: option,
          subscription: option.clicked.subscribe(this.onOptionClick.bind(this)),
        });
      }
    }

    // Sub to selectedValue Changes
    this._selectedValueSub = this.selectedValue.subscribe(
      this._positionLabel.bind(this)
    );

    // Position the label
    this._positionLabel();

    // Set the selected value
    this._setValue(this.selectedValue.getValue());
  }

  /**
   * Determine where the label should be positioned
   */
  private _positionLabel(): void {
    if (this.placeholder || this.selectedValue.getValue()) {
      this.labelUp.next(true);
    } else {
      this.labelUp.next(false);
    }
  }

  /**
   * Sets a value programaticaly.
   * @param value value to be set
   */
  private _setValue<T>(value: T): void {
    if (!this.options) {
      this.selectedValue.next(value);
      return;
    }

    // Get the correct option component that matches the value
    // eslint-disable-next-line eqeqeq
    const optionComponent = this.options.find(
      (option) => option.value == value
    );

    // If no option component can be found, set the value to null
    if (!optionComponent) {
      // throw new Error('No option with the provided value was found');
      this.selectedValue.next(undefined);
      this.selectedText.next(undefined);
      return;
    }

    this._htmlSubscription?.unsubscribe();
    this._textSubscription?.unsubscribe();

    // Get the selected components
    const selectedComponents = this.options.filter(
      (option) => option.isSelected
    );

    // Set the selected componets to not selected
    for (const selectedComponent of selectedComponents) {
      selectedComponent.isSelected = false;
    }

    // Set the newly selected component as selected and set the selectedText and selectedValue
    this.selectedValue.next(value);
    optionComponent.isSelected = true;

    this._textSubscription = optionComponent.selectedText.subscribe((text) => {
      this.selectedText.next(text);
    });

    this._htmlSubscription = optionComponent.selectedHtml.subscribe((html) => {
      this._selectedHTML = html;
      if (!this.container) {
        return;
      }
      this.container.nativeElement.innerHTML = html;
    });
  }
}
