import { createFeature, createReducer, on } from "@ngrx/store";
import { BuchungsaenderungsAntragDto, BuchungserstellAntragDto } from "../../api/models";
import { BuchungskorrekturenFuerMonteureActions } from "./buchungskorrektur-monteure.actions";

export const buchungskorrekturMonteureFeatureKey = 'buchungskorrekturMonteure';

export interface State {
  buchungsAenderungsAntraege: BuchungsaenderungsAntragDto[];
  buchungsErstellAntraege: BuchungserstellAntragDto[];
  page: number;
  pageSize: number;
  totalCount: number | undefined;
}

export const initialState: State = {
  buchungsAenderungsAntraege: [],
  buchungsErstellAntraege: [],
  page: 0,
  pageSize: 15,
  totalCount: undefined,
};

export const reducer = createReducer(
  initialState,
  on(
    BuchungskorrekturenFuerMonteureActions.loadBuchungskorrekturenSuccess,
    (state, { aenderungsAntraege, erstellAntraege, totalCount }): State => ({
      ...state,
      buchungsAenderungsAntraege: aenderungsAntraege,
      buchungsErstellAntraege: erstellAntraege,
      totalCount,
    })
  ),
  );

export const buchungskorrekturMonteureFeature = createFeature({
  name: buchungskorrekturMonteureFeatureKey,
  reducer: reducer,
});