/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-empty-function */
import {
  Component,
  forwardRef,
  Input,
  OnChanges,
  OnDestroy,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DlInput } from '@datenlotse/components/shared';

@Component({
  selector: 'dl-textarea',
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => DlTextareaComponent),
    },
  ],
})
export class DlTextareaComponent
  extends DlInput
  implements OnChanges, ControlValueAccessor, OnDestroy
{
  @Input() text = '';
  @Input() disabled: string | boolean = false;
  @Input() placeholder: string | undefined;
  @Input() name: string | undefined;
  @Input() maxlength?: number;
  @Input() rows?: number;
  @Input() cols?: number;
  hadFocus = false;
  touched = false;

  private _focusSub = this.hasFocus.subscribe((hasFocus) => {
    this.setLabelPosition();
    if (hasFocus) {
      this.hadFocus = true;
    }
    if (!hasFocus && this.hadFocus && !this.touched) {
      this.touched = true;
      this._onTouch();
    }
  });

  // Control value accessor functions
  _onChange: Function = () => {};
  _onTouch: Function = () => {};

  constructor() {
    super();
  }

  ngOnChanges(): void {
    this.setLabelPosition();
  }

  ngOnDestroy(): void {
    this._focusSub.unsubscribe();
  }

  onInputChange(value: string) {
    this.text = value;
    this._onChange(this.text);
  }

  setLabelPosition(): void {
    let shouldUp = false;
    if (this.text?.length > 0 || this.placeholder || this.hasFocus.getValue()) {
      shouldUp = true;
    }
    this.labelUp.next(shouldUp);
  }

  writeValue(text: string): void {
    this.text = text;
  }

  registerOnChange(fn: (_: unknown) => void): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: (_: unknown) => void): void {
    this._onTouch = fn;
  }
}
