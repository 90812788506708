import { createFeature, createReducer, on } from '@ngrx/store';
import { produce } from 'immer';
import { TagesplanDto } from '../../api/models';
import * as TagesplaeneActions from './tagesplaene.actions';

export const tagesplaeneFeatureKey = 'tagesplaene';

export interface State {
  activeTagesplaene: TagesplanDto[];
  inactiveTagesplaene: TagesplanDto[];
  loading: boolean;
}

export const initialState: State = {
  activeTagesplaene: [],
  inactiveTagesplaene: [],
  loading: false,
};

function moveTagesplanFromActiveToInactive(
  activeTagesplaene: State['activeTagesplaene'],
  inactiveTagesplaene: State['inactiveTagesplaene'],
  id: string
) {
  const index = activeTagesplaene.findIndex((v) => v.id === id);
  if (index >= 0) {
    inactiveTagesplaene.push(...activeTagesplaene.splice(index, 1));
  }
}
function addTagesplanToActiveTagesplaeneAfterCreate(
  activeTagesplaene: State['activeTagesplaene'],
  tagesplan: TagesplanDto
) {
  activeTagesplaene.push(tagesplan);
}

export const reducer = createReducer(
  initialState,

  on(
    TagesplaeneActions.loadActiveTagesplaene,
    (state): State => ({
      ...state,
      loading: true,
    })
  ),
  on(
    TagesplaeneActions.loadInactiveTagesplaene,
    (state): State => ({
      ...state,
      loading: true,
    })
  ),

  on(
    TagesplaeneActions.loadActiveTagesplaeneSuccess,
    (state, { activeTagesplaene }): State => ({
      ...state,
      activeTagesplaene,
      loading: false,
    })
  ),
  on(
    TagesplaeneActions.loadInactiveTagesplaeneSuccess,
    (state, { inactiveTagesplaene }): State => ({
      ...state,
      inactiveTagesplaene,
      loading: false,
    })
  ),

  on(
    TagesplaeneActions.loadActiveTagesplaeneFailure,
    (state): State => ({
      ...state,
      loading: false,
    })
  ),

  on(
    TagesplaeneActions.createTagesplan,
    (state): State => ({
      ...state,
      loading: true,
    })
  ),

  on(
    TagesplaeneActions.createTagesplanFailure,
    (state): State => ({
      ...state,
      loading: false,
    })
  ),

  on(TagesplaeneActions.createTagesplanSuccess, (state, { tagesplan }) =>
    produce(state, (draft) => {
      addTagesplanToActiveTagesplaeneAfterCreate(
        draft.activeTagesplaene,
        tagesplan
      );
      draft.loading = false;
    })
  ),

  on(
    TagesplaeneActions.setInactiveTagesplan,
    (state): State => ({
      ...state,
      loading: true,
    })
  ),

  on(
    TagesplaeneActions.setInactiveTagesplanFailure,
    (state): State => ({
      ...state,
      loading: false,
    })
  ),

  on(TagesplaeneActions.setInactiveTagesplanSuccess, (state, { id }) =>
    produce(state, (draft) => {
      moveTagesplanFromActiveToInactive(
        draft.activeTagesplaene,
        draft.inactiveTagesplaene,
        id
      );
      draft.loading = false;
    })
  )
);

export const tagesplaeneFeature = createFeature({
  name: tagesplaeneFeatureKey,
  reducer: reducer,
});
