<div class="input-field">
  <div class="current-value" (click)="toggleOpen()">

    <!-- Placeholder only visible when nothing selected -->
    <ng-content select="dl-select-placeholder" *ngIf="(selectedValue| async) === undefined" #placeholderContent></ng-content>

    <!-- Container for selected item template -->
    <div #container class="option-color" [class.option-color-disabled]="disabled" [hidden]="(selectedValue | async) === undefined"></div>

    <!-- Open / Close Chevron -->
    <img [hidden]="disabled" src="assets/icons/chevron-down.svg" alt="Open" [ngClass]="{'chevron-invert': (isOpen | async)}" class="open-chevron">

  </div>
  <div class="options" [ngClass]="{'options-visible': (isOpen | async)}" #options>
    <ng-content></ng-content>
  </div>
</div>
