import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import {
  AfterContentInit,
  ChangeDetectorRef,
  Component,
  Directive,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  Renderer2,
  ViewChild,
  ViewContainerRef,
  forwardRef,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { DlAutocompleteComponent } from '@datenlotse/components/autocomplete';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { DlInput } from '@datenlotse/components/shared';
import { timer } from 'rxjs';
import { first } from 'rxjs/operators';

@Directive()
export abstract class DlBaseInput
  extends DlInput
  implements OnChanges, OnDestroy, ControlValueAccessor
{
  @Input() type = 'text';
  @Input() auto?: DlAutocompleteComponent;
  @Input() disabled = false;
  @Input() placeholder: string | undefined;
  @Input() autocomplete: string | undefined;
  @Input() name: string | undefined;
  @Input() min: string | undefined;
  @Input() max: string | undefined;
  @Input() maxlength?: number;
  @Input() step?: string;
  @Input() 'readOnly'?: boolean;
  inputText: string | number = '';
  hadFocus = false;
  touched = false;

  @ViewChild('input', { static: true }) input:
    | ElementRef<HTMLInputElement>
    | undefined;

  protected _focusSub = this.hasFocus.subscribe((hasFocus) => {
    if (hasFocus) {
      this.hadFocus = true;
    }

    if (!hasFocus && this.hadFocus && !this.touched) {
      this.touched = true;
      this._onTouch();
    }
    this.setLabelPosition();
  });

  constructor(private _renderer: Renderer2) {
    super();
  }

  // Control value accessor functions

  // eslint-disable-next-line @typescript-eslint/no-empty-function,@typescript-eslint/no-explicit-any
  _onChange: any = () => {};
  // eslint-disable-next-line @typescript-eslint/no-empty-function,@typescript-eslint/no-explicit-any
  _onTouch: any = () => {};

  ngOnChanges(): void {
    this.setLabelPosition();
  }

  ngOnDestroy(): void {
    this._focusSub.unsubscribe();
  }

  onInputChange(value: string) {
    this.inputText = value;
    // If its a number type. Parse the number
    if (this.type === 'number') {
      this.inputText = Number(value);
    }
    if (this.auto) {
      this.auto.filterOptions(value);
    }
    this._onChange(this.inputText);
  }

  setLabelPosition(): void {
    let shouldUp = false;

    /**
     * If the input has focus
     * or the input has a value and the value is not empty
     * or the input has a placeholder
     * the label should be above the input
     * else it should be below the input
     */
    if (
      this.hasFocus.getValue() ||
      (this.inputText &&
        this.inputText !== '' &&
        this.inputText.toString().length > 0) ||
      this.placeholder ||
      this.type === 'time'
    ) {
      shouldUp = true;
    }

    this.labelUp.next(shouldUp);
  }

  /**
   * Registers the onChange Callback. That is called, when the value changes.
   * @param fn Callback
   */
  registerOnChange(fn: (_: unknown) => void): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: (_: unknown) => void): void {
    this._onTouch = fn;
  }

  /**
   * Used for angular forms to write a value to the select
   * @param value value to be set. Needs to be a valid option.
   */
  writeValue(value: string | number | Record<string, unknown> | null): void {
    if (value === undefined || value === null) {
      this.inputText = '';
      return;
    }

    if (typeof value !== 'object') {
      if (this.auto?.displayWith) {
        this.inputText = this.auto.displayWith(value);
      } else {
        this.inputText = value;
      }
    } else if (typeof value === 'object') {
      /** Must come from autocomplete, invoke the displayWith method to get the inputText */
      if (this.auto?.displayWith) {
        this.inputText = this.auto.displayWith(value);
      } else {
        this.inputText = JSON.stringify(value);
        console.error('No displayWith method found for autocomplete');
      }
      this._onChange(value);
    }

    if (this.auto) {
      if (!value) {
        value = '';
      }
      this.auto.filterOptions(value.toString());
    }

    this.setLabelPosition();
  }

  setDisabledState(isDisabled: boolean): void {
    this._renderer.setProperty(
      this.input?.nativeElement,
      'disabled',
      isDisabled,
    );
  }
}

@Component({
  selector: 'dl-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => DlInputComponent),
    },
  ],
})
export class DlInputComponent extends DlBaseInput implements AfterContentInit {
  private _overlayRef?: OverlayRef;
  private _templatePortal?: TemplatePortal<never>;
  protected override _focusSub = this.hasFocus.subscribe((hasFocus) => {
    this.setLabelPosition();
    if (hasFocus) {
      if (this.input) {
        const rect = this.input.nativeElement.getBoundingClientRect();
        const width = rect.width;
        this._overlayRef?.updateSize({ width });
      }

      this.hadFocus = true;
      // Show autocomplete
      if (this.auto && this.input) {
        this._overlayRef?.attach(this._templatePortal);
        this.changeDetector.detectChanges();
      }
    } else {
      // Hide Autocomplete
      if (this._overlayRef) {
        timer(200)
          .pipe(first())
          .subscribe(() => {
            this._overlayRef?.detach();
          });
      }
    }

    if (!hasFocus && this.hadFocus && !this.touched) {
      this.touched = true;
      this._onTouch();
    }
  });

  constructor(
    renderer: Renderer2,
    private overlay: Overlay,
    private viewContainerRef: ViewContainerRef,
    private changeDetector: ChangeDetectorRef,
  ) {
    super(renderer);
  }

  ngAfterContentInit() {
    if (this.input) {
      this._overlayRef = this.overlay.create({
        positionStrategy: this.overlay
          .position()
          .flexibleConnectedTo(this.input)
          .withPositions([
            {
              originX: 'start',
              originY: 'bottom',
              overlayX: 'start',
              overlayY: 'top',
            },
          ])
          .withDefaultOffsetY(3),
      });
      const rect = this.input.nativeElement.getBoundingClientRect();
      const width = rect.width;
      this._overlayRef?.updateSize({ width });
    }

    if (this.auto && this.auto.template) {
      this._templatePortal = new TemplatePortal(
        this.auto.template,
        this.viewContainerRef,
      );

      // Subscribe to select event on autocomplete

      this.auto.onAutocompleteSelected.subscribe((value) => {
        if (this.auto && typeof value === 'object') {
          this.writeValue(value as Record<string, unknown>);
        } else if (typeof value === 'string' || typeof value === 'number') {
          this.writeValue(value);
        }
        this._overlayRef?.detach();
      });
    }
  }
}
