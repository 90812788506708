import { createFeature, createReducer, on } from '@ngrx/store';
import { produce } from 'immer';
import { MonatsauswertungLayoutDto } from '../../api/models/monatsauswertung-layout-dto';
import { MonatsauswertungSpalteDto } from '../../api/models/monatsauswertung-spalte-dto';
import { AuswertungLayoutsActions } from './auswertung-layouts.actions';

export const auswertungLayoutsFeatureKey = 'auswertungLayouts';

export interface State {
  monatsauswertungLayouts: MonatsauswertungLayoutDto[] | undefined;
  monatsauswertungSpalten: MonatsauswertungSpalteDto[] | undefined;
}

export const initialState: State = {
  monatsauswertungLayouts: undefined,
  monatsauswertungSpalten: undefined,
};

export const reducer = createReducer(
  initialState,
  on(
    AuswertungLayoutsActions.loadMonatsauswertungLayoutsSuccess,
    (state, { monatsauswertungLayouts }): State => ({
      ...state,
      monatsauswertungLayouts: monatsauswertungLayouts,
    })
  ),
  on(
    AuswertungLayoutsActions.loadMonatsauswertungLayoutsFailure,
    AuswertungLayoutsActions.loadMonatsauswertungLayouts,
    (state): State => ({
      ...state,
      monatsauswertungLayouts: undefined,
    })
  ),
  on(
    AuswertungLayoutsActions.createMonatsauswertungLayoutSuccess,
    (state, { monatsauswertungLayout }): State => ({
      ...state,
      monatsauswertungLayouts: [
        ...(state.monatsauswertungLayouts ?? []),
        monatsauswertungLayout,
      ],
    })
  ),
  on(
    AuswertungLayoutsActions.loadMonatsauswertungSpaltenSuccess,
    (state, { monatsauswertungSpalten }): State => ({
      ...state,
      monatsauswertungSpalten: monatsauswertungSpalten,
    })
  ),
  on(
    AuswertungLayoutsActions.loadMonatsauswertungSpaltenFailure,
    AuswertungLayoutsActions.loadMonatsauswertungSpalten,
    (state): State => ({
      ...state,
      monatsauswertungSpalten: undefined,
    })
  ),
  on(
    AuswertungLayoutsActions.updateMonatsauswertungLayoutSuccess,
    (state, { monatsauswertungLayout }) =>
      produce(state, (draft) => {
        const layouts = draft.monatsauswertungLayouts;
        if (layouts) {
          const index = layouts.findIndex(
            (l) => l.id === monatsauswertungLayout.id
          );
          if (index >= 0) {
            layouts[index] = monatsauswertungLayout;
          }
        }
      })
  )
);

export const auswertungLayoutsFeature = createFeature({
  name: auswertungLayoutsFeatureKey,
  reducer: reducer,
});
