<div class="dl-sidebar" [ngClass]="{expanded: sidebarService.sidebarOpen | async}">

  <dl-sidebar-open-button dlBrightButton>
    <dl-sidebar-button-image src="assets/icons/menu.svg" dlBrightImage></dl-sidebar-button-image>
    <dl-sidebar-button-text dlBrightText class="title-text">{{title}}</dl-sidebar-button-text>
  </dl-sidebar-open-button>

  <div class="dl-sidebar-content">
    <ng-content select="[slot=start]"></ng-content>
  </div>
  <div class="dl-sidebar-content">
    <ng-content></ng-content>
  </div>
  <div class="dl-sidebar-content-end dl-sidebar-content">
    <ng-content select="[slot=end]"></ng-content>
  </div>

</div>
<div class="overlay" [ngClass]="{visible: sidebarService.sidebarOpen | async}" (click)="sidebarService.closeSidebar()">
  
</div>