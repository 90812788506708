import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromUrlaubsantraege from './urlaubsantraege.reducer';

export const selectUrlaubsantraegeState =
  createFeatureSelector<fromUrlaubsantraege.State>(
    fromUrlaubsantraege.urlaubsantraegeFeatureKey
  );

export const selectUrlaubsantraegeTotalCount = createSelector(
  selectUrlaubsantraegeState,
  (state) => state.totalCount
);

export const selectUrlaubsantraege = createSelector(
  selectUrlaubsantraegeState,
  (state) => state.urlaubsantraege
);

export const selectUrlaubsantraegePaginationData = createSelector(
  selectUrlaubsantraegeState,
  selectUrlaubsantraegeTotalCount,
  (state, totalCount) => ({ ...state.paginationData, totalCount })
);

const selectUrlaubsantraegePageDataIds = createSelector(
  selectUrlaubsantraegeState,
  (state) => state.pageData
);

export const selectUrlaubsantraegePageData = createSelector(
  selectUrlaubsantraege,
  selectUrlaubsantraegePageDataIds,
  (urlaubsantraege, pageData) =>
    pageData?.map((id) => urlaubsantraege[id]) ?? []
);

export const selectUrlaubsantraegeIncludeProcessed = createSelector(
  selectUrlaubsantraegeState,
  (state) => state.includeProcessed
);

export const selectUrlaubsantraegeTotalCountUnit = createSelector(
  selectUrlaubsantraegeState,
  (state) => state.totalCountUnit
);

export const selectUrlaubsantraegePaginationDataUnit = createSelector(
  selectUrlaubsantraegeState,
  selectUrlaubsantraegeTotalCountUnit,
  (state, totalCount) => ({ ...state.paginationDataUnit, totalCount })
);

const selectUrlaubsantraegePageDataUnitIds = createSelector(
  selectUrlaubsantraegeState,
  (state) => state.pageDataUnit
);

export const selectUrlaubsantraegePageDataUnit = createSelector(
  selectUrlaubsantraege,
  selectUrlaubsantraegePageDataUnitIds,
  (urlaubsantraege, pageDataUnit) =>
    pageDataUnit?.map((id) => urlaubsantraege[id]) ?? []
);

export const selectUrlaubsantraegeTotalCountMitarbeiter = createSelector(
  selectUrlaubsantraegeState,
  (state) => state.totalCountMitarbeiter
);

export const selectUrlaubsantraegePaginationDataMitarbeiter = createSelector(
  selectUrlaubsantraegeState,
  selectUrlaubsantraegeTotalCountMitarbeiter,
  (state, totalCount) => ({ ...state.paginationDataMitarbeiter, totalCount })
);

const selectUrlaubsantraegePageDataMitarbeiterIds = createSelector(
  selectUrlaubsantraegeState,
  (state) => state.pageDataMitarbeiter
);

export const selectUrlaubsantraegePageDataMitarbeiter = createSelector(
  selectUrlaubsantraege,
  selectUrlaubsantraegePageDataMitarbeiterIds,
  (urlaubsantraege, pageDataMitarbeiter) =>
    pageDataMitarbeiter?.map((id) => urlaubsantraege[id]) ?? []
);
