import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { loggedInGuard, routeScopeGuard } from './guards';
import { MonatsauswertungLayoutsPage } from './monatsauswertungen-layouts/monatsauswertung-layouts-page.component';
import { Mode as MonatslayoutsMode } from './monatsauswertungen-layouts/monatsauswertung-layouts-page.store';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () =>
      import('./home/home-routing.module').then((m) => m.HomePageRoutingModule),
    canActivate: [loggedInGuard],
  },
  {
    path: 'buchungs-aenderungen',
    loadChildren: () =>
      import('./buchungs-änderungen/buchungs-änderungen-routing.module').then(
        (m) => m.BuchungsAenderungenPageRoutingModule
      ),
    canActivate: [loggedInGuard],
  },
  {
    path: 'buchungs-erstell-antraege',
    loadChildren: () =>
      import(
        './buchungs-erstell-antraege/buchungs-erstell-antraege-routing.module'
      ).then((m) => m.BuchungsErstellAntraegePageRoutingModule),
    canActivate: [loggedInGuard],
  },
  {
    path: 'auswertungen',
    loadChildren: () =>
      import('./mitarbeiter-liste/mitarbeiter-liste-routing.module').then(
        (m) => m.MitarbeiterListePageRoutingModule
      ),
    canActivate: [loggedInGuard, routeScopeGuard],
  },
  {
    path: 'umbuchungen',
    loadComponent: () =>
      import('./umbuchungen/umbuchungen.component').then(
        (c) => c.UmbuchungenComponent
      ),
    canActivate: [loggedInGuard, routeScopeGuard],
  },
  {
    path: 'kalender',
    loadChildren: () =>
      import('./zuordnung/zuordnung-routing.module').then(
        (m) => m.ZuordnungPageRoutingModule
      ),
    canActivate: [loggedInGuard, routeScopeGuard],
  },
  {
    path: 'buchungen',
    loadChildren: () =>
      import('./buchungen/buchungen-routing.module').then(
        (m) => m.BuchungenPageRoutingModule
      ),
    canActivate: [loggedInGuard, routeScopeGuard],
  },
  {
    path: 'wochenplaene',
    loadChildren: () =>
      import('./wochenplaene/wochenplaene-routing.module').then(
        (m) => m.WochenplaenePageRoutingModule
      ),
    canActivate: [loggedInGuard, routeScopeGuard],
  },
  {
    path: 'tagesplaene',
    loadChildren: () =>
      import('./tagesplaene/tagesplaene-routing.module').then(
        (m) => m.TagesplaenePageRoutingModule
      ),
    canActivate: [loggedInGuard, routeScopeGuard],
  },
  {
    path: 'personal-stammdaten',
    loadChildren: () =>
      import('./personal-stammdaten/personal-stammdaten-routing.module').then(
        (m) => m.PersonalStammdatenPageRoutingModule
      ),
    canActivate: [loggedInGuard, routeScopeGuard],
  },
  {
    path: 'cb',
    loadChildren: () =>
      import('./callback/callback-routing.module').then(
        (m) => m.CallbackPageRoutingModule
      ),
  },
  {
    path: 'no-access',
    loadChildren: () =>
      import('./no-access/no-access-routing.module').then(
        (m) => m.NoAccessPageRoutingModule
      ),
    canActivate: [loggedInGuard],
  },
  {
    path: 'page-not-found',
    loadChildren: () =>
      import('./page-not-found/page-not-found-routing.module').then(
        (m) => m.PageNotFoundPageRoutingModule
      ),
    canActivate: [loggedInGuard],
  },

  {
    path: 'layouts',
    component: MonatsauswertungLayoutsPage,
    children: [
      {
        path: '',
        pathMatch: 'prefix',
        data: { mode: 'list' as MonatslayoutsMode },
        component: MonatsauswertungLayoutsPage,
      },
      {
        path: 'create',
        pathMatch: 'prefix',
        data: { mode: 'create' as MonatslayoutsMode },
        component: MonatsauswertungLayoutsPage,
      },
      {
        path: ':id',
        pathMatch: 'prefix',
        data: { mode: 'edit' as MonatslayoutsMode },
        component: MonatsauswertungLayoutsPage,
      },
    ],
    canActivate: [loggedInGuard, routeScopeGuard],
  },
  {
    path: 'urlaubsantraege',
    loadComponent: () =>
      import('./urlaubsantraege/urlaubsantraege.component').then(
        (c) => c.UrlaubsantraegeComponent
      ),
    canActivate: [loggedInGuard, routeScopeGuard],
  },
  {
    path: 'buchungskorrekturen-monteure',
    loadComponent: () =>
      import('./buchungskorrekturen-monteure/buchungskorrekturen-monteure.component').then(
        (c) => c.BuchungskorrekturenMonteureComponent
      ),
    canActivate: [loggedInGuard, routeScopeGuard],
  },
  {
    path: 'zutrittskontrolle',
    loadComponent: () =>
      import('./zutrittskontrolle/zutrittskontrolle.component').then(
        (c) => c.ZutrittskontrolleComponent
      ),
    canActivate: [loggedInGuard, routeScopeGuard],
  },
  {
    path: 'fehlerliste',
    loadComponent: () =>
      import('./fehlerliste/fehlerliste.component').then(
        (c) => c.FehlerlisteComponent
      ),
    canActivate: [loggedInGuard, routeScopeGuard],
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  {
    path: '**',
    redirectTo: 'page-not-found',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
