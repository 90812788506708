import * as equal from 'fast-deep-equal';
import {
  combineLatest,
  debounceTime,
  map,
  Observable,
  shareReplay,
} from 'rxjs';

export function dirtyCheck<U>(source: Observable<U>, dueTime = 100) {
  return function <T>(valueChanges: Observable<T>): Observable<boolean> {
    return combineLatest([source, valueChanges]).pipe(
      debounceTime(dueTime),
      map(([a, b]) => !equal(a, b)),
      shareReplay({ bufferSize: 1, refCount: true })
    );
  };
}
