import { createFeatureSelector, createSelector } from '@ngrx/store';
import { BuchungDto } from '../../api/models/buchung-dto';
import * as fromBuchungen from './buchungen.reducer';

export const selectBuchungenState = createFeatureSelector<fromBuchungen.State>(
  fromBuchungen.buchungenFeatureKey,
);

export const selectBuchungen = createSelector(
  selectBuchungenState,
  (state) => state.buchungen,
);

export const selectBuchungenArray = createSelector(selectBuchungen, (state) =>
  Array.from(state.values()),
);

export const selectNonDeletedBuchungen = createSelector(
  selectBuchungenArray,
  (buchungen) => buchungen.filter((buchung) => !buchung.isDeleted),
);

export const selectTotalCount = createSelector(
  selectBuchungenState,
  (state) => state.totalCount ?? 0,
);

export const selectBuchungenPage = createSelector(
  selectBuchungenState,
  (state) => {
    if (!state.buchungenPage) {
      return undefined;
    }
    return state.buchungenPage
      .map((id) => state.buchungen.get(id) as BuchungDto)
      .filter((buchung) => !!buchung);
  },
);

export type BuchungenDtoGroupedByDate = {
  buchungen: BuchungDto[];
  datum: Date;
  totalDuration: number;
};

export type BuchungenDtoGroupedByDateMap = {
  [datum: string]: BuchungenDtoGroupedByDate;
};

export const selectBuchungenGroupedByDatum = createSelector(
  selectBuchungenPage,
  (buchungen) => {
    if (!buchungen) {
      return undefined;
    }
    return buchungen.reduce((acc, buchung) => {
      const datum = new Date(buchung.start);
      const key = `${datum.getFullYear()}-${
        datum.getMonth() + 1
      }-${datum.getDate()}`;
      if (!acc[key]) {
        acc[key] = {
          buchungen: [buchung],
          datum: datum,
          totalDuration: buchung.dauerInSekunden || 0,
        };
      } else {
        acc[key].buchungen.push(buchung);
        acc[key].totalDuration += buchung.dauerInSekunden || 0;
      }
      return acc;
    }, {} as BuchungenDtoGroupedByDateMap);
  },
);

export const selectBuchungenDesMonats = (monat: number, jahr: number) =>
  createSelector(selectBuchungenPage, (buchungen) => {
    if (!buchungen) {
      return undefined;
    }

    // Erstelle ein Date-Objekt für den ersten Tag des ausgewählten Monats und Jahres
    const firstDayOfMonth = new Date(jahr, monat, 1);

    // Erstelle ein Date-Objekt für den ersten Tag des nächsten Monats
    const firstDayOfNextMonth = new Date(jahr, monat + 1, 1);

    // Initialisiere ein leeres Objekt, um die gruppierten Buchungen zu speichern
    const buchungenDesMonatsMap: BuchungenDtoGroupedByDateMap = {};

    // Durchlaufe alle Tage des ausgewählten Monats
    for (
      let date = new Date(firstDayOfMonth);
      date < firstDayOfNextMonth;
      date.setDate(date.getDate() + 1)
    ) {
      const key = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;

      // Initialisiere einen leeren Eintrag für den aktuellen Tag
      buchungenDesMonatsMap[key] = {
        buchungen: [],
        datum: new Date(date),
        totalDuration: 0,
      };
    }

    // Füge Buchungen für die vorhandenen Tage hinzu
    buchungen.forEach((buchung) => {
      const buchungsMonat = new Date(buchung.start).getMonth();
      const buchungsJahr = new Date(buchung.start).getFullYear();
      if (buchungsMonat === monat && buchungsJahr === jahr) {
        const datum = new Date(buchung.start);
        const key = `${datum.getFullYear()}-${datum.getMonth() + 1}-${datum.getDate()}`;
        buchungenDesMonatsMap[key].buchungen.push(buchung);
        buchungenDesMonatsMap[key].totalDuration +=
          buchung.dauerInSekunden || 0;
      }
    });

    // Sortiere die Einträge nach dem Datum
    const sortedEntries = Object.values(buchungenDesMonatsMap).sort(
      (a, b) => a.datum.getTime() - b.datum.getTime(),
    );

    // Sortiere die Buchungen innerhalb der Einträge nach dem Startzeitpunkt
    sortedEntries.forEach((entry) => {
      entry.buchungen.sort((a, b) => {
        const aStart = new Date(a.start);
        const bStart = new Date(b.start);
        return aStart.getTime() - bStart.getTime();
      });
    });

    return sortedEntries;
  });

export const selectPage = createSelector(
  selectBuchungenState,
  (state) => state.page,
);

export const selectPageSize = createSelector(
  selectBuchungenState,
  (state) => state.pageSize,
);

export const selectLoading = createSelector(
  selectBuchungenState,
  (state) => !state.buchungen,
);

export const selectSelectedMonth = createSelector(
  selectBuchungenState,
  (state) => state.selectedMonth ?? new Date().getMonth() + 1,
);

export const selectSelectedYear = createSelector(
  selectBuchungenState,
  (state) => state.selectedYear ?? new Date().getFullYear(),
);

export const selectIncludeDeleted = createSelector(
  selectBuchungenState,
  (state) => state.includeDeleted,
);
