import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import {
  EventEmitter,
  Inject,
  Injectable,
  InjectionToken,
} from '@angular/core';
import * as CryptoJS from 'crypto-es';
import { jwtDecode } from 'jwt-decode';
import { BehaviorSubject, Observable, lastValueFrom, throwError } from 'rxjs';
import { catchError, map, retry } from 'rxjs/operators';
import { IAccessTokenContent } from './shared/auth/accessTokenContent.interface';
import { IExchangeTokenRequest } from './shared/auth/exchangeTokenRequest.interface';
import { IRefreshTokenRequest } from './shared/auth/refreshTokenRequest.inteface';
import { ITokenPairResponse } from './shared/auth/tokenPairResponse.interface';

export interface IAuthModuleConfig {
  loginUrl: string;
  apiUrl: string;
  clientId: string;
  redirectUrl: string;
  debugToken?: string;
}

export const AuthConfigToken = new InjectionToken<IAuthModuleConfig>(
  'AuthServiceConfig'
);

// @dynamic
@Injectable({
  providedIn: 'root',
})
export class DatenlotseAngularAuthService {
  /**
   * Emits every time on a new access token set with the userID (sub) or undefined if the access token contents are empty.
   */
  public onLogin = new EventEmitter<string | undefined>();
  /**
   * True if the user is logged in
   * False if the user is not logged in
   * null if the user is not logged in and a login process is ongoing
   * @returns {Observable<boolean>} Observable that emits true if the user is logged in, false if the user is not logged in, null if the user is not logged in and a login process is ongoing
   */
  public $isLoggedIn = new BehaviorSubject<boolean | null>(null);
  private supportedLanguages = ['en', 'de'];
  private _codeChallenge: undefined | string = undefined;
  private _codeVerifier: undefined | string = undefined;
  private _state: undefined | string = undefined;
  private _expiresAt: Date | undefined;

  private _accessTokenContent: IAccessTokenContent | undefined = undefined;
  private obtainingAccessToken: Observable<ITokenPairResponse> | null = null;

  constructor(
    @Inject(AuthConfigToken) private _config: IAuthModuleConfig,
    private _http: HttpClient
  ) {
    if (_config.debugToken) {
      this._accessToken = _config.debugToken;
    }
    // Clear the session storage on init
    sessionStorage.clear();
    if (this._checkIfValidTokenPresent()) {
      // Valid token found in local storage, load from local storage
      this._loadFromLocalStorage();
      if (this.accessTokenIsExpired) {
        console.log('Access token is expired');
        lastValueFrom(this.getNewAccessToken()).catch(() => {
          console.log('Not refreshed');
          this.$isLoggedIn.next(false);
        });
      } else {
        if (this._accessToken) {
          this._decodeJwt(this._accessToken);
          this.onLogin.emit(this._accessTokenContent?.sub);
          console.log('Access token found in local storage');
          this.$isLoggedIn.next(true);
        } else {
          this.$isLoggedIn.next(false);
        }
      }
    } else {
      // No valid token found in local storage
      console.log('No valid token found in local storage');
      this.$isLoggedIn.next(false);
    }
  }

  private _accessToken: string | undefined;

  /**
   * Returns the access token as string
   */
  get accessToken(): string | undefined {
    return this._accessToken;
  }

  private _refreshToken: string | undefined;

  /**
   * Returns the refresh token as string
   */
  get refreshToken(): string | undefined {
    return this._refreshToken;
  }

  get userID(): string | undefined {
    return this._accessTokenContent?.sub;
  }

  /**
   * Returns true if the access token is expired, else false
   */
  get accessTokenIsExpired(): boolean {
    const currentDate = new Date();

    if (!this._expiresAt) {
      return true;
    }

    if (this._expiresAt.valueOf() > currentDate.valueOf()) {
      return false;
    } else {
      return true;
    }
  }

  /**
   * Returns an array of scopes:string that the current access token contains.
   * If the access token has not been decoded then it returns empty array
   */
  get userScopes(): string[] {
    if (this._accessTokenContent?.scopes) {
      return this._accessTokenContent.scopes;
    } else {
      return [];
    }
  }

  /**
   * Will return true if an access Token is set and the access token is not expired, else will return false
   */
  get isLoggedIn(): boolean {
    if (
      this._accessToken &&
      this._expiresAt &&
      this._expiresAt.valueOf() > new Date().valueOf()
    ) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * Generates a PKCE code challenge and verifier and saves it into the service
   * @param length Length of the verifier. Defaults to 128
   */
  generatePKCE(length = 128): void {
    if (length > 128) {
      throw new Error('The verifier can not be longer then 128 chars');
    }
    if (length < 43) {
      throw new Error('The verifier has to be at least 43 chars long');
    }

    this._codeVerifier = CryptoJS.default.lib.WordArray.random(length).toString(
      CryptoJS.default.enc.Hex
    );

    // Hash the verifier to get the challenge
    this._codeChallenge = CryptoJS.default
      .SHA256(this._codeVerifier)
      .toString(CryptoJS.default.enc.Hex);

    this._state = CryptoJS.default.lib.WordArray.random(24).toString(
      CryptoJS.default.enc.Hex
    );

    this._saveCookies();
  }

  /**
   * Fetches verifier and challenge from cookies
   */
  public fetchSecretFromCookies() {
    this._codeVerifier =
      localStorage.getItem('_auth_code_verifier') || undefined;
    this._codeChallenge =
      localStorage.getItem('_auth_code_challenge') || undefined;
    this._state = localStorage.getItem('_auth_state') || undefined;
  }

  /**
   * Exchanges the auth code for a token pair (access and refresh token) and sets everything up to be used by the interceptor
   * @param state Application State to match against
   * @param authCode Auth code that has been attached to the callback url
   */
  public async authenticateUser(
    state: string,
    authCode: string
  ): Promise<void> {
    if (state !== this._state) {
      throw new Error('States do not match');
    }

    await lastValueFrom(this._exchangeAuthCode(authCode));
    return;
  }

  /**
   * Generates a redirect url and redirects the user to the external login page
   * @param scopes requested scopes
   * @returns URL the user needs to be redirected to
   * @deprecated in favor of generateLoginUrl
   * @see generateLoginUrl
   */
  public redirectUserToLoginPage(scopes: string[], isMobile: boolean): string {
    return this.generateLoginUrl(scopes, isMobile);
  }

  /**
   * Generates a redirect url and redirects the user to the external login page
   * @param scopes requested scopes
   * @param isMobile generates url with custom scheme, default: false
   * @returns URL the user needs to be redirected to
   */
  public generateLoginUrl(scopes: string[], isMobile = false) {
    const scopesString = this._scopesArrayToString(scopes);

    let language = document.defaultView?.navigator?.language?.substr(0, 2);

    if (!language || !this.supportedLanguages.includes(language)) {
      language = 'en';
    }

    const languageLoginUrl = this._config.loginUrl.replace(
      '/auth',
      `/${language}/auth`
    );

    let loginUrl =
      `${languageLoginUrl}?` +
      'response_type=code' +
      `&client_id=${this._config.clientId}` +
      `&redirect_uri=${this._config.redirectUrl}` +
      `&scope=${scopesString}` +
      `&state=${this._state}` +
      `&code_challenge=${this._codeChallenge}` +
      '&code_challenge_method=S256';

    if (isMobile) {
      loginUrl += '&mobile=true';
    }

    return loginUrl;
  }

  /**
   * Used to obtain a new access token using the refresh token.
   * Automaticly sets the new tokens in the service
   * Used by the interceptor to auto refresh the token
   * Used by the constructor to get a fresh token if the access token is expired, but the refresh token is not
   * @todo refresh token expiration check #2
   */
  public getNewAccessToken(): Observable<ITokenPairResponse> {
    if (!this._refreshToken) {
      throw new Error('No refresh token available');
    }

    // If obtainingAccessToken is true, return the observable that is currently being obtained
    if (this.obtainingAccessToken) {
      return this.obtainingAccessToken;
    }

    console.log('Getting new access token');

    const request: IRefreshTokenRequest = {
      refreshToken: this._refreshToken,
      clientId: this._config.clientId,
    };

    this.$isLoggedIn.next(null);

    const tokenRequest = this._http
      .post<ITokenPairResponse>(
        `${this._config.apiUrl}/tokens/refresh`,
        request
      )
      .pipe(
        retry(3),
        catchError((error) => this._handleError(error)),
        map((response) => {
          this._setTokens(response);
          return response;
        })
      );
    this.obtainingAccessToken = tokenRequest;
    return tokenRequest;
  }

  /**
   * Logs out the currently signed in user
   * Clears the private variables and local storage
   */
  public async logoutUser(): Promise<void> {
    try {
      await this.removeFcmToken();
    } catch (error) {
      console.error('Error during FCM Token removal');
    }
    this._accessToken = undefined;
    this._refreshToken = undefined;
    this._expiresAt = undefined;
    localStorage.removeItem('dl_login_accessToken');
    localStorage.removeItem('dl_login_refreshToken');
    localStorage.removeItem('dl_login_accessToken_expiresAt');
    localStorage.removeItem('dl_login_refresh_expiresAt');
    console.log('Logged out');
    this.$isLoggedIn.next(false);
  }

  public async setFcmToken(fcmToken: string): Promise<void> {
    if (!this._accessTokenContent) {
      throw new Error('Cant set FCM Token: User is not logged in');
    }

    await lastValueFrom(
      this._http.post(
        `${this._config.apiUrl}/users/${this._accessTokenContent.clientId}/fcm/token`,
        { fcmToken, clientId: this._config.clientId }
      )
    );
    return;
  }

  /**
   * Saves state, challenge and verifier in cookies.
   */
  private _saveCookies(): void {
    localStorage.setItem('_auth_code_verifier', this._codeVerifier ?? '');
    localStorage.setItem('_auth_code_challenge', this._codeChallenge ?? '');
    localStorage.setItem('_auth_state', this._state ?? '');
  }

  /**
   * Makes the http POST request to the backend to exchange the auth code for a token pair
   * Does not validate state (see authenticateUser())
   * @param authCode Recieved auth code
   */
  private _exchangeAuthCode(authCode: string): Observable<ITokenPairResponse> {
    if (!this._codeVerifier) {
      throw new Error('No code verifier was found');
    }

    const requestBody: IExchangeTokenRequest = {
      client_id: this._config.clientId,
      code: authCode,
      redirect_uri: this._config.redirectUrl,
      code_verifier: this._codeVerifier,
      grant_type: 'authorization_code',
    };

    return this._http
      .post<ITokenPairResponse>(`${this._config.apiUrl}/tokens`, requestBody)
      .pipe(
        retry(2),
        map((response) => {
          this._setTokens(response);
          return response;
        })
      );
  }

  /**
   * Converts an array of scopes to a ; delimited string of scopes
   * @param scopes Array of scopes
   */
  private _scopesArrayToString(scopes: string[]): string {
    return scopes.join(';');
  }

  /**
   * Handles HTTP Errors
   * @param error HTTP Error
   */
  private _handleError(error: HttpErrorResponse): Observable<never> {
    console.error(error);
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(
        `Backend returned code ${error.status}, body was: ${error.error}`
      );
    }
    // Return an observable with a user-facing error message.
    return throwError('Something bad happened; please try again later.');
  }

  /**
   * Decodes the jwt and saves the content in this._accessTokenContent if the jwt is valid
   * @param jwt JWT as string
   */
  private _decodeJwt(jwt: string): void {
    const decoded = jwtDecode(jwt) as IAccessTokenContent;
    this._accessTokenContent = decoded;
  }

  /**
   * Sets the access and refresh token, the expiration date and calls decodeJwt.
   * @param response Http response of type tokenPairResponse
   */
  private _setTokens(response: ITokenPairResponse): void {
    localStorage.setItem('dl_login_accessToken', response.access_token);
    localStorage.setItem('dl_login_refreshToken', response.refresh_token);
    localStorage.setItem(
      'dl_login_accessToken_expiresAt',
      new Date(+response.expires_at * 1000).toISOString()
    );
    const refreshTokenContents = jwtDecode(response.refresh_token) as {
      exp: string;
    };
    localStorage.setItem(
      'dl_login_refresh_expiresAt',
      new Date(+refreshTokenContents.exp * 1000).toISOString()
    );
    this._accessToken = response.access_token;
    this._refreshToken = response.refresh_token;
    this._expiresAt = new Date(+response.expires_at * 1000);
    this._decodeJwt(this._accessToken);
    console.log('Tokens set');
    this.$isLoggedIn.next(true);
    // this.onLogin.emit(this._accessTokenContent?.sub);
  }

  /**
   * Checks if a valid token pair exists in local storage
   * Checks the expires at as well
   * @returns Boolean if a valid token pair is present (not expired)
   */
  private _checkIfValidTokenPresent(): boolean {
    const tokensExists =
      !!localStorage.getItem('dl_login_accessToken') &&
      !!localStorage.getItem('dl_login_refreshToken');

    const accessTokenExpiredAt = localStorage.getItem(
      'dl_login_accessToken_expiresAt'
    );
    const refreshTokenExpiredAt = localStorage.getItem(
      'dl_login_refresh_expiresAt'
    );
    if (!accessTokenExpiredAt) {
      return false;
    }

    if (!refreshTokenExpiredAt) {
      return false;
    }

    const isExpired =
      new Date(refreshTokenExpiredAt).valueOf() <= new Date().valueOf();
    return !isExpired && tokensExists;
  }

  /**
   * Loads the local storage contents into the private variables
   * Does not check for existence.
   * See _checkIfValidTokenPresent
   */
  private _loadFromLocalStorage(): void {
    this._accessToken =
      localStorage.getItem('dl_login_accessToken') ?? undefined;
    this._refreshToken =
      localStorage.getItem('dl_login_refreshToken') ?? undefined;
    const accessTokenExpiredAt = localStorage.getItem(
      'dl_login_accessToken_expiresAt'
    );

    if (!accessTokenExpiredAt) {
      this._expiresAt = undefined;
      return;
    }

    this._expiresAt = new Date(accessTokenExpiredAt);

    if (this._accessToken) {
      this._decodeJwt(this._accessToken);
    }
  }

  private async removeFcmToken() {
    if (!this._accessTokenContent) {
      throw new Error('Cant remove FCM Token: User is not logged in');
    }

    const fcmToken = sessionStorage.getItem('fcmToken');
    if (!fcmToken) {
      return;
    }
    await lastValueFrom(
      this._http.delete(
        `${this._config.apiUrl}/users/${this._accessTokenContent.clientId}/fcm/token/${fcmToken}?clientId=${this._config.clientId}`
      )
    );
  }
}
