import { CdkDrag, CdkDropList, DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  inject,
  signal,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { DatenlotseAngularAuthService } from '@datenlotse/angular-auth';
import { dirtyCheck } from '@datenlotse/common/operators';
import { DlButtonModule } from '@datenlotse/components/button';
import { DlFormFieldModule } from '@datenlotse/components/form-field';
import { DlInputModule } from '@datenlotse/components/input';
import { Store } from '@ngrx/store';
import { Subject, filter, first, map, tap } from 'rxjs';
import { MonatsauswertungLayoutDto } from '../../api/models/monatsauswertung-layout-dto';
import { MonatsauswertungSpalteDto } from '../../api/models/monatsauswertung-spalte-dto';
import * as fromAuswertungenLayouts from '../../store/auswertung-layouts/auswertung-layouts.selectors';
import { DragDropListComponent } from './drag-drop-list/drag-drop-list.component';

export type EditorOutput = {
  name: string;
  kuerzel: string;
  spalten: string[];
};

@Component({
  selector: 'datenlotse-monatsauswertungen-layout-editor',
  standalone: true,
  imports: [
    CommonModule,
    DlButtonModule,
    DlFormFieldModule,
    DlInputModule,
    DragDropModule,
    CdkDropList,
    CdkDrag,
    ReactiveFormsModule,
    DragDropListComponent,
  ],
  templateUrl: './monatsauswertungen-layout-editor.component.html',
  styleUrls: ['./monatsauswertungen-layout-editor.component.scss'],
})
export class MonatsauswertungenLayoutEditorComponent implements OnInit {
  private readonly store = inject(Store);
  private readonly authService = inject(DatenlotseAngularAuthService);
  public readonly monatsauswertungSpalten$ = this.store.select(
    fromAuswertungenLayouts.selectMonatsauswertungLayoutsSpalten
  );

  public readonly loading$ = this.store.select(
    fromAuswertungenLayouts.selectMonatsauswertungLayoutsSpaltenLoading
  );

  public readonly form = new FormGroup({
    name: new FormControl('', {
      nonNullable: true,
      validators: [Validators.required, Validators.maxLength(255)],
    }),
    kuerzel: new FormControl('', {
      nonNullable: true,
      validators: [Validators.required, Validators.maxLength(50)],
    }),
    aktiveSpalten: new FormControl<MonatsauswertungSpalteDto[]>([], {
      nonNullable: true,
      validators: [Validators.required],
    }),
  });
  public readonly spalten = signal<MonatsauswertungSpalteDto[]>([]);
  @Output()
  public readonly submitForm = new EventEmitter<EditorOutput>();
  private originalValues = new Subject<EditorOutput | undefined>();

  public readonly hasChanges$ = this.form.valueChanges.pipe(
    map((x) => ({
      name: x.name,
      kuerzel: x.kuerzel,
      spalten:
        x.aktiveSpalten?.map(
          (spalte: MonatsauswertungSpalteDto) => spalte.id
        ) || [],
    })),
    dirtyCheck(this.originalValues)
  );

  @Input()
  public set monatsauswertungLayout(
    layout: MonatsauswertungLayoutDto | undefined
  ) {
    if (layout) {
      const values = {
        name: layout.name,
        kuerzel: layout.kuerzel,
        aktiveSpalten: layout.spalten.map((x) => ({
          name: x.name,
          id: x.spalteId,
          kuerzel: x.kuerzel,
        })),
      };
      this.form.patchValue(values);
      this.originalValues.next({
        name: layout.name,
        kuerzel: layout.kuerzel,
        spalten: layout.spalten.map((x) => x.spalteId),
      });
    } else {
      this.form.reset();
      this.originalValues.next(undefined);
    }
  }

  ngOnInit(): void {
    this.monatsauswertungSpalten$
      .pipe(
        filter(Boolean),
        first(),
        tap((spalten) => {
          this.spalten.set(spalten);
        })
      )
      .subscribe();
  }

  onSubmit() {
    if (!this.hasScope('monatsauswertungLayout:write')) {
      return;
    }

    if (this.form.valid) {
      const { name, kuerzel, aktiveSpalten } = this.form.getRawValue();
      const spalten = aktiveSpalten.map((spalte) => spalte.id);
      this.submitForm.emit({ name, kuerzel, spalten });
    }
  }

  hasScope(scope: string) {
    return this.authService.userScopes.includes(scope);
  }
}
