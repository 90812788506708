import { DOCUMENT } from '@angular/common';
import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router } from '@angular/router';
import { DatenlotseAngularAuthService } from '@datenlotse/angular-auth';
import { filter, map } from 'rxjs';
import { environment } from '../environments/environment';

export const loggedInGuard: CanActivateFn = () => {
  const authService = inject(DatenlotseAngularAuthService);
  const document = inject(DOCUMENT);

  const isLoggedIn$ = authService.$isLoggedIn.pipe(
    filter((x): x is boolean => x !== null),
    map((x) => {
      if (x) return x;
      doLogin(authService, document);
      return false;
    }),
  );
  return isLoggedIn$;
};

export const routeScopeGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
) => {
  const router = inject(Router);
  const authService = inject(DatenlotseAngularAuthService);
  const defaultScope = 'pageAccess:' + route.routeConfig?.path;
  const scope = route.data['requiredScope'] || defaultScope;

  if (authService.userScopes.includes(scope)) {
    console.debug('Zugriff für', scope);
    return true;
  } else {
    console.error('Kein Zugriff für', scope);
    return router.navigateByUrl('no-access');
  }
};

function doLogin(
  authService: DatenlotseAngularAuthService,
  document: Document,
) {
  authService.generatePKCE();
  localStorage.setItem('targetUrl', document.location.pathname);

  // Create Scope Array
  const scopes = [];
  for (const scope_item of environment.scope_items) {
    scopes.push(scope_item.scope);
  }

  const loginUrl = authService.generateLoginUrl(scopes, false);

  document.location.href = loginUrl;
}
