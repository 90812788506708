import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  inject,
} from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { RouterLink } from '@angular/router';
import { DatenlotseAngularAuthService } from '@datenlotse/angular-auth';
import { DlButtonModule } from '@datenlotse/components/button';
import {
  DlPaginator,
  DlPaginatorModule,
} from '@datenlotse/components/paginator';
import { DlSearchBarModule } from '@datenlotse/components/search-bar';
import { DlSort, DlSortModule } from '@datenlotse/components/sort';
import { DlDataSource, DlTableModule } from '@datenlotse/components/table';
import { MonatsauswertungLayoutDto } from '../../api/models/monatsauswertung-layout-dto';

@Component({
  selector: 'datenlotse-monatsauswertungen-layouts-list',
  standalone: true,
  imports: [
    CommonModule,
    DlTableModule,
    DlPaginatorModule,
    DlSearchBarModule,
    DlSortModule,
    ReactiveFormsModule,
    DlButtonModule,
    RouterLink,
  ],
  templateUrl: './monatsauswertungen-layouts-list.component.html',
  styleUrls: ['./monatsauswertungen-layouts-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MonatsauswertungenLayoutsListComponent
  implements AfterViewInit, OnInit
{
  private readonly _authService = inject(DatenlotseAngularAuthService);
  @ViewChild(DlPaginator) paginator!: DlPaginator;
  @ViewChild(DlSort) sort!: DlSort;

  @Input()
  public selectedLayoutId: string | undefined;
  public readonly filterControl = new FormControl<string>('');
  public readonly columns = ['name', 'kuerzel'];
  tableData = new DlDataSource<MonatsauswertungLayoutDto>([]);
  @Input()
  public readonly allowCreation = true;
  @Output()
  create = new EventEmitter<void>();
  @Output()
  selectLayout = new EventEmitter<MonatsauswertungLayoutDto>();

  @Input({ required: true })
  public set monatsauswertungenLayouts(layouts: MonatsauswertungLayoutDto[]) {
    this.tableData.data = layouts;
    this.tableData._updateChangeSubscription();
  }

  public onLayoutClick(layout: MonatsauswertungLayoutDto) {
    this.selectLayout.emit(layout);
  }

  ngAfterViewInit(): void {
    this.tableData.paginator = this.paginator;
    this.tableData.sort = this.sort;
  }

  ngOnInit(): void {
    this.tableData.filterPredicate = (data, filter) => {
      return (
        data.name.toLowerCase().includes(filter.toLowerCase()) ||
        data.kuerzel.toLowerCase().includes(filter.toLowerCase())
      );
    };
    this.filterControl.valueChanges.subscribe((value) => {
      this.tableData.filter = value ?? '';
    });
  }

  hasScope(scope: string) {
    return this._authService.userScopes.includes(scope);
  }
}
