import {
  Component,
  effect,
  forwardRef,
  Input,
  signal,
  TemplateRef,
  WritableSignal,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  CdkDrag,
  CdkDragDrop,
  CdkDropList,
  DragDropModule,
} from '@angular/cdk/drag-drop';
import {
  moveItemInArray,
  transferItemsBetweenArrays,
} from '@datenlotse/common';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'datenlotse-drag-drop-list',
  standalone: true,
  imports: [CommonModule, CdkDrag, CdkDropList, DragDropModule],
  templateUrl: './drag-drop-list.component.html',
  styleUrls: ['./drag-drop-list.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DragDropListComponent),
      multi: true,
    },
  ],
})
export class DragDropListComponent<T extends { id: string }>
  implements ControlValueAccessor
{
  @Input({ required: true })
  public itemTemplate!: TemplateRef<{ $implicit: T; index: number }>;
  @Input({ required: true })
  public leftColumnTitle = '';
  @Input({ required: true })
  public rightColumnTitle = '';
  public rightColumnItems = signal<T[]>([]);
  public leftColumnItems = signal<T[]>([]);
  private _allColumnsItems = signal<T[]>([]);

  constructor() {
    // Update the value of the form control whenever the left column items change
    effect(() => {
      this.onChange(this.leftColumnItems());
    });

    effect(
      () => {
        const allItems = this._allColumnsItems();
        const rightItems = this.rightColumnItems();
        const leftItems = this.leftColumnItems();
        // Add all items that are not in the left column to the right column
        const newRightItems = allItems.filter(
          (item) => !leftItems.find((leftItem) => leftItem.id === item.id)
        );

        if (newRightItems.length !== rightItems.length) {
          this.rightColumnItems.set(newRightItems);
        }
      },
      { allowSignalWrites: true }
    );
  }

  @Input({ required: true })
  public set items(items: T[]) {
    this._allColumnsItems.set(items);
  }

  writeValue(value: T[]): void {
    this.leftColumnItems.set(value || []);
  }

  registerOnChange(fn: () => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  public onDrop(
    event: CdkDragDrop<T[]>,
    signal: WritableSignal<T[]>,
    targetSignal: WritableSignal<T[]>
  ): void {
    if (event.previousContainer === event.container) {
      const curr = signal();
      const next = moveItemInArray(
        curr,
        event.previousIndex,
        event.currentIndex
      );
      signal.set(next);
      return;
    }

    const currSource = signal();
    const currTarget = targetSignal();
    const [nextTarget, nextSource] = transferItemsBetweenArrays(
      currTarget,
      currSource,
      event.previousIndex,
      event.currentIndex
    );

    signal.set(nextSource);
    targetSignal.set(nextTarget);
  }

  private onChange: (value: T[]) => void = () => {};

  private onTouched: () => void = () => {};
}
