/**
 * Moves an item in an array from the oldIndex to the newIndex in the target array.
 * This is a pure function so a new array will be returned, instead of mutating the old arrays.
 * @param sourceArray The array where the item should be removed from
 * @param targetArray The array where the item should be inserted into
 * @param oldIndex The index of the item in the source array
 * @param newIndex The index where the item should be inserted into the target array
 */
export function transferItemsBetweenArrays<T>(
  sourceArray: T[],
  targetArray: T[],
  oldIndex: number,
  newIndex: number
): [T[], T[]] {
  const updatedFromArray = sourceArray.slice();
  const updatedToArray = targetArray.slice();

  // Remove item from old the from array
  const removed = updatedFromArray.splice(oldIndex, 1);

  // Insert item into the new array at the new index
  updatedToArray.splice(newIndex, 0, removed[0]);

  return [updatedFromArray, updatedToArray];
}
