export function sortByStringPropertyHelper<T>(
  propertyName: keyof T,
  direction: 'asc' | 'desc' = 'asc'
) {
  return function (a: T, b: T) {
    if (a[propertyName] < b[propertyName]) return direction === 'asc' ? -1 : 1;
    if (a[propertyName] > b[propertyName]) return direction === 'asc' ? 1 : -1;
    return 0;
  };
}
