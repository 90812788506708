import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { map, of } from 'rxjs';
import {
  catchError,
  concatMap,
  debounceTime,
  filter,
  switchMap,
  tap,
} from 'rxjs/operators';
import { handleHttpError } from '../../../common/httpErrorHandler';
import { MehrarbeitAuszahlungApiService } from '../../api/services';
import { PAGINATION_HEADER_NAME } from '../../pagination-header';
import * as fromAuth from '../auth/auth.selectors';
import { MitarbeiterActions } from '../mitarbeiter/mitarbeiter.actions';
import * as fromMitarbeiter from '../mitarbeiter/mitarbeiter.selectors';
import { MehrarbeitAuszahlungenActions } from './mehrarbeitauszahlungen.actions';
import * as fromMehrarbeitAuszahlungen from './mehrarbeitauszahlungen.selectors';

@Injectable()
export class MehrarbeitAuszahlungenEffects {
  loadMehrarbeitAuszahlungens$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        MehrarbeitAuszahlungenActions.loadMehrarbeitauszahlungen,
        MehrarbeitAuszahlungenActions.setPageIndex,
        MehrarbeitAuszahlungenActions.setPageSize,
        MitarbeiterActions.setSelectedMitarbeiterId,
        MehrarbeitAuszahlungenActions.setSelectedMonthAndYear,
      ),
      concatLatestFrom(() => [
        this.store.select(fromMitarbeiter.selectSelectedMitarbeiterId),
        this.store.select(fromMehrarbeitAuszahlungen.selectSelectedMonth),
        this.store.select(fromMehrarbeitAuszahlungen.selectSelectedYear),
        this.store.select(fromAuth.selectHasScope("pageAccess:umbuchungen"))
      ]),
      debounceTime(50),
      filter(([, , , , canAccessUmbuchungen]) => canAccessUmbuchungen),
      filter(([, selectedMitarbeiterId]) => !!selectedMitarbeiterId), // Filter out if no mitarbeiter is selected
      switchMap(([, selectedMitarbeiterId, selectedMonth, selectedYear]) =>
        this._mehrarbeitAuszahlungApi
          .getAllMehrarbeitAuszahlungen$Json$Response({
            date: `${selectedYear}-${selectedMonth}-01`,
            mitarbeiterId: selectedMitarbeiterId,
          })
          .pipe(
            map((response) => {
              return MehrarbeitAuszahlungenActions.loadMehrarbeitauszahlungenSuccess(
                {
                  data: response.body,
                  totalCount: response.headers.get(PAGINATION_HEADER_NAME)
                    ? JSON.parse(
                        response.headers.get(PAGINATION_HEADER_NAME) as string,
                      ).TotalCount
                    : response.body.length,
                },
              );
            }),
            catchError((error) =>
              of(
                MehrarbeitAuszahlungenActions.loadMehrarbeitauszahlungenFailure(
                  { error },
                ),
              ),
            ),
          ),
      ),
    );
  });

  createMehrarbeitAuszahlung$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MehrarbeitAuszahlungenActions.createMehrarbeitauszahlung),
      concatLatestFrom(() => [
        this.store.select(fromMitarbeiter.selectSelectedMitarbeiterId),
        this.store.select(fromMehrarbeitAuszahlungen.selectSelectedYear),
        this.store.select(fromMehrarbeitAuszahlungen.selectSelectedMonth),
      ]),
      filter(([, mitarbeiterId]) => mitarbeiterId !== ''),
      concatMap(([{ auszahlungInSekunden }, mitarbeiterId, jahr, monat]) =>
        this._mehrarbeitAuszahlungApi
          .createMehrarbeitAuszahlung$Json({
            body: {
              jahr: jahr,
              monat: monat,
              mitarbeiterId: mitarbeiterId,
              auszahlungInSekunden: auszahlungInSekunden,
            },
          })
          .pipe(
            map((res) =>
              MehrarbeitAuszahlungenActions.createMehrarbeitauszahlungSuccess({
                data: res,
              }),
            ),
            catchError((err) =>
              of(
                MehrarbeitAuszahlungenActions.createMehrarbeitauszahlungFailure(
                  { error: err },
                ),
              ),
            ),
          ),
      ),
    );
  });

  // Toast Handling
  createMehrarbeitAuszahlungFailure$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(MehrarbeitAuszahlungenActions.createMehrarbeitauszahlungFailure),
        tap(async ({ error }) => {
          console.error(error);
          await handleHttpError(
            error,
            this.toastController,
            'Beim Erstellen der Mehrarbeit Auszahlung ist ein Fehler aufgetreten.',
          );
        }),
      );
    },
    { dispatch: false },
  );

  createMehrarbeitAuszahlungSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(MehrarbeitAuszahlungenActions.createMehrarbeitauszahlungSuccess),
        tap(async () => {
          const toast = await this.toastController.create({
            message: 'Mehrarbeit Auszahlung erfolgreich erstellt.',
            duration: 3000,
            color: 'success',
          });
          await toast.present();
        }),
      );
    },
    { dispatch: false },
  );

  constructor(
    private actions$: Actions,
    private _mehrarbeitAuszahlungApi: MehrarbeitAuszahlungApiService,
    private readonly toastController: ToastController,
    private store: Store,
  ) {}
}
