import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DlInputComponent } from './input/input.component';
import { FormsModule } from '@angular/forms';
import { OverlayModule } from '@angular/cdk/overlay';

@NgModule({
  declarations: [DlInputComponent],
  imports: [CommonModule, FormsModule, OverlayModule],
  exports: [DlInputComponent],
})
export class DlInputModule {}
