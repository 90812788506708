/* eslint-disable @ngrx/avoid-dispatching-multiple-actions-sequentially */
import { CommonModule } from '@angular/common';
import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  NavigationStart,
  Router,
  RouterLink,
  RouterLinkActive,
} from '@angular/router';
import { DatenlotseAngularAuthService } from '@datenlotse/angular-auth';
import { SidebarModule } from '@datenlotse/components/sidebar';
import { IonicModule } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { enableMapSet } from 'immer';
import { BehaviorSubject, filter, tap } from 'rxjs';
import { AuthActions } from './store/auth/auth.actions';
import { ChipsActions } from './store/chips/chips.actions';
import * as TagesplaeneActions from './store/tagesplaene/tagesplaene.actions';
import * as WochenplaeneActions from './store/wochenplaene/wochenplaene.actions';

@Component({
  selector: 'datenlotse-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  standalone: true,
  imports: [
    SidebarModule,
    IonicModule,
    RouterLink,
    CommonModule,
    RouterLinkActive,
  ],
})
export class AppComponent implements OnInit {
  private readonly store = inject(Store);
  private readonly _router = inject(Router);
  currentUrl$ = new BehaviorSubject<string>('');
  destroyref$ = inject(DestroyRef);
  authService = inject(DatenlotseAngularAuthService);

  ngOnInit(): void {
    enableMapSet();
    this.store.dispatch(WochenplaeneActions.loadActiveWochenplaene());
    this.store.dispatch(TagesplaeneActions.loadActiveTagesplaene());
    this.store.dispatch(ChipsActions.loadAvailableChips());
    if(this.hasScope('zutrittskontrolle:verwaltung:all')) {
      this.store.dispatch(ChipsActions.loadAllChips());
    }
    // Fuer ActiveLink Fix Sidebar
    this._router.events
      .pipe(
        filter((e): e is NavigationStart => e instanceof NavigationStart),
        takeUntilDestroyed(this.destroyref$)
      )
      .subscribe((e) => {
        const url = e.url;
        const urlMappings = new Map([
          ['tagesplaene', 'tagesplaene'],
          ['/buchungen', 'buchungen'],
          ['kalender', 'kalender'],
          ['wochenplaene', 'wochenplaene'],
          ['auswertungen', 'auswertungen'],
          ['personal-stammdaten', 'personal-stammdaten'],
          ['layouts', 'layouts'],
          ['/umbuchungen', 'umbuchungen'],
        ]);

        let value = '';
        for (const [pattern, mappingValue] of urlMappings) {
          if (url.includes(pattern)) {
            value = mappingValue;
            break;
          }
        }

        this.currentUrl$.next(value);
      });

    this.authService.$isLoggedIn
      .pipe(
        filter((x) => x !== null),
        tap(() => {
          this.store.dispatch(
            AuthActions.setLoggedInUser({
              userId: this.authService.userID ?? null,
              scopes: this.authService.userScopes,
            })
          );
        })
      )
      .subscribe();

    // Temporary disable Sentry
    // this._store
    //   .select(fromAuth.selectLoggedInMitarbeiter)
    //   .pipe(
    //     takeUntilDestroyed(this.destroyref$),
    //     distinctUntilChanged(),
    //     tap((mitarbeiter) => {
    //       if (mitarbeiter) {
    //         Sentry.setUser({
    //           id: mitarbeiter.id,
    //           username: `${mitarbeiter.vorname} ${mitarbeiter.nachname}`,
    //           email: mitarbeiter.privateEmail,
    //           extra: {
    //             scopes: this.authService.userScopes,
    //           },
    //         });
    //         return;
    //       }

    //       Sentry.setUser(null);
    //     })
    //   )
    //   .subscribe();
  }

  hasScope(scope: string) {
    return this.authService.userScopes.includes(scope);
  }
}

