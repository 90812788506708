/* eslint-disable @angular-eslint/no-host-metadata-property */
import {Component, ElementRef, Input, OnInit} from '@angular/core';

@Component({
  selector: 'dl-form-field-label',
  templateUrl: './form-field-label.component.html',
  styleUrls: ['./form-field-label.component.css'],
  host: {
    '[class.moved-up]': 'movedUp',
    '[class.has-focus]': 'hasFocus',
  },
})
export class DlFormFieldLabelComponent implements OnInit {
  hasFocus = false;

  @Input() required?: string;

  constructor(private element: ElementRef) {}

  private _movedUp = false;

  get movedUp(): boolean {
    return this._movedUp;
  }

  set movedUp(isMovedUp: boolean) {
    this._movedUp = isMovedUp;
  }

  private _isRequired = false;

  get isRequired(): boolean {
    return this._isRequired;
  }

  ngOnInit(): void {
    const host = this.element.nativeElement as HTMLElement;
    this._isRequired = host.hasAttribute('required');
  }
}
