import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { WochenplanApiService } from '../../api/services';
import * as WochenplaeneActions from './wochenplaene.actions';

@Injectable()
export class WochenplaeneEffects {
  loadActiveWochenplaenes$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(WochenplaeneActions.loadActiveWochenplaene),
      switchMap(() =>
        /** An EMPTY observable only emits completion. Replace with your own observable API request */
        this.apiService.getWochenplaene$Json().pipe(
          map((activeWochenplaene) =>
            WochenplaeneActions.loadActiveWochenplaeneSuccess({activeWochenplaene})
          ),
          catchError((error) =>
            of(WochenplaeneActions.loadActiveWochenplaeneFailure({error}))
          )
        )
      )
    );
  });

  loadInactiveWochenplaenes$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(WochenplaeneActions.loadInactiveWochenplaene),
      switchMap(() =>
        this.apiService.getWochenplaene$Json({onlySoftDeletes: true}).pipe(
          map((inactiveWochenplaene) => {
            return WochenplaeneActions.loadInactiveWochenplaeneSuccess({inactiveWochenplaene});}
          ),
          catchError((error) =>
            of(WochenplaeneActions.loadInactiveWochenplaeneFailure({error}))
          )
        )
      )
    );
  });

  setInactiveWochenplan$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(WochenplaeneActions.setInactiveWochenplan),
      switchMap((data) =>
        this.apiService
          .setWochenplanInactive({
            wochenplanid: data.id,
          })
          .pipe(
            map(() =>
              WochenplaeneActions.setInactiveWochenplanSuccess(data)
            ),
            catchError((error) =>
              of(WochenplaeneActions.setInactiveWochenplanFailure({error}))
            )
          )
      )
    );
  });

  createTagesplan$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(WochenplaeneActions.createWochenplan),
      switchMap(({data}) =>
        this.apiService
          .createOneWochenplan$Json({
            body: data,
          })
          .pipe(
            map((wochenplan) =>
              WochenplaeneActions.createWochenplanSuccess({wochenplan})
            ),
            catchError((error) =>
              of(WochenplaeneActions.createWochenplanFailure({error}))
            )
          )
      )
    );
  });

  displaySuccessToast$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          WochenplaeneActions.setInactiveWochenplanSuccess,
        ),
        tap(async () => {
          const toast = await this.toastCtrl.create({
            message: 'Wochenplan inaktiv gesetzt.',
            duration: 5000,
            color: 'success',
          });
          await toast.present();
        })
      ),
    { dispatch: false }
  );

  displayErrorToast$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          WochenplaeneActions.setInactiveWochenplanFailure
        ),
        tap(async ({ error }) => {
          const toast = await this.toastCtrl.create({
            message: 'Der Wochenplan wird als Standardwochenplan eines aktiven Mitarbeiters verwendet. Er kann nicht inaktiv gesetzt werden.',
            duration: 5000,
            color: 'danger',
          });
          await toast.present();
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private readonly apiService: WochenplanApiService,
    private toastCtrl: ToastController
  ) {
  }
}
