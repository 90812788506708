/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { checkPausenzeiten } from '../fn/pausenzeiten/check-pausenzeiten';
import { CheckPausenzeiten$Params } from '../fn/pausenzeiten/check-pausenzeiten';

@Injectable({ providedIn: 'root' })
export class PausenzeitenApiService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `checkPausenzeiten()` */
  static readonly CheckPausenzeitenPath = '/v1/pausenzeiten/checkMitarbeiter/{mitarbeiterId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `checkPausenzeiten()` instead.
   *
   * This method doesn't expect any request body.
   */
  checkPausenzeiten$Response(params: CheckPausenzeiten$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return checkPausenzeiten(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `checkPausenzeiten$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  checkPausenzeiten(params: CheckPausenzeiten$Params, context?: HttpContext): Observable<void> {
    return this.checkPausenzeiten$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
