import { createFeatureSelector, createSelector } from '@ngrx/store';
import { MehrarbeitAuszahlungDto } from '../../api/models';
import { selectSelectedMitarbeiterId } from '../mitarbeiter/mitarbeiter.selectors';
import * as fromMehrarbeitAuszahlungen from './mehrarbeitauszahlungen.reducer';

export const selectMehrarbeitAuszahlungenState =
  createFeatureSelector<fromMehrarbeitAuszahlungen.State>(
    fromMehrarbeitAuszahlungen.mehrarbeitAuszahlungenFeatureKey,
  );

export const selectMehrarbeitAuszahlungen = createSelector(
  selectMehrarbeitAuszahlungenState,
  (state) => state.mehrarbeitAuszahlungen,
);

export const selectMehrarbeitAuszahlungenArray = createSelector(
  selectMehrarbeitAuszahlungen,
  (state) => {
    const mehrarbeitAuszahlungenArray = Object.values(state);
    return mehrarbeitAuszahlungenArray.filter(
      (
        MehrarbeitAuszahlungen,
      ): MehrarbeitAuszahlungen is MehrarbeitAuszahlungDto =>
        !!MehrarbeitAuszahlungen,
    );
  },
);

export const selectTotalCount = createSelector(
  selectMehrarbeitAuszahlungenState,
  (state) => state.totalCount ?? 0,
);

export const selectMehrarbeitAuszahlungenPage = createSelector(
  selectMehrarbeitAuszahlungenState,
  (state) => {
    if (!state.mehrarbeitAuszahlungenPageData) {
      return undefined;
    }
    return state.mehrarbeitAuszahlungenPageData
      .map((id) => state.mehrarbeitAuszahlungen[id] as MehrarbeitAuszahlungDto)
      .filter((mau) => !!mau);
  },
);

export type MehrarbeitAuszahlungenDtoGroupedByDate = {
  mehrarbeitAuszahlungen: MehrarbeitAuszahlungDto[];
  monat: number;
  jahr: number;
};

export type MehrarbeitAuszahlungenDtoGroupedByDateMap = {
  [jahrMonat: string]: MehrarbeitAuszahlungenDtoGroupedByDate;
};

export const selectMehrarbeitAuszahlungenGroupedByDatum = createSelector(
  selectMehrarbeitAuszahlungenPage,
  (mehrarbeitAuszahlungen) => {
    if (!mehrarbeitAuszahlungen) {
      return undefined;
    }
    return mehrarbeitAuszahlungen.reduce((acc, mehrarbeitAuszahlung) => {
      const key = mehrarbeitAuszahlung.jahr + mehrarbeitAuszahlung.monat;
      if (!acc[key]) {
        acc[key] = {
          mehrarbeitAuszahlungen: [mehrarbeitAuszahlung],
          monat: mehrarbeitAuszahlung.monat,
          jahr: mehrarbeitAuszahlung.jahr,
        };
      } else {
        acc[key].mehrarbeitAuszahlungen.push(mehrarbeitAuszahlung);
      }
      return acc;
    }, {} as MehrarbeitAuszahlungenDtoGroupedByDateMap);
  },
);

export const selectMehrarbeitAuszahlungenDesMonats = (
  monat: number,
  jahr: number,
) =>
  createSelector(selectMehrarbeitAuszahlungenPage, (mehrarbeitAuszahlungen) => {
    if (!mehrarbeitAuszahlungen) {
      return undefined;
    }
    // Filtern der Buchungen des übergebenen Monats und Jahres
    const mehrarbeitAuszahlungenDesMonats = mehrarbeitAuszahlungen.filter(
      (mehrarbeitAuszahlung) => {
        return (
          mehrarbeitAuszahlung.monat === monat &&
          mehrarbeitAuszahlung.jahr === jahr
        );
      },
    );

    mehrarbeitAuszahlungenDesMonats.sort(
      (a, b) =>
        new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(),
    );
    return mehrarbeitAuszahlungenDesMonats;
  });

export const selectPage = createSelector(
  selectMehrarbeitAuszahlungenState,
  (state) => state.page,
);

export const selectPageSize = createSelector(
  selectMehrarbeitAuszahlungenState,
  (state) => state.pageSize,
);

export const selectLoading = createSelector(
  selectMehrarbeitAuszahlungenState,
  selectSelectedMitarbeiterId,
  (state, mitarbeiterId) => mitarbeiterId && state.loading,
);

export const selectSelectedMonth = createSelector(
  selectMehrarbeitAuszahlungenState,
  (state) => state.selectedMonth ?? new Date().getMonth() + 1,
);

export const selectSelectedYear = createSelector(
  selectMehrarbeitAuszahlungenState,
  (state) => state.selectedYear ?? new Date().getFullYear(),
);

export const selectSelectedYearMonth = createSelector(
  selectMehrarbeitAuszahlungenState,
  (state) =>
    [state.selectedYear, state.selectedMonth] ?? new Date().getFullYear(),
);
