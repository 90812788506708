import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { CreateMonatsauswertungLayoutDto } from '../../api/models/create-monatsauswertung-layout-dto';
import { MonatsauswertungLayoutDto } from '../../api/models/monatsauswertung-layout-dto';
import { MonatsauswertungSpalteDto } from '../../api/models/monatsauswertung-spalte-dto';

export const AuswertungLayoutsActions = createActionGroup({
  source: 'Auswertung Layouts',
  events: {
    'Load Monatsauswertung Layouts': emptyProps(),
    'Load Monatsauswertung Layouts Success': props<{
      monatsauswertungLayouts: MonatsauswertungLayoutDto[];
    }>(),
    'Load Monatsauswertung Layouts Failure': props<{ error: unknown }>(),

    'Load Monatsauswertung Spalten': emptyProps(),
    'Load Monatsauswertung Spalten Success': props<{
      monatsauswertungSpalten: MonatsauswertungSpalteDto[];
    }>(),
    'Load Monatsauswertung Spalten Failure': props<{ error: unknown }>(),
    'Load Monatsauswertung Layouts und Spalten': emptyProps(),
    'Create Monatsauswertung Layout': props<{
      monatsauswertungLayout: CreateMonatsauswertungLayoutDto;
    }>(),
    'Create Monatsauswertung Layout Success': props<{
      monatsauswertungLayout: MonatsauswertungLayoutDto;
    }>(),
    'Create Monatsauswertung Layout Failure': props<{ error: unknown }>(),
    'Update Monatsauswertung Layout': props<{
      id: string;
      monatsauswertungLayout: CreateMonatsauswertungLayoutDto;
    }>(),
    'Update Monatsauswertung Layout Success': props<{
      monatsauswertungLayout: MonatsauswertungLayoutDto;
    }>(),
    'Update Monatsauswertung Layout Failure': props<{ error: unknown }>(),
  },
});
