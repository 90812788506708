import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ScopeKey } from '../../../environments/scopes';
import * as fromMitarbeiter from '../mitarbeiter/mitarbeiter.selectors';
import * as fromAuth from './auth.reducer';

export const selectAuthState = createFeatureSelector<fromAuth.State>(
  fromAuth.authFeatureKey
);

export const selectUserId = createSelector(
  selectAuthState,
  (state) => state.loggedInUserId
);

export const selectIsLoggedIn = createSelector(
  selectUserId,
  (state) => !!state
);

export const selectLoggedInMitarbeiter = createSelector(
  fromMitarbeiter.selectMitarbeiterState,
  selectUserId,
  (mitarbeiterState, loggedInUserId) => {
    const allMitarbeiter = Object.values(mitarbeiterState.mitarbeiter);
    if (allMitarbeiter) {
      return (
        allMitarbeiter.find(
          (x) =>
            x?.userId.toLocaleLowerCase() ===
            loggedInUserId?.toLocaleLowerCase()
        ) || null
      );
    }
    return null;
  }
);

export const selectLoggedInMitarbeiterUnit = createSelector(
  selectLoggedInMitarbeiter,
  (user) => user?.abteilung || null
);

export const selectUserScopes = createSelector(
  selectAuthState,
  (state) => state.scopes
);

export const selectUserWithScopes = createSelector(
  selectLoggedInMitarbeiter,
  selectUserScopes,
  (user, scopes) => ({ user, scopes })
);

export const selectHasScope = (scope: ScopeKey) =>
  createSelector(selectUserScopes, (scopes) => scopes.includes(scope));

export const selectHasAnyScope = (...scopes: ScopeKey[]) =>
  createSelector(selectUserScopes, (userScopes) =>
    scopes.some((scope) => userScopes.includes(scope))
  );

export const selectHasAllScopes = (...scopes: ScopeKey[]) =>
  createSelector(selectUserScopes, (userScopes) =>
    scopes.every((scope) => userScopes.includes(scope))
  );
