import { Sort } from '@datenlotse/components/sort';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import {
  ArbeitszeitkontoDto,
  MitarbeiterDto,
  MitarbeiterInfosDto,
  NurMitarbeiterInfosDto,
  UpdateMitarbeiterDto,
  UserDto,
} from '../../api/models';
import { CreateMitarbeiterRequest } from './requests/create-mitarbeiter.request';

export const MitarbeiterActions = createActionGroup({
  source: 'Mitarbeiter',
  events: {
    'Load Mitarbeiter By User Id': props<{ userId: string }>(),
    'Load Mitarbeiter By User Id Success': props<{
      mitarbeiter: MitarbeiterDto;
    }>(),
    'Load Mitarbeiter By User Id Failure': props<{ error: unknown }>(),
    'Set Page Size': props<{ pageSize: number }>(),
    'Set Unit Filter': props<{ unit: string | null }>(),
    'Deselect Mitarbeiter': emptyProps(),
    'Load Next Page': emptyProps(),
    'Load Previous Page': emptyProps(),
    'Load Mitarbeiter': emptyProps(),
    'Load Mitarbeiter Success': props<{
      mitarbeiter: MitarbeiterDto[];
      totalCount: number;
    }>(),
    'Load Mitarbeiter Failure': props<{ error: unknown }>(),
    'Set Mitarbeiter Sort Filter PageNumber PageSize': props<{
      sort?: Sort;
      filter?: string;
      pageNumber?: number;
      pageSize?: number;
    }>(),
    'Set Mitarbeiter Sort': props<{ sort: Sort }>(),
    'Set Mitarbeiter Filter': props<{ filter: string }>(),
    'Set Selected MitarbeiterId': props<{ id: string }>(),
    'Include Inactive Mitarbeiter': props<{ include: boolean }>(),
    'Create Mitarbeiter': props<{ data: CreateMitarbeiterRequest }>(),
    'Create Mitarbeiter Success': props<{
      createdMitarbeiter: MitarbeiterDto;
    }>(),
    'Create Mitarbeiter Failure': props<{ error: unknown }>(),
    'Update Mitarbeiter': props<{ data: UpdateMitarbeiterDto }>(),
    'Update Mitarbeiter Success': props<{
      updatedMitarbeiter: MitarbeiterDto;
    }>(),
    'Update Mitarbeiter Failure': props<{ error: unknown }>(),
    'Set Inactive Mitarbeiter': props<{ mitarbeiterId: string }>(),
    'Set Inactive Mitarbeiter Success': props<{
      inactiveMitarbeiter: MitarbeiterDto;
    }>(),
    'Set Inactive Mitarbeiter Failure': props<{ error: unknown }>(),
    'Restore Mitarbeiter': props<{ mitarbeiterId: string }>(),
    'Restore Mitarbeiter Success': props<{
      restoredMitarbeiter: MitarbeiterDto;
    }>(),
    'Restore Mitarbeiter Failure': props<{ error: unknown }>(),
    'Load Arbeitszeitkonto Success': props<{
      arbeitszeitkonto: ArbeitszeitkontoDto;
    }>(),
    'Load Arbeitszeitkonto Failure': props<{ error: unknown }>(),
    'Load Mitarbeiter Infos': props<{
      abteilung?: string;
      kostenstelle?: string;
      attributId?: string;
      monat?: string;
    }>(),
    'Load Mitarbeiter Infos Success': props<{
      mitarbeiterInfos: MitarbeiterInfosDto;
      monat?: string;
    }>(),
    'Load Mitarbeiter Infos Failure': props<{ error: unknown }>(),
    'Load Single Mitarbeiter Infos': props<{
      mitarbeiterId: string;
    }>(),
    'Load Single Mitarbeiter Infos Success': props<{
      mitarbeiterInfos: NurMitarbeiterInfosDto;
    }>(),
    'Load Single Mitarbeiter Infos Failure': props<{ error: unknown }>(),
    'Load Unified Login Mitarbeiter': emptyProps(),
    'Load Unified Login Mitarbeiter Success': props<{
      unifiedLoginMitarbeiter: UserDto[];
    }>(),
    'Load Unified Login Mitarbeiter Failure': props<{ error: unknown }>(),
    'Load Mitarbeiter By Id Success': props<{ mitarbeiter: MitarbeiterDto }>(),
    'Load Mitarbeiter By Id Failure': props<{ error: unknown }>(),
  },
});
