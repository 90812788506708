import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { SparzeitUmbuchungDto } from '../../api/models';

export const SparzeitUmbuchungenActions = createActionGroup({
  source: 'SparzeitUmbuchungen',
  events: {
    'Load Sparzeitumbuchungen': emptyProps(),
    'Load Sparzeitumbuchungen Success': props<{
      data: SparzeitUmbuchungDto[];
      totalCount: number;
    }>(),
    'Load Sparzeitumbuchungen Failure': props<{ error: unknown }>(),
    'Create Sparzeitumbuchung': props<{ umbuchungInSekunden: number }>(),
    'Create Sparzeitumbuchung Success': props<{ data: SparzeitUmbuchungDto }>(),
    'Create Sparzeitumbuchung Failure': props<{ error: unknown }>(),
    'Set Page Size': props<{ pageSize: number }>(),
    'Set Page Index': props<{ pageIndex: number }>(),
    'Set Selected Month And Year': props<{
      selectedMonth: number;
      selectedYear: number;
    }>(),
  },
});
