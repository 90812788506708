/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { BuchungDto } from '../models/buchung-dto';
import { startBuchung$Json } from '../fn/datafox/start-buchung-json';
import { StartBuchung$Json$Params } from '../fn/datafox/start-buchung-json';
import { startBuchung$Plain } from '../fn/datafox/start-buchung-plain';
import { StartBuchung$Plain$Params } from '../fn/datafox/start-buchung-plain';
import { stopBuchung$Json } from '../fn/datafox/stop-buchung-json';
import { StopBuchung$Json$Params } from '../fn/datafox/stop-buchung-json';
import { stopBuchung$Plain } from '../fn/datafox/stop-buchung-plain';
import { StopBuchung$Plain$Params } from '../fn/datafox/stop-buchung-plain';

@Injectable({ providedIn: 'root' })
export class DatafoxApiService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `startBuchung()` */
  static readonly StartBuchungPath = '/v1/datafox/buchungen/start';

  /**
   * Startet eine neue Buchung für den Mitarbeiter mit der übergebenen ChipNr.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `startBuchung$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  startBuchung$Plain$Response(params?: StartBuchung$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<BuchungDto>> {
    return startBuchung$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Startet eine neue Buchung für den Mitarbeiter mit der übergebenen ChipNr.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `startBuchung$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  startBuchung$Plain(params?: StartBuchung$Plain$Params, context?: HttpContext): Observable<BuchungDto> {
    return this.startBuchung$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<BuchungDto>): BuchungDto => r.body)
    );
  }

  /**
   * Startet eine neue Buchung für den Mitarbeiter mit der übergebenen ChipNr.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `startBuchung$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  startBuchung$Json$Response(params?: StartBuchung$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<BuchungDto>> {
    return startBuchung$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Startet eine neue Buchung für den Mitarbeiter mit der übergebenen ChipNr.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `startBuchung$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  startBuchung$Json(params?: StartBuchung$Json$Params, context?: HttpContext): Observable<BuchungDto> {
    return this.startBuchung$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<BuchungDto>): BuchungDto => r.body)
    );
  }

  /** Path part for operation `stopBuchung()` */
  static readonly StopBuchungPath = '/v1/datafox/buchungen/stop';

  /**
   * Stopt die aktuell laufende Buchung des Mitarbeiters mit der übergebenen ChipNr.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stopBuchung$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  stopBuchung$Plain$Response(params?: StopBuchung$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<BuchungDto>> {
    return stopBuchung$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Stopt die aktuell laufende Buchung des Mitarbeiters mit der übergebenen ChipNr.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `stopBuchung$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  stopBuchung$Plain(params?: StopBuchung$Plain$Params, context?: HttpContext): Observable<BuchungDto> {
    return this.stopBuchung$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<BuchungDto>): BuchungDto => r.body)
    );
  }

  /**
   * Stopt die aktuell laufende Buchung des Mitarbeiters mit der übergebenen ChipNr.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stopBuchung$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  stopBuchung$Json$Response(params?: StopBuchung$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<BuchungDto>> {
    return stopBuchung$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Stopt die aktuell laufende Buchung des Mitarbeiters mit der übergebenen ChipNr.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `stopBuchung$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  stopBuchung$Json(params?: StopBuchung$Json$Params, context?: HttpContext): Observable<BuchungDto> {
    return this.stopBuchung$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<BuchungDto>): BuchungDto => r.body)
    );
  }

}
