import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { BuchungsaenderungsAntragDto, BuchungserstellAntragDto, UpdateBuchungsaenderungsAntragDto } from '../../api/models';

export const BuchungskorrekturenFuerMonteureActions = createActionGroup({
  source: 'BuchungskorrekturenFuerMonteure',
  events: {
    'Load Buchungskorrekturen': emptyProps(),
    'Load Buchungskorrekturen Success': props<{
      aenderungsAntraege: BuchungsaenderungsAntragDto[];
      erstellAntraege: BuchungserstellAntragDto[];
      totalCount: number;
    }>(),
    'Load Buchungskorrekturen Failure': props<{ error: unknown }>(),
    'Update Buchungskorrektur': props<{ id: string; data: UpdateBuchungsaenderungsAntragDto }>(),
    'Update Buchungskorrektur Success': props<{ data: BuchungsaenderungsAntragDto }>(),
    'Update Buchungskorrektur Failure': props<{ error: unknown }>(),
    'Set Page Size': props<{ pageSize: number }>(),
    'Set Page Index': props<{ pageIndex: number }>(),
  },
});
