/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { BuchungserstellAntragDto } from '../../models/buchungserstell-antrag-dto';
import { UpdateBuchungserstellAntragDto } from '../../models/update-buchungserstell-antrag-dto';

export interface UpdateOneBuchungserstellAntrag$Json$Params {

/**
 * ID des Antrags, der aktualisiert werden soll
 */
  antragsId: string;
  
    /**
     * Aktualisierter Status
     */
    body?: UpdateBuchungserstellAntragDto
}

export function updateOneBuchungserstellAntrag$Json(http: HttpClient, rootUrl: string, params: UpdateOneBuchungserstellAntrag$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<BuchungserstellAntragDto>> {
  const rb = new RequestBuilder(rootUrl, updateOneBuchungserstellAntrag$Json.PATH, 'put');
  if (params) {
    rb.path('antragsId', params.antragsId, {});
    rb.body(params.body, 'application/*+json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'text/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<BuchungserstellAntragDto>;
    })
  );
}

updateOneBuchungserstellAntrag$Json.PATH = '/v1/buchungserstellAntraege/{antragsId}';
