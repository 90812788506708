/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { createSparzeitUmbuchung$Json } from '../fn/sparzeit-umbuchung/create-sparzeit-umbuchung-json';
import { CreateSparzeitUmbuchung$Json$Params } from '../fn/sparzeit-umbuchung/create-sparzeit-umbuchung-json';
import { createSparzeitUmbuchung$Plain } from '../fn/sparzeit-umbuchung/create-sparzeit-umbuchung-plain';
import { CreateSparzeitUmbuchung$Plain$Params } from '../fn/sparzeit-umbuchung/create-sparzeit-umbuchung-plain';
import { getAllSparzeitUmbuchungen$Json } from '../fn/sparzeit-umbuchung/get-all-sparzeit-umbuchungen-json';
import { GetAllSparzeitUmbuchungen$Json$Params } from '../fn/sparzeit-umbuchung/get-all-sparzeit-umbuchungen-json';
import { getAllSparzeitUmbuchungen$Plain } from '../fn/sparzeit-umbuchung/get-all-sparzeit-umbuchungen-plain';
import { GetAllSparzeitUmbuchungen$Plain$Params } from '../fn/sparzeit-umbuchung/get-all-sparzeit-umbuchungen-plain';
import { SparzeitUmbuchungDto } from '../models/sparzeit-umbuchung-dto';

@Injectable({ providedIn: 'root' })
export class SparzeitUmbuchungApiService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getAllSparzeitUmbuchungen()` */
  static readonly GetAllSparzeitUmbuchungenPath = '/v1/sparzeitumbuchung';

  /**
   * Holt alle Sparzeit Umbuchungen.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllSparzeitUmbuchungen$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllSparzeitUmbuchungen$Plain$Response(params?: GetAllSparzeitUmbuchungen$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<SparzeitUmbuchungDto>>> {
    return getAllSparzeitUmbuchungen$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Holt alle Sparzeit Umbuchungen.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllSparzeitUmbuchungen$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllSparzeitUmbuchungen$Plain(params?: GetAllSparzeitUmbuchungen$Plain$Params, context?: HttpContext): Observable<Array<SparzeitUmbuchungDto>> {
    return this.getAllSparzeitUmbuchungen$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<SparzeitUmbuchungDto>>): Array<SparzeitUmbuchungDto> => r.body)
    );
  }

  /**
   * Holt alle Sparzeit Umbuchungen.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllSparzeitUmbuchungen$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllSparzeitUmbuchungen$Json$Response(params?: GetAllSparzeitUmbuchungen$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<SparzeitUmbuchungDto>>> {
    return getAllSparzeitUmbuchungen$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Holt alle Sparzeit Umbuchungen.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllSparzeitUmbuchungen$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllSparzeitUmbuchungen$Json(params?: GetAllSparzeitUmbuchungen$Json$Params, context?: HttpContext): Observable<Array<SparzeitUmbuchungDto>> {
    return this.getAllSparzeitUmbuchungen$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<SparzeitUmbuchungDto>>): Array<SparzeitUmbuchungDto> => r.body)
    );
  }

  /** Path part for operation `createSparzeitUmbuchung()` */
  static readonly CreateSparzeitUmbuchungPath = '/v1/sparzeitumbuchung/create';

  /**
   * Erstellt eine Sparzeit Umbuchung.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createSparzeitUmbuchung$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createSparzeitUmbuchung$Plain$Response(params?: CreateSparzeitUmbuchung$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<SparzeitUmbuchungDto>> {
    return createSparzeitUmbuchung$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Erstellt eine Sparzeit Umbuchung.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createSparzeitUmbuchung$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createSparzeitUmbuchung$Plain(params?: CreateSparzeitUmbuchung$Plain$Params, context?: HttpContext): Observable<SparzeitUmbuchungDto> {
    return this.createSparzeitUmbuchung$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<SparzeitUmbuchungDto>): SparzeitUmbuchungDto => r.body)
    );
  }

  /**
   * Erstellt eine Sparzeit Umbuchung.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createSparzeitUmbuchung$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createSparzeitUmbuchung$Json$Response(params?: CreateSparzeitUmbuchung$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<SparzeitUmbuchungDto>> {
    return createSparzeitUmbuchung$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Erstellt eine Sparzeit Umbuchung.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createSparzeitUmbuchung$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createSparzeitUmbuchung$Json(params?: CreateSparzeitUmbuchung$Json$Params, context?: HttpContext): Observable<SparzeitUmbuchungDto> {
    return this.createSparzeitUmbuchung$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<SparzeitUmbuchungDto>): SparzeitUmbuchungDto => r.body)
    );
  }

}
