/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { createSchnellauswahl$Json } from '../fn/schnellauswahl/create-schnellauswahl-json';
import { CreateSchnellauswahl$Json$Params } from '../fn/schnellauswahl/create-schnellauswahl-json';
import { createSchnellauswahl$Plain } from '../fn/schnellauswahl/create-schnellauswahl-plain';
import { CreateSchnellauswahl$Plain$Params } from '../fn/schnellauswahl/create-schnellauswahl-plain';
import { deleteSchnellauswahl } from '../fn/schnellauswahl/delete-schnellauswahl';
import { DeleteSchnellauswahl$Params } from '../fn/schnellauswahl/delete-schnellauswahl';
import { getAllSchnellauswahlmoeglichkeiten$Json } from '../fn/schnellauswahl/get-all-schnellauswahlmoeglichkeiten-json';
import { GetAllSchnellauswahlmoeglichkeiten$Json$Params } from '../fn/schnellauswahl/get-all-schnellauswahlmoeglichkeiten-json';
import { getAllSchnellauswahlmoeglichkeiten$Plain } from '../fn/schnellauswahl/get-all-schnellauswahlmoeglichkeiten-plain';
import { GetAllSchnellauswahlmoeglichkeiten$Plain$Params } from '../fn/schnellauswahl/get-all-schnellauswahlmoeglichkeiten-plain';
import { getSchnellauswahlById$Json } from '../fn/schnellauswahl/get-schnellauswahl-by-id-json';
import { GetSchnellauswahlById$Json$Params } from '../fn/schnellauswahl/get-schnellauswahl-by-id-json';
import { getSchnellauswahlById$Plain } from '../fn/schnellauswahl/get-schnellauswahl-by-id-plain';
import { GetSchnellauswahlById$Plain$Params } from '../fn/schnellauswahl/get-schnellauswahl-by-id-plain';
import { SchnellauswahlDto } from '../models/schnellauswahl-dto';

@Injectable({ providedIn: 'root' })
export class SchnellauswahlApiService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getAllSchnellauswahlmoeglichkeiten()` */
  static readonly GetAllSchnellauswahlmoeglichkeitenPath = '/v1/schnellauswahl';

  /**
   * Holt alle Schnellauswahlmöglichkeiten.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllSchnellauswahlmoeglichkeiten$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllSchnellauswahlmoeglichkeiten$Plain$Response(params?: GetAllSchnellauswahlmoeglichkeiten$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<SchnellauswahlDto>>> {
    return getAllSchnellauswahlmoeglichkeiten$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Holt alle Schnellauswahlmöglichkeiten.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllSchnellauswahlmoeglichkeiten$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllSchnellauswahlmoeglichkeiten$Plain(params?: GetAllSchnellauswahlmoeglichkeiten$Plain$Params, context?: HttpContext): Observable<Array<SchnellauswahlDto>> {
    return this.getAllSchnellauswahlmoeglichkeiten$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<SchnellauswahlDto>>): Array<SchnellauswahlDto> => r.body)
    );
  }

  /**
   * Holt alle Schnellauswahlmöglichkeiten.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllSchnellauswahlmoeglichkeiten$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllSchnellauswahlmoeglichkeiten$Json$Response(params?: GetAllSchnellauswahlmoeglichkeiten$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<SchnellauswahlDto>>> {
    return getAllSchnellauswahlmoeglichkeiten$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Holt alle Schnellauswahlmöglichkeiten.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllSchnellauswahlmoeglichkeiten$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllSchnellauswahlmoeglichkeiten$Json(params?: GetAllSchnellauswahlmoeglichkeiten$Json$Params, context?: HttpContext): Observable<Array<SchnellauswahlDto>> {
    return this.getAllSchnellauswahlmoeglichkeiten$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<SchnellauswahlDto>>): Array<SchnellauswahlDto> => r.body)
    );
  }

  /** Path part for operation `getSchnellauswahlById()` */
  static readonly GetSchnellauswahlByIdPath = '/v1/schnellauswahl/{schnellauswahlId}';

  /**
   * Sucht ein Schnellauswahl mit der angegeben ID.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSchnellauswahlById$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSchnellauswahlById$Plain$Response(params: GetSchnellauswahlById$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<SchnellauswahlDto>> {
    return getSchnellauswahlById$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Sucht ein Schnellauswahl mit der angegeben ID.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSchnellauswahlById$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSchnellauswahlById$Plain(params: GetSchnellauswahlById$Plain$Params, context?: HttpContext): Observable<SchnellauswahlDto> {
    return this.getSchnellauswahlById$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<SchnellauswahlDto>): SchnellauswahlDto => r.body)
    );
  }

  /**
   * Sucht ein Schnellauswahl mit der angegeben ID.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSchnellauswahlById$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSchnellauswahlById$Json$Response(params: GetSchnellauswahlById$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<SchnellauswahlDto>> {
    return getSchnellauswahlById$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Sucht ein Schnellauswahl mit der angegeben ID.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSchnellauswahlById$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSchnellauswahlById$Json(params: GetSchnellauswahlById$Json$Params, context?: HttpContext): Observable<SchnellauswahlDto> {
    return this.getSchnellauswahlById$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<SchnellauswahlDto>): SchnellauswahlDto => r.body)
    );
  }

  /** Path part for operation `deleteSchnellauswahl()` */
  static readonly DeleteSchnellauswahlPath = '/v1/schnellauswahl/{schnellauswahlId}';

  /**
   * Löscht eine Schnellauswahl.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteSchnellauswahl()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteSchnellauswahl$Response(params: DeleteSchnellauswahl$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteSchnellauswahl(this.http, this.rootUrl, params, context);
  }

  /**
   * Löscht eine Schnellauswahl.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteSchnellauswahl$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteSchnellauswahl(params: DeleteSchnellauswahl$Params, context?: HttpContext): Observable<void> {
    return this.deleteSchnellauswahl$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `createSchnellauswahl()` */
  static readonly CreateSchnellauswahlPath = '/v1/schnellauswahl/create';

  /**
   * Erstellt eine Schnellauswahl.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createSchnellauswahl$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createSchnellauswahl$Plain$Response(params?: CreateSchnellauswahl$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<SchnellauswahlDto>> {
    return createSchnellauswahl$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Erstellt eine Schnellauswahl.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createSchnellauswahl$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createSchnellauswahl$Plain(params?: CreateSchnellauswahl$Plain$Params, context?: HttpContext): Observable<SchnellauswahlDto> {
    return this.createSchnellauswahl$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<SchnellauswahlDto>): SchnellauswahlDto => r.body)
    );
  }

  /**
   * Erstellt eine Schnellauswahl.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createSchnellauswahl$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createSchnellauswahl$Json$Response(params?: CreateSchnellauswahl$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<SchnellauswahlDto>> {
    return createSchnellauswahl$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Erstellt eine Schnellauswahl.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createSchnellauswahl$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createSchnellauswahl$Json(params?: CreateSchnellauswahl$Json$Params, context?: HttpContext): Observable<SchnellauswahlDto> {
    return this.createSchnellauswahl$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<SchnellauswahlDto>): SchnellauswahlDto => r.body)
    );
  }

}
