import { CommonModule } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  inject,
  OnInit,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { DatenlotseCommonModule } from '@datenlotse/common';
import { DlSpinner } from '@datenlotse/components/spinner';
import { ToastController } from '@ionic/angular';
import { PushPipe } from '@ngrx/component';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { first, tap } from 'rxjs';
import { withLatestFrom } from 'rxjs/operators';
import { handleHttpError } from '../../common/httpErrorHandler';
import { MonatsauswertungLayoutDto } from '../api/models/monatsauswertung-layout-dto';
import { PageHeaderComponent } from '../page-header/page-header.component';
import { AuswertungLayoutsActions } from '../store/auswertung-layouts/auswertung-layouts.actions';
import * as fromMonatsauswertungenLayouts from '../store/auswertung-layouts/auswertung-layouts.selectors';
import { MonatsauswertungLayoutsPageComponentStore } from './monatsauswertung-layouts-page.store';
import {
  EditorOutput,
  MonatsauswertungenLayoutEditorComponent,
} from './monatsauswertungen-layout-editor/monatsauswertungen-layout-editor.component';
import { MonatsauswertungenLayoutsListComponent } from './monatsauswertungen-layouts-list/monatsauswertungen-layouts-list.component';

@Component({
  selector: 'datenlotse-monatsauswertung-layouts-page',
  standalone: true,
  imports: [
    CommonModule,
    MonatsauswertungenLayoutsListComponent,
    DatenlotseCommonModule,
    DlSpinner,
    MonatsauswertungenLayoutEditorComponent,
    PushPipe,
    PageHeaderComponent,
  ],
  templateUrl: './monatsauswertung-layouts-page.component.html',
  styleUrls: ['./monatsauswertung-layouts-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [MonatsauswertungLayoutsPageComponentStore],
})
export class MonatsauswertungLayoutsPage implements OnInit {
  private readonly store = inject(Store);
  private readonly _actions$ = inject(Actions);
  private readonly _destroyRef = inject(DestroyRef);
  private readonly _toastService = inject(ToastController);
  private readonly _router = inject(Router);
  readonly componentStore = inject(MonatsauswertungLayoutsPageComponentStore);

  public readonly monatsauswertungenLayouts$ = this.store.select(
    fromMonatsauswertungenLayouts.selectMonatsauswertungLayouts,
  );

  ngOnInit(): void {
    this.store.dispatch(
      AuswertungLayoutsActions.loadMonatsauswertungLayoutsUndSpalten(),
    );

    this._actions$
      .pipe(
        takeUntilDestroyed(this._destroyRef),
        ofType(AuswertungLayoutsActions.loadMonatsauswertungLayoutsFailure),
        tap(async () => await this.onLoadingFailure()),
      )
      .subscribe();

    this._actions$
      .pipe(
        takeUntilDestroyed(this._destroyRef),
        ofType(AuswertungLayoutsActions.updateMonatsauswertungLayoutFailure),
        tap(async ({ error }) => {
          await this.onFailure(
            error,
            'Beim Aktualisieren des Layouts ist ein Fehler aufgetreten',
          );
        }),
      )
      .subscribe();

    this._actions$
      .pipe(
        takeUntilDestroyed(this._destroyRef),
        ofType(AuswertungLayoutsActions.createMonatsauswertungLayoutFailure),
        tap(async ({ error }) => {
          await this.onFailure(
            error,
            'Beim Erstellen des Layouts ist ein Fehler aufgetreten',
          );
        }),
      )
      .subscribe();

    this._actions$
      .pipe(
        takeUntilDestroyed(this._destroyRef),
        ofType(AuswertungLayoutsActions.createMonatsauswertungLayoutSuccess),
        tap(async () => {
          await this.onSuccess('Das Layout wurde erfolgreich erstellt');
        }),
        tap(async ({ monatsauswertungLayout }) => {
          await this._router.navigate([
            '/monatsauswertungen',
            'layouts',
            monatsauswertungLayout.id,
          ]);
        }),
      )
      .subscribe();

    this._actions$
      .pipe(
        takeUntilDestroyed(this._destroyRef),
        ofType(AuswertungLayoutsActions.updateMonatsauswertungLayoutSuccess),
        tap(async () => {
          await this.onSuccess('Das Layout wurde erfolgreich aktualisiert');
        }),
      )
      .subscribe();
  }

  onCreate() {
    this._router.navigate(['/layouts', 'create']);
  }

  onSubmit($event: EditorOutput) {
    this.componentStore.selectMode$
      .pipe(
        first(),
        withLatestFrom(this.componentStore.selectedMonatsauswertungLayoutId$),
        tap(([mode, id]) => {
          if (mode === 'create') {
            this.onCreateLayout($event);
          } else {
            if (!id) {
              throw new Error('No id selected');
            }
            this.onUpdateLayout($event, id);
          }
        }),
      )
      .subscribe();
  }

  onSelectLayout(layout: MonatsauswertungLayoutDto) {
    this.componentStore.setSelectMonatsauswertungLayoutId(layout.id);
  }

  private onCreateLayout($event: EditorOutput) {
    this.store.dispatch(
      AuswertungLayoutsActions.createMonatsauswertungLayout({
        monatsauswertungLayout: {
          name: $event.name,
          kuerzel: $event.kuerzel,
          spalten: $event.spalten.map((x) => ({
            spalteId: x,
          })),
        },
      }),
    );
  }

  private onUpdateLayout($event: EditorOutput, id: string) {
    this.store.dispatch(
      AuswertungLayoutsActions.updateMonatsauswertungLayout({
        id,
        monatsauswertungLayout: {
          name: $event.name,
          kuerzel: $event.kuerzel,
          spalten: $event.spalten.map((x) => ({
            spalteId: x,
          })),
        },
      }),
    );
  }

  private async onLoadingFailure() {
    const toast = await this._toastService.create({
      color: 'danger',
      message: 'Fehler beim Laden der Monatsauswertungen',
      duration: 3000,
    });

    await toast.present();
  }

  private async onSuccess(message: string) {
    const toast = await this._toastService.create({
      color: 'success',
      message,
      duration: 3000,
    });

    await toast.present();
  }

  private async onFailure(error: unknown, message: string) {
    if (error instanceof HttpErrorResponse) {
      await handleHttpError(error, this._toastService, message);
    } else {
      const toast = await this._toastService.create({
        message,
        color: 'danger',
        duration: 5000,
      });
      await toast.present();
    }
  }
}
