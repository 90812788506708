import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { map, of } from 'rxjs';
import {
  catchError,
  concatMap,
  debounceTime,
  filter,
  switchMap,
  tap,
} from 'rxjs/operators';
import { handleHttpError } from '../../../common/httpErrorHandler';
import { SparzeitUmbuchungApiService } from '../../api/services';
import { PAGINATION_HEADER_NAME } from '../../pagination-header';
import * as fromAuth from '../auth/auth.selectors';
import { MitarbeiterActions } from '../mitarbeiter/mitarbeiter.actions';
import * as fromMitarbeiter from '../mitarbeiter/mitarbeiter.selectors';
import { SparzeitUmbuchungenActions } from './sparzeitumbuchungen.actions';
import * as fromSparzeitUmbuchungen from './sparzeitumbuchungen.selectors';

@Injectable()
export class SparzeitUmbuchungenEffects {
  loadSparzeitUmbuchungens$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        SparzeitUmbuchungenActions.loadSparzeitumbuchungen,
        SparzeitUmbuchungenActions.setPageIndex,
        SparzeitUmbuchungenActions.setPageSize,
        MitarbeiterActions.setSelectedMitarbeiterId,
        SparzeitUmbuchungenActions.setSelectedMonthAndYear,
      ),
      concatLatestFrom(() => [
        this.store.select(fromMitarbeiter.selectSelectedMitarbeiterId),
        this.store.select(fromSparzeitUmbuchungen.selectSelectedMonth),
        this.store.select(fromSparzeitUmbuchungen.selectSelectedYear),
        this.store.select(fromAuth.selectHasScope("pageAccess:umbuchungen"))
      ]),
      debounceTime(50),
      filter(([, , , , canAccessUmbuchungen]) => canAccessUmbuchungen),
      filter(([, selectedMitarbeiterId]) => !!selectedMitarbeiterId), // Filter out if no mitarbeiter is selected
      switchMap(([, selectedMitarbeiterId, selectedMonth, selectedYear]) =>
        this._sparzeitUmbuchungApi
          .getAllSparzeitUmbuchungen$Json$Response({
            date: `${selectedYear}-${selectedMonth}-01`,
            mitarbeiterId: selectedMitarbeiterId,
          })
          .pipe(
            map((response) => {
              return SparzeitUmbuchungenActions.loadSparzeitumbuchungenSuccess({
                data: response.body,
                totalCount: response.headers.get(PAGINATION_HEADER_NAME)
                  ? JSON.parse(
                      response.headers.get(PAGINATION_HEADER_NAME) as string,
                    ).TotalCount
                  : response.body.length,
              });
            }),
            catchError((error) =>
              of(
                SparzeitUmbuchungenActions.loadSparzeitumbuchungenFailure({
                  error,
                }),
              ),
            ),
          ),
      ),
    );
  });

  createSparzeitUmbuchung$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SparzeitUmbuchungenActions.createSparzeitumbuchung),
      concatLatestFrom(() => [
        this.store.select(fromMitarbeiter.selectSelectedMitarbeiterId),
        this.store.select(fromSparzeitUmbuchungen.selectSelectedYear),
        this.store.select(fromSparzeitUmbuchungen.selectSelectedMonth),
      ]),
      filter(([, mitarbeiterId]) => mitarbeiterId !== ''),
      concatMap(([{ umbuchungInSekunden }, mitarbeiterId, jahr, monat]) =>
        this._sparzeitUmbuchungApi
          .createSparzeitUmbuchung$Json({
            body: {
              jahr: jahr,
              monat: monat,
              mitarbeiterId: mitarbeiterId,
              umbuchungInSekunden: umbuchungInSekunden,
            },
          })
          .pipe(
            map((res) =>
              SparzeitUmbuchungenActions.createSparzeitumbuchungSuccess({
                data: res,
              }),
            ),
            catchError((err) =>
              of(
                SparzeitUmbuchungenActions.createSparzeitumbuchungFailure({
                  error: err,
                }),
              ),
            ),
          ),
      ),
    );
  });

  // Toast Handling
  createSparzeitUmbuchungFailure$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(SparzeitUmbuchungenActions.createSparzeitumbuchungFailure),
        tap(async ({ error }) => {
          console.error(error);
          await handleHttpError(
            error,
            this.toastController,
            'Beim Erstellen der Sparzeitumbuchung ist ein Fehler aufgetreten.',
          );
        }),
      );
    },
    { dispatch: false },
  );

  createSparzeitUmbuchungSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(SparzeitUmbuchungenActions.createSparzeitumbuchungSuccess),
        tap(async () => {
          const toast = await this.toastController.create({
            message: 'Sparzeit Umbuchung erfolgreich erstellt.',
            duration: 3000,
            color: 'success',
          });
          await toast.present();
        }),
      );
    },
    { dispatch: false },
  );

  constructor(
    private actions$: Actions,
    private _sparzeitUmbuchungApi: SparzeitUmbuchungApiService,
    private readonly toastController: ToastController,
    private store: Store,
  ) {}
}
