import { createFeatureSelector, createSelector } from '@ngrx/store';
import { MehrarbeitUmbuchungDto } from '../../api/models';
import { selectSelectedMitarbeiterId } from '../mitarbeiter/mitarbeiter.selectors';
import * as fromMehrarbeitUmbuchungen from './mehrarbeitumbuchungen.reducer';

export const selectMehrarbeitUmbuchungenState =
  createFeatureSelector<fromMehrarbeitUmbuchungen.State>(
    fromMehrarbeitUmbuchungen.mehrarbeitUmbuchungenFeatureKey,
  );

export const selectMehrarbeitUmbuchungen = createSelector(
  selectMehrarbeitUmbuchungenState,
  (state) => state.mehrarbeitUmbuchungen,
);

export const selectMehrarbeitUmbuchungenArray = createSelector(
  selectMehrarbeitUmbuchungen,
  (state) => {
    const mehrarbeitUmbuchungenArray = Object.values(state);
    return mehrarbeitUmbuchungenArray.filter(
      (
        MehrarbeitUmbuchungen,
      ): MehrarbeitUmbuchungen is MehrarbeitUmbuchungDto =>
        !!MehrarbeitUmbuchungen,
    );
  },
);

export const selectTotalCount = createSelector(
  selectMehrarbeitUmbuchungenState,
  (state) => state.totalCount ?? 0,
);

export const selectMehrarbeitUmbuchungenPage = createSelector(
  selectMehrarbeitUmbuchungenState,
  (state) => {
    if (!state.mehrarbeitUmbuchungenPageData) {
      return undefined;
    }
    return state.mehrarbeitUmbuchungenPageData
      .map((id) => state.mehrarbeitUmbuchungen[id] as MehrarbeitUmbuchungDto)
      .filter((mau) => !!mau);
  },
);

export type MehrarbeitUmbuchungenDtoGroupedByDate = {
  mehrarbeitUmbuchungen: MehrarbeitUmbuchungDto[];
  monat: number;
  jahr: number;
};

export type MehrarbeitUmbuchungenDtoGroupedByDateMap = {
  [jahrMonat: string]: MehrarbeitUmbuchungenDtoGroupedByDate;
};

export const selectMehrarbeitUmbuchungenGroupedByDatum = createSelector(
  selectMehrarbeitUmbuchungenPage,
  (mehrarbeitUmbuchungen) => {
    if (!mehrarbeitUmbuchungen) {
      return undefined;
    }
    return mehrarbeitUmbuchungen.reduce((acc, mehrarbeitUmbuchung) => {
      const key = mehrarbeitUmbuchung.jahr + mehrarbeitUmbuchung.monat;
      if (!acc[key]) {
        acc[key] = {
          mehrarbeitUmbuchungen: [mehrarbeitUmbuchung],
          monat: mehrarbeitUmbuchung.monat,
          jahr: mehrarbeitUmbuchung.jahr,
        };
      } else {
        acc[key].mehrarbeitUmbuchungen.push(mehrarbeitUmbuchung);
      }
      return acc;
    }, {} as MehrarbeitUmbuchungenDtoGroupedByDateMap);
  },
);

export const selectMehrarbeitUmbuchungenDesMonats = (
  monat: number,
  jahr: number,
) =>
  createSelector(selectMehrarbeitUmbuchungenPage, (mehrarbeitUmbuchungen) => {
    if (!mehrarbeitUmbuchungen) {
      return undefined;
    }
    // Filtern der Buchungen des übergebenen Monats und Jahres
    const mehrarbeitUmbuchungenDesMonats = mehrarbeitUmbuchungen.filter(
      (mehrarbeitUmbuchung) => {
        return (
          mehrarbeitUmbuchung.monat === monat &&
          mehrarbeitUmbuchung.jahr === jahr
        );
      },
    );

    mehrarbeitUmbuchungenDesMonats.sort(
      (a, b) =>
        new Date(a.datum).getTime() - new Date(b.datum).getTime(),
    );
    return mehrarbeitUmbuchungenDesMonats;
  });

export const selectPage = createSelector(
  selectMehrarbeitUmbuchungenState,
  (state) => state.page,
);

export const selectPageSize = createSelector(
  selectMehrarbeitUmbuchungenState,
  (state) => state.pageSize,
);

export const selectLoading = createSelector(
  selectMehrarbeitUmbuchungenState,
  selectSelectedMitarbeiterId,
  (state, mitarbeiterId) => mitarbeiterId && state.loading,
);

export const selectSelectedMonth = createSelector(
  selectMehrarbeitUmbuchungenState,
  (state) => state.selectedMonth ?? new Date().getMonth() + 1,
);

export const selectSelectedYear = createSelector(
  selectMehrarbeitUmbuchungenState,
  (state) => state.selectedYear ?? new Date().getFullYear(),
);

export const selectSelectedYearMonth = createSelector(
  selectMehrarbeitUmbuchungenState,
  (state) =>
    [state.selectedYear, state.selectedMonth] ?? new Date().getFullYear(),
);
