import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DlButtonComponent } from './button.component';
import { DlPrimaryButtonDirective } from './directives/styling/primary-button.directive';
import { DlLightButtonDirective } from './directives/styling/light-button.directive';
import { DlDarkButtonDirective } from './directives/styling/dark-button.directive';
import { DlDangerButtonDirective } from './directives/styling/danger-button.directive';
import { DlWarnButtonDirective } from './directives/styling/warn-button.directive';
import { DlYellowButtonDirective } from './directives/styling/yellow-button.directive';

@NgModule({
  declarations: [
    DlButtonComponent,
    DlPrimaryButtonDirective,
    DlLightButtonDirective,
    DlDarkButtonDirective,
    DlDangerButtonDirective,
    DlWarnButtonDirective,
    DlYellowButtonDirective,
  ],
  imports: [CommonModule],
  exports: [
    DlButtonComponent,
    DlPrimaryButtonDirective,
    DlLightButtonDirective,
    DlDarkButtonDirective,
    DlDangerButtonDirective,
    DlWarnButtonDirective,
    DlYellowButtonDirective,
  ],
})
export class DlButtonModule {}
