import { createActionGroup, emptyProps, props } from '@ngrx/store';
import {
  ZugangDto
} from '../../api/models';

export const ZugaengeActions = createActionGroup({
  source: 'Zugaenge',
  events: {
    "Load Zugaenge": emptyProps(),
    "Load Zugaenge Success": props<{zugaenge: ZugangDto[]}>(),
    "Load Zugaenge Failure": props<{error: unknown}>()
  },
});
