import { Component, Input } from '@angular/core';
import { QueryParamsHandling } from '@angular/router';

@Component({
  selector: 'dl-sidebar-button',
  templateUrl: './sidebar-button.component.html',
  styleUrls: ['./sidebar-button.component.css'],
})
export class DlSidebarButtonComponent {
  @Input() routerLink: string | null = null;
  @Input() queryParamsHandling: QueryParamsHandling | null = null;
  @Input() active = false;
  @Input() matchRouterLinkExact = true;
  @Input() markYellow = false;

  get linkEnabled(): boolean {
    return this.routerLink !== null;
  }
}
