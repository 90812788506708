import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { UrlaubsantragDto } from '../../api/models/urlaubsantrag-dto';
import { CreateUrlaubsantragDto } from '../../api/models/create-urlaubsantrag-dto';

export const UrlaubsantraegeActions = createActionGroup({
  source: 'Urlaubsantraege',
  events: {
    'Load All Urlaubsantraege': emptyProps(),
    'Load All Urlaubsantraege Success': props<{
      urlaubsantraege: UrlaubsantragDto[];
      totalCount: number;
    }>(),
    'Load All Urlaubsantraege Failure': props<{
      error: unknown;
    }>(),
    'Load Unit Urlaubsantraege': emptyProps(),
    'Load Unit Urlaubsantraege Success': props<{
      urlaubsantraege: UrlaubsantragDto[];
      totalCount: number;
    }>(),
    'Load Unit Urlaubsantraege Failure': props<{
      error: unknown;
    }>(),
    'Load User Urlaubsantraege': emptyProps(),
    'Load User Urlaubsantraege Success': props<{
      urlaubsantraege: UrlaubsantragDto[];
      totalCount: number;
    }>(),
    'Load User Urlaubsantraege Failure': props<{
      error: unknown;
    }>(),
    'Set Pagination Data': props<{
      size?: number;
      index?: number;
    }>(),
    'Set Unit Pagination Data': props<{
      size?: number;
      index?: number;
    }>(),
    'Set Mitarbeiter Pagination Data': props<{
      size?: number;
      index?: number;
    }>(),
    'Set Include Processed': props<{
      includeProcessed: boolean;
    }>(),
    'Create Urlaubsantrag': props<{
      urlaubsantrag: CreateUrlaubsantragDto;
    }>(),
    'Create Urlaubsantrag Success': props<{
      urlaubsantrag: UrlaubsantragDto;
    }>(),
    'Create Urlaubsantrag Failure': props<{
      error: unknown;
    }>(),
    'Process Urlaubsantrag': props<{
      id: string;
      genehmigt: boolean;
    }>(),
    'Process Urlaubsantrag Success': props<{
      urlaubsantrag: UrlaubsantragDto;
    }>(),
    'Process Urlaubsantrag Failure': props<{
      error: unknown;
    }>(),
  },
});
