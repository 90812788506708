import { Sort } from "@datenlotse/components/sort";
import { createActionGroup, emptyProps, props } from "@ngrx/store";
import { FehlerlistenEintrag } from "../../api/models";

export const FehlerlisteActions = createActionGroup({
    source: 'Fehlerliste',
    events: {
        'Load Fehlerlisten': emptyProps(),
        'Load Fehlerlisten Success': props<{
        data: Array<FehlerlistenEintrag>;
        totalCount: number;
        }>(),
        'Load Fehlerlisten Failure': props<{ error: unknown }>(),
        'Load Next Page': emptyProps(),
        'Load Previous Page': emptyProps(),
        'Set Fehlerlisten Sort Filter PageNumber PageSize': props<{
          sort?: Sort;
          filter?: string;
          pageNumber?: number;
          pageSize?: number;
        }>(),
        'Set Sort': props<{ sort: Sort }>(),
        'Set Filter': props<{ filter: string }>(),
    }
})