import { createFeatureSelector, createSelector } from '@ngrx/store';
import { FehlerlistenEintrag } from '../../api/models';
import * as fromFehlerlisten from './fehlerliste.reducer';

export const selectFehlerlistenState =
  createFeatureSelector<fromFehlerlisten.State>(
    fromFehlerlisten.fehlerlistenFeatureKey,
  );

export const selectFehlerliste = createSelector(
  selectFehlerlistenState,
  (state) => state.fehlerliste,
);

export const selectFehlerlistenPage = createSelector(
  selectFehlerlistenState,
  (state) => {
    if (!state.fehlerlistenPage) {
      return undefined;
    }
    return state.fehlerlistenPage
      .map((id) => state.fehlerliste.get(id) as FehlerlistenEintrag)
      .filter((fehlerliste) => !!fehlerliste);
  },
);

export const selectPage = createSelector(
  selectFehlerlistenState,
  (state) => state.page,
);

export const selectPageSize = createSelector(
  selectFehlerlistenState,
  (state) => state.pageSize,
);

export const selectTotalCount = createSelector(
  selectFehlerlistenState,
  (state) => state.totalCount,
);

export const selectSort = createSelector(
    selectFehlerlistenState,
    (state) => state.sort,
  );
  
  export const selectFilter = createSelector(
    selectFehlerlistenState,
    (state) => state.filter,
  );

export const selectMitarbeiterPaginationData = createSelector(
    selectPage,
    selectPageSize,
    selectSort,
    selectFilter,
    (index, size, sort, filter) => {
      return {
        index,
        size,
        sort,
        filter,
      };
    },
  );