/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { ArbeitszeitkontoDto } from '../models/arbeitszeitkonto-dto';
import { getAggregiertesArbeitszeitkontoForMitarbeiter$Json } from '../fn/arbeitszeitkonto/get-aggregiertes-arbeitszeitkonto-for-mitarbeiter-json';
import { GetAggregiertesArbeitszeitkontoForMitarbeiter$Json$Params } from '../fn/arbeitszeitkonto/get-aggregiertes-arbeitszeitkonto-for-mitarbeiter-json';
import { getAggregiertesArbeitszeitkontoForMitarbeiter$Plain } from '../fn/arbeitszeitkonto/get-aggregiertes-arbeitszeitkonto-for-mitarbeiter-plain';
import { GetAggregiertesArbeitszeitkontoForMitarbeiter$Plain$Params } from '../fn/arbeitszeitkonto/get-aggregiertes-arbeitszeitkonto-for-mitarbeiter-plain';
import { getArbeitszeitkontoForMitarbeiter$Json } from '../fn/arbeitszeitkonto/get-arbeitszeitkonto-for-mitarbeiter-json';
import { GetArbeitszeitkontoForMitarbeiter$Json$Params } from '../fn/arbeitszeitkonto/get-arbeitszeitkonto-for-mitarbeiter-json';
import { getArbeitszeitkontoForMitarbeiter$Plain } from '../fn/arbeitszeitkonto/get-arbeitszeitkonto-for-mitarbeiter-plain';
import { GetArbeitszeitkontoForMitarbeiter$Plain$Params } from '../fn/arbeitszeitkonto/get-arbeitszeitkonto-for-mitarbeiter-plain';
import { getArbeitszeitkontosForMitarbeiter$Json } from '../fn/arbeitszeitkonto/get-arbeitszeitkontos-for-mitarbeiter-json';
import { GetArbeitszeitkontosForMitarbeiter$Json$Params } from '../fn/arbeitszeitkonto/get-arbeitszeitkontos-for-mitarbeiter-json';
import { getArbeitszeitkontosForMitarbeiter$Plain } from '../fn/arbeitszeitkonto/get-arbeitszeitkontos-for-mitarbeiter-plain';
import { GetArbeitszeitkontosForMitarbeiter$Plain$Params } from '../fn/arbeitszeitkonto/get-arbeitszeitkontos-for-mitarbeiter-plain';

@Injectable({ providedIn: 'root' })
export class ArbeitszeitkontoApiService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getArbeitszeitkontoForMitarbeiter()` */
  static readonly GetArbeitszeitkontoForMitarbeiterPath = '/v1/mitarbeiter/{mitarbeiterId}/arbeitszeitkonten/{monat}';

  /**
   * Holt das Arbeitszeitkonto für einen Mitarbeiter und einen Monat.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getArbeitszeitkontoForMitarbeiter$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getArbeitszeitkontoForMitarbeiter$Plain$Response(params: GetArbeitszeitkontoForMitarbeiter$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ArbeitszeitkontoDto>> {
    return getArbeitszeitkontoForMitarbeiter$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Holt das Arbeitszeitkonto für einen Mitarbeiter und einen Monat.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getArbeitszeitkontoForMitarbeiter$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getArbeitszeitkontoForMitarbeiter$Plain(params: GetArbeitszeitkontoForMitarbeiter$Plain$Params, context?: HttpContext): Observable<ArbeitszeitkontoDto> {
    return this.getArbeitszeitkontoForMitarbeiter$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ArbeitszeitkontoDto>): ArbeitszeitkontoDto => r.body)
    );
  }

  /**
   * Holt das Arbeitszeitkonto für einen Mitarbeiter und einen Monat.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getArbeitszeitkontoForMitarbeiter$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getArbeitszeitkontoForMitarbeiter$Json$Response(params: GetArbeitszeitkontoForMitarbeiter$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ArbeitszeitkontoDto>> {
    return getArbeitszeitkontoForMitarbeiter$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Holt das Arbeitszeitkonto für einen Mitarbeiter und einen Monat.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getArbeitszeitkontoForMitarbeiter$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getArbeitszeitkontoForMitarbeiter$Json(params: GetArbeitszeitkontoForMitarbeiter$Json$Params, context?: HttpContext): Observable<ArbeitszeitkontoDto> {
    return this.getArbeitszeitkontoForMitarbeiter$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ArbeitszeitkontoDto>): ArbeitszeitkontoDto => r.body)
    );
  }

  /** Path part for operation `getArbeitszeitkontosForMitarbeiter()` */
  static readonly GetArbeitszeitkontosForMitarbeiterPath = '/v1/mitarbeiter/{mitarbeiterId}/arbeitszeitkonten';

  /**
   * Holt alle Arbeitszeitkonten für einen Mitarbeiter in einem Zeitraum.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getArbeitszeitkontosForMitarbeiter$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getArbeitszeitkontosForMitarbeiter$Plain$Response(params: GetArbeitszeitkontosForMitarbeiter$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ArbeitszeitkontoDto>>> {
    return getArbeitszeitkontosForMitarbeiter$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Holt alle Arbeitszeitkonten für einen Mitarbeiter in einem Zeitraum.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getArbeitszeitkontosForMitarbeiter$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getArbeitszeitkontosForMitarbeiter$Plain(params: GetArbeitszeitkontosForMitarbeiter$Plain$Params, context?: HttpContext): Observable<Array<ArbeitszeitkontoDto>> {
    return this.getArbeitszeitkontosForMitarbeiter$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ArbeitszeitkontoDto>>): Array<ArbeitszeitkontoDto> => r.body)
    );
  }

  /**
   * Holt alle Arbeitszeitkonten für einen Mitarbeiter in einem Zeitraum.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getArbeitszeitkontosForMitarbeiter$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getArbeitszeitkontosForMitarbeiter$Json$Response(params: GetArbeitszeitkontosForMitarbeiter$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ArbeitszeitkontoDto>>> {
    return getArbeitszeitkontosForMitarbeiter$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Holt alle Arbeitszeitkonten für einen Mitarbeiter in einem Zeitraum.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getArbeitszeitkontosForMitarbeiter$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getArbeitszeitkontosForMitarbeiter$Json(params: GetArbeitszeitkontosForMitarbeiter$Json$Params, context?: HttpContext): Observable<Array<ArbeitszeitkontoDto>> {
    return this.getArbeitszeitkontosForMitarbeiter$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ArbeitszeitkontoDto>>): Array<ArbeitszeitkontoDto> => r.body)
    );
  }

  /** Path part for operation `getAggregiertesArbeitszeitkontoForMitarbeiter()` */
  static readonly GetAggregiertesArbeitszeitkontoForMitarbeiterPath = '/v1/mitarbeiter/{mitarbeiterId}/arbeitszeitkonten/{monat}/aggregiert';

  /**
   * Holt alle Arbeitszeitkonten für einen Mitarbeiter bis zu einem Monat und aggregiert diese.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAggregiertesArbeitszeitkontoForMitarbeiter$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAggregiertesArbeitszeitkontoForMitarbeiter$Plain$Response(params: GetAggregiertesArbeitszeitkontoForMitarbeiter$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ArbeitszeitkontoDto>> {
    return getAggregiertesArbeitszeitkontoForMitarbeiter$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Holt alle Arbeitszeitkonten für einen Mitarbeiter bis zu einem Monat und aggregiert diese.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAggregiertesArbeitszeitkontoForMitarbeiter$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAggregiertesArbeitszeitkontoForMitarbeiter$Plain(params: GetAggregiertesArbeitszeitkontoForMitarbeiter$Plain$Params, context?: HttpContext): Observable<ArbeitszeitkontoDto> {
    return this.getAggregiertesArbeitszeitkontoForMitarbeiter$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ArbeitszeitkontoDto>): ArbeitszeitkontoDto => r.body)
    );
  }

  /**
   * Holt alle Arbeitszeitkonten für einen Mitarbeiter bis zu einem Monat und aggregiert diese.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAggregiertesArbeitszeitkontoForMitarbeiter$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAggregiertesArbeitszeitkontoForMitarbeiter$Json$Response(params: GetAggregiertesArbeitszeitkontoForMitarbeiter$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ArbeitszeitkontoDto>> {
    return getAggregiertesArbeitszeitkontoForMitarbeiter$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Holt alle Arbeitszeitkonten für einen Mitarbeiter bis zu einem Monat und aggregiert diese.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAggregiertesArbeitszeitkontoForMitarbeiter$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAggregiertesArbeitszeitkontoForMitarbeiter$Json(params: GetAggregiertesArbeitszeitkontoForMitarbeiter$Json$Params, context?: HttpContext): Observable<ArbeitszeitkontoDto> {
    return this.getAggregiertesArbeitszeitkontoForMitarbeiter$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ArbeitszeitkontoDto>): ArbeitszeitkontoDto => r.body)
    );
  }

}
