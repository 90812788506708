import { Directive, ElementRef } from '@angular/core';
import { DlButtonStylingDirective } from './button-styling.class';

@Directive({
  selector: '[dlLightButton]',
})
export class DlLightButtonDirective extends DlButtonStylingDirective {
  override primaryColor = '#969AB1';
  override fontColor = '#FFFFFF';
  override hoverColor = '#797e9b';

  constructor(elr: ElementRef) {
    super(elr);
  }
}
