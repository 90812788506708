import { createFeature, createReducer, on } from '@ngrx/store';
import { isAfter, isBefore } from 'date-fns';
import { produce } from 'immer';
import { KalendereintragDto } from '../../api/models';
import * as ZuordnungenActions from './zuordnungen.actions';

export const zuordnungenFeatureKey = 'zuordnungen';

export interface State {
  selectedYear: number;
  zuordnungen: KalendereintragDto[];
  loading: boolean;
}

export const initialState: State = {
  selectedYear: new Date().getFullYear(),
  zuordnungen: [],
  loading: false,
};

function removeZuordnung(zuordnungen: State['zuordnungen'], id: string) {
  const index = zuordnungen.findIndex((v) => v.id === id);
  if (index >= 0) {
    zuordnungen.splice(index, 1);
  }
}

export const reducer = createReducer(
  initialState,
  on(
    ZuordnungenActions.loadZuordnungenSuccess,
    (state, { zuordnungen }): State => ({
      ...state,
      zuordnungen,
      loading: false,
    })
  ),
  on(
    ZuordnungenActions.loadZuordnungenFailure,
    (state): State => ({
      ...state,
      zuordnungen: [],
      loading: false,
    })
  ),
  on(
    ZuordnungenActions.increaseYear,
    (state): State => ({
      ...state,
      selectedYear: state.selectedYear + 1,
      loading: true,
    })
  ),
  on(
    ZuordnungenActions.decreaseYear,
    (state): State => ({
      ...state,
      selectedYear: state.selectedYear - 1,
      loading: true,
    })
  ),
  on(ZuordnungenActions.resetYear, (state) => ({
    ...state,
    selectedYear: new Date().getFullYear(),
    loading: true,
  })),
  on(ZuordnungenActions.createTagesZuordnungSuccess, (state, { zuordnung }) =>
    produce(state, (draft) => {
      const { zuordnungen } = draft;
      upsertZuordnung(zuordnungen, zuordnung);
      draft.loading = false;
    })
  ),
  on(ZuordnungenActions.createTagesZuordnungFuerZeitraumSuccess, (state, { zuordnungen }) =>
    produce(state, (draft) => {
      const { zuordnungen: existing } = draft;
      zuordnungen.forEach((z) => {
        upsertZuordnung(existing, z);
      });
      draft.loading = false;
    })
  ),
  on(
    ZuordnungenActions.createWochenplanZuordnungSuccess,
    (state, { zuordnung }) =>
      produce(state, (draft) => {
        const { zuordnungen } = draft;
        zuordnung.forEach((z) => {
          upsertZuordnung(zuordnungen, z);
        });
        draft.loading = false;
      })
  ),
  on(ZuordnungenActions.deleteZuordnungSuccess, (state, { id }) =>
    produce(state, (draft) => {
      const { zuordnungen } = draft;
      removeZuordnung(zuordnungen, id);
      draft.loading = false;
    })
  ),
  on(
    ZuordnungenActions.deleteMultipleZuordnungenSuccess,
    (state, { from, to, mitarbeiterId }) =>
      produce(state, (draft) => {
        const { zuordnungen } = draft;
        const mitarbeiterZuordnungen = zuordnungen.filter(
          (z) => z.mitarbeiterId === mitarbeiterId
        );
        const zuordnungenToDelete = mitarbeiterZuordnungen.filter((z) => {
          const date = new Date(z.datum);
          date.setHours(0, 0, 0, 0);
          from.setHours(0, 0, 0, 0);
          to.setHours(0, 0, 0, 0);

          return isAfter(date, from) && isBefore(date, to);
        });

        zuordnungenToDelete.forEach((z) => {
          removeZuordnung(zuordnungen, z.id);
        });
        draft.loading = false;
      })
  ),
  on(
    ZuordnungenActions.deleteZuordnung,
    ZuordnungenActions.deleteMultipleZuordnungen,
    ZuordnungenActions.createWochenplanZuordnungFailure,
    ZuordnungenActions.createWochenplanZuordnung,
    ZuordnungenActions.createTagesZuordnung,
    ZuordnungenActions.createTagesZuordnungFuerZeitraum,
    ZuordnungenActions.loadZuordnungen,
    (state): State => ({
      ...state,
      loading: true,
    })
  ),
  on(
    ZuordnungenActions.deleteZuordnungFailure,
    ZuordnungenActions.deleteMultipleZuordnungenFailure,
    ZuordnungenActions.createTagesZuordnungFuerZeitraumFailure,
    (state): State => ({
      ...state,
      loading: false,
    })
  ),
  on(ZuordnungenActions.loadZuordnungFuerTagSuccess, (state, { zuordnung }) =>
    zuordnung
      ? produce(state, (draft) => {
          upsertZuordnung(draft.zuordnungen, zuordnung);
        })
      : state
  )
);

function upsertZuordnung(
  existing: KalendereintragDto[],
  zuordnung: KalendereintragDto
) {
  const index = existing.findIndex((v) => v.datum === zuordnung.datum);
  // Upsert Zuordnung
  if (index >= 0) {
    existing[index] = zuordnung;
  } else {
    existing.push(zuordnung);
  }
}

export const zuordnungenFeature = createFeature({
  name: zuordnungenFeatureKey,
  reducer,
});
