import { Component, Input } from '@angular/core';

@Component({
  selector: 'dl-sidebar-button-image',
  templateUrl: './sidebar-button-image.component.html',
  styleUrls: ['./sidebar-button-image.component.css'],
})
export class DlSidebarButtonImageComponent {
  @Input() src = '';
}
